import * as types from "./types";

export const getMarketOrderAction = () => {
  return {
    type: types.constants.GET_FI_MARKET_ORDER,
  };
};

export const createMarketOrderAction = (payload) => {
  return {
    type: types.constants.CREATE_FI_MARKET_ORDER,
    payload,
  };
};

export const updateMarketOrderAction = (payload) => {
  return {
    type: types.constants.UPDATE_FI_MARKET_ORDER,
    payload,
  };
};

export const createRequestQuoteOrderAction = (payload) => {
  return {
    type: types.constants.CREATE_FI_REQUEST_QUOTE_ORDER,
    payload,
  };
};

export const getAllOrderAction = (payload) => {
  return {
    type: types.constants.GET_FI_ALL_ORDER,
    payload,
  };
};

export const getAllOfferAction = (payload) => {
  return {
    type: types.constants.GET_FI_ALL_OFFER,
    payload,
  };
};

export const getOutgoingOrderAction = (payload) => {
  return {
    type: types.constants.GET_FI_OUTGOING_ORDER,
    payload,
  };
};

export const getOutgoingQuotesOrderAction = (payload) => {
  return {
    type: types.constants.GET_FI_OUTGOING_QUOTES_ORDER,
    payload,
  };
};

export const getOutgoingOfferOrderAction = (payload) => {
  return {
    type: types.constants.GET_FI_OUTGOING_OFFER_ORDER,
    payload,
  };
};

export const createOfferOrderAction = (payload) => {
  return {
    type: types.constants.CREATE_FI_OFFER_ORDER,
    payload,
  };
};

export const updateOfferOrderAction = (payload) => {
  return {
    type: types.constants.UPDATE_FI_OFFER_ORDER,
    payload,
  };
};

export const getSystemUserAction = () => {
  return {
    type: types.constants.GET_SYSTEM_USER,
  };
};

export const getIncomingQuotesOrderAction = (payload) => {
  return {
    type: types.constants.GET_FI_INCOMING_QUOTES_ORDER,
    payload,
  };
};

export const getIncomingOfferOrderAction = (payload) => {
  return {
    type: types.constants.GET_FI_INCOMING_OFFER_ORDER,
    payload,
  };
};

export const orderByUserAction = (payload) => {
  return {
    type: types.constants.GET_FI_ORDER_BY_USER,
    payload,
  };
};

export const createTradeAction = (payload) => {
  return {
    type: types.constants.CREATE_FI_TREDE,
    payload,
  };
};

export const updateTradeAction = (payload) => {
  return {
    type: types.constants.UPDATE_FI_TREDE,
    payload,
  };
};

export const getAllTradeAction = (payload) => {
  return {
    type: types.constants.GET_FI_ALL_TREDE,
    payload,
  };
};

export const getPendingTradeAction = (payload) => {
  return {
    type: types.constants.GET_FI_PENDING_TREDE,
    payload,
  };
};

export const getExecutedTradeAction = (payload) => {
  return {
    type: types.constants.GET_FI_EXECUTED_TREDE,
    payload,
  };
};

export const getUserPotisionListAction = () => {
  return {
    type: types.constants.GET_USER_POSITION_LIST,
  };
};
