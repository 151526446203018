import React, { useEffect, useState } from "react";
import OfferTable from "./History/OfferTable";
import OrderTable from "./History/OrderTable";
import QuoteTable from "./History/QuoteTable";
import TradeTable from "./History/TradeTable";
import * as fixedIncomeCountAction from "../../../../store/actions/fixedIncomeCount.action";
import { useDispatch, useSelector } from "react-redux";

import { Box, styled, Tab, Tabs } from "@material-ui/core";

const StyledTabs = styled((props) => <Tabs {...props} />)({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#0c6fb3",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minWidth: "50px !important",
  maxWidth: "50px !important",
  paddingInline: "5rem",
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "white",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  "&.Mui-selected": {
    backgroundColor: "#0c6fb3",
    borderRadius: "20px",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const HistoryTab = (props) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const totalRecordData = useSelector((state) => state?.fixedincomecount);

  useEffect(() => {
    dispatch(fixedIncomeCountAction.getAllTradeCountAction(props.daterange));
    dispatch(fixedIncomeCountAction.getAllOrderCountAction(props.daterange));
    dispatch(fixedIncomeCountAction.getAllOfferCountAction(props.daterange));
  }, [dispatch, props.daterange]);

  return (
    <Box>
      <StyledTabs
        value={activeTab}
        onChange={(e, value) => setActiveTab(value)}
      >
        <StyledTab
          label={`Trades ${
            totalRecordData?.alltradeCount !== 0
              ? totalRecordData?.alltradeCount
              : ""
          }`}
        />
        <StyledTab
          label={`Orders ${
            totalRecordData?.allorderCount !== 0
              ? totalRecordData?.allorderCount
              : ""
          }`}
        />
        <StyledTab
          label={`Offers ${
            totalRecordData?.allofferCount !== 0
              ? totalRecordData?.allofferCount
              : ""
          }`}
        />
        <StyledTab label={`Quotes`} />
      </StyledTabs>
      {activeTab == 0 && (
        <TradeTable
          totalRecord={totalRecordData?.alltradeCount}
          daterange={props.daterange}
        />
      )}
      {activeTab == 1 && (
        <OrderTable
          totalRecord={totalRecordData?.allorderCount}
          daterange={props.daterange}
        />
      )}
      {activeTab == 2 && (
        <OfferTable
          totalRecord={totalRecordData?.allofferCount}
          daterange={props.daterange}
        />
      )}
      {activeTab == 3 && <QuoteTable totalRecord={0} />}
    </Box>
  );
};

export default React.memo(HistoryTab);
