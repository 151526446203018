import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncCurrencies = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/currencies`
    );
    return data;
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const storeAsyncCurrency = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/currencies`,
      payload
    );

    return {
      error: false,
      data: data.message ?? "Currency created successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncCurrency = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/currencies/${payload}`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const updateAsyncCurrency = async (payload) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/currencies/${payload.id}`,
      payload.data
    );

    return {
      error: false,
      data: data.message ?? "Currency updated successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const deleteAsyncCurrency = async ({ payload }) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/currencies/${payload}`
    );

    return data.message ?? "Currency deleted successfully.";
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

export function* loadCurrencies() {
  try {
    const currencies = yield call(loadAsyncCurrencies);
    yield put({
      type: types.constants.GET_CURRENCY_SUCCESS,
      payload: currencies,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_CURRENCY_ERROR,
      payload: err.message,
    });
  }
}

export function* storeCurrency({ payload }) {
  const response = yield call(storeAsyncCurrency, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({ type: types.constants.STORE_CURRENCY_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STORE_CURRENCY_ERROR,
      payload: response.data,
    });
  }
}

export function* loadCurrency(payload) {
  const response = yield call(loadAsyncCurrency, payload);
  if (!response.error) {
    yield put({
      type: types.constants.GET_CURRENCY_BY_ID_SUCCESS,
      currency: response.data,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.GET_CURRENCY_BY_ID_ERROR,
      payload: response.data,
    });
  }
}

export function* updateCurrency({ payload }) {
  const response = yield call(updateAsyncCurrency, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({ type: types.constants.UPDATE_CURRENCY_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    yield put({
      type: types.constants.UPDATE_CURRENCY_ERROR,
      payload: response.data,
    });
  }
}

export function* deleteCurrency(payload) {
  try {
    const message = yield call(deleteAsyncCurrency, payload);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_CURRENCY_SUCCESS,
      id: payload.payload,
    });
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_CURRENCY_ERROR,
      payload: err.message,
    });
  }
}
