import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import "moment-timezone";
import CustomPagination from "../../../../../components/CustomPagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import * as fixedIncome from "../../../../../store/actions/fixedIncome.action";
import * as fixedIncomeCountAction from "../../../../../store/actions/fixedIncomeCount.action";

const ExecuteTable = (props) => {
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [timerInterval, setTimerInterval] = useState(0);
  const tradeData = useSelector(
    (state) => state?.fixedincomedata?.executedtradedata?.response
  );

  useEffect(() => {
    sessionStorage.removeItem("paginationkey");
    setCurrentPageNumber(1);
    dispatch(fixedIncomeCountAction.getExecutedTradeCountAction());
    dispatch(
      fixedIncome.getExecutedTradeAction(
        sessionStorage.getItem("paginationkey")
      )
    );
    const timer = setInterval(() => {
      dispatch(fixedIncomeCountAction.getExecutedTradeCountAction());
      dispatch(
        fixedIncome.getExecutedTradeAction(
          sessionStorage.getItem("paginationkey")
        )
      );
    }, 10000);
    setTimerInterval(timer);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(timerInterval);
      sessionStorage.removeItem("paginationkey");
    };
  }, [timerInterval]);

  const columns = useMemo(
    () => [
      {
        name: "Order ID",
        selector: "order_id",
        center: true,
        minWidth: "165px",
        cell: (row) => (row?.order_id ? row?.order_id : "-"),
        compact: true,
      },
      {
        name: "Bond ID",
        selector: "bond_id",
        sortable: false,
        center: true,
        minWidth: "170px",
        cell: (row) => (row?.bond_id ? row?.bond_id : "-"),
        compact: true,
      },
      {
        name: "ISIN",
        selector: "isin",
        sortable: false,
        center: true,
        cell: (row) => (row?.isin ? row?.isin : "-"),
        minWidth: "130px",
        compact: true,
      },
      {
        name: "Exchange",
        selector: "exchange_name",
        sortable: false,
        center: true,
        minWidth: "80px",
        cell: (row) => (row?.exchange_name ? row?.exchange_name : "-"),
        compact: true,
      },
      {
        name: "Coupon (%)",
        selector: "coupon_rate",
        sortable: false,
        center: true,
        cell: (row) =>
          row?.coupon_rate ? parseFloat(row?.coupon_rate)?.toFixed(4) : "-",
        compact: true,
      },
      {
        name: "Mat Date",
        selector: "maturity_date",
        sortable: false,
        center: true,
        minWidth: "100px",
        cell: (row) =>
          row?.maturity_date ? (
            <span className="text-uppercase">
              {moment(row?.maturity_date).format("DD/MM/YYYY")}
            </span>
          ) : (
            "-"
          ),
        compact: true,
      },
      {
        name: "Counterparty",
        selector: "market_taker_name",
        sortable: false,
        center: true,
        minWidth: "120px",
        cell: (row) =>
          localStorage.getItem("client") === row?.mm_client
            ? row?.market_taker_name
              ? row?.market_taker_name
              : "-"
            : row?.market_maker_name
            ? row?.market_maker_name
            : "-",
        compact: true,
      },
      {
        name: "Side",
        selector: "market_maker_side",
        sortable: false,
        cell: (row) => (
          <span className="text-uppercase">
            {localStorage.getItem("client") === row?.mm_client
              ? row?.market_maker_side
                ? row?.market_maker_side
                : "-"
              : row?.market_taker_side
              ? row?.market_taker_side
              : "-"}
          </span>
        ),
        compact: true,
        minWidth: "60px",
        center: true,
      },
      {
        name: "All or Nothing ",
        selector: "allOrNothing",
        sortable: false,
        cell: (row) =>
          row?.allOrNothing ? (
            <span className="text-uppercase">{row?.allOrNothing}</span>
          ) : (
            "-"
          ),
        compact: true,
        center: true,
      },
      {
        name: "CCY",
        selector: "ccy_suffix",
        sortable: false,
        right: false,
        center: true,
        minWidth: "80px",
        cell: (row) => (row?.ccy_suffix ? row?.ccy_suffix : "-"),
        compact: true,
      },
      {
        name: "Yield (%)",
        selector: "offer_yield",
        sortable: false,
        minWidth: "100px",
        center: true,
        cell: (row) =>
          row?.offer_yield ? parseFloat(row?.offer_yield)?.toFixed(4) : "",
        compact: true,
      },
      {
        name: "Trade Value",
        selector: "trade_amount",
        sortable: false,
        minWidth: "90px",
        cell: (row) =>
          row?.trade_amount ? (
            <CurrencyFormat
              value={parseFloat(row?.trade_amount)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            "-"
          ),
        compact: true,
        right: true,
      },
      {
        name: "Trade Consideration",
        selector: "trade_consideration",
        sortable: false,
        minWidth: "120px",
        cell: (row) =>
          row?.trade_consideration ? (
            <CurrencyFormat
              value={parseFloat(row?.trade_consideration)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            "-"
          ),
        compact: true,
        right: true,
      },
      {
        name: "Fill Status",
        selector: "",
        sortable: false,
        minWidth: "100px",
        center: true,
        cell: (row) => "-",
        compact: true,
      },
      {
        name: "Date",
        selector: "createdAt",
        sortable: false,
        center: true,
        minWidth: "100px",
        cell: (row) => (
          <span className="text-uppercase">
            {moment(row.createdAt).format("DD/MM/YYYY")}{" "}
          </span>
        ),
      },
      {
        name: "Time (GMT)",
        selector: "createdAt",
        sortable: false,
        center: true,
        minWidth: "80px",
        cell: (row) =>
          row?.createdAt
            ? moment(row.createdAt).tz("GMT").format("hh:mm:ss A")
            : "-",
        compact: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: false,
        minWidth: "80px",
        center: true,
        cell: (row) => (row.status ? row?.status : "-"),
        compact: true,
      },
    ],
    []
  );

  const handlePaginationChange = (page) => {
    if (
      currentPageNumber > page &&
      tradeData?.filter &&
      tradeData?.filter?.prevPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", tradeData?.filter?.prevPage);
      dispatch(fixedIncome.getExecutedTradeAction(tradeData?.filter?.prevPage));
    } else if (
      currentPageNumber < page &&
      tradeData?.filter &&
      tradeData?.filter?.nextPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", tradeData?.filter?.nextPage);
      dispatch(fixedIncome.getExecutedTradeAction(tradeData?.filter?.nextPage));
    }
  };

  return (
    <>
      <DataTable
        defaultSortField="createdAt"
        defaultSortAsc={false}
        columns={columns}
        pagination
        paginationServer
        data={tradeData?.set}
        theme={"dark"}
        highlightOnHover
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationTotalRows={props?.totalRecord}
        paginationPerPage={15}
        paginationDefaultPage={currentPageNumber}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => handlePaginationChange(page)}
        paginationComponent={CustomPagination}
        persistTableHead={true}
        noHeader={true}
      />
    </>
  );
};

export default React.memo(ExecuteTable);
