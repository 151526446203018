import * as types from "../actions/types";

export function accountReducer(state = {}, action) {
  switch (action.type) {
    case types.constants.GET_FUND_ACCOUNT:
      return {
        ...state,
        isloader: true,
      };
    case types.constants.GET_FUND_ACCOUNT_SUCCESS:
      return {
        ...state,
        fundaccount: action?.fundaccount,
        isloader: false,
      };
    case types.constants.GET_FUND_ACCOUNT_ERROR:
      return state;
    default:
      return state;
  }
}
