import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import "moment-timezone";
import CustomPagination from "../../../../../components/CustomPagination/CustomPagination";
import swal from "sweetalert";
import * as fixedIncome from "../../../../../store/actions/fixedIncome.action";
import * as fixedIncomeCountAction from "../../../../../store/actions/fixedIncomeCount.action";
import { useDispatch, useSelector } from "react-redux";
import OfferReViewModal from "../../../Modal/OfferReViewModal";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  actionBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#145582",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#145582",
    },
    "&:disabled": {
      backgroundColor: "#083a5e",
      color: "#888888",
    },
  },
}));

const PendingTable = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [offerReviewShow, setOfferReviewModalShow] = useState(false);
  const [order, setOrder] = useState({});
  const [timerInterval, setTimerInterval] = useState(0);
  const tradeData = useSelector(
    (state) => state?.fixedincomedata?.gettradeorderdata?.response
  );

  useEffect(() => {
    sessionStorage.removeItem("paginationkey");
    setCurrentPageNumber(1);
    dispatch(fixedIncomeCountAction.getPendingTradeCountAction());
    dispatch(
      fixedIncome.getPendingTradeAction(sessionStorage.getItem("paginationkey"))
    );
    const timer = setInterval(() => {
      dispatch(fixedIncomeCountAction.getPendingTradeCountAction());
      dispatch(
        fixedIncome.getPendingTradeAction(
          sessionStorage.getItem("paginationkey")
        )
      );
    }, 10000);
    setTimerInterval(timer);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(timerInterval);
      sessionStorage.removeItem("paginationkey");
    };
  }, [timerInterval]);

  const columns = [
    {
      name: "Order ID",
      selector: "order_id",
      center: true,
      minWidth: "165px",
      cell: (row) => (row?.order_id ? row?.order_id : "-"),
      compact: true,
    },
    {
      name: "Bond ID",
      selector: "bond_id",
      sortable: false,
      center: true,
      minWidth: "170px",
      cell: (row) => (row?.bond_id ? row?.bond_id : "-"),
      compact: true,
    },
    {
      name: "ISIN",
      selector: "isin",
      sortable: false,
      center: true,
      cell: (row) => (row?.isin ? row?.isin : "-"),
      minWidth: "130px",
      compact: true,
    },
    {
      name: "Exchange",
      selector: "exchange_name",
      sortable: false,
      center: true,
      minWidth: "80px",
      cell: (row) => (row?.exchange_name ? row?.exchange_name : "-"),
      compact: true,
    },
    {
      name: "Coupon (%)",
      selector: "coupon_rate",
      sortable: false,
      center: true,
      cell: (row) =>
        row?.coupon_rate ? parseFloat(row?.coupon_rate)?.toFixed(4) : "-",
      compact: true,
    },
    {
      name: "Mat Date",
      selector: "maturity_date",
      sortable: false,
      center: true,
      minWidth: "100px",
      cell: (row) =>
        row?.maturity_date ? (
          <span className="text-uppercase">
            {moment(row?.maturity_date).format("DD/MM/YYYY")}
          </span>
        ) : (
          "-"
        ),
      compact: true,
    },
    {
      name: "Counterparty",
      selector: "market_taker_name",
      sortable: false,
      center: true,
      minWidth: "120px",
      cell: (row) =>
        localStorage.getItem("client") === row?.mm_client
          ? row?.market_taker_name
            ? row?.market_taker_name
            : "-"
          : row?.market_maker_name
          ? row?.market_maker_name
          : "-",
      compact: true,
    },
    {
      name: "Side",
      selector: "market_maker_side",
      sortable: false,
      cell: (row) => (
        <span className="text-uppercase">
          {localStorage.getItem("client") === row?.mm_client
            ? row?.market_maker_side
              ? row?.market_maker_side
              : "-"
            : row?.market_taker_side
            ? row?.market_taker_side
            : "-"}
        </span>
      ),
      compact: true,
      minWidth: "60px",
      center: true,
    },
    {
      name: "All or Nothing ",
      selector: "allOrNothing",
      sortable: false,
      cell: (row) =>
        row?.allOrNothing ? (
          <span className="text-uppercase">{row?.allOrNothing}</span>
        ) : (
          "-"
        ),
      compact: true,
      center: true,
    },
    {
      name: "CCY",
      selector: "ccy_suffix",
      sortable: false,
      right: false,
      center: true,
      minWidth: "80px",
      cell: (row) => (row?.ccy_suffix ? row?.ccy_suffix : "-"),
      compact: true,
    },
    {
      name: "Yield (%)",
      selector: "offer_yield",
      sortable: false,
      minWidth: "100px",
      center: true,
      cell: (row) =>
        row?.offer_yield ? parseFloat(row?.offer_yield)?.toFixed(4) : "",
      compact: true,
    },
    {
      name: "Trade Value",
      selector: "trade_amount",
      sortable: false,
      minWidth: "90px",
      cell: (row) =>
        row?.trade_amount ? (
          <CurrencyFormat
            value={parseFloat(row?.trade_amount)?.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "-"
        ),
      compact: true,
      right: true,
    },
    {
      name: "Trade Consideration",
      selector: "trade_consideration",
      sortable: false,
      minWidth: "120px",
      cell: (row) =>
        row?.trade_consideration ? (
          <CurrencyFormat
            value={parseFloat(row?.trade_consideration)?.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "-"
        ),
      compact: true,
      right: true,
    },
    {
      name: "Fill Status",
      selector: "",
      sortable: false,
      minWidth: "100px",
      center: true,
      cell: (row) => "-",
      compact: true,
    },
    {
      name: "Date",
      selector: "createdAt",
      sortable: false,
      center: true,
      minWidth: "100px",
      cell: (row) => (
        <span className="text-uppercase">
          {moment(row.createdAt).format("DD/MM/YYYY")}{" "}
        </span>
      ),
    },
    {
      name: "Time (GMT)",
      selector: "createdAt",
      sortable: false,
      center: true,
      minWidth: "80px",
      cell: (row) =>
        row?.createdAt
          ? moment(row.createdAt).tz("GMT").format("hh:mm:ss A")
          : "-",
      compact: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      minWidth: "80px",
      center: true,
      cell: (row) => (row.status ? row?.status : "-"),
      compact: true,
    },
    {
      name: "Action",
      minWidth: "220px",
      cell: (row) =>
        localStorage.getItem("client") === row?.mt_client &&
        row?.market_taker_status === "pending" ? (
          <Button
            variant="contained"
            onClick={() => executeTrade(row)}
            className={classes.actionBtn}
          >
            Execute
          </Button>
        ) : localStorage.getItem("client") === row?.mm_client ? (
          <Button
            variant="contained"
            disabled={row?.market_taker_status === "pending"}
            onClick={() => executeTrade(row)}
            className={classes.actionBtn}
          >
            Execute
          </Button>
        ) : (
          "-"
        ),
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      compact: true,
      center: true,
    },
  ];

  const executeTrade = (row) => {
    if (localStorage.getItem("client") === row?.mt_client) {
      swal({
        title: "Are you sure?",
        text: "Are you sure you want to Execute Trade ?",
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((confirm) => {
        if (confirm) {
          let payload = {
            values: [
              { name: "offer_id", value: row?.offer_id },
              { name: "market_taker_status", value: "accepted" },
              {
                name: "market_maker_status",
                value:
                  localStorage.getItem("client") === row?.mt_client
                    ? "pending"
                    : "executed",
              },
              { name: "trade_amount", value: row?.trade_amount?.toString() },
              {
                name: "trade_consideration",
                value: row?.trade_consideration?.toString(),
              },
            ],
          };
          dispatch(
            fixedIncome.updateTradeAction({
              payload: JSON.stringify(payload),
              recordID: row?.recordID,
            })
          );
        }
      });
    } else if (localStorage.getItem("client") === row?.mm_client) {
      setOrder(row);
      setOfferReviewModalShow(true);
    }
  };

  const handlePaginationChange = (page) => {
    if (
      currentPageNumber > page &&
      tradeData?.filter &&
      tradeData?.filter?.prevPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", tradeData?.filter?.prevPage);
      dispatch(fixedIncome.getPendingTradeAction(tradeData?.filter?.prevPage));
    } else if (
      currentPageNumber < page &&
      tradeData?.filter &&
      tradeData?.filter?.nextPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", tradeData?.filter?.nextPage);
      dispatch(fixedIncome.getPendingTradeAction(tradeData?.filter?.nextPage));
    }
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={tradeData?.set}
        theme={"dark"}
        highlightOnHover
        pagination
        paginationServer
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationTotalRows={props?.totalRecord}
        paginationPerPage={15}
        paginationDefaultPage={currentPageNumber}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => handlePaginationChange(page)}
        paginationComponent={CustomPagination}
        persistTableHead={true}
        noHeader={true}
      />
      {offerReviewShow && (
        <OfferReViewModal
          show={offerReviewShow}
          onHide={() => setOfferReviewModalShow(false)}
          order={order}
        />
      )}
    </>
  );
};

export default React.memo(PendingTable);
