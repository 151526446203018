import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import * as watchlist from "../../../store/actions/watchlist.actions";
import moment from "moment";
import * as fixedIncome from "../../../store/actions/fixedIncome.action";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import { useKeycloak } from "@react-keycloak/web";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  Typography,
  Grid,
  TextField,
  OutlinedInput,
  Select as MUISelect,
  MenuItem,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import NumericFormatInput from "../../../components/NumericFormatInput";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#252525",
      color: "#dedede",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
    },
    "& .MuiDialogContent-root": {
      color: "#dedede !important",
    },
  },
  dialogActions: {
    margin: "0 auto",
    marginTop: "2rem",
    "& > *:first-child": {
      marginRight: "0.5rem",
    },
  },
  actionBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#145582",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#145582",
    },
    "&:disabled": {
      backgroundColor: "#083a5e",
      color: "#888888",
    },
  },
  reviewBadge: {
    backgroundColor: "#22312c",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.7rem 1.5rem",
    borderRadius: "10px",
    minWidth: "150px",
  },
  select: {
    borderRadius: "4px",
    color: "white",
    minWidth: "100%",
    minHeight: "3rem",
    backgroundColor: "transparent",
    padding: "0 0.5rem",
    "& > option": {
      backgroundColor: "#424242 !important",
      color: "white !important",
    },
  },
  input: {
    borderRadius: "4px",
    color: "white",
    minWidth: "100%",
    minHeight: "3rem",
    backgroundColor: "transparent",
    padding: "0 0.5rem",
    border: "1px solid rgba(255, 255, 255, 0.3)",
  },
}));

const OrderFormModal = (props) => {
  const classes = useStyles();

  const [counterPartyList, setCounterPartyList] = useState([]);
  const [settlementDayCount, setSettlementDayCount] = useState(0);
  const selectInputRef = useRef();
  const dispatch = useDispatch();
  const watchlistData = useSelector((state) => state?.watchlist);
  const systemUserData = useSelector((state) => state?.fixedincomedata);
  const systemUserOptions = systemUserData?.getsystemuserdata?.response?.set;

  const { keycloak } = useKeycloak();

  useEffect(() => {
    dispatch(fixedIncome.getSystemUserAction());
  }, [dispatch]);

  useEffect(() => {
    if (watchlistData?.exchangelist?.response?.settlement_day_count) {
      setSettlementDayCount(
        watchlistData?.exchangelist?.response?.settlement_day_count
      );
    }
  }, [
    watchlistData,
    watchlistData?.exchangelist?.response?.settlement_day_count,
  ]);

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>{props?.data?.isin}</div>
        <div>{props?.data?.bond_id}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <div style={{ color: "#3469d4" }}>({props?.data?.symbol})</div>
          <div style={{ color: "#db4532" }}>
            Exchange : {props?.data?.exchange_name}
          </div>
        </div>
      </components.Option>
    );
  };

  const loadOptions = (inputValue) => {
    if (inputValue) {
      return fetch(
        `${process.env.REACT_APP_API_URL}api/compose/namespace/${
          process.env.REACT_APP_NAMESPACE_ID
        }/module/${
          process.env.REACT_APP_BOND_MODULE_ID
        }/record/?query=(isin LIKE '%25${inputValue}%25' OR bond_id LIKE '%25${inputValue}%25' OR symbol LIKE '%25${inputValue}%25') AND (maturity_date>='${moment
          .utc()
          .format("YYYY-MM-DDT00:00:00Z")}')`,
        {
          method: "get",
          headers: new Headers({
            authorization: "Bearer " + keycloak.token,
            "Content-Type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (
            res &&
            res.response &&
            res.response.set &&
            res.response.set.length
          ) {
            const setData = res.response.set;
            setData.forEach((item) => {
              if (item && item.values?.length)
                item.values.forEach((data) => {
                  item[data.name] = data.value;
                });
            });
          }
          return res?.response?.set;
        });
    } else {
      return fetch(
        `${process.env.REACT_APP_API_URL}api/compose/namespace/${
          process.env.REACT_APP_NAMESPACE_ID
        }/module/${
          process.env.REACT_APP_BOND_MODULE_ID
        }/record/?query=(maturity_date>='${moment
          .utc()
          .format("YYYY-MM-DDT00:00:00Z")}')`,
        {
          method: "get",
          headers: new Headers({
            authorization: "Bearer " + keycloak.token,
            "Content-Type": "application/json",
          }),
        }
      )
        .then((response) => response.json())
        .then((res) => {
          if (
            res &&
            res.response &&
            res.response.set &&
            res.response.set.length
          ) {
            const setData = res.response.set;
            setData.forEach((item) => {
              if (item && item.values?.length)
                item.values.forEach((data) => {
                  item[data.name] = data.value;
                });
            });
          }
          return res?.response?.set;
        });
    }
  };

  const initialValues = {
    bond_id: "",
    bond: "",
    price: "",
    isin: "",
    yield: "",
    maturity_date: "",
    consideration: "",
    bondDirtyPrice: "",
    settlement_date: "",
    csd_cost: "",
    nominal: "",
    side: "Buy",
    coupon_rate: "",
    allOrNothing: "Yes",
    qty: "",
    sendTo: "ALL",
    clean_price: "",
    issued_date: "",
    settlement_string: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      bond_id: Yup.string().required("This field is required."),
      bond: Yup.string(),
      price: Yup.number()
        .positive("price must be a positive number and not zero")
        .required("This field is required."),
      isin: Yup.string(),
      yield: Yup.number()
        .positive("yield must be a positive number and not zero")
        .required("This field is required."),
      maturity_date: Yup.string(),
      consideration: Yup.string(),
      settlement_date: Yup.string().required("This field is required."),
      csd_cost: Yup.string(),
      nominal: Yup.number()
        .positive("nominal must be a positive number and not zero")
        .required("This field is required."),
      side: Yup.string().required("This field is required."),
      coupon_rate: Yup.string().required("This field is required."),
      allOrNothing: Yup.string().required("This field is required."),
      qty: Yup.number(),
      sendTo: Yup.string(),
      ccy: Yup.string(),
      bondDirtyPrice: Yup.string(),
      clean_price: Yup.string(),
      issued_date: Yup.string(),
      settlement_string: Yup.string(),
    }),
    onSubmit: (e) => {
      let considerationdata = parseFloat(
        parseFloat(e["bondDirtyPrice"])?.toFixed(2) * parseFloat(e["qty"])
      )?.toFixed(2);
      let csd_costdata = parseFloat(
        parseFloat(considerationdata)?.toFixed(2) *
          (parseFloat(
            watchlistData?.exchangelist?.response?.csd_cost_percentage
          ) /
            100)
      )?.toFixed(2);
      let dirty_price = parseFloat(
        parseFloat(e["bondDirtyPrice"]) / 100
      )?.toFixed(4);
      let payload = {
        values: [
          { name: "bond", value: formik?.values["bond"] },
          {
            name: "settlement_date",
            value: moment(formik?.values["settlement_date"])?.format(
              "DD-MMM-YYYY"
            ),
          },
          {
            name: "nominal",
            value: parseFloat(e.qty * e.price)
              ?.toFixed(2)
              ?.toString(),
          },
          { name: "price", value: formik?.values["price"]?.toString() },
          { name: "side", value: formik?.values["side"]?.toString() },
          { name: "consideration", value: considerationdata?.toString() },
          { name: "csd_cost", value: csd_costdata?.toString() },
          { name: "allOrNothing", value: formik?.values["allOrNothing"] },
          { name: "qty", value: formik?.values["qty"]?.toString() },
          {
            name: "amount",
            value: parseFloat(e.qty * e.price)
              ?.toFixed(2)
              ?.toString(),
          },
          {
            name: "order_date",
            value: moment(Date.now())?.format("YYYY/MM/DD"),
          },
          { name: "sendTo", value: formik?.values["sendTo"] },
          { name: "quote", value: props.isneworder ? "no" : "yes" },
          { name: "party", value: "" },
          { name: "yield", value: formik?.values["yield"]?.toString() },
          { name: "maturity_date", value: formik?.values["maturity_date"] },
          { name: "dirty_price", value: dirty_price?.toString() },
          { name: "clean_price", value: e["clean_price"]?.toString() },
          { name: "state", value: "new" },
        ],
      };
      if (props.isneworder)
        dispatch(fixedIncome.createMarketOrderAction(JSON.stringify(payload)));
      else {
        if (e["sendTo"] === "counterpartylist" && counterPartyList?.length)
          payload = { values: payload["values"].concat(counterPartyList) };
        dispatch(
          fixedIncome.createRequestQuoteOrderAction(JSON.stringify(payload))
        );
      }
      handleClose();
    },
  });

  function logChange(e, a) {
    if (e) {
      setTimeout(() => {
        formik.setFieldValue("bond_id", e.bond_id);
      }, 10);
      setTimeout(() => {
        formik.setFieldValue("bond", e.recordID);
      }, 10);
      setTimeout(() => {
        formik.setFieldValue("isin", e.isin);
      }, 10);
      setTimeout(() => {
        formik.setFieldValue("coupon_rate", e?.coupon_rate);
      }, 10);
      setTimeout(() => {
        formik.setFieldValue(
          "maturity_date",
          moment(e.maturity_date)?.format("DD/MMM/YYYY")
        );
      }, 10);
      setTimeout(() => {
        formik.setFieldValue(
          "issued_date",
          moment(e.issued_date)?.format("DD/MMM/YYYY")
        );
      }, 10);
      setTimeout(() => {
        formik.setFieldValue("ccy", e?.ccy_suffix);
      }, 10);
      formik.setFieldValue("settlement_string", "T+0");
      getSelectedDate();
      getExchanges(e);
    }
    if (a.action === "clear") {
      formik.setFieldValue("bond_id", "");
      formik.setFieldValue("bond", "");
      formik.setFieldValue("isin", "");
      formik.setFieldValue("coupon_rate", "");
      formik.setFieldValue("maturity_date", "");
      formik.setFieldValue("ccy", "");
      formik.setFieldValue("issued_date", "");
      formik.setFieldValue("settlement_date", "");
      formik.setFieldValue("settlement_string", "");
      setSettlementDayCount(0);
    }
    formik.setFieldValue("price", "");
    formik.setFieldValue("csd_cost", "");
    formik.setFieldValue("consideration", "");
    formik.setFieldValue("bondDirtyPrice", "");
    formik.setFieldValue("yield", "");
    formik.setFieldValue("clean_price", "");
  }
  const getExchanges = (data) => {
    dispatch(watchlist.getExchange({ exchangeId: data?.exchange }));
  };
  const handleClose = () => {
    props.onHide();
    setCounterPartyList([]);
    setSettlementDayCount(0);
    formik.resetForm({
      values: initialValues,
    });
  };
  function systemUserChange(e, action) {
    if (action?.action === "select-option") {
      counterPartyList.push({
        name: "counterparty_list",
        value: action?.option?.userID,
      });
      setCounterPartyList(counterPartyList);
      formik.setFieldValue("counterparty_list", counterPartyList);
    } else if (action?.action === "remove-value") {
      if (counterPartyList?.length) {
        const removeuser = counterPartyList?.findIndex(
          (data) => data?.value === action?.removedValue?.userID
        );
        if (removeuser >= 0) {
          counterPartyList.splice(removeuser, 1);
          setCounterPartyList(counterPartyList);
          formik.setFieldValue("counterparty_list", counterPartyList);
        }
      }
    }
  }

  const handlessendToChange = (data) => {
    formik.setFieldValue("sendTo", data);
    setCounterPartyList([]);
  };

  const onPriceChange = (e) => {
    e.preventDefault();
    if (
      formik?.values["isin"] &&
      formik?.values["maturity_date"] &&
      formik?.values["coupon_rate"] &&
      formik?.values["ccy"] &&
      formik?.values["issued_date"]
    ) {
      formik.setFieldValue("price", e?.target?.value?.replace(/,/g, ""));
      if (
        e?.target?.value?.replace(/,/g, "") &&
        e?.target?.value?.replace(/,/g, "") > 0
      ) {
        let body = {
          name: formik?.values["isin"],
          price: e.target.value.replace(/,/g, ""),
          ccy: formik?.values["ccy"],
          coupon: formik?.values["coupon_rate"],
          maturity: formik?.values["maturity_date"],
          effectiveDate: formik?.values["issued_date"],
        };
      } else {
        formik.setFieldValue("yield", "");
        formik.setFieldValue("csd_cost", "");
        formik.setFieldValue("consideration", "");
        formik.setFieldValue("bondDirtyPrice", "");
        formik.setFieldValue("clean_price", "");
        formik.setFieldValue("qty", "");
      }
    } else {
      formik.setFieldValue("price", "");
    }
  };

  const onYieldPriceChange = (e) => {
    e.preventDefault();
    if (
      formik?.values["isin"] &&
      formik?.values["maturity_date"] &&
      formik?.values["coupon_rate"] &&
      formik?.values["ccy"] &&
      formik?.values["issued_date"]
    ) {
      formik.setFieldValue("yield", e?.target?.value);
      if (e?.target?.value && e?.target?.value > 0) {
        let body = {
          name: formik?.values["isin"],
          yield: e.target.value,
          ccy: formik?.values["ccy"],
          coupon: formik?.values["coupon_rate"],
          maturity: formik?.values["maturity_date"],
          effectiveDate: formik?.values["issued_date"],
        };
      } else {
        formik.setFieldValue("price", "");
        formik.setFieldValue("csd_cost", "");
        formik.setFieldValue("consideration", "");
        formik.setFieldValue("bondDirtyPrice", "");
        formik.setFieldValue("clean_price", "");
        formik.setFieldValue("qty", "");
      }
    } else {
      formik.setFieldValue("yield", "");
    }
  };

  const onConsiderationChange = (e) => {
    e.preventDefault();
    if (
      formik?.values["isin"] &&
      formik?.values["maturity_date"] &&
      formik?.values["coupon_rate"] &&
      formik?.values["ccy"]
    ) {
      if (e?.target?.value) {
        formik.setFieldValue("consideration", e?.target?.value);
        formik.setFieldValue(
          "csd_cost",
          parseFloat(
            (parseFloat(
              watchlistData?.exchangelist?.response?.csd_cost_percentage
            ) /
              100) *
              parseFloat(e?.target?.value)
          )?.toFixed(2)
        );
      } else {
        formik.setFieldValue("consideration", "");
        formik.setFieldValue("bondDirtyPrice", "");
        formik.setFieldValue("clean_price", "");
        formik.setFieldValue("csd_cost", "");
      }
    } else {
      toast.error(`Please select bond`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getConsideration = (consideration, quantity) => {
    setTimeout(() => {
      if (!consideration && !quantity) {
        formik.setFieldValue("consideration", "");
        formik.setFieldValue("csd_cost", "");
      }
    }, 0);
  };

  const getQuantity = (nominal, price) => {
    setTimeout(() => {
      formik.setFieldValue("nominal", nominal?.replace(/,/g, ""));

      if (nominal && price) {
        let new_qty = Math.trunc(nominal.replace(/,/g, "") / price);

        formik.setFieldValue("qty", new_qty);
        getConsideration(formik?.values["bondDirtyPrice"], new_qty);
      } else {
        formik.setFieldValue("qty", "");
      }
    }, 0);
  };

  const getSelectedDate = () => {
    let newDay = moment().toDate();
    const day = newDay.getDay();
    if (day === 6)
      formik.setFieldValue("settlement_date", moment().add(2, "days").toDate());
    else if (day === 0)
      formik.setFieldValue("settlement_date", moment().add(1, "days").toDate());
    else formik.setFieldValue("settlement_date", moment().toDate());
  };

  const isWeekday = (date) => {
    return [0, 6].includes(date.day());
  };

  const onchangedate = (date) => {
    var moment1 = moment(moment().toDate(), "MM/D/YYYY");
    var moment2 = moment(date, "MM/D/YYYY");
    var result = 0;
    if (moment1.isSame(moment2, "date")) {
      result = 0;
    } else {
      const days = moment2.diff(moment1, "days") + 1;
      let newDay = moment1.toDate(),
        workingDays = 0;
      for (let i = 0; i < days; i++) {
        const day = newDay.getDay();
        newDay = moment1.add(1, "days").toDate();
        const isWeekend = day % 6 === 0;
        if (!isWeekend) {
          workingDays++;
        }
      }
      result = workingDays;
    }
    formik.setFieldValue("settlement_string", "T+" + result);
    formik.setFieldValue("settlement_date", date);
  };

  const onChangeSettlement = (data) => {
    var strreplce = data?.replace("T+", "");
    var addday = 0;
    let workingDays = 0;
    var moment1 = moment(moment().toDate(), "MM/D/YYYY");
    let newDay = moment1.toDate();
    do {
      const day = newDay.getDay();
      const isWeekend = day % 6 === 0;
      if (!isWeekend) {
        workingDays++;
      }
      newDay = moment1.add(1, "days").toDate();
      addday++;
    } while (workingDays < parseInt(strreplce) + 1);
    formik.setFieldValue(
      "settlement_date",
      moment()
        .add(parseInt(addday) - 1, "days")
        .toDate()
    );
    formik.setFieldValue("settlement_string", data);
  };

  const getMaxDate = (count) => {
    var addday = 0;
    let workingDays = 0;
    var moment1 = moment(moment().toDate(), "MM/D/YYYY");
    let newDay = moment1.toDate();
    do {
      const day = newDay.getDay();
      newDay = moment1.add(1, "days").toDate();
      const isWeekend = day % 6 === 0;
      if (!isWeekend) {
        workingDays++;
      }
      addday++;
    } while (workingDays < parseInt(count) + (count ? 1 : 0));
    return moment()
      .add(addday - (count ? 1 : 0), "days")
      .toDate();
  };

  const formatDate = (date, field, format = "YYYY/MM/DD HH:mm:00") => {
    formik.setFieldValue(field, moment(date).format(format));
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      className={classes.dialog}
      disableBackdropClick
    >
      <DialogTitle>
        {props.isneworder ? "CREATE NEW ORDER" : "Request New Quote"}
      </DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <AsyncSelect
                components={{ Option, IndicatorSeparator: () => null }}
                ref={selectInputRef}
                cacheOptions
                defaultOptions
                getOptionLabel={(e) => e.isin}
                loadOptions={loadOptions}
                isClearable={true}
                isSearchable={true}
                onChange={logChange}
                placeholder="Search by Bond ID or ISIN"
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: "999 !important",
                    backgroundColor: "#313131",
                    ":hover": {
                      backgroundColor: "#313131",
                    },
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#313131",
                    border: "0.5px solid #313131",
                    outline: "none",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "#fcfcfc",
                    backgroundColor: "#313131",
                    cursor: "pointer",
                    fontSize: "12px",
                    textAlign: "left",
                    ":hover": {
                      backgroundColor: "#252525",
                    },
                    ":active": {
                      backgroundColor: "#252525",
                    },
                  }),
                  input: (styles) => ({ ...styles, color: "white" }),
                  singleValue: (styles) => ({
                    ...styles,
                    color: "white",
                  }),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["bond_id"] && formik.touched["bond_id"]}
                label="Bond ID"
                name="bond_id"
                helperText={
                  formik.touched["bond_id"] && formik.errors["bond_id"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["bond_id"]}
                fullWidth={true}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["isin"] && formik.touched["isin"]}
                label="ISIN"
                name="isin"
                helperText={formik.touched["isin"] && formik.errors["isin"]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["isin"]}
                fullWidth={true}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                name={"maturity_date"}
                value={formik.values["maturity_date"]}
                onChange={(data) =>
                  formatDate(data, "maturity_date", "YYYY/MM/DD")
                }
                inputVariant="outlined"
                label={"Maturity date"}
                showTodayButton
                clearable
                format="DD/MM/YYYY"
                error={
                  formik.errors["maturity_date"] &&
                  formik.touched["maturity_date"]
                }
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name="settlement_date"
                displayEmpty
                onChange={(e) => onChangeSettlement(e?.target?.value)}
                value={formik.values["settlement_string"]}
                input={<OutlinedInput />}
                className={classes.select}
              >
                {settlementDayCount > 0 &&
                  Array.from(
                    Array(parseInt(settlementDayCount) + 1),
                    (e, i) => {
                      return (
                        <MenuItem key={i} value={`T+${i}`}>
                          T+{i}
                        </MenuItem>
                      );
                    }
                  )}
              </MUISelect>
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name="side"
                displayEmpty
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["side"]}
                disabled
                input={<OutlinedInput />}
                label="Action"
                className={classes.select}
              >
                <MenuItem value="Buy">Buy</MenuItem>
                <MenuItem value="Sell">Sell</MenuItem>
              </MUISelect>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                name={"settlement_date"}
                value={formik.values["settlement_date"]}
                onChange={(date) => onchangedate(date)}
                inputVariant="outlined"
                label={"Settlement date"}
                showTodayButton
                clearable
                format="dd-MMM-yyyy"
                error={
                  formik.errors["settlement_date"] &&
                  formik.touched["settlement_date"]
                }
                fullWidth={true}
                shouldDisableDate={isWeekday}
                maxDate={getMaxDate(settlementDayCount)}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                Nominal
              </Typography>
              <CurrencyFormat
                thousandSeparator={true}
                name="nominal"
                onChange={(e) =>
                  getQuantity(e?.target?.value, formik?.values["price"])
                }
                onBlur={formik.handleBlur}
                value={formik.values["nominal"]}
                className={classes.input}
              />
              {formik.touched["nominal"] && formik.errors["nominal"] ? (
                <Typography component="div" color="secondary">
                  {formik.errors["nominal"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <MUISelect
                name="allOrNothing"
                displayEmpty
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["allOrNothing"]}
                disabled
                input={<OutlinedInput />}
                label="All or nothing"
                className={classes.select}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </MUISelect>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["yield"] && formik.touched["yield"]}
                label="Yield (%)"
                name="yield"
                helperText={formik.touched["yield"] && formik.errors["yield"]}
                onBlur={formik.handleBlur}
                value={formik.values["yield"]}
                onChange={(e) => onYieldPriceChange(e)}
                fullWidth={true}
                disabled
                InputProps={{
                  inputComponent: NumericFormatInput,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["coupon_rate"] && formik.touched["coupon_rate"]
                }
                label="Coupon (%)"
                name="coupon_rate"
                helperText={
                  formik.touched["coupon_rate"] && formik.errors["coupon_rate"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["coupon_rate"]}
                fullWidth={true}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                Price
              </Typography>
              <CurrencyFormat
                name="price"
                onChange={(e) => onPriceChange(e)}
                onBlur={formik.handleBlur}
                thousandSeparator={true}
                value={formik.values["price"]}
                className={classes.input}
              />
              {formik.touched["price"] && formik.errors["price"] ? (
                <Typography component="div" color="secondary">
                  {formik.errors["price"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                Consideration
              </Typography>
              <input
                type="hidden"
                name="bondDirtyPrice"
                onChange={formik.handleChange}
                value={formik.values["bondDirtyPrice"]}
              />
              <CurrencyFormat
                name="consideration"
                onChange={(e) => onConsiderationChange(e)}
                onBlur={formik.handleBlur}
                disabled
                thousandSeparator={true}
                value={
                  formik?.values["bondDirtyPrice"] &&
                  formik?.values["qty"] &&
                  (
                    parseFloat(formik?.values["bondDirtyPrice"])?.toFixed(2) *
                    parseFloat(formik?.values["qty"])
                  )?.toFixed(2)
                }
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                Quantity
              </Typography>
              <CurrencyFormat
                name="qty"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
                thousandSeparator={true}
                value={formik.values["qty"]}
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                CSD Cost
              </Typography>
              <CurrencyFormat
                name="csd_cost"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
                thousandSeparator={true}
                value={
                  formik?.values["bondDirtyPrice"] &&
                  formik?.values["qty"] &&
                  (
                    (parseFloat(
                      watchlistData?.exchangelist?.response?.csd_cost_percentage
                    ) /
                      100) *
                    (parseFloat(formik?.values["bondDirtyPrice"])?.toFixed(2) *
                      parseFloat(formik?.values["qty"]))
                  )?.toFixed(2)
                }
                className={classes.input}
              />
            </Grid>
            {!props.isneworder && (
              <Grid item xs={6}>
                <MUISelect
                  name="sendTo"
                  displayEmpty
                  onChange={(e) => handlessendToChange(e.target.value)}
                  value={formik.values["sendTo"]}
                  disabled
                  input={<OutlinedInput />}
                  label="Send to"
                  className={classes.select}
                >
                  <MenuItem value="ALL">ALL</MenuItem>
                  {!props.isneworder && (
                    <MenuItem value="counterpartylist">
                      Select Counter Party
                    </MenuItem>
                  )}
                </MUISelect>
              </Grid>
            )}
            {formik.values["sendTo"] === "counterpartylist" && (
              <Grid item xs={6}>
                <Select
                  getOptionLabel={(e) => e.handle}
                  options={systemUserOptions}
                  onChange={systemUserChange}
                  placeholder="Select.."
                  isMulti
                  className={classes.select}
                />
                {!counterPartyList?.length ? (
                  <Typography variant="body2" color="secondary">
                    This field is required.
                  </Typography>
                ) : null}
              </Grid>
            )}
          </Grid>
          <div className={classes.dialogActions}>
            <Button
              variant="contained"
              type="submit"
              className={classes.actionBtn}
              size="small"
            >
              Submit order
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(OrderFormModal);
