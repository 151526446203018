import DataTable, { createTheme } from "react-data-table-component";
import moment from "moment";
import "moment-timezone";
import CurrencyFormat from "react-currency-format";
import { useMemo } from "react";

createTheme("dark", {
  background: {
    default: "#0F0F0F",
  },
  text: {
    primary: "#FCFCFC",
    secondary: "#FCFCFC",
  },
});

const OpenOrderFixIncomeTable = (props) => {
  const columns = useMemo(
    () => [
      {
        name: "Order ID",
        selector: "recordID",
        sortable: true,
        minWidth: "165px",
        cell: (row) => (row?.recordID ? row?.recordID : "-"),
        compact: true,
      },
      {
        name: "Bond ID",
        selector: "bond_id",
        sortable: true,
        right: false,
        minWidth: "170px",
        cell: (row) => (row?.bond_id ? row?.bond_id : "-"),
        compact: true,
      },
      {
        name: "ISIN",
        selector: "isin",
        sortable: true,
        right: false,
        cell: (row) => (row?.isin ? row?.isin : "-"),
        minWidth: "130px",
        compact: true,
      },
      {
        name: "Exchange",
        selector: "exchange_name",
        sortable: true,
        center: true,
        minWidth: "70px",
        cell: (row) => (row?.exchange_name ? row?.exchange_name : "-"),
        compact: true,
      },
      {
        name: "All or Nothing",
        selector: "allOrNothing",
        sortable: true,
        center: true,
        minWidth: "110px",
        cell: (row) => (row?.allOrNothing ? row?.allOrNothing : "-"),
        compact: true,
      },
      {
        name: "CCY",
        selector: "ccy_suffix",
        sortable: true,
        right: false,
        minWidth: "80px",
        cell: (row) => (row?.ccy_suffix ? row?.ccy_suffix : "-"),
        compact: true,
      },
      {
        name: "Offer Yield (%)",
        selector: "offer_yield",
        sortable: true,
        center: true,
        minWidth: "110px",
        cell: (row) =>
          row?.offer_yield ? parseFloat(row?.offer_yield)?.toFixed(4) : "-",
        compact: true,
      },
      {
        name: "Offer Amount",
        selector: "offer_amount",
        sortable: true,
        minWidth: "110px",
        cell: (row) =>
          row?.offer_amount ? (
            <CurrencyFormat
              value={parseFloat(row?.offer_amount)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          ) : (
            "-"
          ),
        compact: true,
        right: true,
      },
      {
        name: "CP",
        selector: "counterparty_name",
        sortable: true,
        center: true,
        minWidth: "90px",
        cell: (row) => (row?.counterparty_name ? row?.counterparty_name : "-"),
        compact: true,
      },
      {
        name: "CP Yield (%)",
        selector: "counterparty_yield",
        sortable: true,
        minWidth: "155px",
        center: true,
        cell: (row) =>
          row?.counterparty_yield
            ? parseFloat(row?.counterparty_yield)?.toFixed(4)
            : "-",
        compact: true,
      },
      {
        name: "CP Amount",
        selector: "counterparty_amount",
        sortable: true,
        minWidth: "120px",
        cell: (row) =>
          row?.counterparty_amount &&
          parseFloat(row?.counterparty_amount) !== 0 ? (
            <CurrencyFormat
              value={parseFloat(row?.counterparty_amount)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
              fixedDecimalScale={true}
            />
          ) : (
            "-"
          ),
        compact: true,
        right: true,
      },
      {
        name: "Date",
        selector: "createdAt",
        sortable: true,
        minWidth: "100px",
        cell: (row) => (
          <span className="text-uppercase">
            {moment(row.createdAt).format("DD/MM/YYYY")}
          </span>
        ),
      },
      {
        name: "Time (GMT)",
        selector: "createdAt",
        sortable: true,
        center: true,
        minWidth: "90px",
        cell: (row) =>
          row?.createdAt
            ? moment(row.createdAt).tz("GMT").format("hh:mm:ss A")
            : "-",
        compact: true,
      },
    ],
    []
  );

  return (
    <>
      <DataTable
        columns={columns}
        data={props.openorderdata}
        theme="dark"
        noHeader={true}
        highlightOnHover
        height={300}
        persistTableHead={true}
        className="ps-3"
      />
    </>
  );
};

export default OpenOrderFixIncomeTable;
