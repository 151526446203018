import { call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "axios";

const loadAsyncUserAccountData = async (payload) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/group-accounts/user-my-accounts/${payload}`
    );
    return data;
  } catch (error) {
    throw new Error("Error");
  }
};

export function* userAccountData({ payload }) {
  try {
    const data = yield call(loadAsyncUserAccountData, payload);
    yield put({
      type: types.constants.USER_ACCOUNT_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.constants.USER_ACCOUNT_DATA_ERROR,
      error,
    });
  }
}
