import { Box } from "@material-ui/core";
import React from "react";

function Sorting({ field = null, active, onSort, subField = null }) {
  return (
    <Box display={"flex"} flexDirection={"column"} marginTop={"1px"}>
      <svg
        width="9"
        viewBox="0 0 6 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
        onClick={() => onSort(field, "asc", subField)}
      >
        <path
          d="M3 0.5L5.625 3.125L5.09625 3.65375L3 1.56125L0.90375 3.65375L0.375 3.125L3 0.5Z"
          fill={
            active?.[`${field}${subField ? "-" + subField : ""}`] == "asc"
              ? "#b4b4b4"
              : "#656565"
          }
        />
      </svg>
      <svg
        width="9"
        viewBox="0 0 6 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ cursor: "pointer" }}
        onClick={() => onSort(field, "desc", subField)}
      >
        <path
          d="M3 3.49994L0.375 0.874941L0.90375 0.346191L3 2.43869L5.09625 0.346191L5.625 0.874941L3 3.49994Z"
          fill={
            active?.[`${field}${subField ? "-" + subField : ""}`] == "desc"
              ? "#b4b4b4"
              : "#656565"
          }
        />
      </svg>
    </Box>
  );
}

export default Sorting;
