import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Redirect, useLocation } from "react-router";
import CompanyLogo from "../../assets/Images/logo.png";

import LoginBgImage from "../../assets/Images/login_page_background.png";
import { useFormik } from "formik";

import * as Yup from "yup";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Zoom,
  TextField,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#C5C5C5",
    backgroundImage: `url(${LoginBgImage})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginCard: {
    backgroundColor: "white",
    padding: "1rem 16px",
    width: "400px",
  },
  loginCardContent: {
    padding: "0px",
    textAlign: "center",
  },

  loginCardFooter: {
    justifyContent: "center",
    flexDirection: "column",
    color: "black",
  },
  footerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  },
  footer: {
    marginTop: "20px",
    color: "#C7C5C7",
  },
  submitBtn: {
    marginTop: "1rem",
    marginBottom: "1rem",
    textTransform: "none",
    "&.MuiButton-contained.Mui-disabled": {
      color: "#fff",
      backgroundColor: "#0B6FB4",
    },
  },
  heading1: {
    color: "gray",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
  },
  heading2: {
    color: "gray",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
    marginBottom: "1.2rem",
  },
  logo: {
    width: "100px",
    height: "100%",
    objectFit: "cover",
    maxWidth: "100%",
    margin: "0 auto",
    marginBottom: "10px",
  },
  "@media (max-width: 500px)": {
    loginCard: {
      width: "80%",
      margin: "0 auto",
    },
  },
  "@media (max-width: 360px)": {
    footerFlex: {
      flexDirection: "column",
    },
  },
}));

const MuiTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& input::placeholder": { color: "grey" },
      "& input": { color: "grey" },

      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);

const Forgotpassword = () => {
  const classes = useStyles();
  const [submitted, setsubmitted] = React.useState(false);

  const { keycloak } = useKeycloak();

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("please enter a valid email")
        .required("email is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldError }) => {
      // console.log({ values });
      setSubmitting(true);
      try {
        await axios.post(
          process.env.REACT_APP_API_URL + "/users/api/v1/users-password/forgot",
          values
        );
        setsubmitted(true);
      } catch (error) {
        console.log({ error });
        if (error.response.data.message) {
          setFieldError("email", error.response.data.message);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  if (keycloak.authenticated) {
    return <Redirect to="/trade" />;
  }

  return (
    <Box className={classes.bg}>
      <Zoom in style={{ transitionDelay: "500ms" }}>
        <Card className={classes.loginCard}>
          <CardMedia
            component="img"
            className={classes.logo}
            image={CompanyLogo}
            alt="SSX logo"
          />
          <CardContent className={classes.loginCardContent}>
            {submitted ? (
              <>
                <Typography className={classes.heading1}>
                  Check your email
                </Typography>
                <Typography className={classes.heading2}>
                  We sent a password reset link to{" "}
                  <strong>{formik.values["email"]}</strong>
                </Typography>
              </>
            ) : (
              <>
                <Typography className={classes.heading1}>
                  Forgot password
                </Typography>
                <Typography className={classes.heading2}>
                  No worries, we will send reset instructions.
                </Typography>
                <MuiTextField
                  variant="outlined"
                  type="text"
                  name={"email"}
                  fullWidth={true}
                  placeholder="enter email id"
                  error={formik.errors["email"] && formik.touched["email"]}
                  helperText={formik.touched["email"] && formik.errors["email"]}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["email"]}
                />
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.submitBtn}
                  disabled={formik.isSubmitting}
                  onClick={formik.submitForm}
                >
                  {formik.isSubmitting ? "loading..." : "Reset password"}
                </Button>
              </>
            )}
          </CardContent>
          <CardActions className={classes.loginCardFooter}>
            <Typography variant="subtitle2" className={classes.footer}>
              Copyright {new Date().getFullYear()} @SecondSTAX
            </Typography>
          </CardActions>
        </Card>
      </Zoom>
    </Box>
  );
};

export default Forgotpassword;
