import { Paper, CircularProgress, Typography, styled } from "@material-ui/core";
import React, { memo } from "react";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.getContrastText("#252525"),
  padding: theme.spacing(2),
  boxShadow: "none",
}));

function Loader({ text = "Loading, please wait...", showBg = true }) {
  return (
    <Item style={{ backgroundColor: showBg ? "#252525" : "transparent" }}>
      <CircularProgress color="primary" />
      <Typography variant="h6">{text}</Typography>
    </Item>
  );
}

export default memo(Loader);
