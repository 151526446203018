import { lazy } from "react";
import PastEod from "../components/Admin/PastEod/PastEod";

const Bonds = lazy(() =>
  import(/* webpackChunkName: "Bonds" */ "../components/Admin/Bonds/Bonds")
);

const BondCreate = lazy(() =>
  import(
    /* webpackChunkName: "BondCreate" */ "../components/Admin/Bonds/BondCreate"
  )
);

const BondUpdate = lazy(() =>
  import(
    /* webpackChunkName: "BondUpdate" */ "../components/Admin/Bonds/BondUpdate"
  )
);

const Clients = lazy(() =>
  import(
    /* webpackChunkName: "Clients" */ "../components/Admin/Clients/Clients"
  )
);

const ClientCreate = lazy(() =>
  import(
    /* webpackChunkName: "ClientCreate" */ "../components/Admin/Clients/ClientCreate"
  )
);

const ClientUpdate = lazy(() =>
  import(
    /* webpackChunkName: "ClientUpdate" */ "../components/Admin/Clients/ClientUpdate"
  )
);

const Currencies = lazy(() =>
  import(
    /* webpackChunkName: "Currencies" */ "../components/Admin/Currencies/Currencies"
  )
);

const CurrencyCreate = lazy(() =>
  import(
    /* webpackChunkName: "CurrencyCreate" */ "../components/Admin/Currencies/CurrencyCreate"
  )
);

const CurrencyUpdate = lazy(() =>
  import(
    /* webpackChunkName: "CurrencyUpdate" */ "../components/Admin/Currencies/CurrencyUpdate"
  )
);

const Funds = lazy(() =>
  import(/* webpackChunkName: "Funds" */ "../components/Admin/Funds/Funds")
);

const FundCreate = lazy(() =>
  import(
    /* webpackChunkName: "FundCreate" */ "../components/Admin/Funds/FundCreate"
  )
);

const FundUpdate = lazy(() =>
  import(
    /* webpackChunkName: "FundUpdate" */ "../components/Admin/Funds/FundUpdate"
  )
);

const FundDepositWithdrawalCreate = lazy(() =>
  import(
    /* webpackChunkName: "FundDepositWithdrawalCreate" */ "../components/Admin/Funds/FundDepositWithdrawalCreate"
  )
);

const FundPositionCreate = lazy(() =>
  import(
    /* webpackChunkName: "FundPositionCreate" */ "../components/Admin/Funds/FundPositionCreate"
  )
);

const FundViewStatement = lazy(() =>
  import(
    /* webpackChunkName: "FundViewStatement" */ "../components/Admin/Funds/FundViewStatement"
  )
);

const Venues = lazy(() =>
  import(/* webpackChunkName: "Venues" */ "../components/Admin/Venues/Venues")
);

const VenueCreate = lazy(() =>
  import(
    /* webpackChunkName: "VenueCreate" */ "../components/Admin/Venues/VenueCreate"
  )
);

const VenueUpdate = lazy(() =>
  import(
    /* webpackChunkName: "VenueUpdate" */ "../components/Admin/Venues/VenueUpdate"
  )
);

const Equities = lazy(() =>
  import(
    /* webpackChunkName: "Equities" */ "../components/Admin/Equities/Equities"
  )
);

const EquityCreate = lazy(() =>
  import(
    /* webpackChunkName: "EquityCreate" */ "../components/Admin/Equities/EquityCreate"
  )
);

const EquityUpdate = lazy(() =>
  import(
    /* webpackChunkName: "EquityUpdate" */ "../components/Admin/Equities/EquityUpdate"
  )
);

const Users = lazy(() =>
  import(/* webpackChunkName: "Users" */ "../components/Admin/Users/Users")
);

const UserCreate = lazy(() =>
  import(
    /* webpackChunkName: "UserCreate" */ "../components/Admin/Users/UserCreate"
  )
);

const UserUpdate = lazy(() =>
  import(
    /* webpackChunkName: "UserUpdate" */ "../components/Admin/Users/UserUpdate"
  )
);

const UserRoleUpdate = lazy(() =>
  import(
    /* webpackChunkName: "UserRoleUpdate" */ "../components/Admin/Users/UserRoleUpdate"
  )
);

const UserPermission = lazy(() =>
  import(
    /* webpackChunkName: "UserPermission" */ "../components/Admin/Users/UserPermission"
  )
);

const Roles = lazy(() =>
  import(/* webpackChunkName: "Roles" */ "../components/Admin/Roles/Roles")
);

const RoleCreate = lazy(() =>
  import(
    /* webpackChunkName: "RoleCreate" */ "../components/Admin/Roles/RoleCreate"
  )
);

const ExchangeRates = lazy(() =>
  import(
    /* webpackChunkName: "ExchangeRates" */ "../components/Admin/ExchangeRates/ExchangeRates"
  )
);

const ExchangeRatesCreate = lazy(() =>
  import(
    /* webpackChunkName: "ExchangeRatesCreate" */ "../components/Admin/ExchangeRates/ExchangeRatesCreate"
  )
);

const ExchangeRatesUpdate = lazy(() =>
  import(
    /* webpackChunkName: "ExchangeRatesUpdate" */ "../components/Admin/ExchangeRates/ExchangeRatesUpdate"
  )
);

const Orders = lazy(() =>
  import(/* webpackChunkName: "Orders" */ "../components/Admin/Orders/Orders")
);

const Positions = lazy(() =>
  import(
    /* webpackChunkName: "Positions" */ "../components/Admin/Positions/Positions"
  )
);

const Trades = lazy(() =>
  import(/* webpackChunkName: "Trades" */ "../components/Admin/Trades/Trades")
);

const Countries = lazy(() =>
  import(
    /* webpackChunkName: "Countries" */ "../components/Admin/Countries/Countries"
  )
);

const CountryCreate = lazy(() =>
  import(
    /* webpackChunkName: "CountryCreate" */ "../components/Admin/Countries/CountryCreate"
  )
);

const CountryUpdate = lazy(() =>
  import(
    /* webpackChunkName: "CountryUpdate" */ "../components/Admin/Countries/CountryUpdate"
  )
);

const Brokers = lazy(() =>
  import(
    /* webpackChunkName: "Brokers" */ "../components/Admin/Brokers/Brokers"
  )
);

const BrokerCreate = lazy(() =>
  import(
    /* webpackChunkName: "BrokerCreate" */ "../components/Admin/Brokers/BrokerCreate"
  )
);

const BrokerUpdate = lazy(() =>
  import(
    /* webpackChunkName: "BrokerUpdate" */ "../components/Admin/Brokers/BrokerUpdate"
  )
);

const Eod = lazy(() =>
  import(/* webpackChunkName: "Eod" */ "../components/Admin/Eod/Eod")
);

const HolidayCalendar = lazy(() =>
  import(
    /* webpackChunkName: "HolidayCalendar" */ "../components/Admin/HolidayCalendars/HolidayCalendar"
  )
);

const HolidayCalendarCreate = lazy(() =>
  import(
    /* webpackChunkName: "HolidayCalendarCreate" */ "../components/Admin/HolidayCalendars/HolidayCalendarCreate"
  )
);

const HolidayCalendarUpdate = lazy(() =>
  import(
    /* webpackChunkName: "HolidayCalendarUpdate" */ "../components/Admin/HolidayCalendars/HolidayCalendarUpdate"
  )
);

const ExternalTransactions = lazy(() =>
  import(
    /* webpackChunkName: "ExternalTransactions" */ "../components/Admin/ExternalTransactions/ExternalTransactions"
  )
);

const Schedules = lazy(() =>
  import(
    /* webpackChunkName: "Schedules" */ "../components/Admin/Schedules/Schedule"
  )
);

const ScheduleCreate = lazy(() =>
  import(
    /* webpackChunkName: "ScheduleCreate" */ "../components/Admin/Schedules/ScheduleCreate"
  )
);

const ScheduleUpdate = lazy(() =>
  import(
    /* webpackChunkName: "ScheduleUpdate" */ "../components/Admin/Schedules/ScheduleUpdate"
  )
);


// const TradeConfirmation = lazy(() =>
//   import(/* webpackChunkName: "Users" */ "../components/Admin/Reports/TradeConfirmation")
// );
// const NetSettlement = lazy(() =>
//   import(/* webpackChunkName: "Users" */ "../components/Admin/Reports/NetSettlement")
// );
// const ClientList = lazy(() =>
//   import(/* webpackChunkName: "Users" */ "../components/Admin/Reports/ClientList")
// );
// const CashPosition = lazy(() =>
//   import(/* webpackChunkName: "Users" */ "../components/Admin/Reports/CashPosition")
// );
// const TradesReport = lazy(() =>
//   import(/* webpackChunkName: "Users" */ "../components/Admin/Reports/Trades")
// );
// const BrokerActivitySummary = lazy(() =>
//   import(/* webpackChunkName: "Users" */ "../components/Admin/Reports/BrokerActivitySummary")
// );
// const PayoutPositions = lazy(() =>
//   import(/* webpackChunkName: "Users" */ "../components/Admin/Reports/PayoutPositions")
// );

export const menus = [
  {
    title: "Transactions",
    menus: [
      {
        title: "External Transactions",
        to: "/admin/external-transactions",
        component: ExternalTransactions,
        roles: [
          "DF_ADMIN",
          "bizio_finance_admin",
          "BROKER_ADMIN",
          "OPERATIONS",
        ],
        exact: true,
        subs: [],
      },
      {
        title: "Orders",
        to: "/admin/orders",
        component: Orders,
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],

        exact: true,
        subs: [],
      },
      {
        title: "Positions",
        to: "/admin/positions",
        component: Positions,
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        exact: true,
        subs: [],
      },
      {
        title: "Trades",
        to: "/admin/trades",
        roles: [
          "DF_ADMIN",
          "bizio_finance_admin",
          "BROKER_ADMIN",
          "OPERATIONS",
        ],
        component: Trades,
        exact: true,
        subs: [],
      },
      // {
      //   title: "Journal Vouchers",
      //   to: "/admin/journal-vouchers",
      //   component: null,
      //   exact: true,
      //   subs: [],
      // },
    ],
  },
  {
    title: "Masters",
    menus: [
      {
        title: "Brokers",
        to: "/admin/brokers",
        component: Brokers,
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        exact: true,
        subs: [
          {
            to: "/create",
            component: BrokerCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/:broker/update",
            component: BrokerUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: false,
          },
        ],
      },
      {
        title: "Clients",
        to: "/admin/clients",
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        component: Clients,
        exact: true,
        subs: [
          {
            to: "/create",
            component: ClientCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/:client/update",
            component: ClientUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: false,
          },
        ],
      },
      {
        title: "Group Accounts",
        to: "/admin/group-accounts",
        component: Funds,
        exact: true,
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        subs: [
          {
            to: "/create",
            component: FundCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/:fund/update",
            component: FundUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: false,
          },
          {
            to: "/:fund/deposit-withdrawal/create",
            component: FundDepositWithdrawalCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],

            exact: false,
          },
          {
            to: "/:fund/positions/create",
            component: FundPositionCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],

            exact: false,
          },
          {
            to: "/:fund/view-statements",
            component: FundViewStatement,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],

            exact: false,
          },
        ],
      },
      {
        title: "Venues",
        to: "/admin/venues",
        component: Venues,
        roles: ["DF_ADMIN", "bizio_finance_admin"],

        exact: true,
        subs: [
          {
            to: "/create",
            component: VenueCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin"],

            exact: true,
          },
          {
            to: "/:venue/update",
            component: VenueUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin"],
            exact: false,
          },
        ],
      },
      {
        title: "Countries",
        to: "/admin/countries",
        component: Countries,
        roles: ["DF_ADMIN", "bizio_finance_admin"],
        exact: true,
        subs: [
          {
            to: "/create",
            component: CountryCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin"],
            exact: true,
          },
          {
            to: "/:country/update",
            component: CountryUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin"],
            exact: false,
          },
        ],
      },
    ],
  },
  {
    title: "Market Data",
    menus: [
      {
        title: "Bonds",
        to: "/admin/bonds",
        component: Bonds,
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        exact: true,
        subs: [
          {
            to: "/create",
            component: BondCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/:bond/update",
            component: BondUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],

            exact: false,
          },
        ],
      },
      {
        title: "Equity",
        to: "/admin/equities",
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        component: Equities,
        exact: true,
        subs: [
          {
            to: "/create",
            component: EquityCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/:equity/update",
            component: EquityUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],

            exact: false,
          },
        ],
      },
      {
        title: "Currencies",
        to: "/admin/currencies",
        component: Currencies,
        roles: ["DF_ADMIN", "bizio_finance_admin"],
        exact: true,
        subs: [
          {
            to: "/create",
            component: CurrencyCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin"],
            exact: true,
          },
          {
            to: "/:currency/update",
            roles: ["DF_ADMIN", "bizio_finance_admin"],

            component: CurrencyUpdate,
            exact: false,
          },
        ],
      },
      {
        title: "Exchange Rates",
        to: "/admin/exchange-rates",
        component: ExchangeRates,
        roles: ["DF_ADMIN", "bizio_finance_admin"],
        exact: true,
        subs: [
          {
            to: "/create",
            component: ExchangeRatesCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin"],
            exact: true,
          },
          {
            to: "/:exchangeRate/update",
            component: ExchangeRatesUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin"],
            exact: false,
          },
        ],
      },
      {
        title: "Daily EOD Upload",
        to: "/admin/eod-data-upload",
        component: Eod,
        exact: true,
        roles: ["DF_ADMIN", "bizio_finance_admin"],
        subs: [],
      },
      {
        title: "Historical EOD Upload",
        to: "/admin/historical-eod-upload",
        component: PastEod,
        exact: true,
        roles: ["DF_ADMIN", "bizio_finance_admin"],
        subs: [],
      },
    ],
  },
  {
    title: "Setup",
    menus: [
      {
        title: "Users",
        to: "/admin/users",
        component: Users,
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        exact: true,
        subs: [
          {
            to: "/create",
            component: UserCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/permissions",
            component: UserPermission,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/:user/update",
            component: UserUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: false,
          },
          {
            to: "/:user/roles",
            component: UserRoleUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: false,
          },
        ],
      },
      {
        title: "Roles",
        to: "/admin/roles",
        component: Roles,
        exact: true,
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        subs: [
          {
            to: "/create",
            component: RoleCreate,
            exact: true,
          },
        ],
      },
      {
        title: "Holiday Calendars",
        to: "/admin/holiday-calendars",
        component: HolidayCalendar,
        exact: true,
        roles: ["DF_ADMIN", "bizio_finance_admin"],
        subs: [
          {
            to: "/create",
            component: HolidayCalendarCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/:holiday/update",
            component: HolidayCalendarUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: false,
          },
        ],
      },
      {
        title: "Commissions/Taxes/Duties Schedule",
        to: "/admin/schedule",
        component: Schedules,
        exact: true,
        roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
        subs: [
          {
            to: "/create",
            component: ScheduleCreate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: true,
          },
          {
            to: "/:schedule/update",
            component: ScheduleUpdate,
            roles: ["DF_ADMIN", "bizio_finance_admin", "BROKER_ADMIN"],
            exact: false,
          },
        ],
      },
      // {
      //   title: "Account settings",
      //   to: "/admin/account-settings",
      //   component: null,
      //   exact: true,
      //   subs: [],
      // },
      // {
      //   title: "Trading settings",
      //   to: "/admin/trading-settings",
      //   component: null,
      //   exact: true,
      //   subs: [],
      // },
    ],
  },
  // {
  //   title: "Reports",
  //   menus: [
  //     {
  //       title: "Trade Confirmation",
  //       to: "/admin/reports/trade-confirmation-report",
  //       component: TradeConfirmation,
  //       exact: true,
  //       roles: ["DF_ADMIN", "bizio_finance_admin"],
  //       subs: [],
  //     },
  //     {
  //       title: "Net Settlement",
  //       to: "/admin/reports/net-settlement",
  //       component: NetSettlement,
  //       exact: true,
  //       roles: ["DF_ADMIN", "bizio_finance_admin"],
  //       subs: [],
  //     },
  //     {
  //       title: "Client List",
  //       to: "/admin/reports/client-list",
  //       component: ClientList,
  //       exact: true,
  //       roles: ["DF_ADMIN", "bizio_finance_admin"],
  //       subs: [],
  //     },
  //     {
  //       title: "Cash Position",
  //       to: "/admin/reports/cash-position",
  //       component: CashPosition,
  //       exact: true,
  //       roles: ["DF_ADMIN", "bizio_finance_admin"],
  //       subs: [],
  //     },
  //     {
  //       title: "Trades",
  //       to: "/admin/reports/trades",
  //       component: TradesReport,
  //       exact: true,
  //       roles: ["DF_ADMIN", "bizio_finance_admin"],
  //       subs: [],
  //     },
  //     {
  //       title: "Broker Activity Summary",
  //       to: "/admin/reports/broker-activity-summary",
  //       component: BrokerActivitySummary,
  //       exact: true,
  //       roles: ["DF_ADMIN", "bizio_finance_admin"],
  //       subs: [],
  //     },
  //     {
  //       title: "Payout Positions",
  //       to: "/admin/reports/payout-positions",
  //       component: PayoutPositions,
  //       exact: true,
  //       roles: ["DF_ADMIN", "bizio_finance_admin"],
  //       subs: [],
  //     },
  //   ],
  // },
];
