import * as types from "./types";

export const loading = () => {
  return { type: types.constants.LOADING_VENUE };
};

export const sortVenue = (data) => {
  return {
    type: types.constants.SORT_VENUE,
    data,
  };
};

export const filterVenue = (search) => {
  return { type: types.constants.FILTER_VENUE, search };
};

export const loadAllVenues = () => {
  return { type: types.constants.GET_VENUE };
};

export const loadVenue = (payload) => {
  return { type: types.constants.GET_VENUE_BY_ID, payload };
};

export const storeVenue = (payload) => {
  return { type: types.constants.STORE_VENUE, payload };
};

export const updateVenue = (payload) => {
  return { type: types.constants.UPDATE_VENUE, payload };
};

export const deleteVenue = (payload) => {
  return { type: types.constants.DELETE_VENUE, payload };
};
