import { sortByAsc, sortByDesc } from "../../helpers/utilities";
import * as types from "../actions/types";

const INITIAL_EXTERNAL_TRANSACTION = {
  loading: false,
  error: null,
  items: [],
  filteredItems: [],
  sortBy: {},
};

export const externalTransactionReducer = (
  state = INITIAL_EXTERNAL_TRANSACTION,
  action
) => {
  switch (action.type) {
    case types.constants.GET_EXTERNAL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        error: null,
      };

    case types.constants.LOADING_EXTERNAL_TRANSACTIONS:
      return { ...state, loading: true };

    case types.constants.STATUS_CHANGE_EXTERNAL_TRANSACTIONS_SUCCESS:
      return { ...state, loading: false, error: null };

    case types.constants.ACCOUNT_FILTER_EXTERNAL_TRANSACTION:
      let externalTransactions = [];

      if (action.search) {
        state.items.forEach((_) => {
          if (_.fundAccount.id == action.search) {
            externalTransactions.push(_);
          }
        });
      } else {
        externalTransactions = [...state.items];
      }

      return { ...state, filteredItems: externalTransactions, sortBy: {} };

    case types.constants.TYPE_FILTER_EXTERNAL_TRANSACTION:
      let externalTransactions_ = [];

      if (action.search) {
        state.items.forEach((_) => {
          if (
            _?.type?.toString()?.toLowerCase() ==
            action.search.toString().toLowerCase()
          ) {
            externalTransactions_.push(_);
          }
        });
      } else {
        externalTransactions_ = [...state.items];
      }

      return { ...state, filteredItems: externalTransactions_, sortBy: {} };

    case types.constants.SORT_EXTERNAL_TRANSACTION:
      const sortBy = {
        ...state.sortBy,
        [`${action.data.field}${
          action.data.subField ? "-" + action.data.subField : ""
        }`]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(
          sortedItems,
          action.data.field,
          action.data.subField
        );
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(
          sortedItems,
          action.data.field,
          action.data.subField
        );
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.GET_EXTERNAL_TRANSACTIONS_ERROR:
    case types.constants.STATUS_CHANGE_EXTERNAL_TRANSACTIONS_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
