import * as types from "./types";

export const loading = () => {
  return { type: types.constants.USER_POLICY_DATA_LOADING };
};

export const loadUserPolicyData = () => {
  return { type: types.constants.USER_POLICY_DATA };
};

export const updateUserPolicyData = () => {
  return { type: types.constants.USER_POLICY_UPDATE };
};

export const cancelUserPolicyData = () => {
  return { type: types.constants.USER_POLICY_CANCEL };
};