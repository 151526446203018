import * as types from "./types";

export const loading = () => {
  return { type: types.constants.LOADING_ORDER };
};

export const tradeLoading = () => {
  return { type: types.constants.LOADING_ORDER_TRADE };
};

export const showHandle = (val) => {
  return { type: types.constants.SHOW_HANDLE, val };
};

export const sortOrder = (data) => {
  return {
    type: types.constants.SORT_ORDER,
    data,
  };
};

export const filterOrder = (search) => {
  return { type: types.constants.FILTER_ORDER, search };
};

export const accountFilterOrder = (search) => {
  return { type: types.constants.ACCOUNT_FILTER_ORDER, search };
};

export const loadAllOrders = (payload) => {
  return { type: types.constants.GET_ORDER, payload };
};

export const loadOrder = (payload) => {
  return { type: types.constants.GET_ORDER_BY_ID, payload };
};

export const loadOrderTrades = (payload) => {
  return { type: types.constants.ORDER_TRADE, payload };
};

export const storeOrder = (payload) => {
  return { type: types.constants.STORE_ORDER, payload };
};

export const cancelOrder = (payload) => {
  return { type: types.constants.ORDER_CANCEL, payload };
};

export const closeOrder = (payload) => {
  return { type: types.constants.ORDER_CLOSE, payload };
};
