import * as types from "../actions/types";
import { sortByAsc, sortByDesc } from "../../helpers/utilities";

const INITIAL_EXCHANGE_RATE = {
  loading: false,
  error: null,
  items: [],
  exchangeRate: null,
  filteredItems: [],
  sortBy: {},
};

export const exchangeRateReducer = (state = INITIAL_EXCHANGE_RATE, action) => {
  switch (action.type) {
    case types.constants.SORT_EXCHANGE_RATES:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field, "name");
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field, "name");
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_EXCHANGE_RATES:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter(
        (item) =>
          item?.currency?.name.match(filter) ||
          item?.baseCurrency?.name.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_EXCHANGE_RATES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        error: null,
      };

    case types.constants.LOADING_EXCHANGE_RATE:
      return { ...state, loading: true };

    case types.constants.GET_EXCHANGE_RATE_BY_ID_SUCCESS:
      return { ...state, loading: false, exchangeRate: action.exchangeRate };

    case types.constants.STORE_EXCHANGE_RATE_SUCCESS:
    case types.constants.UPDATE_EXCHANGE_RATE_SUCCESS:
      return { ...state, loading: false, exchangeRate: null };

    case types.constants.DELETE_EXCHANGE_RATE_SUCCESS:
      const exchangeRateIndex = state.items.findIndex(
        (item) => item.id === action.id
      );

      let newState = { ...state };

      if (exchangeRateIndex >= 0) {
        newState.items.splice(exchangeRateIndex, 1);
      }

      newState.loading = false;

      return newState;

    case types.constants.GET_EXCHANGE_RATES_ERROR:
    case types.constants.STORE_EXCHANGE_RATE_ERROR:
    case types.constants.UPDATE_EXCHANGE_RATE_ERROR:
    case types.constants.DELETE_EXCHANGE_RATE_ERROR:
    case types.constants.GET_EXCHANGE_RATE_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
