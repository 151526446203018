import { useKeycloak } from "@react-keycloak/web";
import { NavLink } from "react-router-dom";
import { Redirect } from "react-router";

import LoginBgImage from "../../assets/Images/login_page_background.png";
import CompanyLogo from "../../assets/Images/logo.png";
import LoginPageGraphImg from "../../assets/Images/login_pag_graph.png";

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Zoom,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#C5C5C5",
    backgroundImage: `url(${LoginBgImage})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginCard: {
    backgroundColor: "white",
    padding: "1rem 0px",
    maxWidth: "300px",
  },
  loginCardContent: {
    padding: "0px",
    textAlign: "center",
  },
  logo: {
    width: "100px",
    height: "100%",
    objectFit: "cover",
    maxWidth: "100%",
    margin: "0 auto",
    marginBottom: "10px",
  },
  cardContentImg: {
    display: "block",
    width: "100%",
    objectFit: "cover",
    maxWidth: "100%",
    minHeight: "150px",
    backgroundColor: "#aaa",
  },
  loginBtn: {
    padding: "0.5rem 1.5rem",
    marginTop: "1rem",
    marginBottom: "1rem",
    textTransform: "none",
  },
  loginCardFooter: {
    justifyContent: "center",
    flexDirection: "column",
    color: "black",
  },
  footerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  },
  registerLink: {
    color: theme.palette.primary.main,
    fontWeight: "500",
  },
  footer: {
    marginTop: "20px",
    color: "#C7C5C7",
  },
  "@media (max-width: 500px)": {
    loginCard: {
      maxWidth: "80%",
      margin: "0 auto",
    },
  },
  "@media (max-width: 360px)": {
    footerFlex: {
      flexDirection: "column",
    },
  },
}));

const Login = () => {
  const classes = useStyles();

  const { keycloak } = useKeycloak();

  if (keycloak.authenticated) {
    return <Redirect to="/trade" />;
  }

  return (
    <Box className={classes.bg}>
      <Zoom in style={{ transitionDelay: "500ms" }}>
        <Card className={classes.loginCard}>
          <CardMedia
            component="img"
            className={classes.logo}
            image={CompanyLogo}
            alt="SSX logo"
          />
          <CardContent className={classes.loginCardContent}>
            <Box
              component="img"
              src={LoginPageGraphImg}
              alt="Login page graph image"
              className={classes.cardContentImg}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.loginBtn}
              onClick={() => {
                keycloak.login();
              }}
            >
              <Typography variant="body2">SSO Login</Typography>
            </Button>
          </CardContent>
          <CardActions className={classes.loginCardFooter}>
            {/* <div className={classes.footerFlex}>
              <Typography variant="subtitle2">
                Don't have an account?
              </Typography>
              <NavLink to="/register" className={classes.registerLink}>
                Sign up?
              </NavLink>
            </div> */}
            <Typography variant="subtitle2" className={classes.footer}>
              Copyright {new Date().getFullYear()} @SecondSTAX
            </Typography>
          </CardActions>
        </Card>
      </Zoom>
    </Box>
  );
};

export default Login;
