import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useMemo } from "react";
import { useEffect, useState } from "react";
import CustomOverlay from "../../CustomOverlay/CustomOverlay";
import Alert from "@material-ui/lab/Alert";
import { useRef } from "react";
import AlertTitle from "@material-ui/lab/AlertTitle";
const PastEod = () => {
  const [fetching, setIsFetching] = useState(false);
  const [errors, setErrors] = useState(false);
  const [completeError, setCompleteError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [lastJob, setLastJob] = useState(null);
  const [file, setFile] = useState(null);
  const [dateFormat, setDateFormat] = useState("M/d/yy");

  const hide = useMemo(() => {
    if (completeError) return true;
    if (lastJob?.status === "STARTED") return true;
    if (successMessage) return true;
  }, [completeError, lastJob, successMessage]);

  const fileInputRef = useRef();

  const getLastJob = async () => {
    setIsFetching(true);
    try {
      let { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/securities/api/v1/batches/last-job?jobName=HistoricalDataJob`
      );
      setLastJob(data);
      if (data?.status === "COMPLETED" && data?.exitMessage) {
        setCompleteError(data?.exitMessage);
      }
    } catch (error) {
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    getLastJob();
  }, []);

  const onFileUpload = (e) => {
    let uploaded_file = e.target.files[0];
    console.log({ uploaded_file });
    if (["text/csv", ".csv"].includes(uploaded_file.type)) {
      setFile(uploaded_file ?? null);
    } else {
      setErrors("please upload valid csv file");
      fileInputRef.current.value = "";
    }
  };

  const resetForm = async () => {
    fileInputRef.current.value = "";
    console.log(fileInputRef.current);
  };

  const submitForm = async () => {
    try {
      setIsFetching(true);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("dateFormat", dateFormat);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/securities/api/v1/historical-data`,
        formData
      );
      setErrors(null);
      setIsFetching(false);
      setSuccessMessage("Upload is queued");
    } catch (error) {
      if (error?.response?.data?.message) {
        setErrors(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <CustomOverlay show={fetching}>
        <Container>
          <Box
            bgcolor={"#252525"}
            borderRadius={"5px"}
            marginTop={1}
            marginBottom={1}
          >
            <Box p={2}>
              <Typography variant="h6">Historic EOD Data Upload</Typography>
            </Box>

            {lastJob?.status === "STARTED" && (
              <Box marginX={"20px"}>
                <Alert severity="warning" variant="outlined">
                  Your last upload is in progress ...
                </Alert>
              </Box>
            )}

            {successMessage && (
              <Box marginX={"20px"}>
                <Alert severity="success" variant="outlined">
                  {successMessage}
                </Alert>
              </Box>
            )}

            {completeError && (
              <Box marginX={"20px"}>
                <Alert
                  variant="outlined"
                  severity="error"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => setCompleteError("")}
                    >
                      Ok
                    </Button>
                  }
                >
                  <AlertTitle>
                    Your last upload was a success except the following:
                  </AlertTitle>
                  <ul>
                    {completeError.split("\n").map((e) => (
                      <li key={e}>
                        {e}
                        <br />
                      </li>
                    ))}
                  </ul>
                </Alert>
              </Box>
            )}

            <Box p={2}>
              {hide ? (
                <></>
              ) : (
                <>
                  <Grid container spacing={1} style={{ marginBottom: "20px" }}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type={"file"}
                        inputProps={{ accept: ".csv" }}
                        inputRef={fileInputRef}
                        onChange={(e) => {
                          onFileUpload(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={dateFormat}
                        label="date format"
                        select
                        onChange={(e) => {
                          setDateFormat(e.target.value);
                        }}
                      >
                        <MenuItem value="M/d/yy">M/d/yy</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      {errors && (
                        <Typography color="error" variant="caption">
                          {errors}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="error" variant="body1">
                        Please make sure to follow the {" "}
                        <Link
                          color="error"
                          underline="always"
                          variant="body1"
                          component={"a"}
                          download="historical-data-eod.csv"
                          href="/historical-data-eod.csv"
                        >
                         template
                        </Link>
                        {" "}file.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box style={{ display: "flex", gap: "10px" }}>
                    <Button variant="contained" onClick={resetForm}>
                      Reset
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      disabled={file === null || lastJob?.status == "STARTED"}
                      onClick={() => submitForm()}
                    >
                      Upload
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Container>
      </CustomOverlay>
    </>
  );
};

export default PastEod;
