import { combineReducers } from "redux";
import { accountReducer } from "./account.reducers";
import { authReducer } from "./auth.reducers";
import { fixedIncomeReducer } from "./fixedIncome.reducers";
import { fixedIncomeCountReducers } from "./fixedIncomeCount.reducers";
import { tradeReducer } from "./trade.reducers";
import { watchlistReducer } from "./watchlist.reducers";
import { watchlistBondReducer } from "./watchlistBond.reducers";

import { bondReducer } from "./bond.reducers";
import { brokerReducer } from "./broker.reducers";
import { clientReducer } from "./client.reducers";
import { countryReducer } from "./country.reducers";
import { currencyReducer } from "./currency.reducers";
import { equityReducer } from "./equity.reducers";
import { exchangeRateReducer } from "./exchangeRate.reducers";
import { externalTransactionReducer } from "./externalTransaction.reducers";
import { fundReducer } from "./fund.reducers";
import { fundAccount2Reducer } from "./fundAccount.reducers";
import { fundBalanceReducer } from "./fundBalanceSheet.reducers";
import { fundLedgerReducer } from "./fundLedger.reducers";
import { holidayReducer } from "./holiday.reducers";
import { orderReducer } from "./order.reducers";
import { permissionReducer } from "./permission.reducers";
import { positionReducer } from "./position.reducers";
import { roleReducer } from "./role.reducers";
import { scheduleReducer } from "./schedule.reducers";
import { trade2Reducer } from "./trade2.reducers";
import { userReducer } from "./user.reducers";
import { userAccountReducer } from "./userAccount.reducers";
import { userPolicyReducer } from "./userPolicy.reducers";
import { venueReducer } from "./venue.reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  trade: tradeReducer,
  watchlist: watchlistReducer,
  watchlistbond: watchlistBondReducer,
  accountdata: accountReducer,
  fixedincomedata: fixedIncomeReducer,
  fixedincomecount: fixedIncomeCountReducers,
  bonds: bondReducer,
  equities: equityReducer,
  currencies: currencyReducer,
  venues: venueReducer,
  users: userReducer,
  roles: roleReducer,
  exchangeRates: exchangeRateReducer,
  clients: clientReducer,
  funds: fundReducer,
  fundAccount2s: fundAccount2Reducer,
  orders: orderReducer,
  positions: positionReducer,
  trades: trade2Reducer,
  fundBalanceSheets: fundBalanceReducer,
  fundLedgers: fundLedgerReducer,
  permissions: permissionReducer,
  countries: countryReducer,
  brokers: brokerReducer,
  userAccount: userAccountReducer,
  userPolicy: userPolicyReducer,
  holidays: holidayReducer,
  externalTransactions: externalTransactionReducer,
  schedules: scheduleReducer,
});

export default rootReducer;
