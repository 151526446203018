import * as types from "../actions/types";
import { sortByAsc, sortByDesc } from "../../helpers/utilities";

const INITIAL_POSITION = {
  loading: false,
  error: null,
  items: [],
  position: null,
  filteredItems: [],
  sortBy: {},
};

export const positionReducer = (state = INITIAL_POSITION, action) => {
  switch (action.type) {
    case types.constants.SORT_POSITION:
      const sortBy = {
        ...state.sortBy,
        [`${action.data.field}${
          action.data.subField ? "-" + action.data.subField : ""
        }`]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(
          sortedItems,
          action.data.field,
          action.data.subField
        );
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(
          sortedItems,
          action.data.field,
          action.data.subField
        );
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_POSITION:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter((item) =>
        item.ticker.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        error: null,
      };

    case types.constants.LOADING_POSITION:
      return { ...state, loading: true };

    case types.constants.GET_POSITION_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
