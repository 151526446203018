import React from "react";
import { useDispatch } from "react-redux";
import * as fixedIncome from "../../../store/actions/fixedIncome.action";
import { useFormik } from "formik";
import * as Yup from "yup";
import swal from "sweetalert";
import CurrencyFormat from "react-currency-format";
import { toast } from "react-toastify";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#252525",
      color: "#dedede",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
    },
    "& .MuiDialogContent-root": {
      color: "#dedede !important",
    },
  },
  dialogActions: {
    margin: "0 auto",
    marginTop: "2rem",
    "& > *:first-child": {
      marginRight: "0.5rem",
    },
  },
  actionBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#145582",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#145582",
    },
    "&:disabled": {
      backgroundColor: "#083a5e",
      color: "#888888",
    },
  },
  cancelBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#3d3d3d",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#3d3d3d",
    },
  },
  dialogContentFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "1rem 0",
    "& > *": {
      padding: "0.5rem",
      border: "1px solid grey",
      borderRadius: "2px",
      flexGrow: "1",
      flexShrink: "0 !important",
    },
  },
  reviewBadge: {
    backgroundColor: "#22312c",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.7rem 1.5rem",
    borderRadius: "10px",
    minWidth: "150px",
  },
}));

const ReviewOrderFormModal = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const rejectOffer = () => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to reject this offer ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let body = {
          values: [
            { name: "order", value: props?.order?.order },
            { name: "status", value: "rejected" },
            {
              name: "offer_amount",
              value: props?.order?.offer_amount?.toString(),
            },
            {
              name: "offer_quantity",
              value: props?.order?.offer_quantity?.toString(),
            },
            {
              name: "offer_yield",
              value: props?.order?.offer_yield?.toString(),
            },
            {
              name: "order_amount",
              value: props?.order?.order_amount?.toString(),
            },
            {
              name: "dirty_price",
              value: props?.order?.dirty_price?.toString(),
            },
            {
              name: "clean_price",
              value: props?.order?.clean_price?.toString(),
            },
            { name: "state", value: "closed" },
            { name: "mt_client", value: props?.order?.mt_client?.toString() },
            { name: "mm_client", value: props?.order?.mm_client?.toString() },
            {
              name: "offer_consideration",
              value: props?.order?.offer_consideration?.toString(),
            },
          ],
        };
        if (localStorage.getItem("client") === props?.order?.mm_client) {
          dispatch(
            fixedIncome.updateOfferOrderAction({
              recordID: props?.order.recordID,
              orderdata: JSON.stringify(body),
            })
          );
          setTimeout(() => {
            dispatch(fixedIncome.getIncomingOfferOrderAction());
          }, 2000);
        } else {
          toast.error(`Action declined due to permission issue`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        handleClose();
      }
    });
  };

  const initialValues = {
    yield: "",
    amount: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      yield: Yup.number(),
      amount: Yup.number(),
    }),
    onSubmit: () => {
      swal({
        title: "Are you sure?",
        text: "Are you sure you want to accept this offer ?",
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((confirm) => {
        if (confirm) {
          let body = {
            values: [
              { name: "order", value: props?.order?.order },
              { name: "status", value: "accepted" },
              {
                name: "offer_amount",
                value: props?.order?.offer_amount?.toString(),
              },
              {
                name: "offer_quantity",
                value: props?.order?.offer_quantity?.toString(),
              },
              {
                name: "offer_yield",
                value: props?.order?.offer_yield?.toString(),
              },
              {
                name: "order_amount",
                value: props?.order?.order_amount?.toString(),
              },
              {
                name: "dirty_price",
                value: props?.order?.dirty_price?.toString(),
              },
              {
                name: "clean_price",
                value: props?.order?.clean_price?.toString(),
              },
              { name: "state", value: "closed" },
              { name: "mt_client", value: props?.order?.mt_client?.toString() },
              { name: "mm_client", value: props?.order?.mm_client?.toString() },
              {
                name: "offer_consideration",
                value: props?.order?.offer_consideration?.toString(),
              },
            ],
          };
          if (localStorage.getItem("client") === props?.order?.mm_client) {
            dispatch(
              fixedIncome.updateOfferOrderAction({
                recordID: props?.order.recordID,
                orderdata: JSON.stringify(body),
                counter_offer_check: false,
              })
            );
            setTimeout(() => {
              dispatch(fixedIncome.getIncomingOfferOrderAction());
            }, 2000);

            let payload = {
              values: [
                { name: "offer_id", value: props?.order?.recordID },
                {
                  name: "trade_amount",
                  value: props?.order?.offer_amount?.toString(),
                },
                {
                  name: "trade_consideration",
                  value: props?.order?.offer_consideration?.toString(),
                },
              ],
            };
            dispatch(fixedIncome.createTradeAction(JSON.stringify(payload)));
            setTimeout(() => {
              props.opentradetab();
            }, 1000);
            handleClose();
          } else {
            toast.error(`Action declined due to permission issue`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose();
          }
        }
      });
    },
  });

  const handleClose = () => {
    props.onHide();
    formik.resetForm({
      values: initialValues,
    });
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      className={classes.dialog}
      disableBackdropClick
    >
      <DialogTitle>
        Offer Review for{" "}
        <span style={{ color: "#00BD80" }}>{props?.order?.recordID}</span>
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dialogContentFlex}>
          <Box>
            <Typography variant="body1" component="h6">
              Offer Amount
            </Typography>
            <Typography variant="body2" component="p">
              {props?.order?.ccy_suffix}{" "}
              <CurrencyFormat
                value={parseFloat(props?.order?.offer_amount)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
              />
              {}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Offer Yield (%)
            </Typography>
            <Typography variant="body2" component="p">
              {parseFloat(
                props?.order?.offer_yield ? props?.order?.offer_yield : 0
              )?.toFixed(4)}
              %
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Offer Quantity
            </Typography>
            <Typography variant="body2" component="p">
              <CurrencyFormat
                value={props?.order?.offer_quantity}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Box>
        </Box>
        <div className={classes.dialogActions}>
          <Button
            variant="contained"
            className={classes.cancelBtn}
            size="small"
            style={{ maxWidth: 171, width: 150 }}
            onClick={() => rejectOffer()}
            color="secondary"
          >
            REJECT
          </Button>
          <Button
            variant="contained"
            className={classes.actionBtn}
            size="small"
            type="submit"
          >
            ACCEPT
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ReviewOrderFormModal);
