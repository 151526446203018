import React, { useEffect, useRef, useState } from "react";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import ViewBondModal from "./ViewBondModal";
import { useDispatch, useSelector } from "react-redux";
import * as watchlistBond from "../../../store/actions/watchlistBond.actions";
import moment from "moment";
import { toast } from "react-toastify";

import axios from "axios";
import {
  Box,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
} from "@material-ui/core";
import { AddCircle, RemoveCircle, Delete } from "@material-ui/icons";
import { makeStyles, styled } from "@material-ui/core/styles";
import { loadAllVenues } from "../../../store/actions/venue.action";

const useStyles = makeStyles((theme) => ({
  addBtn: {
    backgroundColor: "#0e3551",
    color: "white",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#0e3551",
      color: "white",
    },
    "&:focus": {
      backgroundColor: "#0e3551",
      color: "white",
    },
  },
  removeBtn: {
    backgroundColor: "#2e2e2f",
    color: "white",
    "&:hover": {
      backgroundColor: "#2e2e2f",
      color: "white",
    },
    "&:focus": {
      backgroundColor: "#2e2e2f",
      color: "white",
    },
  },
}));

const CustomTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    borderBottom: "none !important",
    color: "#808080",
  },
});

const Watchlist = () => {
  const classes = useStyles();

  const optionSelected = { option: {} };
  const selectInputRef = useRef();
  const dispatch = useDispatch();
  const watchlistBondData = useSelector((state) => state?.watchlistbond);
  const getUserWatchListBond =
    watchlistBondData?.userwatchlistbond?.response?.set;
  const [watchListUserData, setWatchListUserData] = useState([]);
  const [watchListBondDelete, setWatchListBondDelete] = useState(false);
  const [viewBondModalShow, setViewBondModalShow] = useState(false);
  const [viewWatchList, setViewWatchList] = useState({});

  const { items: venues } = useSelector((state) => state.venues);

  useEffect(() => {
    dispatch(watchlistBond.getUserWatchListBondAction());
    dispatch(loadAllVenues());
  }, [dispatch]);

  useEffect(() => {
    const getSymbolListBond = () => {
      if (getUserWatchListBond?.length) {
        var newIds = [];
        getUserWatchListBond[0]?.values.forEach((e) => {
          if (e.name !== "type" && e.value) newIds.push(e.value);
        });
        if (newIds.toString()) {
          dispatch(
            watchlistBond.getBondMultipleAction({
              recordIds: newIds.toString(),
            })
          );
        }
      }
    };
    getSymbolListBond();
  }, [getUserWatchListBond, getUserWatchListBond?.length, dispatch]);

  const loadName = (id) => {
    const venue = venues.find((_) => _.id == id);
    if (venue) {
      return venue.name;
    }
    return id;
  };

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <div>{props?.data?.name}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <div style={{ color: "#3469d4" }}>({props?.data?.issuer})</div>
          <div style={{ color: "#db4532" }}>
            Exchange : {loadName(props?.data?.exchange)}
          </div>
        </div>
      </components.Option>
    );
  };

  const loadOptions = (inputValue) => {
    let append = [];
    append.push(`search=${inputValue}`);

    return axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/securities/api/v1/bonds/search?${append.join("&")}`
      )
      .then(({ data }) => {
        return data;
      })
      .catch((err) => console.log(err));
  };

  const logChange = (e, a) => {
    if (a.action === "select-option") {
      optionSelected.option = {
        name: e?.name,
        recordID: e?.recordID,
      };
    } else if (a.action === "clear") {
      optionSelected.option = {};
    }
  };

  const addWatchListData = () => {
    if (watchlistBondData?.multiplebond?.response?.set?.length > 50) {
      toast.info("You can't add more than 50 Bonds", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!watchlistBondData?.multiplebond?.response?.set?.length) {
      if (optionSelected?.option?.recordID) {
        dispatch(
          watchlistBond.createWatchlistBondAction({
            payload: {
              values: [
                {
                  name: "bond_symbol",
                  value: optionSelected?.option?.recordID,
                },
                { name: "type", value: "fixedincome" },
              ],
            },
          })
        );
        getAllWatchListBond();
        optionSelected.option = {};
        selectInputRef?.current?.select?.select?.clearValue();
      }
    } else {
      if (getUserWatchListBond && getUserWatchListBond?.length) {
        if (optionSelected?.option?.recordID) {
          var newArray = getUserWatchListBond[0].values;
          const bondlistdata = newArray?.findIndex(
            (data) => data.value === optionSelected?.option?.recordID
          );
          if (bondlistdata === -1)
            newArray.push({
              name: "bond_symbol",
              value: optionSelected?.option?.recordID,
            });
          dispatch(
            watchlistBond.updateWatchlistBondAction({
              watchlistRecordId: getUserWatchListBond[0]?.recordID,
              payload: {
                values: newArray,
              },
            })
          );
          getAllWatchListBond();
          optionSelected.option = {};
          selectInputRef?.current?.select?.select?.clearValue();
        }
      }
    }
  };

  const removeWatchlistBond = () => {
    if (
      getUserWatchListBond &&
      getUserWatchListBond.length &&
      watchListUserData?.length
    ) {
      var newArray = getUserWatchListBond[0]?.values;
      watchListUserData.forEach((data) => {
        newArray.forEach((item, index) => {
          if (data.recordID === item?.value) {
            newArray.splice(index, 1);
          }
        });
      });
      dispatch(
        watchlistBond.updateWatchlistBondAction({
          watchlistRecordId: getUserWatchListBond[0]?.recordID,
          payload: {
            values: newArray,
          },
        })
      );
    }
    getAllWatchListBond();
    optionSelected.option = {};
    setViewBondModalShow(false);
    setWatchListUserData([]);
    setWatchListBondDelete(false);
  };

  const getAllWatchListBond = () => {
    if (getUserWatchListBond && getUserWatchListBond.length) {
      var newIds = [];
      setTimeout(() => {
        getUserWatchListBond[0]?.values?.forEach((e) => {
          if (e?.name !== "type" && e?.value) newIds.push(e?.value);
        });
        if (newIds.toString()) {
          dispatch(
            watchlistBond.getBondMultipleAction({
              recordIds: newIds.toString(),
            })
          );
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (watchlistBondData?.multiplebond?.response?.set?.length) {
      setViewWatchList(watchlistBondData?.multiplebond?.response?.set[0]);
    }
  }, [watchlistBondData?.multiplebond?.response?.set]);

  const watchlistRowClick = (data) => {
    setViewWatchList(data);
    optionSelected.option = {
      recordID: data?.recordID,
    };
    setViewBondModalShow(true);
  };

  const deleteWatchlistBond = () => {
    setWatchListBondDelete(true);
  };

  const spliceWatchListBond = (data) => {
    setViewBondModalShow(false);
    if (data) {
      if (data.value) {
        setWatchListUserData((prevArray) => [...prevArray, data?.data]);
      } else {
        const watchlistfindindex = [...watchListUserData];
        watchlistfindindex.splice(
          watchlistfindindex.findIndex((item) => item.Id === data?.data?.Id),
          1
        );
        setWatchListUserData(watchlistfindindex);
      }
    }
  };

  return (
    <Box p={2}>
      <Typography variant="h6" align="left">
        Watchlist
      </Typography>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={8}>
          <AsyncSelect
            components={{ Option, IndicatorSeparator: () => null }}
            ref={selectInputRef}
            cacheOptions
            defaultOptions
            getOptionLabel={(e) => e.isin}
            loadOptions={loadOptions}
            isClearable={true}
            isSearchable={true}
            onChange={logChange}
            placeholder="Search by Bond ID or ISIN"
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: "999 !important",
                backgroundColor: "#313131",
                ":hover": {
                  backgroundColor: "#313131",
                },
              }),
              control: (provided) => ({
                ...provided,
                backgroundColor: "#313131",
                border: "0.5px solid #313131",
                outline: "none",
              }),
              option: (provided) => ({
                ...provided,
                color: "#fcfcfc",
                backgroundColor: "#313131",
                cursor: "pointer",
                fontSize: "12px",
                textAlign: "left",
                ":hover": {
                  backgroundColor: "#252525",
                },
                ":active": {
                  backgroundColor: "#252525",
                },
              }),
              input: (styles) => ({ ...styles, color: "white" }),
              singleValue: (styles) => ({
                ...styles,
                color: "white",
              }),
            }}
          />
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Button
            className={classes.addBtn}
            size="small"
            startIcon={<AddCircle />}
            onClick={(e) => addWatchListData()}
          >
            Add
          </Button>
          {watchListBondDelete ? (
            <Button
              className={classes.removeBtn}
              size="small"
              startIcon={<RemoveCircle />}
              onClick={() => removeWatchlistBond()}
            >
              Remove
            </Button>
          ) : (
            <Button
              className={classes.removeBtn}
              size="small"
              startIcon={<Delete />}
              onClick={() => deleteWatchlistBond()}
            >
              Delete
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Bond ID
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      ISIN
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Exchange
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      CCY
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Coupon (%)
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Mat Date
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Mkt Yld
                    </Typography>
                  </div>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!("multiplebond" in watchlistBondData) ||
              watchlistBondData.watchlist.length === 0 ? (
                <TableRow>
                  <CustomTableCell
                    colSpan={7}
                    className="text-center border-bottom-0 py-3"
                  >
                    No data
                  </CustomTableCell>
                </TableRow>
              ) : (
                watchlistBondData?.multiplebond?.response?.set.map(
                  (e, indexi) => (
                    <TableRow
                      key={`equity-watchlist-${indexi}`}
                      className="pointer"
                      onClick={() => watchlistRowClick(e)}
                    >
                      {watchListBondDelete && (
                        <CustomTableCell className="border-0">
                          <Checkbox
                            onChange={(event) => {
                              spliceWatchListBond({
                                data: e,
                                value: event?.target?.checked,
                              });
                            }}
                            size="small"
                            color="default"
                          />
                        </CustomTableCell>
                      )}
                      <CustomTableCell padding="default" align="center">
                        {e?.bond_id ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {e?.isin ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {e?.exchange_name ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {e?.ccy_suffix ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {parseFloat(e?.coupon_rate)?.toFixed(4) ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {e?.maturity_date
                          ? moment(e?.maturity_date).format("DD/MM/YYYY")
                          : "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {e?.last_traded_yield
                          ? parseFloat(e?.last_traded_yield)?.toFixed(4)
                          : "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {e?.tender_number ?? "-"}
                      </CustomTableCell>
                    </TableRow>
                  )
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <ViewBondModal
        open={viewBondModalShow}
        onHide={() => setViewBondModalShow(false)}
        bond={viewWatchList}
        RemoveWatchListBond={removeWatchlistBond}
      />
    </Box>
  );
};

export default React.memo(Watchlist);
