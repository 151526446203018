import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import SessionTimeout from "../SessionTimeout";

function PrivateRoute({ component: Component, ...rest }) {
  const { keycloak } = useKeycloak();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!keycloak.authenticated) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }
        return (
          <>
            <SessionTimeout />
            <Component {...props} />
          </>
        );
      }}
    />
  );
}

export { PrivateRoute };

