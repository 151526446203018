import { sortByAsc, sortByDesc } from "../../helpers/utilities";
import * as types from "../actions/types";

const INITIAL_BOND = {
  loading: false,
  error: null,
  items: [],
  bond: null,
  filteredItems: [],
  sortBy: {},
};

export const bondReducer = (state = INITIAL_BOND, action) => {
  switch (action.type) {
    case types.constants.SORT_BOND:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_BOND:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter(
        (item) => item.name.match(filter) || item.exchange.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_BOND_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        bond: null,
        error: null,
      };

    case types.constants.LOADING_BOND:
      return { ...state, loading: true };

    case types.constants.GET_BOND_BY_ID_SUCCESS:
      return { ...state, loading: false, bond: action.bond };

    case types.constants.STORE_BOND_SUCCESS:
    case types.constants.UPDATE_BOND_SUCCESS:
      return { ...state, loading: false, bond: null };

    case types.constants.DELETE_BOND_SUCCESS:
      const bondIndex = state.items.findIndex((item) => item.id === action.id);

      let newState = { ...state };

      if (bondIndex >= 0) {
        newState.items.splice(bondIndex, 1);
      }

      newState.loading = false;

      return newState;

    case types.constants.TOGGLE_BOND_SUCCESS_STATUS:
      const bondIndex_ = state.items.findIndex((item) => item.id == action.id);

      let newState__ = { ...state };

      if (bondIndex_ >= 0) {
        newState__.items[bondIndex_] = {
          ...newState__.items[bondIndex_],
          disabled: action.to,
        };
      }

      newState__.loading = false;

      return newState__;

    case types.constants.GET_BOND_ERROR:
    case types.constants.STORE_BOND_ERROR:
    case types.constants.UPDATE_BOND_ERROR:
    case types.constants.DELETE_BOND_ERROR:
    case types.constants.GET_BOND_BY_ID_ERROR:
    case types.constants.TOGGLE_BOND_ERROR_STATUS:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
