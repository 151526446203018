import { sortByAsc, sortByDesc } from "../../helpers/utilities";
import * as types from "../actions/types";

const INITIAL_STATE = {
  items: [],
  activeWatchlist: null,
  active: null,
  error: null,
  sortBy: {},
};

export function watchlistReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.constants.SORT_WATCHLIST:
      const sortBy = {
        ...state.sortBy,
        [`${action.data.field}${action.data.subField ? "-" + action.data.subField : ""
          }`]: action.data.sortBy,
      };

      let sortedItems = [...state.items];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(
          sortedItems,
          action.data.field,
          action.data.subField
        );
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(
          sortedItems,
          action.data.field,
          action.data.subField
        );
      }

      return {
        ...state,
        items: sortedItems,
        sortBy,
      };

    case types.constants.GET_EXCHANGE_LIST_SUCCESS:
      return {
        ...state,
        exchangelist: action?.exchangelist,
      };

    case types.constants.GET_EXCHANGE_LIST_ERROR:
      return {
        ...state,
        exchangelisterror: "Error",
      };

    case types.constants.GET_WATCH_LIST_SUCCESS:
      return {
        ...state,
        items: action.watchlist,
        sortBy: {},
      };

    case types.constants.GET_WATCH_LIST_ERROR:
      return {
        ...state,
        error: "Error",
      };

    case types.constants.CREATE_WATCH_LIST_SUCCESS:
      let userWatchlistData = state.items ? [...state.items] : [];

      action.data.forEach((data) => {
        const userIndex = userWatchlistData.findIndex(
          (watchlist) =>
            watchlist.exchange == data.exchange &&
            watchlist.security == data.security &&
            watchlist.watchlist == data.watchlist
        );
        if (userIndex === -1) {
          userWatchlistData.unshift(data);
        }
      });

      return {
        ...state,
        items: [...userWatchlistData],
        sortBy: {},
      };

    case types.constants.CREATE_WATCH_LIST_ERROR:
      return {
        ...state,
        createwatchlisterror: "Error",
      };

    case types.constants.UPDATE_WATCH_LIST_SUCCESS:
      let watchlists = state.items ? [...state.items] : [];
      const data = action.data.data;
      data.forEach((d) => {
        const index = watchlists.findIndex(
          (watchlist) =>
            watchlist.exchange == d.exchange &&
            watchlist.security == d.security &&
            watchlist.watchlist == d.watchlist
        );
        if (index >= 0) {
          watchlists.splice(index, 1);
        }
      });
      return {
        ...state,
        items: [...watchlists],
        sortBy: {},
      };

    case types.constants.UPDATE_WATCH_LIST_ERROR:
      return {
        ...state,
        updatewatchlisterror: "Error",
      };

    case types.constants.WEB_SOCKET_SUCCESS:
      let newState = { ...state };

      if (action?.webSocketlist?.data?.detail) {
        const watchListIndex = newState.items.findIndex(
          (_) => _.security == action.webSocketlist.data.name && _.exchange == action.webSocketlist.data.exchange
        );

        if (watchListIndex != -1) {
          if (!("data" in newState.items[watchListIndex])) {
            newState.items[watchListIndex]["data"] = {};
          }

          newState.items[watchListIndex] = {
            ...newState.items[watchListIndex],
            data: {
              ...newState.items[watchListIndex].data,
              ...action.webSocketlist.data,
            },
          };

          newState = {
            ...newState,
            activeWatchlist: newState?.items?.[newState?.active],
          };
        }
      } else if ("key" in action.webSocketlist.data) {
        const watchListIndex = newState.items.findIndex(
          (_) => _.security == action.webSocketlist.data.key.symbol && _.exchange == action.webSocketlist.data.key.exchange
        );
        if (watchListIndex != -1) {
          if (!("data" in newState.items[watchListIndex])) {
            newState.items[watchListIndex]["data"] = {};
          }

          newState.items[watchListIndex] = {
            ...newState.items[watchListIndex],
            data: {
              ...newState.items[watchListIndex].data,
              ...action.webSocketlist.data,
            },
          };

          newState = {
            ...newState,
            activeWatchlist: newState?.items?.[newState?.active],
          };
        } else {
          let activeWatchlist__ = { ...(newState.activeWatchlist ?? {}) };
          activeWatchlist__ = {
            ...activeWatchlist__,
            data: {
              ...(activeWatchlist__?.data ?? {}),
              ...action.webSocketlist.data,
            },
          };

          newState.activeWatchlist = activeWatchlist__;
        }
      }

      return {
        ...newState,
      };

    case types.constants.WATCHLIST_INDEX:
      return {
        ...state,
        active: action.payload,
      };

    case types.constants.ACTIVE_WATCHLIST:
      return {
        ...state,
        activeWatchlist: { ...state.activeWatchlist, ...action.payload },
      };

    case types.constants.ADD_NON_EXISTING:
      let newState_ = { ...state };

      return {
        ...newState_,
        activeWatchlist: {
          ...action?.payload?.data,
        },
        active: null,
      };

    default:
      return state;
  }
}
