import * as types from "../actions/types";
import { sortByAsc, sortByDesc } from "../../helpers/utilities";

const INITIAL_USER = {
  loading: false,
  error: null,
  items: [],
  user: null,
  filteredItems: [],
  sortBy: {},
};

export const userReducer = (state = INITIAL_USER, action) => {
  switch (action.type) {
    case types.constants.SORT_USER:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_USER:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter(
        (item) =>
          item?.firstName?.match(filter) ||
          item?.lastName?.match(filter) ||
          item?.username?.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_USER_SUCCESS:
      const users = action.payload.map((_) => ({
        ..._,
        enabled: _.enabled ? "Enabled" : "Disabled",
      }));

      return {
        ...state,
        loading: false,
        items: users,
        filteredItems: users,
        sortBy: {},
        error: null,
      };

    case types.constants.LOADING_USER:
      return { ...state, loading: true };

    case types.constants.GET_USER_BY_ID_SUCCESS:
      return { ...state, loading: false, user: action.user, error: null };

    case types.constants.STORE_USER_SUCCESS:
    case types.constants.UPDATE_USER_SUCCESS:
    case types.constants.UPDATE_ROLE_USER_SUCCESS:
      return { ...state, loading: false, user: null, error: null };

    case types.constants.DELETE_USER_SUCCESS:
      const userIndex = state.items.findIndex((item) => item.id == action.id);

      let newState = { ...state };

      if (userIndex >= 0) {
        newState.items.splice(userIndex, 1);
      }

      newState.loading = false;
      newState.error = null;

      return newState;

    case types.constants.ENABLE_DISABLE_USER_SUCCESS:
      const userUpdateIndex = state.items.findIndex(
        (item) => item.id == action.payload.id
      );

      let updatedState = { ...state };

      if (userUpdateIndex >= 0) {
        updatedState.items[userUpdateIndex].enabled =
          !updatedState.items[userUpdateIndex].enabled;
      }

      updatedState.loading = false;
      updatedState.error = null;

      return updatedState;

    case types.constants.GET_USER_ERROR:
    case types.constants.STORE_USER_ERROR:
    case types.constants.UPDATE_USER_ERROR:
    case types.constants.DELETE_USER_ERROR:
    case types.constants.GET_USER_BY_ID_ERROR:
    case types.constants.ENABLE_DISABLE_USER_ERROR:
    case types.constants.UPDATE_ROLE_USER_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
