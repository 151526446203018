import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";

const loadAsyncPositions = async (payload) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/df-mo/api/v1/group-accounts/positions`;

    if (payload.fund == "") {
      url += "-all";
    } else {
      url += `/${payload.fund}`;
    }

    if (payload.account) {
      url = `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount/positions/${payload.account}`;
    }

    const { data } = await axios.get(url);

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const storeAsyncPosition = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount/positions`,
      payload
    );

    return data.message ?? "Position created successfully.";
  } catch (error) {
    throw new Error(error);
  }
};

const loadAsyncPosition = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount/positions/${payload}`
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const updateAsyncPosition = async (payload) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount/positions/${payload.id}`,
      payload.data
    );

    return data.message ?? "Position updated successfully.";
  } catch (error) {
    throw new Error(error);
  }
};

const deleteAsyncPosition = async ({ payload }) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount/positions/${payload}`
    );

    return data.message ?? "Position deleted successfully.";
  } catch (error) {
    throw new Error(error);
  }
};

export function* loadPositions({ payload }) {
  try {
    const positions = yield call(loadAsyncPositions, payload);
    yield put({
      type: types.constants.GET_POSITION_SUCCESS,
      payload: positions,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_POSITION_ERROR,
      payload: err.message,
    });
  }
}

export function* storePosition({ payload }) {
  try {
    const message = yield call(storeAsyncPosition, payload.data);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.STORE_POSITION_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } catch (err) {
    yield put({
      type: types.constants.STORE_POSITION_ERROR,
      payload: err.message,
    });
  }
}

export function* loadPosition(payload) {
  try {
    const position = yield call(loadAsyncPosition, payload);
    yield put({ type: types.constants.GET_POSITION_BY_ID_SUCCESS, position });
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.GET_POSITION_BY_ID_ERROR,
      payload: err.message,
    });
  }
}

export function* updatePosition({ payload }) {
  try {
    const message = yield call(updateAsyncPosition, payload.data);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.UPDATE_POSITION_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.UPDATE_POSITION_ERROR,
      payload: err.message,
    });
  }
}

export function* deletePosition(payload) {
  try {
    const message = yield call(deleteAsyncPosition, payload);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_POSITION_SUCCESS,
      id: payload.payload,
    });
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_POSITION_ERROR,
      payload: err.message,
    });
  }
}
