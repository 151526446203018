import React, { useEffect } from "react";

import {
  Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography
} from "@material-ui/core";

import { makeStyles, styled } from "@material-ui/core/styles";
import { useState } from "react";
import CurrencyFormat from "react-currency-format";
import {
  comma_separator,
  sortByAsc,
  sortByDesc,
  toCurrency,
  toDecimal
} from "../../helpers/utilities";
import Sorting from "../Sorting/Sorting";

const CustomTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    borderBottom: "none !important",
    color: "#808080",
  },
  "@media print": {
    "&.MuiTableCell-head": {
      color: "black !important",
      borderBottom: "1px solid black !important",
    },
    "&.MuiTableCell-body": {
      borderBottom: "1px solid black !important",
    },
  },
});

const useStyles = makeStyles(() => ({
  tableHead: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "75px",
    minWidth: "75px",
  },
  tableHeadRight: {
    justifyContent: "flex-end !important",
    textAlign: "right !important",
  },
  "@media (max-width: 1024px)": {
    tableHead: {
      justifyContent: "flex-start",
      maxWidth: "125px",
      minWidth: "125px",
    },
  },
  "@media print": {
    tableHead: {
      maxWidth: "50px",
      minWidth: "50px",
    },
  },
}));

function PositionTable({ data: positions = [], positionLoading }) {
  const classes = useStyles();

  let positionsData = null;

  const [filteredPositions, setFilteredPositions] = useState([...positions]);
  const [sortBy, setSortBy] = useState({});

  const onSort = (field, sortBy_, subField) => {
    setSortBy((prev) => {
      return {
        ...prev,
        [`${field}${subField ? "-" + subField : ""}`]: sortBy_,
      };
    });

    let sortedItems = [...filteredPositions];

    if (sortBy_ === "asc") {
      sortedItems = sortByAsc(sortedItems, field, subField);
    }

    if (sortBy_ === "desc") {
      sortedItems = sortByDesc(sortedItems, field, subField);
    }

    setFilteredPositions(sortedItems);
  };

  useEffect(() => {
    setFilteredPositions(positions);
  }, [positions]);

  if (positionLoading) {
    positionsData = (
      <tr className="text-center">
        <td colSpan={10}>Please wait loading...</td>
      </tr>
    );
  } else if (filteredPositions.length === 0) {
    positionsData = (
      <tr className="text-center">
        <td colSpan={10}>No positions yet</td>
      </tr>
    );
  } else {
    positionsData = filteredPositions.map((position, positionIndex) => {
      return (
        <TableRow hover key={`tr-${positionIndex}`}>
          <CustomTableCell padding="default" align="left">
            {position["ticker"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {position["symbol"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {position["origVenue"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {position["ccy"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {position?.fundAccount?.fundAccountName ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {position["price"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(position["price"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {position["volume"] ? comma_separator(position["volume"]) : "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {position["localPrice"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(position["localPrice"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {position["fxRate"] > 0 ? toDecimal(position["fxRate"]) : "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {position["marketValue"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(position["marketValue"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
        </TableRow>
      );
    });
  }

  return (
    <>
      <TableContainer style={{ marginTop: "0.5rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Ticker</Typography>&nbsp;
                  <Sorting field={"ticker"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Symbol</Typography>&nbsp;
                  <Sorting field={"symbol"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Exchange</Typography>&nbsp;
                  <Sorting
                    field={"origVenue"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">CCY</Typography>&nbsp;
                  <Sorting field={"ccy"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Account</Typography>&nbsp;
                  <Sorting
                    field={"fundAccount"}
                    subField={"fundAccountName"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                >
                  <Typography variant="subtitle2">Local Price</Typography>&nbsp;
                  <Sorting field={"price"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                >
                  <Typography variant="subtitle2">Quantity</Typography>&nbsp;
                  <Sorting field={"volume"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                  style={{ minWidth: "100px" }}
                >
                  <Typography variant="subtitle2">
                    Local Market Value
                  </Typography>
                  &nbsp;
                  <Sorting
                    field={"localPrice"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                >
                  <Typography variant="subtitle2">Fx Rate</Typography>&nbsp;
                  <Sorting field={"fxRate"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                >
                  <Typography variant="subtitle2">Market Value</Typography>
                  &nbsp;
                  <Sorting
                    field={"marketValue"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{positionsData}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default PositionTable;
