import { call, put, fork } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncFundAccount2s = async (payload) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount`;
    if (payload.fund) {
      url += `/${payload.fund}`;
    }

    const { data } = await axios.get(url);
    return data;
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const storeAsyncFundAccount2 = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount`,
      { ...payload.data, groupAccountId: payload.fund }
    );

    return {
      error: false,
      data: data.message ?? "Fund account created successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncFundAccount2 = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/funds/${payload.fund}/fund-accounts/${payload.id}`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const updateAsyncFundAccount2 = async (payload) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/funds/${payload.fund}/fund-accounts/${payload.id}`,
      payload.data
    );

    return {
      error: false,
      data: data.message ?? "Fund account updated successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const deleteAsyncFundAccount2 = async ({ payload }) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/funds/${payload.fund}/fund-accounts/${payload.id}`
    );

    return data.message ?? "Fund account deleted successfully.";
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const toggleAsyncFundAccount2Status = async ({ payload }) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount/${payload.id}/${payload.type}`
    );

    return {
      error: false,
      data: data.message ?? `Fund account ${payload.type} successfully.`,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncCurrentBalance = async (id) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/fundAccount/getBalance/${id}`
    );
    return data;
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

export function* loadCurrentBalance(id) {
  try {
    const balance = yield call(loadAsyncCurrentBalance, id);

    yield put({
      type: types.constants.LOAD_FUND_ACCOUNT2_CURRENT_BALANCE_SUCCESS,
      payload: {
        id,
        balance,
      },
    });
  } catch (err) {
    yield put({
      type: types.constants.LOAD_FUND_ACCOUNT2_CURRENT_BALANCE_ERROR,
      payload: err.message,
    });
  }
}

export function* loadFundAccount2s(fund) {
  try {
    const accounts = yield call(loadAsyncFundAccount2s, fund);

    for (let i = 0; i < accounts.length; i++) {
      yield fork(loadCurrentBalance, accounts[i].id);
    }

    yield put({
      type: types.constants.GET_FUND_ACCOUNT2_SUCCESS,
      payload: accounts,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_FUND_ACCOUNT2_ERROR,
      payload: err.message,
    });
  }
}

export function* storeFundAccount2({ payload }) {
  const response = yield call(storeAsyncFundAccount2, payload);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.STORE_FUND_ACCOUNT2_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STORE_FUND_ACCOUNT2_ERROR,
      payload: response.data,
    });
  }
}

export function* loadFundAccount2(payload) {
  const response = yield call(loadAsyncFundAccount2, payload);
  if (!response.error) {
    yield put({
      type: types.constants.GET_FUND_ACCOUNT2_BY_ID_SUCCESS,
      fundAccount2: response.data,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.GET_FUND_ACCOUNT2_BY_ID_ERROR,
      payload: response.data,
    });
  }
}

export function* updateFundAccount2({ payload }) {
  const response = yield call(updateAsyncFundAccount2, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.UPDATE_FUND_ACCOUNT2_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.UPDATE_FUND_ACCOUNT2_ERROR,
      payload: response.data,
    });
  }
}

export function* deleteFundAccount2(payload) {
  try {
    const message = yield call(deleteAsyncFundAccount2, payload);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_FUND_ACCOUNT2_SUCCESS,
      id: payload.payload.id,
    });
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_FUND_ACCOUNT2_ERROR,
      payload: err.message,
    });
  }
}

export function* toggleFundAccount2Status(payload) {
  const response = yield call(toggleAsyncFundAccount2Status, payload);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.TOGGLE_FUND_ACCOUNT2_STATUS_SUCCESS,
      id: payload.payload.id,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.TOGGLE_FUND_ACCOUNT2_STATUS_ERROR,
      payload: response.data,
    });
  }
}
