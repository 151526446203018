import { Box } from "@material-ui/core";
import React from "react";
import Loader from "../Loader";

function CustomOverlay({ show = false, children }) {
  return (
    <>
      {show && (
        <Box
          {...{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: "100%",
            height: "100%",
            bgcolor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
          }}
        >
          <Loader showBg={false} />
        </Box>
      )}
      {children}
    </>
  );
}

export default CustomOverlay;
