import * as types from "./types";

export const getUserWatchListBondAction = () => {
  return {
    type: types.constants.GET_USER_WATCHLIST_BOND,
  };
};

export const getBondMultipleAction = (data) => {
  return {
    type: types.constants.GET_BOND_MULTIPLE,
    data,
  };
};

export const createWatchlistBondAction = (data) => {
  return {
    type: types.constants.CREATE_WATCH_LIST_BOND,
    data,
  };
};

export const updateWatchlistBondAction = (data) => {
  return {
    type: types.constants.UPDATE_WATCH_LIST_BOND,
    data,
  };
};
