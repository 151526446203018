import axios from "axios";
import { call, put } from "redux-saga/effects";
import * as types from "../actions/types";

const loadAsyncUserPolicyData = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users/uap`
    );
    return data;
  } catch (error) {
    throw new Error("Error");
  }
};

export function* userPolicyData() {
  try {
    const data = yield call(loadAsyncUserPolicyData);
    yield put({
      type: types.constants.USER_POLICY_DATA_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.constants.USER_POLICY_DATA_ERROR,
      error,
    });
  }
}


const updateAsyncUserPolicy = async () => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users/agree-uap`
    );
    return data;
  } catch (error) {
    throw new Error("Error");
  }
};
export function* userPolicyUpdate() {
  try {
    const data = yield call(updateAsyncUserPolicy);
    yield put({
      type: types.constants.USER_POLICY_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.constants.USER_POLICY_UPDATE_ERROR,
      error,
    });
  }
}

const cancelAsyncUserPolicy = async () => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users/disagree-uap`
    );
    return data;
  } catch (error) {
    throw new Error("Error");
  }
};
export function* cancelPolicyUpdate() {
  try {
    const data = yield call(cancelAsyncUserPolicy);
    yield put({
      type: types.constants.USER_POLICY_CANCEL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: types.constants.USER_POLICY_CANCEL_ERROR,
      error,
    });
  }
}