import * as types from "./types";

export const loading = () => {
  return { type: types.constants.LOADING_TRADE };
};

export const sortTrade = (data) => {
  return {
    type: types.constants.SORT_TRADE,
    data,
  };
};

export const filterTrade = (search) => {
  return { type: types.constants.FILTER_TRADE, search };
};

export const accountFilterTrade = (search) => {
  return { type: types.constants.ACCOUNT_FILTER_TRADE, search };
};

export const loadAllTrades = (payload) => {
  return { type: types.constants.GET_TRADE, payload };
};

export const loadTrade = (payload) => {
  return { type: types.constants.GET_TRADE_BY_ID, payload };
};

export const settleTrade = (payload) => {
  return { type: types.constants.SETTLE_TRADE, payload };
};
