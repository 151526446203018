import * as types from "../actions/types";

const initialstate = {
    outgoingOrderCount : 0,
    outgoingOfferCount : 0,
    outgoingQuotesCount : 0,
    incomingOfferCount : 0,
    incomingQuotesCount : 0,
    blotterCount : 0
}

export function fixedIncomeCountReducers(state = initialstate, action) {
  switch (action.type) {
    case types.constants.GET_OUTGOING_ORDER_COUNT_SUCCESS:
      return {
        ...state,
        outgoingOrderCount: action.outgoingOrderCount,
      };
    case types.constants.GET_OUTGOING_ORDER_COUNT_ERROR:
      return state;

    case types.constants.GET_OUTGOING_OFFER_COUNT_SUCCESS:
      return {
        ...state,
        outgoingOfferCount: action.outgoingOfferCount,
      };
    case types.constants.GET_OUTGOING_OFFER_COUNT_ERROR:
      return state;

    case types.constants.GET_OUTGOING_QUOTES_COUNT_SUCCESS:
      return {
        ...state,
        outgoingQuotesCount: action.outgoingQuotesCount,
      };
    case types.constants.GET_OUTGOING_QUOTES_COUNT_ERROR:
      return state;

    case types.constants.GET_INCOMING_OFFER_COUNT_SUCCESS:
      return {
        ...state,
        incomingOfferCount: action.incomingOfferCount,
      };
    case types.constants.GET_INCOMING_OFFER_COUNT_ERROR:
      return state;
    case types.constants.GET_INCOMING_QUOTES_COUNT_SUCCESS:
      return {
        ...state,
        incomingQuotesCount: action.incomingQuotesCount,
      };
    case types.constants.GET_INCOMING_QUOTES_COUNT_ERROR:
      return state;
    case types.constants.GET_PENDING_TREDE_COUNT_SUCCESS:
      return {
          ...state,
          blotterCount: action.blotterCount,
      };
    case types.constants.GET_PENDING_TREDE_COUNT_ERROR:
      return state;
    case types.constants.GET_EXECUTED_TREDE_COUNT_SUCCESS:
      return {
          ...state,
          executedtradeCount: action.executedtradeCount,
      };
    case types.constants.GET_EXECUTED_TREDE_COUNT_ERROR:
      return state;
    case types.constants.GET_ALL_TREDE_COUNT_SUCCESS:
      return {
          ...state,
          alltradeCount: action.alltradeCount,
        };
    case types.constants.GET_ALL_TREDE_COUNT_ERROR:
      return state;
    case types.constants.GET_ALL_ORDER_COUNT_SUCCESS:
      return {
          ...state,
          allorderCount: action.allorderCount,
        };
    case types.constants.GET_ALL_ORDER_COUNT_ERROR:
       return state;
    case types.constants.GET_ALL_OFFER_COUNT_SUCCESS:
        return {
          ...state,
          allofferCount: action.allofferCount,
        };
    case types.constants.GET_ALL_OFFER_COUNT_ERROR:
        return state;    
    default:
      return state;
  }
}
