import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import "moment-timezone";
import * as fixedIncome from "../../../../../store/actions/fixedIncome.action";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../../../../components/CustomPagination/CustomPagination";

const OrderTable = (props) => {
  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const orderData = useSelector(
    (state) => state?.fixedincomedata?.allorderdata?.response
  );

  useEffect(() => {
    setCurrentPageNumber(1);
    dispatch(
      fixedIncome.getAllOrderAction({
        historyStartDate: props?.daterange?.historyStartDate,
        historyEndDate: props?.daterange?.historyEndDate,
      })
    );
  }, [dispatch, props.daterange]);

  const columns = useMemo(
    () => [
      {
        name: "Order ID",
        selector: "recordID",
        sortable: false,
        compact: true,
        cell: (row) => (row?.recordID ? row?.recordID : "-"),
        minWidth: "165px",
      },
      {
        name: "Bond ID",
        selector: "bond_id",
        sortable: false,
        right: false,
        cell: (row) => (row?.bond_id ? row?.bond_id : "-"),
        compact: true,
        minWidth: "170px",
      },
      {
        name: "ISIN",
        selector: "isin",
        sortable: false,
        right: false,
        cell: (row) => (row?.isin ? row?.isin : "-"),
        compact: true,
        minWidth: "110px",
      },
      {
        name: "Exchange",
        selector: "exchange_name",
        sortable: false,
        center: true,
        cell: (row) => (row?.exchange_name ? row?.exchange_name : "-"),
        compact: true,
      },
      {
        name: "Coupon (%)",
        selector: "coupon_rate",
        sortable: false,
        center: true,
        cell: (row) =>
          row?.coupon_rate ? parseFloat(row?.coupon_rate)?.toFixed(4) : "-",
        compact: true,
      },
      {
        name: "Mat Date",
        selector: "maturity_date",
        sortable: false,
        right: false,
        cell: (row) =>
          row.createdAt ? (
            <span className="text-uppercase">
              {moment(row.maturity_date).format("DD/MMM/YY")}
            </span>
          ) : (
            "-"
          ),
        compact: true,
      },
      {
        name: "Side",
        selector: "side",
        sortable: false,
        cell: (row) =>
          row?.side ? <span className="text-uppercase">{row?.side}</span> : "-",
        compact: true,
        minWidth: "60px",
      },
      {
        name: "All or Nothing ",
        selector: "allOrNothing",
        minWidth: "110px",
        sortable: false,
        cell: (row) =>
          row?.allOrNothing ? (
            <span className="text-uppercase">{row?.allOrNothing}</span>
          ) : (
            "-"
          ),
        compact: true,
        center: true,
      },
      {
        name: "CCY",
        selector: "ccy_suffix",
        sortable: false,
        right: false,
        minWidth: "50px",
        cell: (row) => (row?.ccy_suffix ? row?.ccy_suffix : "-"),
        compact: true,
      },
      {
        name: "Market Yield (%)",
        selector: "yield",
        sortable: false,
        center: true,
        cell: (row) =>
          row?.offer_yield ? parseFloat(row?.offer_yield)?.toFixed(4) : "-",
        compact: true,
      },
      {
        name: "Order Yield (%)",
        selector: "offer_yield",
        sortable: false,
        center: true,
        cell: (row) => (row?.yield ? parseFloat(row?.yield)?.toFixed(4) : "-"),
        compact: true,
      },

      {
        name: "Market Amount",
        selector: "amount",
        sortable: false,
        cell: (row) =>
          row?.offer_amount ? (
            <CurrencyFormat
              value={parseFloat(row?.offer_amount)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            "-"
          ),
        compact: true,
        right: true,
      },
      {
        name: "Order Amount",
        selector: "offer_amount",
        sortable: false,
        cell: (row) =>
          row?.order_amount ? (
            row?.order_amount ? (
              <CurrencyFormat
                value={parseFloat(row?.order_amount)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
              />
            ) : (
              "-"
            )
          ) : row?.amount ? (
            <CurrencyFormat
              value={parseFloat(row?.amount)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            "-"
          ),
        compact: true,
        right: true,
      },
      {
        name: "Date",
        selector: "createdAt",
        sortable: false,
        minWidth: "100px",
        cell: (row) => (
          <span className="text-uppercase">
            {moment(row.createdAt).format("DD/MMM/YY")}
          </span>
        ),
      },
      {
        name: "Time (GMT)",
        selector: "createdAt",
        sortable: false,
        center: true,
        minWidth: "80px",
        cell: (row) =>
          row?.createdAt
            ? moment(row.createdAt).tz("GMT").format("hh:mm:ss A")
            : "-",
        compact: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: false,
        center: true,
        cell: (row) => (row.status ? row?.status : "-"),
        compact: true,
        minWidth: "100px",
      },
    ],
    []
  );

  const handlePaginationChange = (page) => {
    if (
      currentPageNumber > page &&
      orderData?.filter &&
      orderData?.filter?.prevPage
    ) {
      setCurrentPageNumber(page);
      dispatch(
        fixedIncome.getAllOrderAction({
          pageCursor: orderData?.filter?.prevPage,
          historyStartDate: props?.daterange?.historyStartDate,
          historyEndDate: props?.daterange?.historyEndDate,
        })
      );
    } else if (
      currentPageNumber < page &&
      orderData?.filter &&
      orderData?.filter?.nextPage
    ) {
      setCurrentPageNumber(page);
      dispatch(
        fixedIncome.getAllOrderAction({
          pageCursor: orderData?.filter?.nextPage,
          historyStartDate: props?.daterange?.historyStartDate,
          historyEndDate: props?.daterange?.historyEndDate,
        })
      );
    }
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={orderData?.set}
        theme={"dark"}
        highlightOnHover
        pagination
        paginationServer
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationTotalRows={props.totalRecord}
        paginationPerPage={15}
        paginationDefaultPage={currentPageNumber}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => handlePaginationChange(page)}
        paginationComponent={CustomPagination}
        persistTableHead={true}
        noHeader={true}
      />
    </>
  );
};

export default React.memo(OrderTable);
