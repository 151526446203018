import React, { useEffect, useState } from "react";

import {
  Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Typography
} from "@material-ui/core";

import { makeStyles, styled } from "@material-ui/core/styles";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
import Sorting from "../../../components/Sorting/Sorting";
import { sortByAsc, sortByDesc, toCurrency } from "../../../helpers/utilities";

const CustomTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    borderBottom: "none !important",
    color: "#808080",
  },
  "@media print": {
    "&.MuiTableCell-head": {
      color: "black !important",
      borderBottom: "1px solid black !important",
    },
    "&.MuiTableCell-body": {
      borderBottom: "1px solid black !important",
    },
  },
});

const useStyles = makeStyles(() => ({
  tableHead: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "75px",
    minWidth: "75px",
  },
  "@media print": {
    tableHead: {
      maxWidth: "40px",
      minWidth: "40px",
    },
  },
}));

function OpenOrderTable({ data: orders = [], orderLoading, error }) {
  const classes = useStyles();

  const [filteredOrders, setFilteredOrders] = useState([...orders]);
  const [sortBy, setSortBy] = useState({});

  const onSort = (field, sortBy_, subField) => {
    setSortBy((prev) => {
      return {
        ...prev,
        [`${field}${subField ? "-" + subField : ""}`]: sortBy_,
      };
    });

    let sortedItems = [...filteredOrders];

    if (sortBy_ === "asc") {
      sortedItems = sortByAsc(sortedItems, field, subField);
    }

    if (sortBy_ === "desc") {
      sortedItems = sortByDesc(sortedItems, field, subField);
    }

    setFilteredOrders(sortedItems);
  };

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders]);

  let ordersData = null;

  const mapFundAccount = (allocations) => {
    let result = allocations.map(
      (allocation) => allocation?.fundAccount?.fundAccountName ?? ""
    );
    return result.join(", ");
  };

  if (orderLoading) {
    ordersData = (
      <tr className="text-center">
        <td colSpan={15}>Please wait loading...</td>
      </tr>
    );
  } else if (!orderLoading && error) {
    ordersData = (
      <tr className="text-center">
        <td colSpan={15}>
          {typeof error === "string"
            ? error
            : "Something went wrong! Please try again."}
        </td>
      </tr>
    );
  } else if (filteredOrders.length === 0) {
    ordersData = (
      <tr className="text-center">
        <td colSpan={15}>No orders yet</td>
      </tr>
    );
  } else {
    ordersData = filteredOrders.map((order, orderIndex) => {
      return (
        <TableRow hover key={`tr-${orderIndex}`}>
          <CustomTableCell padding="default" align="left">
            {order["id"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["ticker"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["symbol"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["venue"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["action"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["timeInForce"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["ccy"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["allocations"]?.length > 0
              ? mapFundAccount(order["allocations"])
              : "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {order["price"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(order["price"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {order["volume"] > 0 ? (
              <CurrencyFormat
                value={order["volume"]}
                thousandSeparator
                displayType="text"
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {order["commissions"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(order["commissions"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {order["dutiesTaxes"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(order["dutiesTaxes"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["status"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {moment(order["orderDate"]).format("DD/MM/YYYY") ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {order["orderTime"] ?? "-"}
          </CustomTableCell>
        </TableRow>
      );
    });
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Order #</Typography>&nbsp;
                <Sorting field={"id"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Ticker</Typography>&nbsp;
                <Sorting field={"ticker"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Symbol</Typography>&nbsp;
                <Sorting field={"symbol"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Exchange</Typography>&nbsp;
                <Sorting field={"venue"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Side</Typography>&nbsp;
                <Sorting field={"action"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Type</Typography>&nbsp;
                <Sorting
                  field={"timeInForce"}
                  onSort={onSort}
                  active={sortBy}
                />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Ccy</Typography>&nbsp;
                <Sorting field={"ccy"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Account</Typography>&nbsp;
              </div>
            </CustomTableCell>
            <CustomTableCell align="right">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Price</Typography>&nbsp;
                <Sorting field={"price"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="right">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Quantity</Typography>&nbsp;
                <Sorting field={"volume"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="right">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Commissions</Typography>&nbsp;
                <Sorting
                  field={"commissions"}
                  onSort={onSort}
                  active={sortBy}
                />
              </div>
            </CustomTableCell>
            <CustomTableCell align="right">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Duties &amp; Taxes</Typography>
                &nbsp;
                <Sorting
                  field={"dutiesTaxes"}
                  onSort={onSort}
                  active={sortBy}
                />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Status</Typography>&nbsp;
                <Sorting field={"status"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Order date (GMT)</Typography>
                &nbsp;
                <Sorting field={"orderDate"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Order time (GMT)</Typography>
                &nbsp;
                <Sorting field={"orderTime"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{ordersData}</TableBody>
      </Table>
    </TableContainer>
  );
}

export default OpenOrderTable;
