import moment from "moment";
import "moment-timezone";
import React, { useState, useEffect, useCallback } from "react";
import CurrencyFormat from "react-currency-format";
import DataTable from "react-data-table-component";
import CustomPagination from "../../../../../components/CustomPagination/CustomPagination";
import OfferFormModal from "../../../Modal/OfferFormModal";
import { useDispatch, useSelector } from "react-redux";
import * as fixedIncome from "../../../../../store/actions/fixedIncome.action";
import * as fixedIncomeCountAction from "../../../../../store/actions/fixedIncomeCount.action";
import { toast } from "react-toastify";
import swal from "sweetalert";

import { useKeycloak } from "@react-keycloak/web";
import { Button, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  actionBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#145582",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#145582",
    },
    "&:disabled": {
      backgroundColor: "#083a5e",
      color: "#888888",
    },
  },
  cancelBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#3d3d3d",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#3d3d3d",
    },
  },
}));

const OfferTable = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [offerModalShow, setOfferModalShow] = useState(false);
  const [bondData, setBondData] = useState({});
  const [timerInterval, setTimerInterval] = useState(0);
  const offerData = useSelector(
    (state) => state?.fixedincomedata?.outgoingofferorderdata?.response
  );

  const { keycloak } = useKeycloak();

  useEffect(() => {
    sessionStorage.removeItem("paginationkey");
    setCurrentPageNumber(1);
    dispatch(fixedIncomeCountAction.getOutgoingOfferCountAction());
    dispatch(
      fixedIncome.getOutgoingOfferOrderAction(
        sessionStorage.getItem("paginationkey")
      )
    );
    const timer = setInterval(() => {
      dispatch(fixedIncomeCountAction.getOutgoingOfferCountAction());
      dispatch(
        fixedIncome.getOutgoingOfferOrderAction(
          sessionStorage.getItem("paginationkey")
        )
      );
    }, 10000);
    setTimerInterval(timer);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(timerInterval);
      sessionStorage.removeItem("paginationkey");
    };
  }, [timerInterval]);

  const columns = [
    {
      name: "Order ID",
      selector: "order",
      sortable: false,
      compact: true,
      cell: (row) => (row?.order ? row?.order : "-"),
      minWidth: "165px",
    },
    {
      name: "Bond ID",
      selector: "bond_id",
      sortable: false,
      right: false,
      cell: (row) => (row?.bond_id ? row?.bond_id : "-"),
      compact: true,
      minWidth: "170px",
    },
    {
      name: "ISIN",
      selector: "isin",
      sortable: false,
      right: false,
      cell: (row) => (row?.isin ? row?.isin : "-"),
      compact: true,
      minWidth: "110px",
    },
    {
      name: "Exchange",
      selector: "exchange_name",
      sortable: false,
      center: true,
      cell: (row) => (row?.exchange_name ? row?.exchange_name : "-"),
      compact: true,
    },
    {
      name: "Coupon (%)",
      selector: "coupon_rate",
      sortable: false,
      center: true,
      cell: (row) =>
        row?.coupon_rate ? parseFloat(row?.coupon_rate)?.toFixed(4) : "-",
      compact: true,
    },
    {
      name: "Mat Date",
      selector: "maturity_date",
      sortable: false,
      right: false,
      cell: (row) =>
        row.createdAt ? (
          <span className="text-uppercase">
            {moment(row.maturity_date).format("DD/MM/YYYY")}
          </span>
        ) : (
          "-"
        ),
      compact: true,
    },
    {
      name: "Counterparty",
      selector: "market_maker_name",
      sortable: false,
      minWidth: "100px",
      cell: (row) => (row?.market_maker_name ? row?.market_maker_name : "-"),
    },
    {
      name: "Side",
      selector: "market_taker_side",
      sortable: false,
      cell: (row) =>
        row?.market_taker_side ? (
          <span className="text-uppercase">{row?.market_taker_side}</span>
        ) : (
          "-"
        ),
      compact: true,
      minWidth: "60px",
    },
    {
      name: "All or Nothing ",
      selector: "allOrNothing",
      sortable: false,
      cell: (row) =>
        row?.allOrNothing ? (
          <span className="text-uppercase">{row?.allOrNothing}</span>
        ) : (
          "-"
        ),
      compact: true,
      center: true,
    },
    {
      name: "CCY",
      selector: "ccy_suffix",
      sortable: false,
      right: false,
      minWidth: "80px",
      cell: (row) => (row?.ccy_suffix ? row?.ccy_suffix : "-"),
      compact: true,
    },
    {
      name: "Order Yield (%)",
      selector: "yield",
      sortable: false,
      center: true,
      cell: (row) => (row?.yield ? parseFloat(row?.yield)?.toFixed(4) : "-"),
      compact: true,
    },
    {
      name: "Offer Yield (%)",
      selector: "offer_yield",
      sortable: false,
      center: true,
      cell: (row) =>
        row?.offer_yield ? parseFloat(row?.offer_yield)?.toFixed(4) : "-",
      compact: true,
    },
    {
      name: "Order Amount",
      selector: "amount",
      sortable: false,
      cell: (row) =>
        row?.order_amount ? (
          row?.order_amount ? (
            <CurrencyFormat
              value={parseFloat(row?.order_amount)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            "-"
          )
        ) : row?.amount ? (
          <CurrencyFormat
            value={parseFloat(row?.amount)?.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "-"
        ),
      compact: true,
      right: true,
    },
    {
      name: "Offer Amount",
      selector: "offer_amount",
      sortable: false,
      cell: (row) =>
        row?.offer_amount ? (
          <CurrencyFormat
            value={parseFloat(row?.offer_amount)?.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "-"
        ),
      compact: true,
      right: true,
    },

    {
      name: "Date",
      selector: "createdAt",
      sortable: false,
      minWidth: "100px",
      cell: (row) => (
        <span className="text-uppercase">
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      name: "Time (GMT)",
      selector: "createdAt",
      sortable: false,
      center: true,
      minWidth: "80px",
      cell: (row) =>
        row?.createdAt
          ? moment(row.createdAt).tz("GMT").format("hh:mm:ss A")
          : "-",
      compact: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      center: true,
      cell: (row) => (row.status ? row?.status : "-"),
      compact: true,
      minWidth: "100px",
    },
    {
      name: "Action",
      selector: "action",
      sortable: false,
      minWidth: "100px",
      cell: (row) => (row?.status === "open" ? actionButton(row) : "-"),
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      omit: false,
      compact: true,
    },
  ];

  const actionButton = (data) => (
    <Grid justify="space-between" alignItems="center" container spacing={1}>
      <Grid item>
        <Button
          variant="contained"
          style={{ minWidth: 60 }}
          onClick={() => openOfferModal(data)}
          className={classes.actionBtn}
        >
          MODIFY
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          style={{ minWidth: 60 }}
          onClick={(e) => cencelOfferModal(data)}
          className={classes.cancelBtn}
        >
          CANCEL
        </Button>
      </Grid>
    </Grid>
  );

  const cencelOfferModal = (data) => {
    fetch(
      `${process.env.REACT_APP_API_URL}api/compose/namespace/${process.env.REACT_APP_NAMESPACE_ID}/module/${process.env.REACT_APP_FI_OFFER_MODULE_ID}/record/${data?.recordID}`,
      {
        method: "get",
        headers: new Headers({
          authorization: "Bearer " + keycloak.token,
          "Content-Type": "application/json",
        }),
      }
    )
      .then((response) => response.json())
      .then((res) => {
        if (res?.response) {
          let setData = res.response.values;
          setData.forEach((data) => {
            setData[data.name] = data.value;
          });
          if (setData.status === "open") {
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to canceled this offer ?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                let payload = {
                  values: [
                    { name: "order", value: data?.order },
                    {
                      name: "offer_quantity",
                      value: data?.offer_quantity?.toString(),
                    },
                    {
                      name: "offer_yield",
                      value: data?.offer_yield?.toString(),
                    },
                    {
                      name: "offer_amount",
                      value: data?.offer_amount?.toString(),
                    },
                    { name: "status", value: "canceled" },
                    {
                      name: "order_amount",
                      value: data?.order_amount?.toString(),
                    },
                    {
                      name: "dirty_price",
                      value: data?.dirty_price?.toString(),
                    },
                    {
                      name: "clean_price",
                      value: data?.clean_price?.toString(),
                    },
                    { name: "state", value: "closed" },
                  ],
                };
                dispatch(
                  fixedIncome.updateOfferOrderAction({
                    recordID: data?.recordID,
                    orderdata: JSON.stringify(payload),
                    counter_offer_check: true,
                  })
                );
              }
            });
          } else {
            toast.error(
              `This offer can't be modified, because it is already ${setData.status}`,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      });
  };

  const openOfferModal = useCallback((obj) => {
    setBondData(obj);
    setOfferModalShow(true);
  }, []);

  const handlePaginationChange = (page) => {
    if (
      currentPageNumber > page &&
      offerData?.filter &&
      offerData?.filter?.prevPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", offerData?.filter?.prevPage);
      dispatch(
        fixedIncome.getOutgoingOfferOrderAction(offerData?.filter?.prevPage)
      );
    } else if (
      currentPageNumber < page &&
      offerData?.filter &&
      offerData?.filter?.nextPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", offerData?.filter?.nextPage);
      dispatch(
        fixedIncome.getOutgoingOfferOrderAction(offerData?.filter?.nextPage)
      );
    }
  };

  return (
    <>
      <DataTable
        defaultSortField="createdAt"
        defaultSortAsc={false}
        columns={columns}
        data={offerData?.set}
        theme={"dark"}
        highlightOnHover
        pagination
        paginationServer
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationTotalRows={props?.totalRecord}
        paginationPerPage={15}
        paginationDefaultPage={currentPageNumber}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => handlePaginationChange(page)}
        paginationComponent={CustomPagination}
        persistTableHead={true}
        noHeader={true}
      />
      <OfferFormModal
        show={offerModalShow}
        onHide={() => setOfferModalShow(false)}
        bond={bondData}
        outgonigorder={true}
      />
    </>
  );
};

export default React.memo(OfferTable);
