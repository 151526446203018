import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import CurrencyFormat from "react-currency-format";
import ReviewOrderFormModal from "../../../Modal/ReviewOrderFormModal";
import moment from "moment";
import "moment-timezone";
import CustomPagination from "../../../../../components/CustomPagination/CustomPagination";
import { useDispatch, useSelector } from "react-redux";
import * as fixedIncome from "../../../../../store/actions/fixedIncome.action";
import * as fixedIncomeCountAction from "../../../../../store/actions/fixedIncomeCount.action";

import { Button } from "@material-ui/core";

const OfferTable = (props) => {
  const dispatch = useDispatch();
  const [orderModalShow, setOrderModalShow] = useState(false);
  const [order, setOrder] = useState({});
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [timerInterval, setTimerInterval] = useState(0);
  const offerData = useSelector(
    (state) => state?.fixedincomedata?.incomingofferorderdata?.response
  );

  useEffect(() => {
    sessionStorage.removeItem("paginationkey");
    setCurrentPageNumber(1);
    dispatch(fixedIncomeCountAction.getIncomingOfferCountAction());
    dispatch(
      fixedIncome.getIncomingOfferOrderAction(
        sessionStorage.getItem("paginationkey")
      )
    );
    const timer = setInterval(() => {
      dispatch(fixedIncomeCountAction.getIncomingOfferCountAction());
      dispatch(
        fixedIncome.getIncomingOfferOrderAction(
          sessionStorage.getItem("paginationkey")
        )
      );
    }, 10000);
    setTimerInterval(timer);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(timerInterval);
      sessionStorage.removeItem("paginationkey");
    };
  }, [timerInterval]);

  const openOrderModal = (obj) => {
    setOrder(obj);
    setOrderModalShow(true);
  };

  const columns = [
    {
      name: "Order ID",
      selector: "order",
      sortable: false,
      minWidth: "165px",
      cell: (row) => (row?.order ? row?.order : "-"),
      compact: true,
    },
    {
      name: "Bond ID",
      selector: "bond_id",
      sortable: false,
      right: false,
      minWidth: "170px",
      cell: (row) => (row?.bond_id ? row?.bond_id : "-"),
      compact: true,
    },
    {
      name: "ISIN",
      selector: "isin",
      sortable: false,
      right: false,
      cell: (row) => (row?.isin ? row?.isin : "-"),
      minWidth: "130px",
      compact: true,
    },
    {
      name: "Exchange",
      selector: "exchange_name",
      sortable: false,
      center: true,
      minWidth: "70px",
      cell: (row) => (row?.exchange_name ? row?.exchange_name : "-"),
      compact: true,
    },
    {
      name: "Coupon (%)",
      selector: "coupon_rate",
      sortable: false,
      center: true,
      cell: (row) =>
        row?.coupon_rate ? parseFloat(row?.coupon_rate)?.toFixed(4) : "-",
      compact: true,
    },
    {
      name: "Mat Date",
      selector: "maturity_date",
      sortable: false,
      right: false,
      cell: (row) =>
        row.createdAt ? (
          <span className="text-uppercase">
            {moment(row.maturity_date).format("DD/MM/YYYY")}
          </span>
        ) : (
          "-"
        ),
      compact: true,
    },
    {
      name: "Counterparty",
      selector: "market_taker_name",
      sortable: false,
      center: true,
      minWidth: "120px",
      cell: (row) => (row?.market_taker_name ? row?.market_taker_name : "-"),
      compact: true,
    },
    {
      name: "Side",
      selector: "market_maker_side",
      sortable: false,
      cell: (row) =>
        row?.market_maker_side ? (
          <span className="text-uppercase">{row?.market_maker_side}</span>
        ) : (
          "-"
        ),
      compact: true,
      minWidth: "60px",
      center: true,
    },
    {
      name: "All or Nothing ",
      selector: "allOrNothing",
      sortable: false,
      cell: (row) =>
        row?.allOrNothing ? (
          <span className="text-uppercase">{row?.allOrNothing}</span>
        ) : (
          "-"
        ),
      compact: true,
      center: true,
    },
    {
      name: "CCY",
      selector: "ccy_suffix",
      sortable: false,
      right: false,
      minWidth: "60px",
      cell: (row) => (row?.ccy_suffix ? row?.ccy_suffix : "-"),
      compact: true,
    },
    {
      name: "Order Yield (%)",
      selector: "offer_yield",
      sortable: false,
      center: true,
      minWidth: "110px",
      cell: (row) => (row?.yield ? parseFloat(row?.yield)?.toFixed(4) : "-"),
      compact: true,
    },
    {
      name: "Offer Yield (%)",
      selector: "offer_yield",
      sortable: false,
      center: true,
      minWidth: "110px",
      cell: (row) =>
        row?.offer_yield ? parseFloat(row?.offer_yield)?.toFixed(4) : "-",
      compact: true,
    },
    {
      name: "Order Amount",
      selector: "order_amount",
      sortable: false,
      minWidth: "110px",
      cell: (row) =>
        row?.order_amount ? (
          <CurrencyFormat
            value={parseFloat(row?.order_amount)?.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "-"
        ),
      compact: true,
      right: true,
    },
    {
      name: "Offer Amount",
      selector: "offer_amount",
      sortable: false,
      minWidth: "120px",
      cell: (row) =>
        row?.offer_amount ? (
          <CurrencyFormat
            value={parseFloat(row?.offer_amount)?.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "-"
        ),
      compact: true,
      right: true,
    },
    {
      name: "Date",
      selector: "createdAt",
      sortable: false,
      minWidth: "100px",
      cell: (row) => (
        <span className="text-uppercase">
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      name: "Time (GMT)",
      selector: "createdAt",
      sortable: false,
      center: true,
      minWidth: "80px",
      cell: (row) =>
        row?.createdAt
          ? moment(row.createdAt).tz("GMT").format("hh:mm:ss A")
          : "-",
      compact: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      minWidth: "80px",
      center: true,
      cell: (row) => (row.status ? row?.status : "-"),
      compact: true,
    },
    {
      name: "Action",
      selector: "action",
      sortable: false,
      minWidth: "100px",
      cell: (row) =>
        row?.status === "open" && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => openOrderModal(row)}
          >
            Review
          </Button>
        ),
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      omit: false,
      compact: true,
    },
  ];

  const opentradetab = () => {
    props.opentradetab();
  };

  const handlePaginationChange = (page) => {
    if (
      currentPageNumber > page &&
      offerData?.filter &&
      offerData?.filter?.prevPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", offerData?.filter?.prevPage);
      dispatch(
        fixedIncome.getIncomingOfferOrderAction(offerData?.filter?.prevPage)
      );
    } else if (
      currentPageNumber < page &&
      offerData?.filter &&
      offerData?.filter?.nextPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", offerData?.filter?.nextPage);
      dispatch(
        fixedIncome.getIncomingOfferOrderAction(offerData?.filter?.nextPage)
      );
    }
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={offerData?.set}
        theme={"dark"}
        highlightOnHover
        pagination
        paginationServer
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationTotalRows={props?.totalRecord}
        paginationPerPage={15}
        paginationDefaultPage={currentPageNumber}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => handlePaginationChange(page)}
        paginationComponent={CustomPagination}
        persistTableHead={true}
        noHeader={true}
      />
      {orderModalShow && (
        <ReviewOrderFormModal
          show={orderModalShow}
          onHide={() => setOrderModalShow(false)}
          order={order}
          opentradetab={opentradetab}
        />
      )}
    </>
  );
};

export default React.memo(OfferTable);
