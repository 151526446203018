import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OfferTable from "./Incoming/OfferTable";
import QuoteTable from "./Incoming/QuoteTable";
import * as fixedIncomeCountAction from "../../../../store/actions/fixedIncomeCount.action";

import { Box, styled, Tab, Tabs } from "@material-ui/core";

const StyledTabs = styled((props) => <Tabs {...props} />)({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#0c6fb3",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minWidth: "50px !important",
  maxWidth: "50px !important",
  paddingInline: "5rem",
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "white",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  "&.Mui-selected": {
    backgroundColor: "#0c6fb3",
    borderRadius: "20px",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const IncomingTab = (props) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const totalRecordData = useSelector((state) => state?.fixedincomecount);

  useEffect(() => {
    dispatch(fixedIncomeCountAction.getIncomingQuotesCountAction());
  }, [dispatch]);

  const opentradetab = () => {
    props.opentradetab();
  };

  return (
    <Box>
      <StyledTabs
        value={activeTab}
        onChange={(e, value) => setActiveTab(value)}
      >
        <StyledTab
          label={`Offers ${
            totalRecordData?.incomingOfferCount !== 0
              ? totalRecordData?.incomingOfferCount
              : ""
          }`}
        />
        <StyledTab
          label={`Quotes ${
            totalRecordData?.incomingQuotesCount !== 0
              ? totalRecordData?.incomingQuotesCount
              : ""
          }`}
        />
      </StyledTabs>
      {activeTab == 0 && (
        <OfferTable
          totalRecord={totalRecordData?.incomingOfferCount}
          opentradetab={opentradetab}
        />
      )}
      {activeTab == 1 && (
        <QuoteTable totalRecord={totalRecordData?.incomingQuotesCount} />
      )}
    </Box>
  );
};

export default React.memo(IncomingTab);
