import Routes from "./routes/routes";
import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
} from "@material-ui/core";
import useDynamicFavicon from "./useDynamicFavicon";

function App() {
  useDynamicFavicon();

  return (
    <MuiThemeProvider
      theme={createMuiTheme({
        typography: {
          htmlFontSize: 20,
        },
        palette: {
          type: "dark",
          primary: {
            main: "#0B6FB4",
          },
        },
      })}
    >
      <CssBaseline />
      <Routes />
    </MuiThemeProvider>
  );
}

export default App;
