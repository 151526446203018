import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import OpenOrderTable from "./Table/OpenOrderTable";
import PositionSectorPieChart from "./Charts/PositionSectorPieChart";
import PositionVenuePieChart from "./Charts/PositionVenuePieChart";
import AccountLineChart from "./Charts/AccountLineChart";
import AccountDetail from "./AccountDetail/AccountDetail";
import moment from "moment";
import "moment-timezone";
import CurrencyList from "../../components/CurrencyList/CurrencyList";
import PositionTable from "../../components/PositionTable/PositionTable";
import OpenOrderFixIncomeTable from "./Table/OpenOrderFixIncomeTable";
import {
  Box,
  Divider,
  Grid,
  Typography,
  makeStyles,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Table,
  TableBody,
} from "@material-ui/core";
import Loader from "../../components/Loader";
import {
  loadAllOrders,
  loading as orderLoading,
} from "../../store/actions/order.action";

import axios from "axios";

import { styled } from "@material-ui/core/styles";
import {
  loading as userAccountLoading,
  loadUserAccountData,
} from "../../store/actions/userAccount.action";
import { sortByAsc, sortByDesc, toCurrency } from "../../helpers/utilities";
import CurrencyFormat from "react-currency-format";

const CustomTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    borderBottom: "none !important",
    color: "#808080",
  },
  "@media print": {
    "&.MuiTableCell-head": {
      color: "black !important",
      borderBottom: "1px solid black !important",
    },
    "&.MuiTableCell-body": {
      borderBottom: "1px solid black !important",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  tenureFlex: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  tenureBadge: {
    padding: "5px 7px",
    borderRadius: "50%",
    fontSize: "10px",
    cursor: "pointer",
    "&:not(:last-child)": {
      marginRight: "0.5rem",
    },
    "&.active": {
      backgroundColor: "#213168",
      color: theme.palette.getContrastText("#213168"),
    },
  },
  select: {
    width: "auto !important",
    height: "40px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabs: {
    fontSize: "0.9375rem",
    maxWidth: "264px",
    minWidth: "72px",
    boxSizing: "border-box",
    textAlign: "center",
    flexShrink: 0,
    lineHeight: 1.75,
    whiteSpace: "normal",
    letterSpacing: "0.02857em",
    padding: "6px 12px",
    cursor: "pointer",
    marginRight: "8px",
    userSelect: "none",
  },
  activeTab: {
    borderBottom: "2px solid #0c6fb3",
    fontWeight: "400",
    textTransform: "none",
    color: "white",
  },
  border_: {
    borderTop: "0.5px solid rgba(255, 255, 255, 0.12)",
    borderBottom: "0.5px solid rgba(255, 255, 255, 0.12)",
  },
  border: {
    borderTop: "1px solid rgba(255, 255, 255, 0.12)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
  },
  borderTop: {
    borderTop: "1px solid rgba(255, 255, 255, 0.12)",
  },
}));

const LIMIT = 5;

const Account = ({ fund, account, accounts, accountLoading }) => {
  const classes = useStyles();

  const [positions, setPositions] = useState([]);

  const [dates, setDates] = useState([]);
  const [cashBalanceLabels, setCashBalanceLabels] = useState([]);
  const [cashBalanceData, setCashBalanceData] = useState([]);

  const [fxRates, setFxRates] = useState({});
  const [positionsMetaData, setPositionsMetaData] = useState({});

  const [accountPrevious, setAccountPrevious] = useState(0);
  const [accountTotal, setAccountTotal] = useState(0);
  const [timestamp, setTimestamp] = useState(null);

  const [openOrderActiveTab, setOpenOrderActiveTab] = useState(0);

  const [ccy, setCcy] = useState(null);
  const [clientCcy, setClientCcy] = useState(null);

  const dispatch = useDispatch();

  const { items: orders, loading: orderLoader } = useSelector(
    (state) => state.orders
  );

  const { items: currencies } = useSelector((state) => state.currencies);
  const {
    data: userAccount,
    loading: userAccountLoader,
    error,
  } = useSelector((state) => state.userAccount);

  const loadFxRates = async () => {
    let fxData_ = {};
    await Promise.all(
      currencies.map((currency) =>
        axios.post(
          `${process.env.REACT_APP_API_URL}/currencies/api/v1/exchange-rates/getForCurrencies/${currency.isoCode}`,
          {
            currencies: Array.from(
              new Set(
                currencies
                  .filter((_) => _.isoCode != currency.isoCode)
                  .map((_) => _.isoCode)
              )
            ),
          }
        )
      )
    )
      .then((fxData) => {
        fxData.forEach((_) => {
          _.data.forEach((__) => {
            if (!fxData_[__.baseCurrency]) {
              fxData_[__.baseCurrency] = {};
            }

            fxData_[__.baseCurrency] = {
              ...fxData_[__.baseCurrency],
              [__.currency]: __.multiplier,
            };
          });
        });
      })
      .catch((error) => {
        console.log({ error });
      });

    setFxRates(fxData_);
  };

  useEffect(() => {
    if (currencies.length > 0) {
      loadFxRates();
    }
  }, [currencies]);

  useEffect(() => {
    const loadClientData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/clients/api/v1/${fund.clientId}/details`
        );
        setClientCcy(data?.currency);
      } catch (error) {
        console.log({ error });
      }
    };

    if ("clientId" in fund) {
      loadClientData();
    }
  }, [fund]);

  useEffect(() => {
    if (clientCcy) {
      setCcy(clientCcy);
    }
  }, [clientCcy]);

  useEffect(() => {
    if ("id" in fund) {
      dispatch(orderLoading());
      dispatch(
        loadAllOrders({
          fund,
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        })
      );
      dispatch(userAccountLoading());
      dispatch(loadUserAccountData(fund.id));
    }
  }, [fund, dispatch]);

  useEffect(() => {
    if (account != "All" && "ccy" in account) {
      setCcy(account.ccy);
    } else {
      setCcy(clientCcy);
    }
  }, [account, clientCcy]);

  useEffect(() => {
    if (Object.keys(userAccount).length > 0) {
      let body = {};

      Object.keys(userAccount).forEach((_) => {
        userAccount[_].positions.forEach((_) => {
          body[_["ticker"]] = {
            symbol: _["ticker"],
            exchange: _.origVenue,
          };
        });
      });

      if (Object.keys(body).length > 0) {
        const loadMetaData = async () => {
          try {
            const { data: res } = await axios.post(
              `${process.env.REACT_APP_API_URL}/ws/ltes/symbols`,
              Object.values(body)
            );

            body = {};

            res.forEach((_) => {
              if (!body[`${_?.key?.symbol}-${_?.key?.exchange}`]) {
                body[`${_?.key?.symbol}-${_?.key?.exchange}`] = {};
              }
              body[`${_?.key?.symbol}-${_?.key?.exchange}`] = {
                ...body[`${_?.key?.symbol}-${_?.key?.exchange}`],
                data: _,
              };
            });

            setPositionsMetaData(body);
          } catch (error) {
            console.log({ error });
          }
        };

        loadMetaData();
      }
    }
  }, [userAccount]);

  useEffect(() => {
    if (Object.keys(userAccount).length > 0) {
      let positionsData = [];
      let previous_ = 0;
      let cashInW = {};
      let cashInWDates = [];
      let timestamp_ = [];
      let dates_ = [];

      Object.keys(userAccount).forEach((_) => {
        cashInWDates = Array.from(
          new Set([...cashInWDates, ...Object.keys(userAccount?.[_]?.cash1w)])
        );
      });

      cashInWDates = cashInWDates.map((_) => ({ label: _ }));
      cashInWDates = sortByAsc(cashInWDates, "label");
      cashInWDates = cashInWDates.slice(Math.max(cashInWDates.length - 6, 0));

      if (account == "All") {
        Object.keys(userAccount).forEach((_) => {
          Object.keys(userAccount?.[_]?.ptPositions).forEach((__) => {
            previous_ +=
              (positionsMetaData?.[__]?.data?.prevClose ?? 0) *
              userAccount?.[_]?.ptPositions[__] *
              (account == "All"
                ? ccy == userAccount?.[_].ccy
                  ? 1
                  : fxRates?.[userAccount?.[_].ccy]?.[ccy] ?? 0
                : 0);
          });

          timestamp_.push({ label: userAccount?.[_]?.timestamp });
          positionsData = [...positionsData, ...userAccount?.[_]?.positions];
          dates_ = [...dates_, ...Object.keys(userAccount?.[_]?.cash)];

          let sum = 0;

          cashInWDates.forEach((date) => {
            if (userAccount?.[_]?.cash1w?.[date?.label] != undefined) {
              sum = userAccount?.[_]?.cash1w?.[date?.label] ?? 0;
            } else {
              let newDate = date?.label;
              let i = 0;
              while (userAccount?.[_]?.cash1w?.[newDate] == undefined) {
                if (i > Object.keys(userAccount?.[_]?.cash1w).length) {
                  break;
                }

                newDate = moment(newDate, "YYYY-MM-DD")
                  .subtract(1, "day")
                  .format("YYYY-MM-DD");

                i++;
              }

              sum = userAccount?.[_]?.cash1w?.[newDate] ?? 0;
            }

            sum =
              sum *
              (ccy == userAccount[_].ccy
                ? 1
                : fxRates?.[userAccount[_].ccy]?.[ccy] ?? 0);

            cashInW = {
              ...cashInW,
              [date?.label]: {
                label: date?.label,
                value: (cashInW?.[date?.label]?.value ?? 0) + sum,
              },
            };
          });
        });
      } else {
        if (userAccount?.[account?.id] && "ccy" in userAccount?.[account?.id]) {
          Object.keys(userAccount?.[account?.id]?.ptPositions).forEach((__) => {
            previous_ +=
              (positionsMetaData?.[__]?.data?.prevClose ?? 0) *
              userAccount?.[account?.id]?.ptPositions[__] *
              (ccy == userAccount?.[account?.id].ccy
                ? 1
                : fxRates?.[userAccount?.[account?.id].ccy]?.[ccy] ?? 0);
          });

          positionsData = [
            ...positionsData,
            ...(userAccount?.[account?.id]?.positions ?? []),
          ];
          dates_ = [
            ...dates_,
            ...Object.keys(userAccount?.[account?.id]?.cash),
          ];
          timestamp_.push({ label: userAccount?.[account?.id]?.timestamp });

          if (userAccount?.[account?.id]?.cash1w) {
            Object.keys(userAccount?.[account?.id]?.cash1w).forEach((__) => {
              cashInW = {
                ...cashInW,
                [__]: {
                  label: __,
                  value:
                    (ccy == account?.ccy
                      ? 1
                      : fxRates?.[account?.ccy]?.[ccy] ?? 0) *
                    userAccount?.[account?.id]?.cash1w[__],
                },
              };
            });
          }
        }
      }

      dates_ = sortByAsc(
        Array.from(new Set(dates_)).map((date__) => ({ label: date__ })),
        "label"
      ).map((date__) => date__.label);

      if (account == "All") {
        const workingDays = [...dates_];

        const workingDayIndex = workingDays.findIndex(
          (_) => _ == moment().format("YYYY-MM-DD")
        );

        if (workingDayIndex != -1) {
          dates_ = [];
          dates_[0] = workingDays?.[workingDayIndex - 1] ?? null;
          dates_[1] = workingDays?.[workingDayIndex] ?? null;
          dates_[2] = workingDays?.[workingDayIndex + 1] ?? null;
          dates_[3] = workingDays?.[workingDayIndex + 2] ?? null;
          dates_[4] = workingDays?.[workingDayIndex + 3] ?? null;
        }
      }

      dates_ = dates_.slice(Math.max(dates_.length - LIMIT - 1, 0));
      setDates(dates_);

      let total_ = 0;

      positionsData = positionsData.map((_) => {
        total_ +=
          (positionsMetaData?.[`${_.ticker}-${_.origVenue}`]?.data?.price ??
            0) *
          _.volume *
          (ccy == _.ccy ? 1 : fxRates?.[_.ccy]?.[ccy] ?? 0);

        let response = {
          ..._,
          fxRate: ccy == _.ccy ? 1 : fxRates?.[_.ccy]?.[ccy],
          data: positionsMetaData?.[`${_.ticker}-${_.origVenue}`]
            ? positionsMetaData?.[`${_.ticker}-${_.origVenue}`]?.data
            : {},
        };

        let price = 0;

        if (response?.data?.price) {
          price = response?.data?.price;
        } else if (
          response?.data?.openingPrice &&
          response?.data?.openingPrice > 0
        ) {
          price = response?.data?.openingPrice;
        } else {
          price = response?.data?.prevClose ?? 0;
        }

        return {
          ...response,
          price,
          localPrice: price * _.volume,
          marketValue:
            price * _.volume * (ccy == _.ccy ? 1 : fxRates?.[_.ccy]?.[ccy]),
        };
      });
      setPositions(positionsData);

      previous_ +=
        loadCashDataCurrent(dates_?.[0], ccy) -
        loadCashDataOutgoing(dates_?.[0], ccy);
      total_ +=
        loadCashDataCurrent(dates_?.[1], ccy) -
        loadCashDataOutgoing(dates_?.[1], ccy);

      setAccountPrevious(previous_);
      setAccountTotal(total_);

      timestamp_ = sortByDesc(Object.values(timestamp_), "label");

      if (timestamp_.length > 0) {
        setTimestamp(
          moment(timestamp_[0].label).tz("GMT").format("DD/MM/YYYY HH:mm:ss")
        );
      }

      cashInW = Object.values(cashInW);
      cashInW = cashInW.slice(Math.max(cashInW.length - 6, 0));
      setCashBalanceLabels(
        cashInW.map((_) => moment(_.label).format("DD/MM/YYYY"))
      );
      setCashBalanceData(cashInW.map((_) => _.value));
    }
  }, [fund, userAccount, account, positionsMetaData, ccy]);

  const loadCashDataCurrent = (key, ccy) => {
    let total = 0;
    if (account == "All") {
      Object.keys(userAccount).forEach((_) => {
        let sum = 0;
        if (userAccount?.[_]?.cash?.[key] != undefined) {
          sum = userAccount?.[_]?.cash?.[key] ?? 0;
        } else {
          let newDate = key;
          let i = 0;
          while (userAccount?.[_]?.cash?.[newDate] == undefined) {
            if (i > Object.keys(userAccount?.[_]?.cash).length) {
              break;
            }

            newDate = moment(newDate, "YYYY-MM-DD")
              .subtract(1, "day")
              .format("YYYY-MM-DD");

            i++;
          }

          sum = userAccount?.[_]?.cash?.[newDate] ?? 0;
        }
        sum *=
          ccy == userAccount?.[_]?.ccy
            ? 1
            : fxRates?.[userAccount?.[_]?.ccy]?.[ccy] ?? 0;
        total += sum;
      });
    } else if (
      userAccount?.[account?.id] &&
      "ccy" in userAccount?.[account?.id]
    ) {
      total =
        (userAccount[account?.id]?.cash?.[key] ?? 0) *
        (ccy == userAccount?.[account?.id]?.ccy
          ? 1
          : fxRates?.[userAccount?.[account?.id]?.ccy]?.[ccy] ?? 0);
    }

    return total;
  };

  const loadCashDataOutgoing = (key, ccy) => {
    let total = 0;
    if (account == "All") {
      Object.keys(userAccount).forEach((_) => {
        let sum = 0;
        if (userAccount?.[_]?.cashInTransitBuy?.[key] != undefined) {
          sum = userAccount?.[_]?.cashInTransitBuy?.[key] ?? 0;
        } else {
          let newDate = key;
          let i = 0;
          while (userAccount?.[_]?.cashInTransitBuy?.[newDate] == undefined) {
            if (i > Object.keys(userAccount?.[_]?.cashInTransitBuy).length) {
              break;
            }

            newDate = moment(newDate, "YYYY-MM-DD")
              .subtract(1, "day")
              .format("YYYY-MM-DD");

            i++;
          }

          sum = userAccount?.[_]?.cashInTransitBuy?.[newDate] ?? 0;
        }
        sum *=
          ccy == userAccount?.[_]?.ccy
            ? 1
            : fxRates?.[userAccount?.[_]?.ccy]?.[ccy] ?? 0;

        total += sum;
      });
    } else if (
      userAccount?.[account?.id] &&
      "ccy" in userAccount?.[account?.id]
    ) {
      total =
        (userAccount[account?.id]?.cashInTransitBuy?.[key] ?? 0) *
        (ccy == userAccount?.[account?.id]?.ccy
          ? 1
          : fxRates?.[userAccount?.[account?.id]?.ccy]?.[ccy] ?? 0);
    }

    return total;
  };

  const loadCashDataIncoming = (key, ccy) => {
    let total = 0;
    if (account == "All") {
      Object.keys(userAccount).forEach((_) => {
        let sum = 0;
        if (userAccount?.[_]?.cashInTransitSell?.[key] != undefined) {
          sum = userAccount?.[_]?.cashInTransitSell?.[key] ?? 0;
        } else {
          let newDate = key;
          let i = 0;
          while (userAccount?.[_]?.cashInTransitSell?.[newDate] == undefined) {
            if (i > Object.keys(userAccount?.[_]?.cashInTransitSell).length) {
              break;
            }

            newDate = moment(newDate, "YYYY-MM-DD")
              .subtract(1, "day")
              .format("YYYY-MM-DD");

            i++;
          }

          sum = userAccount?.[_]?.cashInTransitSell?.[newDate] ?? 0;
        }
        sum *=
          ccy == userAccount?.[_]?.ccy
            ? 1
            : fxRates?.[userAccount?.[_]?.ccy]?.[ccy] ?? 0;

        total += sum;
      });
    } else if (
      userAccount?.[account?.id] &&
      "ccy" in userAccount?.[account?.id]
    ) {
      total =
        (userAccount[account?.id]?.cashInTransitSell?.[key] ?? 0) *
        (ccy == userAccount?.[account?.id]?.ccy
          ? 1
          : fxRates?.[userAccount?.[account?.id]?.ccy]?.[ccy] ?? 0);
    }

    return total;
  };

  const cashDetails = (
    <TableContainer>
      <Table>
        <TableHead
          style={{
            borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
            paddingBottom: "1rem",
          }}
        >
          <TableRow>
            <CustomTableCell>Outstanding Balance</CustomTableCell>
            <CustomTableCell align="right">
              {dates?.[0] ? moment(dates?.[0]).format("DD/MM/YYYY") : "t-1"}
            </CustomTableCell>
            <CustomTableCell align="right">
              {dates?.[1]
                ? moment(dates?.[1]).format("DD/MM/YYYY")
                : "t (Today)"}
            </CustomTableCell>
            <CustomTableCell align="right">Net change</CustomTableCell>
            <CustomTableCell align="right">
              {dates?.[2] ? moment(dates?.[2]).format("DD/MM/YYYY") : "t+1"}
            </CustomTableCell>
            <CustomTableCell align="right">
              {dates?.[3] ? moment(dates?.[3]).format("DD/MM/YYYY") : "t+2"}
            </CustomTableCell>
            <CustomTableCell align="right">
              {dates?.[4] ? moment(dates?.[4]).format("DD/MM/YYYY") : "t+3"}
            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <CustomTableCell>Cash (in bank)</CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataCurrent(dates?.[0], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[0], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[0], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[0], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataCurrent(dates?.[1], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[1], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[1], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[1], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataCurrent(dates?.[1], ccy) -
                loadCashDataCurrent(dates?.[0], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    loadCashDataCurrent(dates?.[1], ccy) -
                      loadCashDataCurrent(dates?.[0], ccy)
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[1], ccy) -
                      loadCashDataCurrent(dates?.[0], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[1], ccy) -
                      loadCashDataCurrent(dates?.[0], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataCurrent(dates?.[2], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[2], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[2], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[2], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataCurrent(dates?.[3], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[3], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[3], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[3], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataCurrent(dates?.[4], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[4], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[4], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[4], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell>Cash Alternatives</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell className={classes.border}>
              Total Cash (current)
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataCurrent(dates?.[0], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[0], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[0], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[0], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataCurrent(dates?.[1], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[1], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[1], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[1], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataCurrent(dates?.[1], ccy) -
                loadCashDataCurrent(dates?.[0], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataCurrent(dates?.[1], ccy) -
                        loadCashDataCurrent(dates?.[0], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[1], ccy) -
                      loadCashDataCurrent(dates?.[0], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[1], ccy) -
                      loadCashDataCurrent(dates?.[0], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataCurrent(dates?.[2], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[2], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[2], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[2], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataCurrent(dates?.[3], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[3], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[3], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[3], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataCurrent(dates?.[4], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataCurrent(dates?.[4], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[4], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[4], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell colSpan={7} className={classes.border}>
              &nbsp;
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell className={classes.border}>
              Cash Outgoing (in-transit)
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataOutgoing(dates?.[0], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataOutgoing(dates?.[0], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataOutgoing(dates?.[0], ccy) * -1 < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataOutgoing(dates?.[0], ccy) * -1 < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataOutgoing(dates?.[1], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataOutgoing(dates?.[1], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataOutgoing(dates?.[1], ccy) * -1 < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataOutgoing(dates?.[1], ccy) * -1 < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataOutgoing(dates?.[1], ccy) -
                loadCashDataOutgoing(dates?.[0], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    loadCashDataOutgoing(dates?.[1], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy)
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataOutgoing(dates?.[1], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy) * -1 <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataOutgoing(dates?.[1], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy) * -1 <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataOutgoing(dates?.[2], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataOutgoing(dates?.[2], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataOutgoing(dates?.[2], ccy) * -1 < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataOutgoing(dates?.[2], ccy) * -1 < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataOutgoing(dates?.[3], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataOutgoing(dates?.[3], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataOutgoing(dates?.[3], ccy) * -1 < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataOutgoing(dates?.[3], ccy) * -1 < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border}>
              {loadCashDataOutgoing(dates?.[4], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataOutgoing(dates?.[4], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataOutgoing(dates?.[4], ccy) * -1 < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataOutgoing(dates?.[4], ccy) * -1 < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell>Cash Incoming (in-transit)</CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataIncoming(dates?.[0], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataIncoming(dates?.[0], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[0], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[0], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataIncoming(dates?.[1], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataIncoming(dates?.[1], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[1], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[1], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataIncoming(dates?.[1], ccy) -
                loadCashDataIncoming(dates?.[0], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataIncoming(dates?.[1], ccy) -
                        loadCashDataIncoming(dates?.[0], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[1], ccy) -
                      loadCashDataIncoming(dates?.[0], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[1], ccy) -
                      loadCashDataIncoming(dates?.[0], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataIncoming(dates?.[2], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataIncoming(dates?.[2], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[2], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[2], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataIncoming(dates?.[3], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataIncoming(dates?.[3], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[3], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[3], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right">
              {loadCashDataIncoming(dates?.[4], ccy) == 0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(loadCashDataIncoming(dates?.[4], ccy))
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[4], ccy) < 0 ? "(" : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[4], ccy) < 0 ? ")" : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell>Cash Alternatives (in-transit)</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
            <CustomTableCell align="right">-</CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell className={classes.border}>
              Total Cash (in-transit)
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataIncoming(dates?.[0], ccy) -
                loadCashDataOutgoing(dates?.[0], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataIncoming(dates?.[0], ccy) -
                        loadCashDataOutgoing(dates?.[0], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[0], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[0], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataIncoming(dates?.[1], ccy) -
                loadCashDataOutgoing(dates?.[1], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataIncoming(dates?.[1], ccy) -
                        loadCashDataOutgoing(dates?.[1], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[1], ccy) -
                      loadCashDataOutgoing(dates?.[1], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[1], ccy) -
                      loadCashDataOutgoing(dates?.[1], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataIncoming(dates?.[0], ccy) -
                loadCashDataOutgoing(dates?.[0], ccy) -
                (loadCashDataIncoming(dates?.[1], ccy) -
                  loadCashDataOutgoing(dates?.[1], ccy)) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataIncoming(dates?.[0], ccy) -
                        loadCashDataOutgoing(dates?.[0], ccy) -
                        (loadCashDataIncoming(dates?.[1], ccy) -
                          loadCashDataOutgoing(dates?.[1], ccy))
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[0], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy) -
                      (loadCashDataIncoming(dates?.[1], ccy) -
                        loadCashDataOutgoing(dates?.[1], ccy)) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[0], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy) -
                      (loadCashDataIncoming(dates?.[1], ccy) -
                        loadCashDataOutgoing(dates?.[1], ccy)) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataIncoming(dates?.[2], ccy) -
                loadCashDataOutgoing(dates?.[2], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataIncoming(dates?.[2], ccy) -
                        loadCashDataOutgoing(dates?.[2], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[2], ccy) -
                      loadCashDataOutgoing(dates?.[2], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[2], ccy) -
                      loadCashDataOutgoing(dates?.[2], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataIncoming(dates?.[3], ccy) -
                loadCashDataOutgoing(dates?.[3], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataIncoming(dates?.[3], ccy) -
                        loadCashDataOutgoing(dates?.[3], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[3], ccy) -
                      loadCashDataOutgoing(dates?.[3], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[3], ccy) -
                      loadCashDataOutgoing(dates?.[3], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataIncoming(dates?.[4], ccy) -
                loadCashDataOutgoing(dates?.[4], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataIncoming(dates?.[4], ccy) -
                        loadCashDataOutgoing(dates?.[4], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataIncoming(dates?.[4], ccy) -
                      loadCashDataOutgoing(dates?.[4], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataIncoming(dates?.[4], ccy) -
                      loadCashDataOutgoing(dates?.[4], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell colSpan={7} className={classes.border_}>
              &nbsp;
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell className={classes.border_}>
              Cash Available to Withdraw
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataCurrent(dates?.[0], ccy) -
                loadCashDataOutgoing(dates?.[0], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataCurrent(dates?.[0], ccy) -
                        loadCashDataOutgoing(dates?.[0], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[0], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[0], ccy) -
                      loadCashDataOutgoing(dates?.[0], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataCurrent(dates?.[1], ccy) -
                loadCashDataOutgoing(dates?.[1], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataCurrent(dates?.[1], ccy) -
                        loadCashDataOutgoing(dates?.[1], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[1], ccy) -
                      loadCashDataOutgoing(dates?.[1], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[1], ccy) -
                      loadCashDataOutgoing(dates?.[1], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataCurrent(dates?.[0], ccy) -
                loadCashDataOutgoing(dates?.[0], ccy) -
                (loadCashDataCurrent(dates?.[1], ccy) -
                  loadCashDataOutgoing(dates?.[1], ccy)) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataCurrent(dates?.[0], ccy) -
                        loadCashDataCurrent(dates?.[0], ccy) -
                        (loadCashDataOutgoing(dates?.[1], ccy) -
                          loadCashDataOutgoing(dates?.[1], ccy))
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[0], ccy) -
                      loadCashDataCurrent(dates?.[0], ccy) -
                      (loadCashDataOutgoing(dates?.[1], ccy) -
                        loadCashDataOutgoing(dates?.[1], ccy)) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[0], ccy) -
                      loadCashDataCurrent(dates?.[0], ccy) -
                      (loadCashDataOutgoing(dates?.[1], ccy) -
                        loadCashDataOutgoing(dates?.[1], ccy)) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataCurrent(dates?.[2], ccy) -
                loadCashDataOutgoing(dates?.[2], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataCurrent(dates?.[2], ccy) -
                        loadCashDataOutgoing(dates?.[2], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[2], ccy) -
                      loadCashDataOutgoing(dates?.[2], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[2], ccy) -
                      loadCashDataOutgoing(dates?.[2], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataCurrent(dates?.[3], ccy) -
                loadCashDataOutgoing(dates?.[3], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataCurrent(dates?.[3], ccy) -
                        loadCashDataOutgoing(dates?.[3], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[3], ccy) -
                      loadCashDataOutgoing(dates?.[3], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[3], ccy) -
                      loadCashDataOutgoing(dates?.[3], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
            <CustomTableCell align="right" className={classes.border_}>
              {loadCashDataCurrent(dates?.[4], ccy) -
                loadCashDataOutgoing(dates?.[4], ccy) ==
              0 ? (
                "-"
              ) : (
                <CurrencyFormat
                  displayType="text"
                  value={toCurrency(
                    Math.abs(
                      loadCashDataCurrent(dates?.[4], ccy) -
                        loadCashDataOutgoing(dates?.[4], ccy)
                    )
                  )}
                  thousandSeparator={true}
                  prefix={`${ccy} ${
                    loadCashDataCurrent(dates?.[4], ccy) -
                      loadCashDataOutgoing(dates?.[4], ccy) <
                    0
                      ? "("
                      : ""
                  }`}
                  suffix={`${
                    loadCashDataCurrent(dates?.[4], ccy) -
                      loadCashDataOutgoing(dates?.[4], ccy) <
                    0
                      ? ")"
                      : ""
                  }`}
                  fixedDecimalScale={true}
                />
              )}
            </CustomTableCell>
          </TableRow>

          <TableRow>
            <CustomTableCell colSpan={7} className={classes.border_}>
              &nbsp;
            </CustomTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {accountLoading || userAccountLoader ? (
        <Loader text="Loading data..." />
      ) : error ? (
        <Box textAlign={"center"} p={2}>
          <Typography variant="h6" color="secondary">
            Something went wrong. Please try again later!
          </Typography>
        </Box>
      ) : accounts.length > 0 ? (
        <Grid container spacing={1}>
          <Grid item xs={12} className="js-my-accounts">
            <Box p={2} borderRadius={5} bgcolor={"#252525"}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography variant="h6">Total Account value</Typography>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems="center"
                >
                  <Box
                    style={{
                      paddingRight: "0.5rem",
                      marginRight: timestamp ? "0.5rem" : "0",
                      borderRight: timestamp ? "1px solid lightgrey" : "none",
                    }}
                  >
                    <CurrencyList
                      activeCurrency={ccy}
                      setActiveCurrency={(index) => {
                        setCcy(index);
                      }}
                    />
                  </Box>
                  {timestamp && (
                    <Typography variant="subtitle2">
                      Data as of {timestamp} (GMT)
                    </Typography>
                  )}
                </Box>
              </Box>
              <Divider style={{ marginTop: "0.5rem" }} />
              <AccountDetail
                previous={accountPrevious}
                total={accountTotal}
                ccy={ccy}
              />
            </Box>
          </Grid>
          <Grid item xs={12} className="js-my-accounts">
            <Grid container spacing={1}>
              <Grid item xs={12} lg={5}>
                <Box p={2} borderRadius={5} bgcolor={"#252525"} height={"100%"}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Cash balance history
                    </Typography>
                    {/* <Box item className={classes.tenureFlex}>
                      {["1W", "2M", "6M", "YTD", "1YR"].map((tenure, index) => (
                        <Typography
                          key={`tenure-${tenure}`}
                          className={`${classes.tenureBadge} ${
                            index == 1 ? "active" : ""
                          }`}
                        >
                          {tenure}
                        </Typography>
                      ))}
                    </Box> */}
                  </Box>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  <Box>
                    <AccountLineChart
                      labels={cashBalanceLabels}
                      data={cashBalanceData}
                      ccy={ccy}
                    />
                  </Box>
                </Box>
                <Divider />
              </Grid>
              <Grid item xs={12} lg={7}>
                <Box p={2} borderRadius={5} bgcolor={"#252525"} height={"100%"}>
                  {cashDetails}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="js-my-accounts">
            <Grid container spacing={1}>
              <Grid item xs={12} lg={5}>
                <Box
                  p={2}
                  borderRadius={5}
                  bgcolor={"#252525"}
                  height={"300px"}
                  overflow={"auto"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    marginTop={"0.5rem"}
                  >
                    <Typography
                      className={`${classes.tabs} ${
                        openOrderActiveTab == 0 ? classes.activeTab : ""
                      }`}
                      onClick={() => setOpenOrderActiveTab(0)}
                    >
                      Open Orders Equity
                    </Typography>
                    <Typography
                      className={`${classes.tabs} ${
                        openOrderActiveTab == 1 ? classes.activeTab : ""
                      }`}
                      onClick={() => setOpenOrderActiveTab(1)}
                    >
                      Open Offers Fixed Income
                    </Typography>
                  </Box>
                  {openOrderActiveTab == 0 && (
                    <OpenOrderTable
                      data={orders.filter((order) =>
                        ["ENQUEUED", "OPEN", "PARTIALLY_FILLED"].includes(
                          order.status
                        )
                      )}
                      orderLoading={orderLoader}
                    />
                  )}
                  {openOrderActiveTab == 1 && (
                    <OpenOrderFixIncomeTable openorderdata={[]} />
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} lg={7}>
                <Box
                  p={2}
                  borderRadius={5}
                  bgcolor={"#252525"}
                  height={"300px"}
                  overflow={"auto"}
                >
                  <Typography variant="h6" component={"h6"}>
                    Positions
                  </Typography>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  <PositionTable data={positions} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="js-my-accounts">
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <Box p={2} borderRadius={5} bgcolor={"#252525"} height={"100%"}>
                  <Typography variant="h6" component={"h6"}>
                    Sector value overview
                  </Typography>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  <PositionSectorPieChart positions={positions} />
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Box p={2} borderRadius={5} bgcolor={"#252525"} height={"100%"}>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography variant="h6" component={"h6"}>
                      Market value by venue
                    </Typography>
                  </Box>
                  <Divider style={{ margin: "0.5rem 0" }} />
                  <PositionVenuePieChart positions={positions} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Box textAlign={"center"} p={2}>
          <Typography variant="h6" color="secondary">
            You don't have any fund account, hence there is nothing to show in
            this page.
          </Typography>
        </Box>
      )}
    </>
  );
};

export default React.memo(Account);
