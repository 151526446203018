import Footer from "../../components/Layout/Footer";
import Logo from "../../assets/Images/headerlogo.svg";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { useFormik } from "formik";
import * as Yup from "yup";
import TimezoneSelect from "react-timezone-select";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import * as auth from "../../store/actions/auth.actions";
import { useDispatch } from "react-redux";
import { useState } from "react";

import {
  AppBar,
  Box,
  Container,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  TextField,
  Toolbar,
  Typography,
  Select as MUISelect,
  Button,
} from "@material-ui/core";

import { CheckCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  appBar: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  logo: {
    width: "150px",
    maxWidth: "100%",
    objectFit: "cover",
    cursor: "pointer",
  },
  container: {
    margin: "2rem auto",
  },
  titles: {
    color: "white",
    margin: "1rem 0",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  backBtn: {
    backgroundColor: "#E04C38 !important",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#E04C38 !important",
    },
  },
  submitBtn: {
    backgroundColor: "#0B6FB4 !important",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0B6FB4 !important",
    },
  },
  actions: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "1rem 0",
    marginRight: "1rem",
    "& > *:not(:first-child)": {
      marginLeft: "0.5rem",
    },
  },
  registerForm: {
    backgroundColor: theme.palette.common.black,
  },
  select: {
    borderRadius: "4px",
    color: "white",
    minWidth: "100%",
    minHeight: "3rem",
    backgroundColor: "transparent",
    padding: "0 0.5rem",
    "& > option": {
      backgroundColor: "#424242 !important",
      color: "white !important",
    },
  },
}));
const Register = (props) => {
  const classes = useStyles();

  const history = useHistory();
  const dispatch = useDispatch();
  const [marketList, setMarketList] = useState([
    { name: "markets", value: "equity" },
  ]);
  const [timezonevalue, setTimezonevalue] = useState({});

  const marketoptions = [
    { value: "equity", label: "Equity market" },
    { value: "bond", label: "Bond market" },
  ];

  function marketoptionChange(e, action) {
    if (action?.action === "select-option") {
      marketList.push({ name: "markets", value: action?.option?.value });
      setMarketList(marketList);
      formik.setFieldValue("markets", e);
    } else if (action.action === "remove-value" || action.action === "clear") {
      if (marketList?.length) {
        if (!action?.removedValues?.length) {
          const removemarkets = marketList?.findIndex(
            (data) => data.value === action?.removedValue?.value
          );
          if (removemarkets >= 0) {
            marketList.splice(removemarkets, 1);
            setMarketList(marketList);
          }
        } else {
          setMarketList([]);
        }
      }
    }
  }

  const initialValues = {
    company_name: "",
    description: "",
    street_name: "",
    country: "United States",
    state: "",
    city: "",
    zipcode: "",
    timezone: "",
    mailing_address: "",
    website: "",
    employees: "",
    sector: "",
    company_type: "",
    first_name: "",
    last_name: "",
    position: "",
    email_address: "",
    phone_number: "",
    other_phone_number: "",
    alternate_first_name: "",
    alternate_last_name: "",
    alternate_position: "",
    alternate_email_address: "",
    alternate_phone_number: "",
    alternate_other_phone_number: "",
    markets: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      company_name: Yup.string().required(
        "The Company Name field is required."
      ),
      description: Yup.string().required("The Description field is required."),
      street_name: Yup.string().required("The Street Name field is required."),
      country: Yup.string().required("The Country field is required."),
      state: Yup.string().required("The State field is required."),
      city: Yup.string().required("The City field is required."),
      zipcode: Yup.number(),
      timezone: Yup.string().required("The Timezone field is required."),
      mailing_address: Yup.string()
        .email("Invalid email address")
        .required("The Company Mail Address field is required."),
      website: Yup.string().required("The Website field is required."),
      employees: Yup.string(),
      sector: Yup.string().required("The Sector field is required."),
      company_type: Yup.string().required("The Type field is required."),
      first_name: Yup.string().required("The First Name field is required."),
      last_name: Yup.string().required("The Last Name field is required."),
      position: Yup.string().required("The Position field is required."),
      email_address: Yup.string()
        .email("Invalid email address")
        .required("The Email field is required."),
      phone_number: Yup.string().required(
        "The Phone Number field is required."
      ),
      other_phone_number: Yup.string(),
      alternate_first_name: Yup.string(),
      alternate_last_name: Yup.string(),
      alternate_position: Yup.string(),
      alternate_email_address: Yup.string(),
      alternate_phone_number: Yup.string(),
      alternate_other_phone_number: Yup.string(),
    }),
    onSubmit: (e, { resetForm }) => {
      if (marketList?.length) {
        let payload = {
          values: [
            { name: "name", value: e?.company_name },
            { name: "description", value: e?.description },
            { name: "street_name", value: e?.street_name },
            { name: "country", value: e?.country },
            { name: "state", value: e?.state },
            { name: "city", value: e?.city },
            { name: "zipcode", value: e?.zipcode?.toString() },
            { name: "timezone", value: e?.timezone },
            { name: "company_email", value: e?.mailing_address },
            { name: "website", value: e?.website },
            { name: "employees", value: e?.employees },
            { name: "sector", value: e?.sector },
            { name: "type", value: e?.company_type },
            { name: "first_name", value: e?.first_name },
            { name: "last_name", value: e?.last_name },
            { name: "position", value: e?.position },
            { name: "contact_email", value: e?.email_address },
            { name: "contact_phone", value: e?.phone_number },
            { name: "contact_other_phone", value: e?.other_phone_number },
            { name: "alternate_first_name", value: e?.alternate_first_name },
            { name: "alternate_last_name", value: e?.alternate_last_name },
            { name: "alternate_position", value: e?.alternate_position },
            {
              name: "alternate_email_address",
              value: e?.alternate_email_address,
            },
            {
              name: "alternate_phone_number",
              value: e?.alternate_phone_number,
            },
            {
              name: "alternate_other_phone_number",
              value: e?.alternate_other_phone_number,
            },
          ].concat(marketList),
        };

        dispatch(auth.registerUserAction(JSON.stringify(payload), props));
      }
    },
  });

  const onChangeCountry = (val) => {
    setTimeout(() => {
      formik.setFieldValue("country", val);
      formik.setFieldValue("state", "");
    }, 0);
  };

  const onChangeState = (val) => {
    setTimeout(() => {
      formik.setFieldValue("state", val);
    }, 0);
  };

  const onChangeTimeZone = (val) => {
    setTimeout(() => {
      setTimezonevalue(val);
      formik.setFieldValue("timezone", val.label);
    }, 0);
  };

  const onCencel = () => {
    history.push("/login");
  };

  return (
    <>
      <AppBar color="default" position="static">
        <Toolbar className={classes.appBar}>
          <Typography
            className={classes.logo}
            component="img"
            src={Logo}
            alt="Company logo"
            width="100"
          />
        </Toolbar>
      </AppBar>
      <Container className={classes.container}>
        <Typography
          variant="h5"
          style={{ fontWeight: "900", color: "#007bff" }}
        >
          New client application form
        </Typography>
        <Box
          component={"form"}
          sx={{
            "& .MuiOutlinedInput-input": {
              padding: "1rem !important",
            },
          }}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
          p={2}
          className={classes.registerForm}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.titles}>
                Company information&nbsp;
                <CheckCircle />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["company_name"] &&
                  formik.touched["company_name"]
                }
                label="Company name*"
                name={"company_name"}
                helperText={
                  formik.touched["company_name"] &&
                  formik.errors["company_name"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["company_name"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["description"] && formik.touched["description"]
                }
                label="Description*"
                name={"description"}
                helperText={
                  formik.touched["description"] && formik.errors["description"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["description"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.titles}>
                Company physical address
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["street_name"] && formik.touched["street_name"]
                }
                label="Street name*"
                name={"street_name"}
                helperText={
                  formik.touched["street_name"] && formik.errors["street_name"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["street_name"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="subtitle1">Country*</Typography>
              <CountryDropdown
                name="country"
                onBlur={formik.handleBlur}
                value={formik.values["country"]}
                onChange={(val) => onChangeCountry(val)}
                className={classes.select}
              />
              {formik.touched["country"] && formik.errors["country"] ? (
                <Typography variant="subtitle2" color="secondary">
                  {formik.errors["country"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="subtitle1">State*</Typography>
              <RegionDropdown
                country={formik.values["country"]}
                name="state"
                onBlur={formik.handleBlur}
                value={formik.values["state"]}
                onChange={(val) => onChangeState(val)}
                className={classes.select}
              />
              {formik.touched["state"] && formik.errors["state"] ? (
                <Typography variant="subtitle2" color="secondary">
                  {formik.errors["state"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["city"] && formik.touched["city"]}
                label="City*"
                name={"city"}
                helperText={formik.touched["city"] && formik.errors["city"]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["city"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="number"
                error={formik.errors["zipcode"] && formik.touched["zipcode"]}
                label="Zipcode"
                name={"zipcode"}
                helperText={
                  formik.touched["zipcode"] && formik.errors["zipcode"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["zipcode"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography variant="subtitle1">Time zone*</Typography>
              <TimezoneSelect
                name="timezone"
                value={timezonevalue}
                onBlur={formik.handleBlur}
                onChange={(val) => onChangeTimeZone(val)}
                className={classes.select}
              />
              {formik.touched["timezone"] && formik.errors["timezone"] ? (
                <Typography variant="subtitle2" color="secondary">
                  {formik.errors["timezone"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["mailing_address"] &&
                  formik.touched["mailing_address"]
                }
                label="Company mailing address*"
                name={"mailing_address"}
                helperText={
                  formik.touched["mailing_address"] &&
                  formik.errors["mailing_address"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["mailing_address"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["website"] && formik.touched["website"]}
                label="Website*"
                name={"website"}
                helperText={
                  formik.touched["website"] && formik.errors["website"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["website"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["employees"] && formik.touched["employees"]
                }
                label="Number of employees"
                name={"employees"}
                helperText={
                  formik.touched["employees"] && formik.errors["employees"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["employees"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MUISelect
                name="sector"
                displayEmpty
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                input={<OutlinedInput />}
                value={formik.values["sector"]}
                error={formik.errors["sector"] && formik.touched["sector"]}
                className={classes.select}
              >
                <MenuItem value="">Sector*</MenuItem>
                <MenuItem value="Energy">Energy</MenuItem>
                <MenuItem value="Materials">Materials</MenuItem>
                <MenuItem value="Health Care">Health Care</MenuItem>
                <MenuItem value="Financials">Financials</MenuItem>
                <MenuItem value="Consumer Discretionary">
                  Consumer Discretionary
                </MenuItem>
              </MUISelect>
              {formik.touched["sector"] && formik.errors["sector"] ? (
                <Typography variant="subtitle2" color="secondary">
                  {formik.errors["sector"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MUISelect
                name="company_type"
                displayEmpty
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                input={<OutlinedInput />}
                value={formik.values["company_type"]}
                error={
                  formik.errors["company_type"] &&
                  formik.touched["company_type"]
                }
                className={classes.select}
              >
                <MenuItem value="">Company type*</MenuItem>
                <MenuItem value="LLP">LLP</MenuItem>
                <MenuItem value="Public Company">Public Company</MenuItem>
                <MenuItem value="Private Company">Private Company</MenuItem>
              </MUISelect>
              {formik.touched["company_type"] &&
              formik.errors["company_type"] ? (
                <Typography variant="subtitle2" color="secondary">
                  {formik.errors["company_type"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.titles}>
                Primary contact information&nbsp;
                <CheckCircle />
              </Typography>
              <Typography variant="subtitle1" className={classes.titles}>
                Contact person
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["first_name"] && formik.touched["first_name"]
                }
                label="First name*"
                name={"first_name"}
                helperText={
                  formik.touched["first_name"] && formik.errors["first_name"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["first_name"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["last_name"] && formik.touched["last_name"]
                }
                label="Last name*"
                name={"last_name"}
                helperText={
                  formik.touched["last_name"] && formik.errors["last_name"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["last_name"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["position"] && formik.touched["position"]}
                label="Position*"
                name={"position"}
                helperText={
                  formik.touched["position"] && formik.errors["position"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["position"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["email_address"] &&
                  formik.touched["email_address"]
                }
                label="Email address*"
                name={"email_address"}
                helperText={
                  formik.touched["email_address"] &&
                  formik.errors["email_address"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["email_address"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["phone_number"] &&
                  formik.touched["phone_number"]
                }
                label="Phone number*"
                name={"phone_number"}
                helperText={
                  formik.touched["phone_number"] &&
                  formik.errors["phone_number"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["phone_number"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["other_phone_number"] &&
                  formik.touched["other_phone_number"]
                }
                label="Alternate phone number"
                name={"other_phone_number"}
                helperText={
                  formik.touched["other_phone_number"] &&
                  formik.errors["other_phone_number"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["other_phone_number"]}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.titles}>
                Please select that is applicable&nbsp;
                <CheckCircle />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Choose markets*</Typography>
              <Select
                options={marketoptions}
                placeholder="Select.."
                isMulti
                onChange={marketoptionChange}
                defaultValue={{ value: "equity", label: "Equity market" }}
                styles={{
                  option: (provided) => ({
                    ...provided,
                    color: "#fcfcfc",
                    backgroundColor: "#181818",
                    cursor: "pointer",
                    fontSize: "12px",
                    textAlign: "left",
                    ":active": {
                      backgroundColor: "#181818",
                    },
                  }),
                }}
              />
              {!marketList?.length ? (
                <Typography variant="subtitle2" color="secondary">
                  This field is required.
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Box className={classes.actions}>
            <Button
              className={`${classes.backBtn}`}
              variant="contained"
              onClick={() => onCencel()}
              size="small"
            >
              Back
            </Button>
            <Button
              className={classes.submitBtn}
              type="submit"
              variant="contained"
              size="small"
              style={{ backgroundColor: "#2B96DD" }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default Register;
