import React from "react";

import {
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  Table,
  Typography,
} from "@material-ui/core";

import { makeStyles, styled } from "@material-ui/core/styles";
import { toCurrency } from "../../../helpers/utilities";
import moment from "moment";
import "moment-timezone";
import CurrencyFormat from "react-currency-format";
import { sortTrade } from "../../../store/actions/trade.action";
import Sorting from "../../../components/Sorting/Sorting";
import { useDispatch } from "react-redux";

const CustomTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    borderBottom: "none !important",
    color: "#808080",
  },
});

const useStyles = makeStyles(() => ({
  tableHead: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "@media (max-width: 1024px)": {
    tableHead: {
      maxWidth: "125px",
      minWidth: "125px",
    },
  },
  select: {
    width: "auto !important",
    height: "40px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

function TradeTable({ data: trades = [], sortBy = {}, tradeLoading, error }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  let tradesData = null;

  if (tradeLoading) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={17}>Please wait loading...</td>
      </tr>
    );
  } else if (!tradeLoading && error) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={17}>
          {typeof error === "string"
            ? error
            : "Something went wrong! Please try again."}
        </td>
      </tr>
    );
  } else if (trades.length === 0) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={17}>No trades yet</td>
      </tr>
    );
  } else {
    tradesData = trades.map((trade, tradeIndex) => {
      return (
        <TableRow hover key={`tr-${tradeIndex}`}>
          <CustomTableCell padding="default" align="left">
            {trade["id"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade?.["order"]?.["createdBy"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade?.["order"]?.["id"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["ticker"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["symbol"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["venue"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["action"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["ccy"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["fundAccount"]?.fundAccountName ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["price"] ? (
              <CurrencyFormat
                value={toCurrency(trade["price"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["volume"] ? (
              <CurrencyFormat
                value={trade["volume"]}
                thousandSeparator
                displayType="text"
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["commissions"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(trade["commissions"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["dutiesTaxes"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(trade["dutiesTaxes"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["status"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["execId"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["tradeDate"]
              ? moment(trade["tradeDate"]).format("DD/MM/YYYY")
              : "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["tradeDate"]
              ? moment(trade["tradeDate"]).tz("GMT").format("hh:mm:ss")
              : "-"}
          </CustomTableCell>
        </TableRow>
      );
    });
  }

  const onSort = (field, sortBy, subField) => {
    dispatch(sortTrade({ field, sortBy, subField }));
  };

  return (
    <>
      <TableContainer style={{ marginTop: "0.5rem" }}>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Trade #</Typography>&nbsp;
                  <Sorting field={"id"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Owner</Typography>&nbsp;
                  <Sorting
                    field={"order"}
                    subField={"createdBy"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Order #</Typography>&nbsp;
                  <Sorting
                    field={"order"}
                    subField={"id"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Ticker</Typography>&nbsp;
                  <Sorting field={"ticker"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Symbol</Typography>&nbsp;
                  <Sorting field={"symbol"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Exchange</Typography>&nbsp;
                  <Sorting field={"venue"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Action</Typography>&nbsp;
                  <Sorting field={"action"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">CCY</Typography>&nbsp;
                  <Sorting field={"ccy"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Account</Typography>&nbsp;
                  <Sorting
                    field={"fundAccount"}
                    subField={"fundAccountName"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Price</Typography>&nbsp;
                  <Sorting field={"price"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Quantity</Typography>&nbsp;
                  <Sorting field={"volume"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Commissions</Typography>&nbsp;
                  <Sorting
                    field={"commissions"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">
                    Duties &amp; Taxes
                  </Typography>
                  &nbsp;
                  <Sorting
                    field={"dutiesTaxes"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Status</Typography>&nbsp;
                  <Sorting field={"status"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Execution ID</Typography>
                  &nbsp;
                  <Sorting field={"execId"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Trade Date (GMT)</Typography>
                  &nbsp;
                  <Sorting
                    field={"tradeDate"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Trade Time (GMT)</Typography>
                  &nbsp;
                  <Sorting
                    field={"tradeDate"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{tradesData}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TradeTable;
