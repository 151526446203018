import { Box, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import Loader from "../../../components/Loader";
import { randomColor } from "../../../helpers/utilities";

const PositionSectorPieChart = ({ positions, loader }) => {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState([]);

  const [sectors, setSectors] = useState({});

  useEffect(() => {
    if (!loader) {
      const sectors_ = Array.from(
        new Set(positions.map((position) => [position.ticker, position.origVenue]))
      );

      const loadData = async (sectors_) => {
        let data = {};
        await Promise.all(
          sectors_.map((_) =>
            axios.get(
              // `${process.env.REACT_APP_API_URL}/securities/api/v1/equities/getByName?name=${_}`
              `${process.env.REACT_APP_API_URL}/securities/api/v1/equities/${_[0]}/venues/${_[1]}`
            )
          )
        )
          .then((sectorsData) => {
            sectorsData.forEach((_) => {
              data = { ...data, [_?.data?.sector]: _?.data?.name };
            });
          })
          .catch((error) => {
            console.log({ error });
          });
        // console.log({ data });

        setSectors(data);
      };

      loadData(sectors_);
    } else {
      setSectors({});
      setLabels([]);
      setData([]);
      setBackgroundColors([]);
    }
  }, [positions, loader]);

  useEffect(() => {
    if (Object.keys(sectors).length > 0) {
      let sectors__ = {};
      for (let sectorK in sectors) {
        positions.forEach((position) => {
          if (position.ticker == sectors[sectorK]) {
            if (!sectors__[sectorK]) {
              sectors__[sectorK] = 0;
            }

            sectors__[sectorK] += position?.marketValue
              ? position?.marketValue
              : 0;
          }
        });
      }

      setData(Object.values(sectors__));

      let colors = [];

      for (let i = 0; i < Object.values(sectors__).length; i++) {
        colors.push(randomColor());
      }

      setBackgroundColors(colors);

      setLabels(Object.keys(sectors));
    } else {
      setLabels([]);
      setData([]);
      setBackgroundColors([]);
    }
  }, [sectors]);

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "start",
        fullWidth: true,
        reverse: false,
        labels: {
          fontColor: "#fff",
          boxWidth: 12,
          fontSize: 14,
          fill: true,
        },
      },
    },
  };

  return (
    <Box
      p={2}
      style={{
        height: `${loader || Object.keys(sectors).length == 0 ? "auto" : "500px"
          }`,
        width: "500px",
        margin: "0 auto",
      }}
    >
      {loader ? (
        <Loader text="Loading data..." />
      ) : Object.keys(sectors).length > 0 ? (
        <Pie
          data={{
            labels: [...labels],
            datasets: [
              {
                label: "Market value by Sector",
                data: [...data],
                backgroundColor: [...backgroundColors],
                hoverOffset: 4,
              },
            ],
          }}
          options={doughnutOptions}
        />
      ) : (
        <Box height={"100%"}>
          <Typography variant="subtitle2" align="center">
            No data
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(PositionSectorPieChart);
