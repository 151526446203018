import { useKeycloak } from "@react-keycloak/web";
import { Route } from "react-router-dom";
import ContactAdministrator from "../components/ContactAdministrator/ContactAdministrator";

export const useAuthorizatedRole = (roles_) => {
  const { keycloak } = useKeycloak();

  if (roles_.length === 0) {
    return true;
  }

  return keycloak?.realmAccess?.roles.some((val) => roles_.includes(val));
};

const RoleBasedRoute = ({ component: Component, roles = [], ...rest }) => {
  const grantPermission = useAuthorizatedRole(roles);

  return (
    <>
      <Route
        {...rest}
        render={(props) => {
          if (!grantPermission) {
            return <ContactAdministrator />;
          }
          return <Component {...props} />;
        }}
      />
    </>
  );
};

export default RoleBasedRoute;

export const UnlockAccess = ({ children, roles }) => {
  const grantPermission = useAuthorizatedRole(roles);
  return <>{grantPermission && children}</>;
};
