export const constants = {
  REGISTER_USER: "REGISTER_USER",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_ERROR: "REGISTER_USER_ERROR",

  GET_WATCH_LIST: "GET_WATCH_LIST",
  GET_WATCH_LIST_SUCCESS: "GET_WATCH_LIST_SUCCESS",
  GET_WATCH_LIST_ERROR: "GET_WATCH_LIST_ERROR",

  GET_CCY_LIST: "GET_CCY_LIST",
  GET_CCY_LIST_SUCCESS: "GET_CCY_LIST_SUCCESS",
  GET_CCY_LIST_ERROR: "GET_CCY_LIST_ERROR",

  GET_EXCHANGE_LIST: "GET_EXCHANGE_LIST",
  GET_EXCHANGE_LIST_SUCCESS: "GET_EXCHANGE_LIST_SUCCESS",
  GET_EXCHANGE_LIST_ERROR: "GET_EXCHANGE_LIST_ERROR",

  UPDATE_WATCH_LIST: "UPDATE_WATCH_LIST",
  UPDATE_WATCH_LIST_SUCCESS: "UPDATE_WATCH_LIST_SUCCESS",
  UPDATE_WATCH_LIST_ERROR: "UPDATE_WATCH_LIST_ERROR",

  CREATE_WATCH_LIST: "CREATE_WATCH_LIST",
  CREATE_WATCH_LIST_SUCCESS: "CREATE_WATCH_LIST_SUCCESS",
  CREATE_WATCH_LIST_ERROR: "CREATE_WATCH_LIST_ERROR",

  INCREMENT_SAGA: "INCREMENT_SAGA",
  INCREMENT: "INCREMENT",
  DECREMENT_SAGA: "DECREMENT_SAGA",
  DECREMENT: "DECREMENT",

  GET_USER_WATCHLIST_BOND: "GET_USER_WATCHLIST_BOND",
  GET_USER_WATCHLIST_BOND_SUCCESS: "GET_USER_WATCHLIST_BOND_SUCCESS",
  GET_USER_WATCHLIST_BOND_ERROR: "GET_USER_WATCHLIST_BOND_ERROR",

  GET_BOND_MULTIPLE: "GET_BOND_MULTIPLE",
  GET_BOND_MULTIPLE_SUCCESS: "GET_BOND_MULTIPLE_SUCCESS",
  GET_BOND_MULTIPLE_ERROR: "GET_BOND_MULTIPLE_ERROR",

  CREATE_WATCH_LIST_BOND: "CREATE_WATCH_LIST_BOND",
  CREATE_WATCH_LIST_BOND_SUCCESS: "CREATE_WATCH_LIST_BOND_SUCCESS",
  CREATE_WATCH_LIST_BOND_ERROR: "CREATE_WATCH_LIST_BOND_ERROR",

  UPDATE_WATCH_LIST_BOND: "UPDATE_WATCH_LIST_BOND",
  UPDATE_WATCH_LIST_BOND_SUCCESS: "UPDATE_WATCH_LIST_BOND_SUCCESS",
  UPDATE_WATCH_LIST_BOND_ERROR: "UPDATE_WATCH_LIST_BOND_ERROR",
  WEB_SOCKET: "WEB_SOCKET",
  WEB_SOCKET_SUCCESS: "WEB_SOCKET_SUCCESS",
  WATCHLIST_INDEX: "WATCHLIST_INDEX",
  ACTIVE_WATCHLIST: "ACTIVE_WATCHLIST",

  ADD_NON_EXISTING: "ADD_NON_EXISTING",
  SORT_WATCHLIST: "SORT_WATCHLIST",

  // TRANSECTION_ORDER_STATUS_ERROR: "TRANSECTION_ORDER_STATUS_ERROR",

  GET_ALL_EXCHANGE_SINGLE_ACCOUNT: "GET_ALL_EXCHANGE_SINGLE_ACCOUNT",
  GET_ALL_EXCHANGE_SINGLE_ACCOUNT_SUCCESS:
    "GET_ALL_EXCHANGE_SINGLE_ACCOUNT_SUCCESS",
  GET_ALL_EXCHANGE_SINGLE_ACCOUNT_ERROR:
    "GET_ALL_EXCHANGE_SINGLE_ACCOUNT_ERROR",

  GET_ALL_EXCHANGE_MULTIPLE_ACCOUNT: "GET_ALL_EXCHANGE_MULTIPLE_ACCOUNT",
  GET_ALL_EXCHANGE_MULTIPLE_ACCOUNT_SUCCESS:
    "GET_ALL_EXCHANGE_MULTIPLE_ACCOUNT_SUCCESS",
  GET_ALL_EXCHANGE_MULTIPLE_ACCOUNT_ERROR:
    "GET_ALL_EXCHANGE_MULTIPLE_ACCOUNT_ERROR",

  GET_FI_MARKET_ORDER: "GET_FI_MARKET_ORDER",
  GET_FI_MARKET_ORDER_SUCCESS: "GET_FI_MARKET_ORDER_SUCCESS",
  GET_FI_MARKET_ORDER_ERROR: "GET_FI_MARKET_ORDER_ERROR",

  CREATE_FI_MARKET_ORDER: "CREATE_FI_MARKET_ORDER",
  CREATE_FI_MARKET_ORDER_SUCCESS: "CREATE_FI_MARKET_ORDER_SUCCESS",
  CREATE_FI_MARKET_ORDER_ERROR: "CREATE_FI_MARKET_ORDER_ERROR",

  UPDATE_FI_MARKET_ORDER: "UPDATE_FI_MARKET_ORDER",
  UPDATE_FI_MARKET_ORDER_SUCCESS: "UPDATE_FI_MARKET_ORDER_SUCCESS",
  UPDATE_FI_MARKET_ORDER_ERROR: "UPDATE_FI_MARKET_ORDER_ERROR",

  CREATE_FI_REQUEST_QUOTE_ORDER: "CREATE_FI_REQUEST_QUOTE_ORDER",
  CREATE_FI_REQUEST_QUOTE_SUCCESS: "CREATE_FI_REQUEST_QUOTE_SUCCESS",
  CREATE_FI_REQUEST_QUOTE_ERROR: "CREATE_FI_REQUEST_QUOTE_ERROR",

  GET_FI_ALL_ORDER: "GET_FI_ALL_ORDER",
  GET_FI_ALL_ORDER_SUCCESS: "GET_FI_ALL_ORDER_SUCCESS",
  GET_FI_ALL_ORDER_ERROR: "GET_FI_ALL_ORDER_ERROR",

  GET_FI_ALL_OFFER: "GET_FI_ALL_OFFER",
  GET_FI_ALL_OFFER_SUCCESS: "GET_FI_ALL_OFFER_SUCCESS",
  GET_FI_ALL_OFFER_ERROR: "GET_FI_ALL_OFFER_ERROR",

  GET_FI_OUTGOING_ORDER: "GET_FI_OUTGOING_ORDER",
  GET_FI_OUTGOING_ORDER_SUCCESS: "GET_FI_OUTGOING_ORDER_SUCCESS",
  GET_FI_OUTGOING_ORDER_ERROR: "GET_FI_OUTGOING_ORDER_ERROR",

  GET_FI_OUTGOING_QUOTES_ORDER: "GET_FI_OUTGOING_QUOTES_ORDER",
  GET_FI_OUTGOING_QUOTES_ORDER_SUCCESS: "GET_FI_OUTGOING_QUOTES_ORDER_SUCCESS",
  GET_FI_OUTGOING_QUOTES_ORDER_ERROR: "GET_FI_OUTGOING_QUOTES_ORDER_ERROR",

  GET_FI_OUTGOING_OFFER_ORDER: "GET_FI_OUTGOING_OFFER_ORDER",
  GET_FI_OUTGOING_OFFER_ORDER_SUCCESS: "GET_FI_OUTGOING_OFFER_ORDER_SUCCESS",
  GET_FI_OUTGOING_OFFER_ORDER_ERROR: "GET_FI_OUTGOING_OFFER_ORDER_ERROR",

  CREATE_FI_OFFER_ORDER: "CREATE_FI_OFFER_ORDER",
  CREATE_FI_OFFER_ORDER_SUCCESS: "CREATE_FI_OFFER_ORDER_SUCCESS",
  CREATE_FI_OFFER_ORDER_ERROR: "CREATE_FI_OFFER_ORDER_ERROR",

  UPDATE_FI_OFFER_ORDER: "UPDATE_FI_OFFER_ORDER",
  UPDATE_FI_OFFER_ORDER_SUCCESS: "UPDATE_FI_OFFER_ORDER_SUCCESS",
  UPDATE_FI_OFFER_ORDER_ERROR: "UPDATE_FI_OFFER_ORDER_ERROR",

  GET_SYSTEM_USER: "GET_SYSTEM_USER_ORDER",
  GET_SYSTEM_USER_SUCCESS: "GET_SYSTEM_USER_SUCCESS",
  GET_SYSTEM_USER_ERROR: "GET_SYSTEM_USER_ERROR",

  GET_FI_INCOMING_QUOTES_ORDER: "GET_FI_INCOMING_QUOTES_ORDER",
  GET_FI_INCOMING_QUOTES_ORDER_SUCCESS: "GET_FI_INCOMING_QUOTES_ORDER_SUCCESS",
  GET_FI_INCOMING_QUOTES_ORDER_ERROR: "GET_FI_INCOMING_QUOTES_ORDER_ERROR",

  GET_FI_INCOMING_OFFER_ORDER: "GET_FI_INCOMING_OFFER_ORDER",
  GET_FI_INCOMING_OFFER_ORDER_SUCCESS: "GET_FI_INCOMING_OFFER_ORDER_SUCCESS",
  GET_FI_INCOMING_OFFER_ORDER_ERROR: "GET_FI_INCOMING_OFFER_ORDER_ERROR",

  GET_FI_ORDER_BY_USER: "GET_FI_ORDER_BY_USER",
  GET_FI_ORDER_BY_USER_SUCCESS: "GET_FI_ORDER_BY_USER_SUCCESS",
  GET_FI_ORDER_BY_USER_ERROR: "GET_FI_ORDER_BY_USER_ERROR",

  CREATE_FI_TREDE: "CREATE_FI_TREDE",
  CREATE_FI_TREDE_SUCCESS: "CREATE_FI_TREDE_SUCCESS",
  CREATE_FI_TREDE_ERROR: "CREATE_FI_TREDE_ERROR",

  UPDATE_FI_TREDE: "UPDATE_FI_TREDE",
  UPDATE_FI_TREDE_SUCCESS: "UPDATE_FI_TREDE_SUCCESS",
  UPDATE_FI_TREDE_ERROR: "UPDATE_FI_TREDE_ERROR",

  GET_FI_ALL_TREDE: "GET_FI_ALL_TREDE",
  GET_FI_ALL_TREDE_SUCCESS: "GET_FI_ALL_TREDE_SUCCESS",
  GET_FI_ALL_TREDE_ERROR: "GET_FI_ALL_TREDE_ERROR",

  GET_FI_PENDING_TREDE: "GET_FI_PENDING_TREDE",
  GET_FI_PENDING_TREDE_SUCCESS: "GET_FI_PENDING_TREDE_SUCCESS",
  GET_FI_PENDING_TREDE_ERROR: "GET_FI_PENDING_TREDE_ERROR",

  GET_FI_EXECUTED_TREDE: "GET_FI_EXECUTED_TREDE",
  GET_FI_EXECUTED_TREDE_SUCCESS: "GET_FI_EXECUTED_TREDE_SUCCESS",
  GET_FI_EXECUTED_TREDE_ERROR: "GET_FI_EXECUTED_TREDE_ERROR",

  GET_USER_POSITION_LIST: "GET_USER_POSITION_LIST",
  GET_USER_POSITION_SUCCESS: "GET_USER_POSITION_SUCCESS",
  GET_USER_POSITION_ERROR: "GET_USER_POSITION_ERROR",

  GET_OUTGOING_ORDER_COUNT: "GET_OUTGOING_ORDER_COUNT",
  GET_OUTGOING_ORDER_COUNT_SUCCESS: "GET_OUTGOING_ORDER_COUNT_SUCCESS",
  GET_OUTGOING_ORDER_COUNT_ERROR: "GET_OUTGOING_ORDER_COUNT_ERROR",

  GET_OUTGOING_OFFER_COUNT: "GET_OUTGOING_OFFER_COUNT",
  GET_OUTGOING_OFFER_COUNT_SUCCESS: "GET_OUTGOING_OFFER_COUNT_SUCCESS",
  GET_OUTGOING_OFFER_COUNT_ERROR: "GET_OUTGOING_OFFER_COUNT_ERROR",

  GET_OUTGOING_QUOTES_COUNT: "GET_OUTGOING_QUOTES_COUNT",
  GET_OUTGOING_QUOTES_COUNT_SUCCESS: "GET_OUTGOING_QUOTES_COUNT_SUCCESS",
  GET_OUTGOING_QUOTES_COUNT_ERROR: "GET_OUTGOING_QUOTES_COUNT_ERROR",

  GET_INCOMING_OFFER_COUNT: "GET_INCOMING_OFFER_COUNT",
  GET_INCOMING_OFFER_COUNT_SUCCESS: "GET_INCOMING_OFFER_COUNT_SUCCESS",
  GET_INCOMING_OFFER_COUNT_ERROR: "GET_INCOMING_OFFER_COUNT_ERROR",

  GET_INCOMING_QUOTES_COUNT: "GET_INCOMING_QUOTES_COUNT",
  GET_INCOMING_QUOTES_COUNT_SUCCESS: "GET_INCOMING_QUOTES_COUNT_SUCCESS",
  GET_INCOMING_QUOTES_COUNT_ERROR: "GET_INCOMING_QUOTES_COUNT_ERROR",

  GET_PENDING_TREDE_COUNT: "GET_PENDING_TREDE_COUNT",
  GET_PENDING_TREDE_COUNT_SUCCESS: "GET_PENDING_TREDE_COUNT_SUCCESS",
  GET_PENDING_TREDE_COUNT_ERROR: "GET_PENDING_TREDE_COUNT_ERROR",

  GET_EXECUTED_TREDE_COUNT: "GET_EXECUTED_TREDE_COUNT",
  GET_EXECUTED_TREDE_COUNT_SUCCESS: "GET_EXECUTED_TREDE_COUNT_SUCCESS",
  GET_EXECUTED_TREDE_COUNT_ERROR: "GET_EXECUTED_TREDE_COUNT_ERROR",

  GET_ALL_TREDE_COUNT: "GET_ALL_TREDE_COUNT",
  GET_ALL_TREDE_COUNT_SUCCESS: "GET_ALL_TREDE_COUNT_SUCCESS",
  GET_ALL_TREDE_COUNT_ERROR: "GET_ALL_TREDE_COUNT_ERROR",

  GET_ALL_ORDER_COUNT: "GET_ALL_ORDER_COUNT",
  GET_ALL_ORDER_COUNT_SUCCESS: "GET_ALL_ORDER_COUNT_SUCCESS",
  GET_ALL_ORDER_COUNT_ERROR: "GET_ALL_ORDER_COUNT_ERROR",

  GET_ALL_OFFER_COUNT: "GET_ALL_OFFER_COUNT",
  GET_ALL_OFFER_COUNT_SUCCESS: "GET_ALL_OFFER_COUNT_SUCCESS",
  GET_ALL_OFFER_COUNT_ERROR: "GET_ALL_OFFER_COUNT_ERROR",

  GET_FUND_ACCOUNT: "GET_FUND_ACCOUNT",
  GET_FUND_ACCOUNT_SUCCESS: "GET_FUND_ACCOUNT_SUCCESS",
  GET_FUND_ACCOUNT_ERROR: "GET_FUND_ACCOUNT_ERROR",

  // Bonds
  LOADING_BOND: "LOADING_BOND",

  SORT_BOND: "SORT_BOND",
  FILTER_BOND: "FILTER_BOND",

  GET_BOND: "GET_BOND",
  GET_BOND_SUCCESS: "GET_BOND_SUCCESS",
  GET_BOND_ERROR: "GET_BOND_ERROR",

  STORE_BOND: "STORE_BOND",
  STORE_BOND_SUCCESS: "STORE_BOND_SUCCESS",
  STORE_BOND_ERROR: "STORE_BOND_ERROR",

  GET_BOND_BY_ID: "GET_BOND_BY_ID",
  GET_BOND_BY_ID_SUCCESS: "GET_BOND_BY_ID_SUCCESS",
  GET_BOND_BY_ID_ERROR: "GET_BOND_BY_ID_ERROR",

  UPDATE_BOND: "UPDATE_BOND",
  UPDATE_BOND_SUCCESS: "UPDATE_BOND_SUCCESS",
  UPDATE_BOND_ERROR: "UPDATE_BOND_ERROR",

  DELETE_BOND: "DELETE_BOND",
  DELETE_BOND_SUCCESS: "DELETE_BOND_SUCCESS",
  DELETE_BOND_ERROR: "DELETE_BOND_ERROR",

  TOGGLE_BOND_STATUS: "TOGGLE_BOND_STATUS",
  TOGGLE_BOND_SUCCESS_STATUS: "TOGGLE_BOND_SUCCESS_STATUS",
  TOGGLE_BOND_ERROR_STATUS: "TOGGLE_BOND_ERROR_STATUS",

  // Equities
  LOADING_EQUITY: "LOADING_EQUITY",

  SORT_EQUITY: "SORT_EQUITY",
  FILTER_EQUITY: "FILTER_EQUITY",
  GET_EQUITY: "GET_EQUITY",
  GET_EQUITY_SUCCESS: "GET_EQUITY_SUCCESS",
  GET_EQUITY_ERROR: "GET_EQUITY_ERROR",

  STORE_EQUITY: "STORE_EQUITY",
  STORE_EQUITY_SUCCESS: "STORE_EQUITY_SUCCESS",
  STORE_EQUITY_ERROR: "STORE_EQUITY_ERROR",

  GET_EQUITY_BY_ID: "GET_EQUITY_BY_ID",
  GET_EQUITY_BY_ID_SUCCESS: "GET_EQUITY_BY_ID_SUCCESS",
  GET_EQUITY_BY_ID_ERROR: "GET_EQUITY_BY_ID_ERROR",

  UPDATE_EQUITY: "UPDATE_EQUITY",
  UPDATE_EQUITY_SUCCESS: "UPDATE_EQUITY_SUCCESS",
  UPDATE_EQUITY_ERROR: "UPDATE_EQUITY_ERROR",

  DELETE_EQUITY: "DELETE_EQUITY",
  DELETE_EQUITY_SUCCESS: "DELETE_EQUITY_SUCCESS",
  DELETE_EQUITY_ERROR: "DELETE_EQUITY_ERROR",

  TOGGLE_EQUITY_STATUS: "TOGGLE_EQUITY_STATUS",
  TOGGLE_EQUITY_SUCCESS_STATUS: "TOGGLE_EQUITY_SUCCESS_STATUS",
  TOGGLE_EQUITY_ERROR_STATUS: "TOGGLE_EQUITY_ERROR_STATUS",

  // Venues
  LOADING_VENUE: "LOADING_VENUE",

  SORT_VENUE: "SORT_VENUE",
  FILTER_VENUE: "FILTER_VENUE",
  GET_VENUE: "GET_VENUE",
  GET_VENUE_SUCCESS: "GET_VENUE_SUCCESS",
  GET_VENUE_ERROR: "GET_VENUE_ERROR",

  STORE_VENUE: "STORE_VENUE",
  STORE_VENUE_SUCCESS: "STORE_VENUE_SUCCESS",
  STORE_VENUE_ERROR: "STORE_VENUE_ERROR",

  GET_VENUE_BY_ID: "GET_VENUE_BY_ID",
  GET_VENUE_BY_ID_SUCCESS: "GET_VENUE_BY_ID_SUCCESS",
  GET_VENUE_BY_ID_ERROR: "GET_VENUE_BY_ID_ERROR",

  UPDATE_VENUE: "UPDATE_VENUE",
  UPDATE_VENUE_SUCCESS: "UPDATE_VENUE_SUCCESS",
  UPDATE_VENUE_ERROR: "UPDATE_VENUE_ERROR",

  DELETE_VENUE: "DELETE_VENUE",
  DELETE_VENUE_SUCCESS: "DELETE_VENUE_SUCCESS",
  DELETE_VENUE_ERROR: "DELETE_VENUE_ERROR",

  // Currencies
  LOADING_CURRENCY: "LOADING_CURRENCY",

  SORT_CURRENCY: "SORT_CURRENCY",
  FILTER_CURRENCY: "FILTER_CURRENCY",
  GET_CURRENCY: "GET_CURRENCY",
  GET_CURRENCY_SUCCESS: "GET_CURRENCY_SUCCESS",
  GET_CURRENCY_ERROR: "GET_CURRENCY_ERROR",

  STORE_CURRENCY: "STORE_CURRENCY",
  STORE_CURRENCY_SUCCESS: "STORE_CURRENCY_SUCCESS",
  STORE_CURRENCY_ERROR: "STORE_CURRENCY_ERROR",

  GET_CURRENCY_BY_ID: "GET_CURRENCY_BY_ID",
  GET_CURRENCY_BY_ID_SUCCESS: "GET_CURRENCY_BY_ID_SUCCESS",
  GET_CURRENCY_BY_ID_ERROR: "GET_CURRENCY_BY_ID_ERROR",

  UPDATE_CURRENCY: "UPDATE_CURRENCY",
  UPDATE_CURRENCY_SUCCESS: "UPDATE_CURRENCY_SUCCESS",
  UPDATE_CURRENCY_ERROR: "UPDATE_CURRENCY_ERROR",

  DELETE_CURRENCY: "DELETE_CURRENCY",
  DELETE_CURRENCY_SUCCESS: "DELETE_CURRENCY_SUCCESS",
  DELETE_CURRENCY_ERROR: "DELETE_CURRENCY_ERROR",

  // Users
  LOADING_USER: "LOADING_USER",

  SORT_USER: "SORT_USER",
  FILTER_USER: "FILTER_USER",
  GET_USER: "GET_USER",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_ERROR: "GET_USER_ERROR",

  STORE_USER: "STORE_USER",
  STORE_USER_SUCCESS: "STORE_USER_SUCCESS",
  STORE_USER_ERROR: "STORE_USER_ERROR",

  GET_USER_BY_ID: "GET_USER_BY_ID",
  GET_USER_BY_ID_SUCCESS: "GET_USER_BY_ID_SUCCESS",
  GET_USER_BY_ID_ERROR: "GET_USER_BY_ID_ERROR",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR: "UPDATE_USER_ERROR",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR: "DELETE_USER_ERROR",

  ENABLE_DISABLE_USER: "ENABLE_DISABLE_USER",
  ENABLE_DISABLE_USER_SUCCESS: "ENABLE_DISABLE_USER_SUCCESS",
  ENABLE_DISABLE_USER_ERROR: "ENABLE_DISABLE_USER_ERROR",

  UPDATE_ROLE_USER: "UPDATE_ROLE_USER",
  UPDATE_ROLE_USER_SUCCESS: "UPDATE_ROLE_USER_SUCCESS",
  UPDATE_ROLE_USER_ERROR: "UPDATE_ROLE_USER_ERROR",

  // Roles
  LOADING_ROLE: "LOADING_ROLE",

  SORT_ROLE: "SORT_ROLE",
  FILTER_ROLE: "FILTER_ROLE",
  LOAD_ROLE: "LOAD_ROLE",
  LOAD_ROLE_SUCCESS: "LOAD_ROLE_SUCCESS",
  LOAD_ROLE_ERROR: "LOAD_ROLE_ERROR",

  STORE_ROLE: "STORE_ROLE",
  STORE_ROLE_SUCCESS: "STORE_ROLE_SUCCESS",
  STORE_ROLE_ERROR: "STORE_ROLE_ERROR",

  // Exchange rates
  LOADING_EXCHANGE_RATE: "LOADING_EXCHANGE_RATE",

  SORT_EXCHANGE_RATES: "SORT_EXCHANGE_RATES",
  FILTER_EXCHANGE_RATES: "FILTER_EXCHANGE_RATES",
  GET_EXCHANGE_RATES: "GET_EXCHANGE_RATES",
  GET_EXCHANGE_RATES_SUCCESS: "GET_EXCHANGE_RATES_SUCCESS",
  GET_EXCHANGE_RATES_ERROR: "GET_EXCHANGE_RATES_ERROR",

  STORE_EXCHANGE_RATE: "STORE_EXCHANGE_RATE",
  STORE_EXCHANGE_RATE_SUCCESS: "STORE_EXCHANGE_RATE_SUCCESS",
  STORE_EXCHANGE_RATE_ERROR: "STORE_EXCHANGE_RATE_ERROR",

  GET_EXCHANGE_RATE_BY_ID: "GET_EXCHANGE_RATE_BY_ID",
  GET_EXCHANGE_RATE_BY_ID_SUCCESS: "GET_EXCHANGE_RATE_BY_ID_SUCCESS",
  GET_EXCHANGE_RATE_BY_ID_ERROR: "GET_EXCHANGE_RATE_BY_ID_ERROR",

  UPDATE_EXCHANGE_RATE: "UPDATE_EXCHANGE_RATE",
  UPDATE_EXCHANGE_RATE_SUCCESS: "UPDATE_EXCHANGE_RATE_SUCCESS",
  UPDATE_EXCHANGE_RATE_ERROR: "UPDATE_EXCHANGE_RATE_ERROR",

  DELETE_EXCHANGE_RATE: "DELETE_EXCHANGE_RATE",
  DELETE_EXCHANGE_RATE_SUCCESS: "DELETE_EXCHANGE_RATE_SUCCESS",
  DELETE_EXCHANGE_RATE_ERROR: "DELETE_EXCHANGE_RATE_ERROR",

  // Clients
  LOADING_CLIENT: "LOADING_CLIENT",

  SORT_CLIENT: "SORT_CLIENT",
  FILTER_CLIENT: "FILTER_CLIENT",
  GET_CLIENT: "GET_CLIENT",
  GET_CLIENT_SUCCESS: "GET_CLIENT_SUCCESS",
  GET_CLIENT_ERROR: "GET_CLIENT_ERROR",

  STORE_CLIENT: "STORE_CLIENT",
  STORE_CLIENT_SUCCESS: "STORE_CLIENT_SUCCESS",
  STORE_CLIENT_ERROR: "STORE_CLIENT_ERROR",

  GET_CLIENT_BY_ID: "GET_CLIENT_BY_ID",
  GET_CLIENT_BY_ID_SUCCESS: "GET_CLIENT_BY_ID_SUCCESS",
  GET_CLIENT_BY_ID_ERROR: "GET_CLIENT_BY_ID_ERROR",

  UPDATE_CLIENT: "UPDATE_CLIENT",
  UPDATE_CLIENT_SUCCESS: "UPDATE_CLIENT_SUCCESS",
  UPDATE_CLIENT_ERROR: "UPDATE_CLIENT_ERROR",

  DELETE_CLIENT: "DELETE_CLIENT",
  DELETE_CLIENT_SUCCESS: "DELETE_CLIENT_SUCCESS",
  DELETE_CLIENT_ERROR: "DELETE_CLIENT_ERROR",

  GET_CLIENT_FUNDS: "GET_CLIENT_FUNDS",
  GET_CLIENT_FUNDS_SUCCESS: "GET_CLIENT_FUNDS_SUCCESS",
  GET_CLIENT_FUNDS_ERROR: "GET_CLIENT_FUNDS_ERROR",

  // Funds
  LOADING_FUND: "LOADING_FUND",

  SORT_FUND: "SORT_FUND",
  FILTER_FUND: "FILTER_FUND",
  GET_FUND: "GET_FUND",
  GET_FUND_SUCCESS: "GET_FUND_SUCCESS",
  GET_FUND_ERROR: "GET_FUND_ERROR",

  STORE_FUND: "STORE_FUND",
  STORE_FUND_SUCCESS: "STORE_FUND_SUCCESS",
  STORE_FUND_ERROR: "STORE_FUND_ERROR",

  GET_FUND_BY_ID: "GET_FUND_BY_ID",
  GET_FUND_BY_ID_SUCCESS: "GET_FUND_BY_ID_SUCCESS",
  GET_FUND_BY_ID_ERROR: "GET_FUND_BY_ID_ERROR",

  UPDATE_FUND: "UPDATE_FUND",
  UPDATE_FUND_SUCCESS: "UPDATE_FUND_SUCCESS",
  UPDATE_FUND_ERROR: "UPDATE_FUND_ERROR",

  DELETE_FUND: "DELETE_FUND",
  DELETE_FUND_SUCCESS: "DELETE_FUND_SUCCESS",
  DELETE_FUND_ERROR: "DELETE_FUND_ERROR",

  // Fund Accounts
  LOADING_FUND_ACCOUNT2: "LOADING_FUND_ACCOUNT2",

  SORT_FUND_ACCOUNT2: "SORT_FUND_ACCOUNT2",
  FILTER_FUND_ACCOUNT2: "FILTER_FUND_ACCOUNT2",
  GET_FUND_ACCOUNT2: "GET_FUND_ACCOUNT2",
  GET_FUND_ACCOUNT2_SUCCESS: "GET_FUND_ACCOUNT2_SUCCESS",
  GET_FUND_ACCOUNT2_ERROR: "GET_FUND_ACCOUNT2_ERROR",

  STORE_FUND_ACCOUNT2: "STORE_FUND_ACCOUNT2",
  STORE_FUND_ACCOUNT2_SUCCESS: "STORE_FUND_ACCOUNT2_SUCCESS",
  STORE_FUND_ACCOUNT2_ERROR: "STORE_FUND_ACCOUNT2_ERROR",

  TOGGLE_FUND_ACCOUNT2_STATUS: "TOGGLE_FUND_ACCOUNT2_STATUS",
  TOGGLE_FUND_ACCOUNT2_STATUS_SUCCESS: "TOGGLE_FUND_ACCOUNT2_STATUS_SUCCESS",
  TOGGLE_FUND_ACCOUNT2_STATUS_ERROR: "TOGGLE_FUND_ACCOUNT2_STATUS_ERROR",

  LOAD_FUND_ACCOUNT2_CURRENT_BALANCE: "LOAD_FUND_ACCOUNT2_CURRENT_BALANCE",
  LOAD_FUND_ACCOUNT2_CURRENT_BALANCE_SUCCESS:
    "LOAD_FUND_ACCOUNT2_CURRENT_BALANCE_SUCCESS",
  LOAD_FUND_ACCOUNT2_CURRENT_BALANCE_ERROR:
    "LOAD_FUND_ACCOUNT2_CURRENT_BALANCE_ERROR",

  // Fund clients
  LOADING_FUND_CLIENT: "LOADING_FUND_CLIENT",

  GET_FUND_CLIENT: "GET_FUND_CLIENT",
  GET_FUND_CLIENT_SUCCESS: "GET_FUND_CLIENT_SUCCESS",
  GET_FUND_CLIENT_ERROR: "GET_FUND_CLIENT_ERROR",

  STORE_FUND_CLIENT: "STORE_FUND_CLIENT",
  STORE_FUND_CLIENT_SUCCESS: "STORE_FUND_CLIENT_SUCCESS",
  STORE_FUND_CLIENT_ERROR: "STORE_FUND_CLIENT_ERROR",

  // Fund positions
  LOADING_FUND_POSITIONS: "LOADING_FUND_POSITIONS",

  STORE_FUND_POSITIONS: "STORE_FUND_POSITIONS",
  STORE_FUND_POSITIONS_SUCCESS: "STORE_FUND_POSITIONS_SUCCESS",
  STORE_FUND_POSITIONS_ERROR: "STORE_FUND_POSITIONS_ERROR",

  // Fund balance sheet
  LOADING_FUND_BALANCE_SHEET: "LOADING_FUND_BALANCE_SHEET",

  LOAD_FUND_BALANCE_SHEET: "LOAD_FUND_BALANCE_SHEET",
  LOAD_FUND_BALANCE_SHEET_SUCCESS: "LOAD_FUND_BALANCE_SHEET_SUCCESS",
  LOAD_FUND_BALANCE_SHEET_ERROR: "LOAD_FUND_BALANCE_SHEET_ERROR",

  // Fund ledger
  LOADING_FUND_LEDGER: "LOADING_FUND_LEDGER",

  LOAD_FUND_LEDGER: "LOAD_FUND_LEDGER",
  LOAD_FUND_LEDGER_SUCCESS: "LOAD_FUND_LEDGER_SUCCESS",
  LOAD_FUND_LEDGER_ERROR: "LOAD_FUND_LEDGER_ERROR",

  // Order
  LOADING_ORDER: "LOADING_ORDER",
  LOADING_ORDER_TRADE: "LOADING_ORDER_TRADE",

  SHOW_HANDLE: "SHOW_HANDLE",

  SORT_ORDER: "SORT_ORDER",
  FILTER_ORDER: "FILTER_ORDER",
  ACCOUNT_FILTER_ORDER: "ACCOUNT_FILTER_ORDER",

  GET_ORDER: "GET_ORDER",
  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_ERROR: "GET_ORDER_ERROR",

  STORE_ORDER: "STORE_ORDER",
  STORE_ORDER_SUCCESS: "STORE_ORDER_SUCCESS",
  STORE_ORDER_ERROR: "STORE_ORDER_ERROR",

  GET_ORDER_BY_ID: "GET_ORDER_BY_ID",
  GET_ORDER_BY_ID_SUCCESS: "GET_ORDER_BY_ID_SUCCESS",
  GET_ORDER_BY_ID_ERROR: "GET_ORDER_BY_ID_ERROR",

  ORDER_TRADE: "ORDER_TRADE",
  ORDER_TRADE_SUCCESS: "ORDER_TRADE_SUCCESS",
  ORDER_TRADE_ERROR: "ORDER_TRADE_ERROR",

  ORDER_CANCEL: "ORDER_CANCEL",
  ORDER_CANCEL_SUCCESS: "ORDER_CANCEL_SUCCESS",
  ORDER_CANCEL_ERROR: "ORDER_CANCEL_ERROR",

  ORDER_CLOSE: "ORDER_CLOSE",
  ORDER_CLOSE_SUCCESS: "ORDER_CLOSE_SUCCESS",
  ORDER_CLOSE_ERROR: "ORDER_CLOSE_ERROR",

  // Position
  LOADING_POSITION: "LOADING_POSITION",

  SORT_POSITION: "SORT_POSITION",
  FILTER_POSITION: "FILTER_POSITION",
  GET_POSITION: "GET_POSITION",
  GET_POSITION_SUCCESS: "GET_POSITION_SUCCESS",
  GET_POSITION_ERROR: "GET_POSITION_ERROR",

  // Trade
  LOADING_TRADE: "LOADING_TRADE",

  SORT_TRADE: "SORT_TRADE",
  FILTER_TRADE: "FILTER_TRADE",
  ACCOUNT_FILTER_TRADE: "ACCOUNT_FILTER_TRADE",
  GET_TRADE: "GET_TRADE",
  GET_TRADE_SUCCESS: "GET_TRADE_SUCCESS",
  GET_TRADE_ERROR: "GET_TRADE_ERROR",

  GET_TRADE_BY_ID: "GET_TRADE_BY_ID",
  GET_TRADE_BY_ID_SUCCESS: "GET_TRADE_BY_ID_SUCCESS",
  GET_TRADE_BY_ID_ERROR: "GET_TRADE_BY_ID_ERROR",

  SETTLE_TRADE: "SETTLE_TRADE",
  SETTLE_TRADE_SUCCESS: "SETTLE_TRADE_SUCCESS",
  SETTLE_TRADE_ERROR: "SETTLE_TRADE_ERROR",

  // Permission
  LOADING_PERMISSION: "LOADING_PERMISSION",

  GET_PERMISSION: "GET_PERMISSION",
  GET_PERMISSION_SUCCESS: "GET_PERMISSION_SUCCESS",
  GET_PERMISSION_ERROR: "GET_PERMISSION_ERROR",

  STORE_PERMISSION: "STORE_PERMISSION",
  STORE_PERMISSION_SUCCESS: "STORE_PERMISSION_SUCCESS",
  STORE_PERMISSION_ERROR: "STORE_PERMISSION_ERROR",

  DELETE_PERMISSION: "DELETE_PERMISSION",
  DELETE_PERMISSION_SUCCESS: "DELETE_PERMISSION_SUCCESS",
  DELETE_PERMISSION_ERROR: "DELETE_PERMISSION_ERROR",

  // Country
  LOADING_COUNTRY: "LOADING_COUNTRY",

  SORT_COUNTRY: "SORT_COUNTRY",
  FILTER_COUNTRY: "FILTER_COUNTRY",

  GET_COUNTRY: "GET_COUNTRY",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_ERROR: "GET_COUNTRY_ERROR",

  STORE_COUNTRY: "STORE_COUNTRY",
  STORE_COUNTRY_SUCCESS: "STORE_COUNTRY_SUCCESS",
  STORE_COUNTRY_ERROR: "STORE_COUNTRY_ERROR",

  GET_COUNTRY_BY_ID: "GET_COUNTRY_BY_ID",
  GET_COUNTRY_BY_ID_SUCCESS: "GET_COUNTRY_BY_ID_SUCCESS",
  GET_COUNTRY_BY_ID_ERROR: "GET_COUNTRY_BY_ID_ERROR",

  UPDATE_COUNTRY: "UPDATE_COUNTRY",
  UPDATE_COUNTRY_SUCCESS: "UPDATE_COUNTRY_SUCCESS",
  UPDATE_COUNTRY_ERROR: "UPDATE_COUNTRY_ERROR",

  DELETE_COUNTRY: "DELETE_COUNTRY",
  DELETE_COUNTRY_SUCCESS: "DELETE_COUNTRY_SUCCESS",
  DELETE_COUNTRY_ERROR: "DELETE_COUNTRY_ERROR",

  // Broker
  LOADING_BROKER: "LOADING_BROKER",

  SORT_BROKER: "SORT_BROKER",
  FILTER_BROKER: "FILTER_BROKER",

  GET_BROKER: "GET_BROKER",
  GET_BROKER_SUCCESS: "GET_BROKER_SUCCESS",
  GET_BROKER_ERROR: "GET_BROKER_ERROR",

  STORE_BROKER: "STORE_BROKER",
  STORE_BROKER_SUCCESS: "STORE_BROKER_SUCCESS",
  STORE_BROKER_ERROR: "STORE_BROKER_ERROR",

  GET_BROKER_BY_ID: "GET_BROKER_BY_ID",
  GET_BROKER_BY_ID_SUCCESS: "GET_BROKER_BY_ID_SUCCESS",
  GET_BROKER_BY_ID_ERROR: "GET_BROKER_BY_ID_ERROR",

  UPDATE_BROKER: "UPDATE_BROKER",
  UPDATE_BROKER_SUCCESS: "UPDATE_BROKER_SUCCESS",
  UPDATE_BROKER_ERROR: "UPDATE_BROKER_ERROR",

  DELETE_BROKER: "DELETE_BROKER",
  DELETE_BROKER_SUCCESS: "DELETE_BROKER_SUCCESS",
  DELETE_BROKER_ERROR: "DELETE_BROKER_ERROR",

  TOGGLE_BROKER_STATUS: "TOGGLE_BROKER_STATUS",
  TOGGLE_BROKER_STATUS_SUCCESS: "TOGGLE_BROKER_STATUS_SUCCESS",
  TOGGLE_BROKER_STATUS_ERROR: "TOGGLE_BROKER_STATUS_ERROR",

  // User Account data
  USER_ACCOUNT_DATA_LOADING: "USER_ACCOUNT_DATA_LOADING",

  USER_ACCOUNT_DATA: "USER_ACCOUNT_DATA",
  USER_ACCOUNT_DATA_SUCCESS: "USER_ACCOUNT_DATA_SUCCESS",
  USER_ACCOUNT_DATA_ERROR: "USER_ACCOUNT_DATA_ERROR",

  // User POLICY data
  USER_POLICY_DATA_LOADING: "USER_POLICY_DATA_LOADING",

  USER_POLICY_DATA: "USER_POLICY_DATA",
  USER_POLICY_DATA_SUCCESS: "USER_POLICY_DATA_SUCCESS",
  USER_POLICY_DATA_ERROR: "USER_POLICY_DATA_ERROR",

  USER_POLICY_UPDATE: "USER_POLICY_UPDATE",
  USER_POLICY_UPDATE_SUCCESS: "USER_POLICY_UPDATE_SUCCESS",
  USER_POLICY_UPDATE_ERROR: "USER_POLICY_UPDATE_ERROR",

  USER_POLICY_CANCEL: "USER_POLICY_CANCEL",
  USER_POLICY_CANCEL_SUCCESS: "USER_POLICY_CANCEL_SUCCESS",
  USER_POLICY_CANCEL_ERROR: "USER_POLICY_CANCEL_ERROR",

  // Holiday
  LOADING_HOLIDAY: "LOADING_HOLIDAY",
  SORT_HOLIDAY: "SORT_HOLIDAY",

  GET_HOLIDAY: "GET_HOLIDAY",
  GET_HOLIDAY_SUCCESS: "GET_HOLIDAY_SUCCESS",
  GET_HOLIDAY_ERROR: "GET_HOLIDAY_ERROR",

  STORE_HOLIDAY: "STORE_HOLIDAY",
  STORE_HOLIDAY_SUCCESS: "STORE_HOLIDAY_SUCCESS",
  STORE_HOLIDAY_ERROR: "STORE_HOLIDAY_ERROR",

  GET_HOLIDAY_BY_ID: "GET_HOLIDAY_BY_ID",
  GET_HOLIDAY_BY_ID_SUCCESS: "GET_HOLIDAY_BY_ID_SUCCESS",
  GET_HOLIDAY_BY_ID_ERROR: "GET_HOLIDAY_BY_ID_ERROR",

  UPDATE_HOLIDAY: "UPDATE_HOLIDAY",
  UPDATE_HOLIDAY_SUCCESS: "UPDATE_HOLIDAY_SUCCESS",
  UPDATE_HOLIDAY_ERROR: "UPDATE_HOLIDAY_ERROR",

  // Cash positions
  LOADING_EXTERNAL_TRANSACTIONS: "LOADING_EXTERNAL_TRANSACTIONS",

  GET_EXTERNAL_TRANSACTIONS: "GET_EXTERNAL_TRANSACTIONS",
  GET_EXTERNAL_TRANSACTIONS_SUCCESS: "GET_EXTERNAL_TRANSACTIONS_SUCCESS",
  GET_EXTERNAL_TRANSACTIONS_ERROR: "GET_EXTERNAL_TRANSACTIONS_ERROR",

  STATUS_CHANGE_EXTERNAL_TRANSACTIONS: "STATUS_CHANGE_EXTERNAL_TRANSACTIONS",
  STATUS_CHANGE_EXTERNAL_TRANSACTIONS_SUCCESS:
    "STATUS_CHANGE_EXTERNAL_TRANSACTIONS_SUCCESS",
  STATUS_CHANGE_EXTERNAL_TRANSACTIONS_ERROR:
    "STATUS_CHANGE_EXTERNAL_TRANSACTIONS_ERROR",

  ACCOUNT_FILTER_EXTERNAL_TRANSACTION: "ACCOUNT_FILTER_EXTERNAL_TRANSACTION",
  TYPE_FILTER_EXTERNAL_TRANSACTION: "TYPE_FILTER_EXTERNAL_TRANSACTION",

  SORT_EXTERNAL_TRANSACTION: "SORT_EXTERNAL_TRANSACTION",

  // Schedule
  LOADING_SCHEDULE: "LOADING_SCHEDULE",
  SORT_SCHEDULE: "SORT_SCHEDULE",

  GET_SCHEDULE: "GET_SCHEDULE",
  GET_SCHEDULE_SUCCESS: "GET_SCHEDULE_SUCCESS",
  GET_SCHEDULE_ERROR: "GET_SCHEDULE_ERROR",

  STORE_SCHEDULE: "STORE_SCHEDULE",
  STORE_SCHEDULE_SUCCESS: "STORE_SCHEDULE_SUCCESS",
  STORE_SCHEDULE_ERROR: "STORE_SCHEDULE_ERROR",

  GET_SCHEDULE_BY_ID: "GET_SCHEDULE_BY_ID",
  GET_SCHEDULE_BY_ID_SUCCESS: "GET_SCHEDULE_BY_ID_SUCCESS",
  GET_SCHEDULE_BY_ID_ERROR: "GET_SCHEDULE_BY_ID_ERROR",

  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
  UPDATE_SCHEDULE_SUCCESS: "UPDATE_SCHEDULE_SUCCESS",
  UPDATE_SCHEDULE_ERROR: "UPDATE_SCHEDULE_ERROR",

  TOGGLE_SCHEDULE_STATUS: "TOGGLE_SCHEDULE_STATUS",
  TOGGLE_SCHEDULE_STATUS_SUCCESS: "TOGGLE_SCHEDULE_STATUS_SUCCESS",
  TOGGLE_SCHEDULE_STATUS_ERROR: "TOGGLE_SCHEDULE_STATUS_ERROR",
};
