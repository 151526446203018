import * as types from "./types";

export const loading = () => {
  return { type: types.constants.LOADING_CURRENCY };
};

export const sortCurrency = (data) => {
  return {
    type: types.constants.SORT_CURRENCY,
    data,
  };
};

export const filterCurrency = (search) => {
  return { type: types.constants.FILTER_CURRENCY, search };
};

export const loadAllCurrencies = () => {
  return { type: types.constants.GET_CURRENCY };
};

export const loadCurrency = (payload) => {
  return { type: types.constants.GET_CURRENCY_BY_ID, payload };
};

export const storeCurrency = (payload) => {
  return { type: types.constants.STORE_CURRENCY, payload };
};

export const updateCurrency = (payload) => {
  return { type: types.constants.UPDATE_CURRENCY, payload };
};

export const deleteCurrency = (payload) => {
  return { type: types.constants.DELETE_CURRENCY, payload };
};
