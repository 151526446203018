import * as types from "./types";

export const loading = () => {
  return { type: types.constants.LOADING_FUND_ACCOUNT2 };
};

export const sortFundAccount = (data) => {
  return {
    type: types.constants.SORT_FUND_ACCOUNT2,
    data,
  };
};

export const filterFundAccount = (search) => {
  return { type: types.constants.FILTER_FUND_ACCOUNT2, search };
};

export const loadAllFundAccounts = (fund) => {
  return { type: types.constants.GET_FUND_ACCOUNT2, fund };
};

export const storeFundAccount = (payload) => {
  return { type: types.constants.STORE_FUND_ACCOUNT2, payload };
};

export const loadFundAccountCurrentBalance = (payload) => {
  return { type: types.constants.LOAD_FUND_ACCOUNT2_CURRENT_BALANCE, payload };
};

export const toggleStatus = (payload) => {
  return { type: types.constants.TOGGLE_FUND_ACCOUNT2_STATUS, payload };
};
