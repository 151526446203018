import React from "react";
import { TablePagination, Button } from "@material-ui/core";

function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
  const handleBackButtonClick = () => {
    onChangePage(page);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 2);
  };

  const getNumberOfPages = (rowCount, rowsPerPage) =>
    Math.ceil(rowCount / rowsPerPage);

  return (
    <>
      <Button
        variant="secondary"
        size="small"
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        style={{ marginRight: "0.5rem" }}
      >
        Previous
      </Button>
      <Button
        variant="secondary"
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="next page"
      >
        Next
      </Button>
    </>
  );
}

const CustomPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
}) => (
  <TablePagination
    component="nav"
    count={rowCount}
    rowsPerPage={rowsPerPage}
    page={currentPage - 1}
    onChangePage={onChangePage}
    onChangeRowsPerPage={({ target }) =>
      onChangeRowsPerPage(Number(target.value))
    }
    ActionsComponent={TablePaginationActions}
  />
);

export default CustomPagination;
