import { call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "axios";

const loadBondWatchlist = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/watchlists/api/v1/getWatchlist?watchlist=ssx-bond-default`
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export function* getUserWatchListBondSaga() {
  try {
    const userwatchlistbond = yield call(loadBondWatchlist);
    yield put({
      type: types.constants.GET_USER_WATCHLIST_BOND_SUCCESS,
      userwatchlistbond,
    });
  } catch (error) {
    yield put({ type: types.constants.GET_USER_WATCHLIST_BOND_ERROR, error });
  }
}
