import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  "@media print": {
    hideOnPrint: {
      display: "none !important",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box
      component={"footer"}
      bgcolor={"#131313"}
      color={"white"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      p={3}
      className={classes.hideOnPrint}
    >
      <Typography variant="subtitle2">
        Copyright {new Date().getFullYear()} @SecondSTAX
      </Typography>
    </Box>
  );
};

export default Footer;
