import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as fixedIncome from "../../../store/actions/fixedIncome.action";
import { toast } from "react-toastify";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import swal from "sweetalert";
import * as AccountAction from "../../../store/actions/account.action";
import * as TradeAction from "../../../store/actions/Trade.actions";
import { useKeycloak } from "@react-keycloak/web";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  Typography,
  Box,
  Grid,
  Select,
  OutlinedInput,
  MenuItem,
  TextField,
  CircularProgress,
} from "@material-ui/core";

import { Add, RemoveCircle } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#252525",
      color: "#dedede",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
    },
    "& .MuiDialogContent-root": {
      color: "#dedede !important",
    },
  },
  dialogActions: {
    margin: "0 auto",
    marginTop: "2rem",
    "& > *:first-child": {
      marginRight: "0.5rem",
    },
  },
  actionBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#145582",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#145582",
    },
    "&:disabled": {
      backgroundColor: "#083a5e",
      color: "#888888",
    },
  },
  cancelBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#3d3d3d",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#3d3d3d",
    },
  },
  dialogContentFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "1rem 0",
    "& > *": {
      padding: "0.5rem",
      border: "1px solid grey",
      borderRadius: "2px",
      flexGrow: "1",
      flexShrink: "0 !important",
    },
  },
  reviewBadge: {
    backgroundColor: "#22312c",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.7rem 1.5rem",
    borderRadius: "10px",
    minWidth: "150px",
  },
}));

const OfferReViewModal = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { keycloak } = useKeycloak();

  const orderById = useSelector(
    (state) => state?.fixedincomedata?.userorderbyiddata?.response
  );
  const fundacconut = useSelector(
    (state) => state?.accountdata?.fundaccount?.response?.set
  );
  const currencyList = useSelector(
    (state) => state?.trade?.ccylist?.response?.set
  );

  useEffect(() => {
    dispatch(AccountAction.getFundAccountListAction());
    dispatch(TradeAction.getCCYAction());
  }, [dispatch]);

  useEffect(() => {
    if (props.order.order_id) {
      dispatch(fixedIncome.orderByUserAction(props.order.order_id));
    }
  }, [props.order.order_id, dispatch]);

  const [fields, setFields] = useState([
    {
      type: "pct",
      percent: 100,
      amount: 0,
      portfolioAccount: "",
    },
  ]);

  function handleChange(i, event) {
    if (event?.target?.value) {
      const val = event?.target?.value;
      event.target.value =
        val.indexOf(".") >= 0
          ? val.substr(0, val.indexOf(".")) + val.substr(val.indexOf("."), 3)
          : val;
    }

    if (event?.target?.value < 0) event.target.value = 0;
    if (event?.target?.value > 100) event.target.value = 100;
    const values = [...fields];
    values[i].percent = event.target.value;
    setFields(values);
  }

  function handleAccountChange(i, event) {
    const values = [...fields];
    values[i].portfolioAccount = event.target.value;
    setFields(values);
  }

  function handleTypeChange(i, event) {
    const values = [...fields];
    values[i].type = event.target.value;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({
      type: "pct",
      percent: 0,
      amount: 0,
      portfolioAccount: "",
    });
    setFields(values);
  }

  function handleRemove(i) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  }
  const initialValues = {
    csdAccount: "",
    BankName: "",
    AccountName: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      csdAccount: Yup.string(),
      BankName: Yup.string(),
      AccountName: Yup.string(),
      trade_amount: Yup.string(),
    }),
    onSubmit: async () => {
      if (fields.length) {
        let resultTopercent = false;
        let isportfolioAccount = false;
        let acconutarray = [];
        let acconutarraystr = [];

        let percentval = fields.reduce((a, v) => a + parseFloat(v.percent), 0);
        var valueArr = fields.map((item) => {
          if (!item.portfolioAccount) {
            isportfolioAccount = true;
          }
          if (parseFloat(item.percent) === 0) {
            resultTopercent = true;
          }
          acconutarraystr.push({
            fund_account: item.portfolioAccount,
            fund_account_pct: item.percent,
          });
          acconutarray.push(
            {
              name: "fund_account",
              value: item.portfolioAccount,
            },
            { name: "fund_account_pct", value: item.percent?.toString() }
          );

          return item.portfolioAccount;
        });
        var isDuplicate = new Set(valueArr).size !== valueArr.length;

        if (isportfolioAccount) {
          handleError("Please select your account");
          return;
        } else if (resultTopercent) {
          handleError("Percentage must be a positive number and not zero");
          return;
        } else if (isDuplicate) {
          handleError(`You can't choose the same account multiple times.`);
          return;
        } else if (parseFloat(percentval).toFixed(2) !== "100.00") {
          handleError(
            `Amount percentage should be equal to 100 not more or less than that.`
          );
          return;
        }

        if (orderById?.side?.toLocaleLowerCase() === "buy") {
          let IsBuyOrder = await fundabalacecheck();
          if (!IsBuyOrder) {
            return;
          }
        } else {
          setIsLoading(true);
          let positionsdata = await checkPositions();
          setIsLoading(false);
          if (
            positionsdata &&
            positionsdata.response &&
            positionsdata.response.set &&
            positionsdata.response.set.length
          ) {
            let resp = positionsdata.response.set[0].values;
            let symbolData = resp?.filter((data) => data.name === "quantity");
            if (symbolData?.length) {
              if (
                symbolData[0] &&
                (/^(\-(\d)*)$/.test(parseFloat(symbolData[0].value)) ||
                  parseFloat(symbolData[0].value) <
                    parseFloat(props?.order?.offer_quantity))
              ) {
                handleError("You do not have the required quantity to sell");
                return;
              }
            } else {
              handleError("You do not have the required quantity to sell");
              return;
            }
          } else {
            let msg = "You do not have the required quantity to sell";
            if (positionsdata?.error?.message)
              msg = positionsdata.error.message;
            handleError(msg);
            return;
          }
        }

        if (
          parseInt(props?.order?.offer_quantity) <= parseInt(orderById?.rem_qty)
        ) {
          swal({
            title: "Are you sure?",
            text: "Are you sure you want to Execute Trade ?",
            icon: "warning",
            buttons: true,
            dangerMode: false,
          }).then((confirm) => {
            if (confirm) {
              let body = {
                values: [
                  { name: "offer_id", value: props?.order?.offer_id },
                  { name: "market_taker_status", value: "accepted" },
                  { name: "market_maker_status", value: "accepted" },
                  {
                    name: "fund_account_str",
                    value: JSON.stringify(acconutarraystr),
                  },
                  {
                    name: "trade_amount",
                    value: props?.order?.trade_amount?.toString(),
                  },
                  {
                    name: "trade_consideration",
                    value: props?.order?.trade_consideration?.toString(),
                  },
                ].concat(acconutarray),
              };
              dispatch(
                fixedIncome.updateTradeAction({
                  payload: JSON.stringify(body),
                  recordID: props?.order?.recordID,
                  isExecute: true,
                })
              );
              setTimeout(() => {
                dispatch(fixedIncome.getMarketOrderAction());
              }, 1000);
              props.onHide();
            }
          });
        } else {
          toast.error(`Trade can't be executed because of quantity mismatch`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          props.onHide();
        }
      }
    },
  });

  async function fundabalacecheck() {
    if (fundacconut.length) {
      let isbalance = true;
      const indexnumber = currencyList.findIndex((resp) => resp.ccy_default);
      fields.map((obj) => {
        let ordermultiplier = orderAccountFxRate(
          props?.order?.ccy,
          indexnumber
        );
        const preval =
          (parseFloat(obj.percent) / 100) *
          (parseFloat(props?.order?.trade_consideration)?.toFixed(2) *
            parseFloat(ordermultiplier));

        return fundacconut.map((element) => {
          let multiplier = orderAccountFxRate(element.ccy, indexnumber);
          let newmultiplier =
            parseFloat(multiplier) * parseFloat(element.total_balance);
          if (element.recordID === obj.portfolioAccount) {
            if (preval > newmultiplier) {
              handleError(
                `The required order amount is not available in the ${element?.name}`
              );
              return (isbalance = false);
            }
          }
        });
      });
      return isbalance;
    }
  }

  async function checkPositions() {
    return fetch(
      `${process.env.REACT_APP_API_URL}api/compose/namespace/${
        process.env.REACT_APP_NAMESPACE_ID
      }/module/${
        process.env.REACT_APP_FI_POSITIONS_MODULE_ID
      }/record/?query=client=${localStorage.getItem("client")} AND bond=${
        orderById?.bond
      }`,
      {
        method: "GET",
        headers: new Headers({
          authorization: "Bearer " + keycloak.token,
          "Content-Type": "application/json",
        }),
      }
    )
      .then(async (response) => {
        return await response.json();
      })
      .catch((error) => {
        handleError(error?.error?.message);
        return error?.error?.message;
      });
  }

  const orderAccountFxRate = (ccy, index) => {
    if (currencyList?.length) {
      let ccylist = currencyList;
      for (var i = 0; i < ccylist?.length; i++) {
        if (ccylist[i]?.ccy_default === "1" && ccy === ccylist[i]?.recordID) {
          return ccylist[i]?.ccy_multiplier;
        } else if (
          ccy === ccylist[i]?.recordID &&
          ccylist[index]?.recordID !== ccylist[i]?.recordID
        ) {
          return (
            1 /
            ccylist[index]?.ccy_multiplier /
            (1 / ccylist[i]?.ccy_multiplier)
          )?.toFixed(4);
        } else if (
          ccy === ccylist[i]?.recordID &&
          ccylist[index]?.recordID === ccylist[i]?.recordID
        ) {
          return 1?.toFixed(4);
        }
      }
    }
  };

  const handleError = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleClose = () => {
    props.onHide();
    setFields([
      {
        type: "percent",
        percent: "",
        amount: 0,
        portfolioAccount: "371449635398431",
      },
    ]);
    formik.resetForm({
      values: initialValues,
    });
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      className={classes.dialog}
      disableBackdropClick
    >
      <DialogTitle>Offer Review</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">My Trade Details</Typography>
        <Box className={classes.dialogContentFlex}>
          <Box>
            <Typography variant="body1" component="h6">
              Bond ID
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.bond_id ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              ISIN
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.isin ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Maturity Date
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.maturity_date
                ? moment(orderById?.maturity_date).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Coupon
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.coupon_rate}%
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Settlement Date
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.settlement_date
                ? moment(orderById?.settlement_date).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Buy/Sell
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.side ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              QTY
            </Typography>
            <Typography variant="body2" component="p">
              <CurrencyFormat
                value={parseFloat(orderById?.qty)}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Yield
            </Typography>
            <Typography variant="body2" component="p">
              {parseFloat(orderById?.yield)?.toFixed(4)}%
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Amount
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.ccy_suffix}{" "}
              <CurrencyFormat
                value={parseFloat(orderById?.amount)?.toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Box>
        </Box>
        <Typography variant="subtitle2">
          {props?.order?.market_taker_name}
        </Typography>
        <Box className={classes.dialogContentFlex}>
          <Box>
            <Typography variant="body1" component="h6">
              Bond ID
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond_id ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              ISIN
            </Typography>
            <Typography variant="body2" component="p">
              {props?.isin ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Maturity Date
            </Typography>
            <Typography variant="body2" component="p">
              {props?.maturity_date
                ? moment(props?.maturity_date).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Coupon
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.coupon_rate}%
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Settlement Date
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.settlement_date
                ? moment(orderById?.settlement_date).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Buy/Sell
            </Typography>
            <Typography variant="body2" component="p">
              {props?.side ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              QTY
            </Typography>
            <Typography variant="body2" component="p">
              <CurrencyFormat
                value={parseFloat(props.order?.offer_quantity)}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Yield
            </Typography>
            <Typography variant="body2" component="p">
              {parseFloat(
                props?.order?.offer_yield ? props?.order?.offer_yield : 0
              )?.toFixed(4)}
              %
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Amount
            </Typography>
            <Typography variant="body2" component="p">
              {orderById?.ccy_suffix}{" "}
              <CurrencyFormat
                value={parseFloat(props?.order?.trade_consideration)?.toFixed(
                  2
                )}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Box>
        </Box>
        <Typography variant="subtitle2">Financial Information</Typography>
        <Box className={classes.dialogContentFlex}>
          <Box>
            <Typography variant="body1" component="h6">
              Bank Name
            </Typography>
            <Typography variant="body2" component="p">
              Stanbic Bank
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Account Number
            </Typography>
            <Typography variant="body2" component="p">
              07987645678
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Account Name
            </Typography>
            <Typography variant="body2" component="p">
              Kestrel Capital
            </Typography>
          </Box>
        </Box>
        <Typography variant="subtitle2">CSD Information</Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Select
                name="BankName"
                displayEmpty
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                input={<OutlinedInput />}
                error={formik.errors["BankName"] && formik.touched["BankName"]}
                fullWidth={true}
              >
                <MenuItem disabled value="">
                  Select your bank
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["csdAccount"] && formik.touched["csdAccount"]
                }
                label="Account Number"
                name="csdAccount"
                helperText={
                  formik.touched["csdAccount"] && formik.errors["csdAccount"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["csdAccount"]}
                fullWidth={true}
              />
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["AccountName"] && formik.touched["AccountName"]
                }
                label="Account Name"
                name="AccountName"
                helperText={
                  formik.touched["AccountName"] && formik.errors["AccountName"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["AccountName"]}
                fullWidth={true}
              />
            </Grid>
            {fundacconut?.length > 0 && (
              <>
                <Typography variant="subtitle2">
                  Allocation&nbsp;
                  <Add
                    title="Add"
                    onClick={() =>
                      fundacconut?.length > 1 ? handleAdd() : null
                    }
                  ></Add>
                </Typography>
              </>
            )}
            {fundacconut &&
              fundacconut?.length > 0 &&
              fields &&
              fields.length > 0 &&
              fields.map((field, index) => {
                <Grid container spacing={1}>
                  <Grid xs={12} md={6} lg={4}>
                    <Select
                      displayEmpty
                      value={field.portfolioAccount || ""}
                      onChange={(e) => handleAccountChange(index, e)}
                      input={<OutlinedInput />}
                    >
                      <MenuItem disabled value="">
                        Select your account
                      </MenuItem>
                      {fundacconut?.length > 0 &&
                        fundacconut.map((e, key) => {
                          return (
                            <MenuItem key={key} value={e.recordID}>
                              {e.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid xs={12} md={6} lg={3}>
                    <Select
                      displayEmpty
                      value={field.type || ""}
                      onChange={(e) => handleTypeChange(index, e)}
                      input={<OutlinedInput />}
                    >
                      <MenuItem disabled value="">
                        Select amount or pct
                      </MenuItem>
                      <MenuItem value="pct">By pct</MenuItem>
                      <MenuItem disabled value="amount">
                        By amount
                      </MenuItem>
                    </Select>
                  </Grid>
                  <Grid xs={12} md={6} lg={fields.length > 1 ? 4 : 5}>
                    <TextField
                      variant="outlined"
                      type="text"
                      label="Enter amount or Enter pct"
                      name={"multiplier"}
                      value={field?.percent}
                      min="1"
                      max="100"
                      onChange={(e) => handleChange(index, e)}
                    />
                  </Grid>
                  {fields.length > 1 && (
                    <Grid xs={12} md={6} lg={1}>
                      <RemoveCircle
                        title="Remove"
                        onClick={() => handleRemove(index)}
                      />
                    </Grid>
                  )}
                </Grid>;
              })}
          </Grid>
          {!fundacconut?.length && (
            <Typography variant="subtitle2" color="secondary">
              You don't have any fund account, hence you can't proceed with any
              order.
            </Typography>
          )}
          <div className={classes.dialogActions}>
            <Button
              variant="contained"
              type="submit"
              className={classes.actionBtn}
              size="small"
              disabled={!fundacconut?.length}
            >
              {isLoading && <CircularProgress />}
              Execute
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.cancelBtn}
              size="small"
            >
              Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(OfferReViewModal);
