import * as types from "../actions/types";

export function watchlistBondReducer(state = {}, action) {
  switch (action.type) {
    case types.constants.GET_USER_WATCHLIST_BOND_SUCCESS:
      return {
        ...state,
        userwatchlistbond: action?.userwatchlistbond,
      };
    case types.constants.GET_USER_WATCHLIST_BOND_ERROR:
      return {
        ...state,
        userwatchlistbonderror: "Error",
      };
    case types.constants.GET_BOND_MULTIPLE_SUCCESS:
      let newsymbollist = [],
        response = { response: {} };
      state?.userwatchlistbond?.response?.set &&
        state?.userwatchlistbond?.response?.set[0]?.values?.forEach((data) => {
          if (data?.value !== "fixedincome") {
            let symboldata = action?.multiplebond?.response?.set?.filter(
              (resp) => resp?.recordID === data?.value
            );
            if (symboldata?.length) {
              newsymbollist.push(symboldata[0]);
            }
          }
        });
      response = {
        response: {
          ...action?.multiplebond.response,
          ...{ set: newsymbollist },
        },
      };
      return {
        ...state,
        multiplebond: response,
      };
    case types.constants.GET_BOND_MULTIPLE_ERROR:
      return {
        ...state,
        multiplebonderror: "Error",
      };
    case types.constants.CREATE_WATCH_LIST_BOND_SUCCESS:
      state?.userwatchlistbond?.response?.set?.push(
        action?.createwatchlistbond?.response
      );
      return {
        ...state,
        createwatchlistbond: action?.createwatchlistbond,
      };
    case types.constants.CREATE_WATCH_LIST_BOND_ERROR:
      return {
        ...state,
        createwatchlistbonderror: "Error",
      };
    case types.constants.UPDATE_WATCH_LIST_BOND_SUCCESS:
      if (action?.updatewatchlistbond?.success) {
        const symboldata = state?.multiplebond?.response?.set;
        state?.multiplebond?.response?.set?.splice(0, symboldata?.length);
        state?.userwatchlistbond?.response?.set?.splice(0, 1);
      }
      return {
        ...state,
        updatewatchlistbond: action?.updatewatchlistbond,
      };
    case types.constants.UPDATE_WATCH_LIST_BOND_ERROR:
      return {
        ...state,
        updatewatchlistbonderror: "Error",
      };
    default:
      return state;
  }
}
