import * as types from "../actions/types";

const INITIAL_USER_POLICY_DATA = {
  loading: false,
  error: null,
  data: {},
};

export const userPolicyReducer = (
  state = INITIAL_USER_POLICY_DATA,
  action
) => {
  switch (action.type) {
    case types.constants.USER_POLICY_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case types.constants.USER_POLICY_DATA_LOADING:
      return { ...state, loading: true };

    case types.constants.USER_POLICY_DATA_ERROR:
      return { ...state, data:{}, error: action.payload };
    
    case types.constants.USER_POLICY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {'update':true},
        error: null,
      };
    
    case types.constants.USER_POLICY_UPDATE_ERROR:
      return { ...state, data:{'update':false}, error: action.payload };

    case types.constants.USER_POLICY_CANCEL_SUCCESS:
    case types.constants.USER_POLICY_CANCEL_ERROR:
      return {
        ...state,
        loading: false,
        data: {'cancel':true},
        error: null,
      };
    
    default:
      return state;
  }
};
