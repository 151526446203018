import React, { lazy, Suspense, useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  styled,
  Typography,
} from "@material-ui/core";
import Loader from "../../components/Loader";

import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";

import axios from "axios";
import WebSocketContext from "../../store/context/websocket-context";

import {
  cancelOrder,
  closeOrder,
  loadAllOrders,
  loading as orderLoad,
} from "../../store/actions/order.action";
import {
  loadAllTrades,
  loading as loadTrade,
} from "../../store/actions/trade.action";

import {
  loadAllVenues,
} from "../../store/actions/venue.action";

import moment from "moment";

import CurrencyList from "../../components/CurrencyList/CurrencyList";
import PositionTable from "../../components/PositionTable/PositionTable";
import HistoryTable from "./Table/HistoryTable";
import OrderTable from "./Table/OrderTable";
import TradeTable from "./Table/TradeTable";

const EquityWatchlist = lazy(() =>
  import(
    /* webpackChunkName: "EquityWatchlist" */ "./EquityWatchlist/EquityWatchlist"
  )
);
const EquitySnapshot = lazy(() =>
  import(
    /* webpackChunkName: "EquitySnapshot" */ "./EquitySnapshot/EquitySnapshot"
  )
);
const EquityOrderModal = lazy(() =>
  import(
    /* webpackChunkName: "EquityOrderModal" */ "./EquityOrderModal/EquityOrderModal"
  )
);

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#252525",
  height: "100%",
}));

const useStyles = makeStyles(() => ({
  tabBox: {
    marginBottom: "1rem",
    marginTop: "1rem",
  },
  tabs: {
    maxWidth: "264px",
    minWidth: "72px",
    boxSizing: "border-box",
    textAlign: "center",
    flexShrink: 0,
    lineHeight: 1.75,
    whiteSpace: "normal",
    letterSpacing: "0.02857em",
    padding: "6px 12px",
    cursor: "pointer",
    marginRight: "8px",
    userSelect: "none",
  },
  activeTab: {
    borderBottom: "2px solid #0c6fb3",
    fontWeight: "400",
    textTransform: "none",
    color: "white",
  },
  select: {
    width: "auto !important",
    height: "35px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  dividerMargin: {
    marginLeft: "0.5rem !important",
    marginRight: "1rem !important",
  },
  filterFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& >:not(:last-child)": {
      marginRight: "0.5rem",
    },
  },
  fixedHeight: {
    maxHeight: "550px",
    overflow: "auto",
  },
  "@media (max-width: 768px)": {
    headerFlex: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginTop: "0.5rem",
    },
    filterFlex: {
      flexWrap: "wrap",
      marginTop: "0.5rem",
      "& > *": {
        flexGrow: 1,
        marginTop: "0.5rem",
      },
    },
  },
}));

const Equity = ({ fund }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedFund, setSelectedFund] = useState(null);

  useEffect(() => {
    if ("id" in fund) {
      setSelectedFund(fund);
    }
  }, [fund]);

  const {
    filteredItems: orders,
    loading: orderLoading,
    error: orderError,
    sortBy: orderSortBy,
  } = useSelector((state) => state.orders);

  const {
    filteredItems: trades,
    loading: tradeLoading,
    error: tradeError,
    sortBy: tradeSortBy,
  } = useSelector((state) => state.trades);

  const [positions, setPositions] = useState([]);

  const [tradeTab, setTradeTab] = useState(1);
  const [positionLoading, setPositionLoading] = useState(false);

  const [orderStartDate, setOrderStartDate] = useState(moment());
  const [orderEndDate, setOrderEndDate] = useState(moment());

  const [tradeStartDate, setTradeStartDate] = useState(moment());
  const [tradeEndDate, setTradeEndDate] = useState(moment());

  const [historyStartDate, setHistoryStartData] = useState(moment());
  const [historyEndDate, setHistoryEndDate] = useState(moment());

  const [positionsData, setPositionsData] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [tradesData, setTradesData] = useState([]);
  const [historyData, setHistoryData] = useState([]);

  const [statusFilters, setStatusFilters] = useState([]);
  const [symbolFilters, setSymbolFilters] = useState([]);
  const [exchangeFilters, setExchangeFilters] = useState([]);
  const [accountFilters, setAccountFilters] = useState([]);

  const [statusFilter, setStatusFilter] = useState("");
  const [symbolFilter, setSymbolFilter] = useState("");
  const [exchangeFilter, setExchangeFilter] = useState("");
  const [accountFilter, setAccountFilter] = useState("");

  const [fxRates, setFxRates] = useState({});

  const [client, setClient] = useState(null);

  const [positionCurrency, setPositionCurrency] = useState(null);

  const [orderEvent, setOrderEvent] = useState({});
  const { items: currencies } = useSelector((state) => state.currencies);

  const { activeWatchlist } = useSelector((state) => state.watchlist);

  const loadFxRates = async () => {
    let fxData_ = {};
    await Promise.all(
      currencies.map((currency) =>
        axios.post(
          `${process.env.REACT_APP_API_URL}/currencies/api/v1/exchange-rates/getForCurrencies/${currency.isoCode}`,
          {
            currencies: Array.from(
              new Set(
                currencies
                  .filter((_) => _.isoCode != currency.isoCode)
                  .map((_) => _.isoCode)
              )
            ),
          }
        )
      )
    )
      .then((fxData) => {
        fxData.forEach((_) => {
          _.data.forEach((__) => {
            if (!fxData_[__.baseCurrency]) {
              fxData_[__.baseCurrency] = {};
            }

            fxData_[__.baseCurrency] = {
              ...fxData_[__.baseCurrency],
              [__.currency]: __.multiplier,
            };
          });
        });
      })
      .catch((error) => {
        console.log({ error });
      });

    setFxRates(fxData_);
  };

  useEffect(() => {
    if (currencies.length > 0) {
      loadFxRates();
    }
  }, [currencies]);

  useEffect(() => {
    const loadClientData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/clients/api/v1/${fund.clientId}/details`
        );
        setClient(data);
        setPositionCurrency(data?.currency);
      } catch (error) {
        console.log({ error });
      }
    };

    if ("clientId" in fund) {
      loadClientData();
    }
  }, [fund]);

  const reset = () => {
    setSymbolFilter("");
    setExchangeFilter("");
    setAccountFilter("");

    setSymbolFilters([]);
    setExchangeFilters([]);
    setAccountFilters([]);
  };

  useEffect(() => {
    setPositionsData(positions);

    if (tradeTab == 0) {
      reset();
      setSymbolFilters(
        Array.from(
          new Set(
            positions
              .filter((position) => position.origVenue)
              .map((position) => position.ticker)
          )
        )
      );

      let exchanges_ = {};
      let accounts_ = {};

      positions.forEach((position) => {
        let price = 0;

        if (position?.data?.price) {
          price = position?.data?.price;
        } else if (
          position?.data?.openingPrice &&
          position?.data?.openingPrice > 0
        ) {
          price = position?.data?.openingPrice;
        } else {
          price = position?.data?.prevClose ?? 0;
        }

        position["price"] = price;
        position["localPrice"] = price * position.volume;
        position["marketValue"] = price * position.volume * position.fxRate;

        if (
          position?.fundAccount?.id &&
          position?.fundAccount?.fundAccountName
        ) {
          accounts_[
            `${position?.fundAccount?.fundAccountName}-${position?.fundAccount?.id}`
          ] = {
            name: position?.fundAccount?.fundAccountName,
            id: position?.fundAccount?.id,
          };
        }
        if (position?.data?.key?.exchange) {
          exchanges_[position?.data?.key?.exchange] =
            position?.data?.key?.exchange;
        }
      });

      setAccountFilters(Object.values(accounts_));
      setExchangeFilters(Object.values(exchanges_));
    }
  }, [positions, tradeTab]);

  useEffect(() => {
    setOrdersData(orders);
    setHistoryData(orders);

    if ([1, 3].includes(tradeTab)) {
      reset();

      setStatusFilter("");

      setSymbolFilters(
        Array.from(
          new Set(
            orders.filter((order) => order.ticker).map((order) => order.ticker)
          )
        )
      );
      setExchangeFilters(
        Array.from(
          new Set(
            orders.filter((order) => order.venue).map((order) => order.venue)
          )
        )
      );

      setStatusFilters(
        Array.from(
          new Set(
            orders.filter((order) => order.status).map((order) => order.status)
          )
        )
      );

      let accounts_ = {};

      orders.forEach((order) => {
        if ("allocations" in order) {
          order.allocations.forEach((allocation) => {
            accounts_[
              `${allocation.fundAccount?.fundAccountName}-${allocation.fundAccount.id}`
            ] = {
              name: allocation.fundAccount.fundAccountName,
              id: allocation.fundAccount.id,
            };
          });
        }
      });

      setAccountFilters(Object.values(accounts_));
    }
  }, [orders, tradeTab]);

  useEffect(() => {
    setTradesData(trades);

    if (tradeTab == 2) {
      reset();

      setStatusFilter("");

      setSymbolFilters(
        Array.from(
          new Set(
            trades.filter((trade) => trade.ticker).map((trade) => trade.ticker)
          )
        )
      );
      setExchangeFilters(
        Array.from(
          new Set(
            trades.filter((trade) => trade.venue).map((trade) => trade.venue)
          )
        )
      );
      setStatusFilters(
        Array.from(
          new Set(
            trades.filter((trade) => trade.status).map((trade) => trade.status)
          )
        )
      );

      let accounts_ = {};

      trades.forEach((trade) => {
        if (trade?.fundAccount?.id && trade?.fundAccount?.fundAccountName) {
          accounts_[
            `${trade?.fundAccount?.fundAccountName}-${trade?.fundAccount?.id}`
          ] = {
            name: trade?.fundAccount?.fundAccountName,
            id: trade?.fundAccount?.id,
          };
        }
      });
      setAccountFilters(Object.values(accounts_));
    }
  }, [trades, tradeTab]);

  useEffect(() => {
    var data =
      tradeTab == 0
        ? [...positions]
        : tradeTab == 1
          ? [...orders]
          : tradeTab == 2
            ? [...trades]
            : [...orders];

    var setFunc =
      tradeTab == 0
        ? setPositionsData
        : tradeTab == 1
          ? setOrdersData
          : tradeTab == 2
            ? setTradesData
            : setHistoryData;

    if (symbolFilter) {
      data = data.filter((_) => {
        if (tradeTab == 0) {
          return _.ticker == symbolFilter;
        }
        if (tradeTab == 1) {
          return _.ticker == symbolFilter;
        }
        if (tradeTab == 2) {
          return _.ticker == symbolFilter;
        }
        if (tradeTab == 3) {
          return _.ticker == symbolFilter;
        }
        return true;
      });
    }

    if (exchangeFilter) {
      data = data.filter((_) => {
        if (tradeTab == 0) {
          return _?.data?.key?.exchange == exchangeFilter;
        }
        if (tradeTab == 1) {
          return _?.venue == exchangeFilter;
        }
        if (tradeTab == 2) {
          return _?.venue == exchangeFilter;
        }
        if (tradeTab == 3) {
          return _?.venue == exchangeFilter;
        }
        return true;
      });
    }

    if (accountFilter) {
      data = data.filter((_) => {
        if (tradeTab == 0) {
          return _?.fundAccount?.id == accountFilter;
        }
        if (tradeTab == 1) {
          return (
            _.allocations.filter(
              (allocation) => allocation.fundAccount.id == accountFilter
            ).length > 0
          );
        }
        if (tradeTab == 2) {
          return _?.fundAccount?.id == accountFilter;
        }
        if (tradeTab == 3) {
          return (
            _.allocations.filter(
              (allocation) => allocation.fundAccount.id == accountFilter
            ).length > 0
          );
        }
        return true;
      });
    }

    if (statusFilter) {
      data = data.filter((_) => {
        if (tradeTab == 1) {
          return _?.status == statusFilter;
        }
        if (tradeTab == 2) {
          return _?.status == statusFilter;
        }
        if (tradeTab == 3) {
          return _?.status == statusFilter;
        }
        return true;
      });
    }

    setFunc(data);
  }, [
    symbolFilter,
    exchangeFilter,
    accountFilter,
    statusFilter,
    positions,
    orders,
    trades,
  ]);

  const loadPositions = (fund, fxRates) => {
    if (fund?.id && Object.keys(fxRates).length > 0) {
      const loadData = async () => {
        try {
          setPositionLoading(true);
          let { data: positions_ } = await axios.get(
            `${process.env.REACT_APP_API_URL}/df-mo/api/v1/group-accounts/eq-positions/${fund.id}`
          );

          const body = positions_.map((_) => {
            return {
              symbol: _["ticker"],
              exchange: _.origVenue,
            };
          });

          const positionsData = [...positions_];
          positions_ = {};
          positionsData.forEach((_) => {
            positions_[`${_.ticker}-AES`] = {
              ..._,
              fxRate:
                positionCurrency == _.ccy
                  ? 1
                  : fxRates?.[_.ccy]?.[positionCurrency] ?? 0,
            };
          });

          if (positionsData.length > 0) {
            const { data: res } = await axios.post(
              `${process.env.REACT_APP_API_URL}/ws/ltes/symbols`,
              body
            );

            res.forEach((_) => {
              if (positions_[`${_?.key?.symbol}-AES`]) {
                positions_[`${_?.key?.symbol}-AES`] = {
                  ...positions_[`${_?.key?.symbol}-AES`],
                  data: _,
                };
              }
            });
          }

          setPositions(Object.values(positions_));
          setPositionLoading(false);
        } catch (err) {
          console.log({ err });
        }
      };

      loadData();
    }
  };

  const loadOrders = (fund, startDate, endDate) => {
    if (fund?.id && startDate && endDate) {
      dispatch(orderLoad());
      dispatch(
        loadAllOrders({
          fund,
          startDate: moment(startDate).format("Y-MM-DD"),
          endDate: moment(endDate).format("Y-MM-DD"),
        })
      );
    }
  };

  const loadTrades = (fund, startDate, endDate) => {
    if (fund?.id && startDate && endDate) {
      dispatch(loadTrade());
      dispatch(
        loadAllTrades({
          fund,
          startDate: moment(startDate).format("Y-MM-DD"),
          endDate: moment(endDate).format("Y-MM-DD"),
        })
      );
    }
  };

  useEffect(() => {
    loadPositions(selectedFund, fxRates);
  }, [selectedFund, positionCurrency, fxRates]);

  useEffect(() => {
    loadOrders(selectedFund, historyStartDate, historyEndDate);
  }, [selectedFund, historyStartDate, historyEndDate]);

  useEffect(() => {
    loadOrders(selectedFund, orderStartDate, orderEndDate);
  }, [selectedFund, orderStartDate, orderEndDate]);

  useEffect(() => {
    loadTrades(selectedFund, tradeStartDate, tradeEndDate);
  }, [selectedFund, tradeStartDate, tradeEndDate]);

  const wsContext = useContext(WebSocketContext);

  useEffect(() => {
    if ("orderStatus" in orderEvent) {
      if (["FILLED", "PARTIALLY_FILLED"].includes(orderEvent?.orderStatus)) {
        loadPositions(selectedFund, fxRates);
        loadTrades(selectedFund, tradeStartDate, tradeEndDate);
      }
      loadOrders(selectedFund, orderStartDate, orderEndDate);
    }
  }, [orderEvent]);

  useEffect(() => {
    if (wsContext.connected && wsContext.ws) {

      Object.keys(wsContext.ws.subscriptions).forEach(function (k, idx) {
        if (k.includes("oe-all")) {
          wsContext.ws.unsubscribe(k);
        }
      });

      wsContext.ws.subscribe("/topic/order-events", function (message) {
        const body = JSON.parse(message.body);
        setOrderEvent(body);
      }, { id: "oe-all" });
    }
  }, [wsContext, dispatch]);

  const cancelOrderHandler = (
    id,
    selectedFund,
    orderStartDate,
    orderEndDate
  ) => {
    if (window.confirm(`Do you really want to cancel the order?`)) {
      dispatch(
        cancelOrder({
          id,
          onSuccess: () => {
            loadOrders(selectedFund, orderStartDate, orderEndDate);
          },
        })
      );
    }
  };

  const closeOrderHandler = (
    id,
    selectedFund,
    orderStartDate,
    orderEndDate
  ) => {
    if (window.confirm(`Do you really want to close the order?`)) {
      dispatch(
        closeOrder({
          id,
          onSuccess: () => {
            loadOrders(selectedFund, orderStartDate, orderEndDate);
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(loadAllVenues());
  }, [dispatch]);
  const { filteredItems: venues } = useSelector((state) => state.venues);

  return (
    <Suspense fallback={<Loader />}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={5}>
          <Item className={classes.fixedHeight} id="equity-watchlist-container">
            <EquityWatchlist />
          </Item>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Item className={classes.fixedHeight} style={{ 'overflow': "hidden" }}>
            <EquityOrderModal key={activeWatchlist?.data?.name} fund={selectedFund} venues={venues} />
          </Item>
        </Grid>
        <Grid item xs={12} md={12} lg={4}>
          <Item className={classes.fixedHeight}>
            <EquitySnapshot key={activeWatchlist?.data?.name} />
          </Item>
        </Grid>
      </Grid>
      <Box className={classes.tabBox}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Item style={{ padding: "1rem" }}>
              <Box className={classes.headerFlex}>
                <Typography variant="h6" align="left">
                  Trading Blotter
                </Typography>
                <Box className={classes.filterFlex}>
                  {tradeTab == 0 && (
                    <>
                      <CurrencyList
                        activeCurrency={positionCurrency ?? ""}
                        setActiveCurrency={(code) => {
                          setPositionCurrency(code);
                        }}
                      />
                      <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.dividerMargin}
                      />
                    </>
                  )}
                  <Select
                    displayEmpty
                    onChange={(e) => {
                      setSymbolFilter(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    value={symbolFilter}
                    className={classes.select}
                  >
                    <MenuItem value="">All Tickers</MenuItem>
                    {symbolFilters.map((symbol) => (
                      <MenuItem key={`symbol-${symbol}`} value={symbol}>
                        {symbol}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    displayEmpty
                    onChange={(e) => {
                      setExchangeFilter(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    value={exchangeFilter}
                    className={classes.select}
                  >
                    <MenuItem value="">All Exchanges</MenuItem>
                    {exchangeFilters.map((exchange) => (
                      <MenuItem key={`exchange-${exchange}`} value={exchange}>
                        {exchange}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    displayEmpty
                    onChange={(e) => {
                      setAccountFilter(e.target.value);
                    }}
                    input={<OutlinedInput />}
                    value={accountFilter}
                    className={classes.select}
                  >
                    <MenuItem value="">All Fund Accounts</MenuItem>
                    {accountFilters.map((account) => (
                      <MenuItem
                        key={`account-${account.id}`}
                        value={account.id}
                      >
                        {account.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {[1, 2, 3].includes(tradeTab) && (
                    <Select
                      displayEmpty
                      onChange={(e) => {
                        setStatusFilter(e.target.value);
                      }}
                      input={<OutlinedInput />}
                      value={statusFilter}
                      className={classes.select}
                    >
                      <MenuItem value="">All Status</MenuItem>
                      {statusFilters.map((status) => (
                        <MenuItem key={`status-${status}`} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {tradeTab == 1 && (
                    <DateRangePicker
                      startingDate={orderStartDate}
                      endingDate={orderEndDate}
                      onChange={(startDate, endDate) => {
                        setOrderStartDate(startDate);
                        setOrderEndDate(endDate);
                      }}
                    />
                  )}
                  {tradeTab == 2 && (
                    <DateRangePicker
                      startingDate={tradeStartDate}
                      endingDate={tradeEndDate}
                      onChange={(startDate, endDate) => {
                        setTradeStartDate(startDate);
                        setTradeEndDate(endDate);
                      }}
                    />
                  )}
                  {tradeTab == 3 && (
                    <DateRangePicker
                      startingDate={historyStartDate}
                      endingDate={historyEndDate}
                      onChange={(startDate, endDate) => {
                        setHistoryStartData(startDate);
                        setHistoryEndDate(endDate);
                      }}
                    />
                  )}
                </Box>
              </Box>
              <Divider />
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                marginTop={"0.5rem"}
              >
                <Typography
                  className={`${classes.tabs} ${tradeTab == 0 ? classes.activeTab : ""
                    }`}
                  variant="subtitle1"
                  onClick={() => setTradeTab(0)}
                >
                  Positions ({positionsData.length})
                </Typography>
                <Typography
                  className={`${classes.tabs} ${tradeTab == 1 ? classes.activeTab : ""
                    }`}
                  variant="subtitle1"
                  onClick={() => setTradeTab(1)}
                >
                  Orders ({ordersData.length})
                </Typography>
                <Typography
                  className={`${classes.tabs} ${tradeTab == 2 ? classes.activeTab : ""
                    }`}
                  variant="subtitle1"
                  onClick={() => setTradeTab(2)}
                >
                  Trades ({tradesData.length})
                </Typography>
                <Typography
                  className={`${classes.tabs} ${tradeTab == 3 ? classes.activeTab : ""
                    }`}
                  variant="subtitle1"
                  onClick={() => setTradeTab(3)}
                >
                  History ({historyData.length})
                </Typography>
              </Box>
              <Divider style={{ margin: "0.5rem 0" }} />
              {tradeTab == 0 && (
                <PositionTable
                  data={positionsData}
                  positionLoading={positionLoading}
                />
              )}
              {tradeTab == 1 && (
                <OrderTable
                  data={ordersData}
                  orderLoading={orderLoading}
                  error={orderError}
                  sortBy={orderSortBy}
                  detailPageBtn={true}
                  cancelOrderHandler={(id) =>
                    cancelOrderHandler(
                      id,
                      selectedFund,
                      orderStartDate,
                      orderEndDate
                    )
                  }
                  closeOrderHandler={(id) =>
                    closeOrderHandler(
                      id,
                      selectedFund,
                      orderStartDate,
                      orderEndDate
                    )
                  }
                />
              )}
              {tradeTab == 2 && (
                <TradeTable
                  data={tradesData}
                  tradeLoading={tradeLoading}
                  error={tradeError}
                  sortBy={tradeSortBy}
                />
              )}
              {tradeTab == 3 && (
                <HistoryTable
                  data={historyData}
                  historyLoading={orderLoading}
                  error={orderError}
                  sortBy={orderSortBy}
                  detailPageBtn={true}
                  cancelOrderHandler={(id) =>
                    cancelOrderHandler(
                      id,
                      selectedFund,
                      orderStartDate,
                      orderEndDate
                    )
                  }
                  closeOrderHandler={(id) =>
                    closeOrderHandler(
                      id,
                      selectedFund,
                      orderStartDate,
                      orderEndDate
                    )
                  }
                />
              )}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Suspense>
  );
};

export default Equity;
