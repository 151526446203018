import {
  Box, Checkbox, CircularProgress
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';

import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import LoginBgImage from "./assets/Images/login_page_background.png";
import {
  cancelUserPolicyData, loading as userPolicyLoading,
  loadUserPolicyData,
  updateUserPolicyData
} from "./store/actions/userPolicy.action";

const AcceptancePolicy = ({setAccModel}) => {
  const [showModal, setShowModal] = useState(true);
  const [agreeBtn, setAgreeBtn]   = useState(false);
  const { keycloak, initialized } = useKeycloak();
  const dispatch = useDispatch();

  const {
    data: userPolicy,
    loading: userPolicyLoader,
    error,
  } = useSelector((state) => state.userPolicy);

  useEffect(() => {
    if (initialized) {
      axios.interceptors.request.use((config) => {
        if (keycloak.authenticated) {
          config.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
        }
        return config;
      });
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    if (initialized && keycloak.authenticated) {
      dispatch(userPolicyLoading());
      dispatch(loadUserPolicyData());
    }
  }, [dispatch, initialized, keycloak]);
  
  useEffect(() => {
    // console.log(userPolicy)
    if(userPolicy?.uap_status !== undefined){
      setShowModal(!userPolicy?.uap_status??false)
      setAccModel(!userPolicy?.uap_status??false);
    }
    // on update call
    if(userPolicy?.update !== undefined){
      if(userPolicy?.update){
        setShowModal(false);
        setAccModel(false);
      }
      if(!userPolicy?.update){
        toast.error("Something went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          onClose: () => {
            // console.log("closing");
            keycloak.logout();
          },
        });
      }
    }
    // on cancel call
    if(userPolicy?.cancel !== undefined && userPolicy?.cancel){
      keycloak.logout();
    }
  },[userPolicy]);
  
  const sumbit = () => {
    // call api to set user accepted policy
    dispatch(updateUserPolicyData());
    // setShowModal(false);
    // setAccModel(false);
  };
  const cancel = () => {
    // logout
    dispatch(cancelUserPolicyData());
    // keycloak.logout();
  };

  
  const useStyles = makeStyles(() => ({
    backBtn: {
      backgroundColor: "#E04C38 !important",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#E04C38 !important",
      },
    },
    submitBtn: {
      backgroundColor: "#0B6FB4 !important",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#0B6FB4 !important",
      },
    },
    bg: {
      backgroundColor: "#C5C5C5",
      backgroundImage: `url(${LoginBgImage})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    loading:{
      margin: '0 30%'
    },
    liMarginLeft:{
      marginLeft: '25px'
    }
  }));
  
  const classes = useStyles();
  
  return (
    <>
    {
      userPolicyLoader? (
        <Box  className={classes.bg}>
          <div>
            <CircularProgress className={classes.loading} color="primary" />
            <Typography variant="h6">Loading, please wait...</Typography>
          </div>
        </Box>
      ) : error ? (
        <Box textAlign={"center"} p={2}>
          <Typography variant="h6" color="secondary">
            Something went wrong. Please try again later!
          </Typography>
        </Box>
      ) :(
      <Box  className={classes.bg}>
        <Dialog maxWidth={'md'} open={showModal} hideBackdrop={true}>
          <DialogTitle className="text-center">Terms and Conditions</DialogTitle>
          <DialogContent>
            <DialogContent dividers>
              
              <Typography gutterBottom>
                SecondSTAX has taken steps, including those below to ensure:
                <br />
                <ul>
                  <li className={`${classes.liMarginLeft}`}>The development team conforms to international best practice standards in team structure, code storage and versioning and coding techniques. </li>
                  <li className={`${classes.liMarginLeft}`}>Engaging the services of a qualified third-party cybersecurity firm to periodically perform vulnerability assessments of our system. </li>
                  <li className={`${classes.liMarginLeft}`}>Prioritizing the fixing of identified vulnerabilities in system development activities. </li>
                  <li className={`${classes.liMarginLeft}`}>Implementing robust account protection including two-factor authentication (2FA) for login and access to the system. </li>
                  <li className={`${classes.liMarginLeft}`}>Ensuring that our specialized authentication service generates the initial password which complies to the password policy stipulated with norms prescribed by the Exchanges we are integrated with. </li>
                  <li className={`${classes.liMarginLeft}`}>Protecting our data from potential attackers by implementing encryption and tokenization technologies. </li>
                  <li className={`${classes.liMarginLeft}`}>Preparing and regularly updating our cybersecurity awareness document (which highlights social engineering/phishing attacks) and sharing same with staff and clients. </li>

                </ul>
                <br />
                <Typography variant="h5">
                  1.	&nbsp;Cybersecurity 
                </Typography>
                The user shall be responsible for keeping the Username and Password confidential and secure and shall be solely responsible for all orders entered and transactions done on behalf of  his/her organization independent of whether or not such a person was authorized to do so. Also, Users are aware that authentication technologies and strict security measures are required for internet trading/securities trading using wireless or wired network access technology through this order management and routing system and undertake to ensure that any password and authentication information is not revealed to any third party including other staff of their organization. 
                User organizations and its representatives/designated staff shall immediately notify SecondSTAX (questions@secondstax.info) in writing if it: 
                <br />
                <ul>
                  <li className={`${classes.liMarginLeft}`}>
                    discovers a security flaw in SecondSTAX's system, 
                  </li>
                  <li className={`${classes.liMarginLeft}`}>
                    discovers/suspects discrepancies/unauthorized access through his/her username/password/account with full details of such unauthorized use, the date, the manner, and the transactions affected pursuant to such unauthorized use, etc. 
                  </li>
                </ul>
                <br />
              </Typography>
              <Typography variant="h5">
              2.	&nbsp;Fraud and Money Laundering 
              </Typography>
              <Typography gutterBottom>

                SecondSTAX AML and KYC policies are fundamental to combating money laundering schemes. We comply with each market’s KYC requirement and make those mandatory before we onboard users and accounts onto our system. Our clients (investment firms) perform these checks in strict adherence to current regulatory requirements. 
                <br />
                <ul>
                  <li className={`${classes.liMarginLeft}`}>Liveness Detection Technologies </li>
                  <li className={`${classes.liMarginLeft}`}>Document Checking (including passports and other IDs) to detect fake ID and forestall multiple usage of valid ID for separate accounts. </li>
                </ul>
                
                All Users are fully aware of and understand the risks associated with availing of a service for routing orders over the internet/securities trading through wireless or wired network access technology and shall be fully liable and responsible for any and all acts done with their username/password in any manner whatsoever. 

                Users and their organizations are responsible for following all Anti Money Laundering/Combating the Financing of Terrorism and the Financing of the Proliferation of Weapons of Mass Destruction (AML/CFT/PF) guidelines in country and all our clients will also have to pass all AML/CFT/PF requirements of the countries into which they are trading.   
                
              </Typography>
              <br />
              <Typography variant="h5">
                3.	&nbsp;Encryption and Data Integrity 
              </Typography>
              <Typography gutterBottom>
                SecondSTAX implements encryption and tokenization technologies to enforce safety of data in Transit and Storage. We employ best practice approaches to manage encryption keys. 
                We enforce data integrity and completeness by implementing checks in all user data entry screens and fields and alert the user where there is an incomplete entry. 
                
              </Typography>
              <br />
              <Typography variant="h5">
                4.	&nbsp;Compliance (Regulatory) 
              </Typography>
              <Typography gutterBottom>
                We work closely with regulators within the markets we have presence in or are looking to establish a presence in and ensure we have successfully completed and passed all regulatory requirements before launching and enabling trades for those markets. 
                
              </Typography>
              <br />
              <Typography variant="h5">
                5.	&nbsp;System Downtime 
              </Typography>
              <Typography gutterBottom>
                Users are aware that trading over the internet involves many uncertain factors and complex hardware, software, systems, communication lines, peripherals, etc. that are susceptible to interruptions and dislocations. SecondSTAX does not make any representation or warranty that its cloud-based service will be always available to users without any interruption. 
                User organizations or their representatives shall not have any claim against SecondSTAX on account of any suspension, interruption, non-availability or malfunctioning of its cloud-based service. 
              </Typography>

              <br /><br />
              <Typography variant="h6">
                <Checkbox
                  onChange={(e) => {
                    setAgreeBtn(e.target.checked);
                  }}
                  color="primary"
                />
              I have read and understood the Terms and Conditions outlined in this User Acceptance Policy document.
              </Typography>
            </DialogContent>

            

          </DialogContent>
          <DialogActions>
            <Button 
              onClick={sumbit}
              className={`${classes.submitBtn}`}
              disabled={!agreeBtn}
            >
              I agree
            </Button>
            <Button 
              onClick={cancel}
              className={`${classes.backBtn}`}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      )
    }
    </>
  );
};

export default AcceptancePolicy;
