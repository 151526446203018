import * as types from "../actions/types";

const INITIAL_FUND_BALANCE_SHEET = {
  loading: false,
  error: null,
  items: [],
};

export const fundBalanceReducer = (
  state = INITIAL_FUND_BALANCE_SHEET,
  action
) => {
  switch (action.type) {
    case types.constants.LOAD_FUND_BALANCE_SHEET_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case types.constants.LOADING_FUND_BALANCE_SHEET:
      return { ...state, loading: true };

    case types.constants.LOAD_FUND_BALANCE_SHEET_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
