import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncUsers = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users`
    );
    return data;
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const storeAsyncUser = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users`,
      payload
    );

    return {
      error: false,
      data: data.message ?? "User created successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncUser = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users/${payload}`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const updateAsyncUser = async (payload) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users/${payload.id}`,
      payload.data
    );

    return {
      error: false,
      data: data.message ?? "User updated successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const deleteAsyncUser = async ({ payload }) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/users/${payload}`
    );

    return data.message ?? "User deleted successfully.";
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const enableDisableAsyncUser = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users/${payload.id}/enable?status=${payload.enable}`
    );

    return (
      data.message ??
      `User is ${payload.enable ? "enabled" : "disabled"} successfully.`
    );
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const updateUserRoleAsync = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/api/v1/users/${payload.id}/map`,
      payload.data
    );

    return data.message ?? "Roles assignment updated successfully.";
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

export function* loadUsers() {
  try {
    const users = yield call(loadAsyncUsers);
    yield put({
      type: types.constants.GET_USER_SUCCESS,
      payload: users,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_USER_ERROR,
      payload: err.message,
    });
  }
}

export function* storeUser({ payload }) {
  const response = yield call(storeAsyncUser, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.STORE_USER_SUCCESS });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STORE_USER_ERROR,
      payload: response.data,
    });
  }
}

export function* loadUser(payload) {
  const response = yield call(loadAsyncUser, payload);
  if (!response.error) {
    yield put({
      type: types.constants.GET_USER_BY_ID_SUCCESS,
      user: response.data,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.GET_USER_BY_ID_ERROR,
      payload: response.data,
    });
  }
}

export function* updateUser({ payload }) {
  const response = yield call(updateAsyncUser, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({ type: types.constants.UPDATE_USER_SUCCESS });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    yield put({
      type: types.constants.UPDATE_USER_ERROR,
      payload: response.data,
    });
  }
}

export function* deleteUser(payload) {
  try {
    const message = yield call(deleteAsyncUser, payload);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_USER_SUCCESS,
      id: payload.payload,
    });
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_USER_ERROR,
      payload: err.message,
    });
  }
}

export function* enableDisableUser({ payload }) {
  try {
    const message = yield call(enableDisableAsyncUser, payload.data);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.ENABLE_DISABLE_USER_SUCCESS,
      payload: payload.data,
    });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.ENABLE_DISABLE_USER_ERROR,
      payload: err.message,
    });
  }
}

export function* updateUserRole({ payload }) {
  try {
    const message = yield call(updateUserRoleAsync, payload.data);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
}
