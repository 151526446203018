import { takeLatest } from "redux-saga/effects";
import * as types from "../actions/types";

import { userAccountData } from "./account.sagas";
import {
  createWatchListSaga, getWatchListSaga, getWebSocketListSaga, updateWatchListSaga
} from "./watchlist.sagas";
import { getUserWatchListBondSaga } from "./watchlistbond.sagas";

import { cancelPolicyUpdate, userPolicyData, userPolicyUpdate } from "./policy.sagas";

import {
  deleteBond,
  loadBond,
  loadBonds,
  storeBond,
  toggleBondStatus,
  updateBond
} from "./bond.saga";

import {
  deleteVenue, loadVenue, loadVenues,
  storeVenue, updateVenue
} from "./venue.saga";

import {
  deleteCurrency, loadCurrencies, loadCurrency, storeCurrency, updateCurrency
} from "./currency.saga";

import {
  deleteUser,
  enableDisableUser, loadUser, loadUsers,
  storeUser, updateUser, updateUserRole
} from "./users.saga";

import {
  deleteEquity,
  loadEquities,
  loadEquity,
  storeEquity,
  toggleEquityStatus,
  updateEquity
} from "./equity.saga";

import { loadRoles, storeRole } from "./role.saga";

import {
  deleteExchangeRate,
  loadExchangeRate,
  loadExchangeRates,
  storeExchangeRate,
  updateExchangeRate
} from "./exchangeRate.saga";

import {
  deleteClient,
  loadClient,
  loadClientFunds,
  loadClients,
  storeClient,
  updateClient
} from "./client.saga";

import {
  deleteFund,
  loadFund,
  loadFunds,
  storeFund,
  storeFundPosition,
  updateFund
} from "./fund.saga";

import {
  loadFundAccount2s,
  storeFundAccount2,
  toggleFundAccount2Status
} from "./fundAccount.saga";

import {
  cancelOrder,
  closeOrder, loadOrder, loadOrders, loadOrderTrades, storeOrder
} from "./order.saga";

import { loadPositions } from "./position.saga";

import { loadTrade, loadTrades, settleTrade } from "./trade.saga";

import { loadFundLedgers } from "./fundLedger.saga";

import { loadFundBalanceSheets } from "./fundBalanceSheet.saga";

import {
  deletePermission,
  loadPermissions,
  storePermission
} from "./permission.saga";

import {
  deleteCountry,
  loadCountries,
  loadCountry,
  storeCountry,
  updateCountry
} from "./country.saga";

import {
  deleteBroker,
  loadBroker,
  loadBrokers,
  storeBroker,
  toggleBrokerStatus,
  updateBroker
} from "./broker.saga";
import {
  loadExternalTransactions,
  statusChangeExternalTransactions
} from "./externalTransaction.saga";
import {
  loadHoliday,
  loadHolidays,
  storeHoliday,
  updateHoliday
} from "./holiday.saga";
import {
  loadSchedule,
  loadSchedules,
  storeSchedule,
  toggleScheduleStatus,
  updateSchedule
} from "./schedule.saga";

export function* watchUserAuthentication() {
  yield takeLatest(types.constants.GET_WATCH_LIST, getWatchListSaga);
  yield takeLatest(types.constants.CREATE_WATCH_LIST, createWatchListSaga);
  yield takeLatest(types.constants.UPDATE_WATCH_LIST, updateWatchListSaga);
  yield takeLatest(
    types.constants.GET_USER_WATCHLIST_BOND,
    getUserWatchListBondSaga
  );
  yield takeLatest(types.constants.WEB_SOCKET, getWebSocketListSaga);

  // Bonds
  yield takeLatest(types.constants.GET_BOND, loadBonds);
  yield takeLatest(types.constants.STORE_BOND, storeBond);
  yield takeLatest(types.constants.GET_BOND_BY_ID, loadBond);
  yield takeLatest(types.constants.UPDATE_BOND, updateBond);
  yield takeLatest(types.constants.DELETE_BOND, deleteBond);
  yield takeLatest(types.constants.TOGGLE_BOND_STATUS, toggleBondStatus);

  // Equities
  yield takeLatest(types.constants.GET_EQUITY, loadEquities);
  yield takeLatest(types.constants.STORE_EQUITY, storeEquity);
  yield takeLatest(types.constants.GET_EQUITY_BY_ID, loadEquity);
  yield takeLatest(types.constants.UPDATE_EQUITY, updateEquity);
  yield takeLatest(types.constants.DELETE_EQUITY, deleteEquity);
  yield takeLatest(types.constants.TOGGLE_EQUITY_STATUS, toggleEquityStatus);

  // Venues
  yield takeLatest(types.constants.GET_VENUE, loadVenues);
  yield takeLatest(types.constants.STORE_VENUE, storeVenue);
  yield takeLatest(types.constants.GET_VENUE_BY_ID, loadVenue);
  yield takeLatest(types.constants.UPDATE_VENUE, updateVenue);
  yield takeLatest(types.constants.DELETE_VENUE, deleteVenue);

  // Users
  yield takeLatest(types.constants.GET_USER, loadUsers);
  yield takeLatest(types.constants.STORE_USER, storeUser);
  yield takeLatest(types.constants.GET_USER_BY_ID, loadUser);
  yield takeLatest(types.constants.UPDATE_USER, updateUser);
  yield takeLatest(types.constants.DELETE_USER, deleteUser);
  yield takeLatest(types.constants.ENABLE_DISABLE_USER, enableDisableUser);
  yield takeLatest(types.constants.UPDATE_ROLE_USER, updateUserRole);

  // Currencies
  yield takeLatest(types.constants.GET_CURRENCY, loadCurrencies);
  yield takeLatest(types.constants.STORE_CURRENCY, storeCurrency);
  yield takeLatest(types.constants.GET_CURRENCY_BY_ID, loadCurrency);
  yield takeLatest(types.constants.UPDATE_CURRENCY, updateCurrency);
  yield takeLatest(types.constants.DELETE_CURRENCY, deleteCurrency);

  // Roles
  yield takeLatest(types.constants.LOAD_ROLE, loadRoles);
  yield takeLatest(types.constants.STORE_ROLE, storeRole);

  // Exchange rates
  yield takeLatest(types.constants.GET_EXCHANGE_RATES, loadExchangeRates);
  yield takeLatest(types.constants.STORE_EXCHANGE_RATE, storeExchangeRate);
  yield takeLatest(types.constants.GET_EXCHANGE_RATE_BY_ID, loadExchangeRate);
  yield takeLatest(types.constants.UPDATE_EXCHANGE_RATE, updateExchangeRate);
  yield takeLatest(types.constants.DELETE_EXCHANGE_RATE, deleteExchangeRate);

  // Client
  yield takeLatest(types.constants.GET_CLIENT, loadClients);
  yield takeLatest(types.constants.STORE_CLIENT, storeClient);
  yield takeLatest(types.constants.GET_CLIENT_BY_ID, loadClient);
  yield takeLatest(types.constants.UPDATE_CLIENT, updateClient);
  yield takeLatest(types.constants.DELETE_CLIENT, deleteClient);
  yield takeLatest(types.constants.GET_CLIENT_FUNDS, loadClientFunds);

  // Fund
  yield takeLatest(types.constants.GET_FUND, loadFunds);
  yield takeLatest(types.constants.STORE_FUND, storeFund);
  yield takeLatest(types.constants.GET_FUND_BY_ID, loadFund);
  yield takeLatest(types.constants.UPDATE_FUND, updateFund);
  yield takeLatest(types.constants.DELETE_FUND, deleteFund);

  // Fund Account
  yield takeLatest(types.constants.GET_FUND_ACCOUNT2, loadFundAccount2s);
  yield takeLatest(types.constants.STORE_FUND_ACCOUNT2, storeFundAccount2);
  yield takeLatest(types.constants.STORE_FUND_POSITIONS, storeFundPosition);
  yield takeLatest(
    types.constants.TOGGLE_FUND_ACCOUNT2_STATUS,
    toggleFundAccount2Status
  );

  // Fund balance sheets
  yield takeLatest(
    types.constants.LOAD_FUND_BALANCE_SHEET,
    loadFundBalanceSheets
  );

  // Fund ledgers
  yield takeLatest(types.constants.LOAD_FUND_LEDGER, loadFundLedgers);

  // Order
  yield takeLatest(types.constants.GET_ORDER, loadOrders);
  yield takeLatest(types.constants.STORE_ORDER, storeOrder);
  yield takeLatest(types.constants.GET_ORDER_BY_ID, loadOrder);
  yield takeLatest(types.constants.ORDER_TRADE, loadOrderTrades);
  yield takeLatest(types.constants.ORDER_CANCEL, cancelOrder);
  yield takeLatest(types.constants.ORDER_CLOSE, closeOrder);

  // Position
  yield takeLatest(types.constants.GET_POSITION, loadPositions);

  // Trade
  yield takeLatest(types.constants.GET_TRADE, loadTrades);
  yield takeLatest(types.constants.GET_TRADE_BY_ID, loadTrade);
  yield takeLatest(types.constants.SETTLE_TRADE, settleTrade);

  // Permission
  yield takeLatest(types.constants.GET_PERMISSION, loadPermissions);
  yield takeLatest(types.constants.STORE_PERMISSION, storePermission);
  yield takeLatest(types.constants.DELETE_PERMISSION, deletePermission);

  // Country
  yield takeLatest(types.constants.GET_COUNTRY, loadCountries);
  yield takeLatest(types.constants.STORE_COUNTRY, storeCountry);
  yield takeLatest(types.constants.GET_COUNTRY_BY_ID, loadCountry);
  yield takeLatest(types.constants.UPDATE_COUNTRY, updateCountry);
  yield takeLatest(types.constants.DELETE_COUNTRY, deleteCountry);

  // Broker
  yield takeLatest(types.constants.GET_BROKER, loadBrokers);
  yield takeLatest(types.constants.STORE_BROKER, storeBroker);
  yield takeLatest(types.constants.GET_BROKER_BY_ID, loadBroker);
  yield takeLatest(types.constants.UPDATE_BROKER, updateBroker);
  yield takeLatest(types.constants.DELETE_BROKER, deleteBroker);
  yield takeLatest(types.constants.TOGGLE_BROKER_STATUS, toggleBrokerStatus);

  // User account data
  yield takeLatest(types.constants.USER_ACCOUNT_DATA, userAccountData);

  // User policy data
  yield takeLatest(types.constants.USER_POLICY_DATA, userPolicyData);
  yield takeLatest(types.constants.USER_POLICY_UPDATE, userPolicyUpdate);
  yield takeLatest(types.constants.USER_POLICY_CANCEL, cancelPolicyUpdate);

  

  // Holiday
  yield takeLatest(types.constants.GET_HOLIDAY, loadHolidays);
  yield takeLatest(types.constants.STORE_HOLIDAY, storeHoliday);
  yield takeLatest(types.constants.GET_HOLIDAY_BY_ID, loadHoliday);
  yield takeLatest(types.constants.UPDATE_HOLIDAY, updateHoliday);

  // Cash positions
  yield takeLatest(
    types.constants.GET_EXTERNAL_TRANSACTIONS,
    loadExternalTransactions
  );
  yield takeLatest(
    types.constants.STATUS_CHANGE_EXTERNAL_TRANSACTIONS,
    statusChangeExternalTransactions
  );

  // Schedule
  yield takeLatest(types.constants.GET_SCHEDULE, loadSchedules);
  yield takeLatest(types.constants.STORE_SCHEDULE, storeSchedule);
  yield takeLatest(types.constants.GET_SCHEDULE_BY_ID, loadSchedule);
  yield takeLatest(types.constants.UPDATE_SCHEDULE, updateSchedule);
  yield takeLatest(
    types.constants.TOGGLE_SCHEDULE_STATUS,
    toggleScheduleStatus
  );
}
