import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import * as fixedIncome from "../../../store/actions/fixedIncome.action";
import { toast } from "react-toastify";
import * as watchlist from "../../../store/actions/watchlist.actions";

import CurrencyFormat from "react-currency-format";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  Typography,
  Grid,
  TextField,
  OutlinedInput,
  Select,
  MenuItem,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import NumericFormatInput from "../../../components/NumericFormatInput";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#252525",
      color: "#dedede",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
    },
    "& .MuiDialogContent-root": {
      color: "#dedede !important",
    },
  },
  dialogActions: {
    margin: "0 auto",
    marginTop: "2rem",
    "& > *:first-child": {
      marginRight: "0.5rem",
    },
  },
  actionBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#145582",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#145582",
    },
    "&:disabled": {
      backgroundColor: "#083a5e",
      color: "#888888",
    },
  },
  reviewBadge: {
    backgroundColor: "#22312c",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.7rem 1.5rem",
    borderRadius: "10px",
    minWidth: "150px",
  },
  select: {
    borderRadius: "4px",
    color: "white",
    minWidth: "100%",
    minHeight: "3rem",
    backgroundColor: "transparent",
    padding: "0 0.5rem",
    "& > option": {
      backgroundColor: "#424242 !important",
      color: "white !important",
    },
  },
  input: {
    borderRadius: "4px",
    color: "white",
    minWidth: "100%",
    minHeight: "3rem",
    backgroundColor: "transparent",
    padding: "0 0.5rem",
    border: "1px solid rgba(255, 255, 255, 0.3)",
  },
}));

const OrderFormModal = (props) => {
  const classes = useStyles();

  const [settlementDayCount, setSettlementDayCount] = useState(0);
  const dispatch = useDispatch();
  const watchlistData = useSelector((state) => state?.watchlist);

  useEffect(() => {
    if (watchlistData?.exchangelist?.response?.settlement_day_count) {
      setSettlementDayCount(
        watchlistData?.exchangelist?.response?.settlement_day_count
      );
    }
  }, [
    watchlistData,
    watchlistData?.exchangelist?.response?.settlement_day_count,
  ]);

  const initialValues = {
    bond_id: "",
    bond: "",
    isin: "",
    maturity_date: "",
    settlement_date: "",
    side: "Buy",
    allOrNothing: "Yes",
    nominal: "",
    coupon_rate: "",
    yield: "",
    price: "",
    consideration: "",
    bondDirtyPrice: "",
    qty: "",
    csd_cost: "",
    sendTo: "ALL",
    ccy: "",
    clean_price: "",
    issued_date: "",
    settlement_string: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      bond_id: Yup.string().required("This field is required."),
      bond: Yup.string(),
      price: Yup.number()
        .positive("price must be a positive number and not zero")
        .required("This field is required."),
      isin: Yup.string(),
      yield: Yup.number()
        .positive("yield must be a positive number and not zero")
        .required("This field is required."),
      maturity_date: Yup.string(),
      consideration: Yup.string(),
      settlement_date: Yup.string().required("This field is required."),
      csd_cost: Yup.string(),
      nominal: Yup.number()
        .positive("nominal must be a positive number and not zero")
        .required("This field is required."),
      side: Yup.string().required("This field is required."),
      coupon_rate: Yup.string().required("This field is required."),
      allOrNothing: Yup.string().required("This field is required."),
      qty: Yup.number(),
      sendTo: Yup.string(),
      ccy: Yup.string(),
      bondDirtyPrice: Yup.string(),
      clean_price: Yup.string(),
      issued_date: Yup.string(),
      settlement_string: Yup.string(),
    }),
    onSubmit: (e) => {},
  });

  useEffect(() => {
    if (props.show && props?.bond) {
      getExchanges(props?.bond);
      formik.setFieldValue("bond_id", props?.bond?.bond_id);
      formik.setFieldValue("bond", props?.bond?.bond);
      formik.setFieldValue("isin", props?.bond?.isin);
      formik.setFieldValue(
        "maturity_date",
        moment(props?.bond?.maturity_date)?.format("DD/MMM/YYYY")
      );
      formik.setFieldValue("side", props?.bond?.side);
      formik.setFieldValue("nominal", props?.bond?.nominal);
      formik.setFieldValue("allOrNothing", props?.bond?.allOrNothing);
      formik.setFieldValue("coupon_rate", props?.bond?.coupon_rate);
      formik.setFieldValue("ccy", props?.bond?.ccy_suffix);
      formik.setFieldValue("yield", props?.bond?.yield);
      formik.setFieldValue("price", props?.bond?.amount);
      formik.setFieldValue("consideration", props?.bond?.consideration);
      formik.setFieldValue("qty", props?.bond?.qty);
      formik.setFieldValue("csd_cost", props?.bond?.csd_cost);
      formik.setFieldValue(
        "issued_date",
        moment(props?.bond?.issued_date)?.format("DD/MMM/YYYY")
      );
      onchangedate(moment(props?.bond?.settlement_date).toDate());
      if (
        props?.bond?.isin &&
        props?.bond?.maturity_date &&
        props?.bond?.coupon_rate &&
        props?.bond?.ccy_suffix &&
        props?.bond?.yield &&
        props?.bond?.issued_date
      ) {
        let body = {
          name: props?.bond?.isin,
          yield: props?.bond?.yield,
          ccy: props?.bond?.ccy_suffix,
          coupon: props?.bond?.coupon_rate,
          maturity: props?.bond?.maturity_date,
          effectiveDate: props?.bond?.issued_date,
        };
      } else {
        formik.setFieldValue("yield", "");
      }
    }
  }, [props.show]);

  const getExchanges = (data) => {
    dispatch(watchlist.getExchange({ exchangeId: data?.exchange }));
  };

  const handleClose = () => {
    props.onHide();
    setSettlementDayCount(0);
    formik.resetForm({
      values: initialValues,
    });
  };

  const onPriceChange = (e) => {
    e.preventDefault();
    if (
      formik?.values["isin"] &&
      formik?.values["maturity_date"] &&
      formik?.values["coupon_rate"] &&
      formik?.values["ccy"] &&
      formik?.values["issued_date"]
    ) {
      formik.setFieldValue("price", e?.target?.value?.replace(/,/g, ""));
      if (
        e?.target?.value?.replace(/,/g, "") &&
        e?.target?.value?.replace(/,/g, "") > 0
      ) {
        let body = {
          name: formik?.values["isin"],
          price: e.target.value.replace(/,/g, ""),
          ccy: formik?.values["ccy"],
          coupon: formik?.values["coupon_rate"],
          maturity: formik?.values["maturity_date"],
          effectiveDate: formik?.values["issued_date"],
        };
      } else {
        formik.setFieldValue("yield", "");
        formik.setFieldValue("csd_cost", "");
        formik.setFieldValue("consideration", "");
        formik.setFieldValue("bondDirtyPrice", "");
        formik.setFieldValue("clean_price", "");
        formik.setFieldValue("qty", "");
      }
    } else {
      formik.setFieldValue("price", "");
    }
  };

  const onYieldPriceChange = (e) => {
    e.preventDefault();
    if (
      formik?.values["isin"] &&
      formik?.values["maturity_date"] &&
      formik?.values["coupon_rate"] &&
      formik?.values["ccy"] &&
      formik?.values["issued_date"]
    ) {
      formik.setFieldValue("yield", e?.target?.value);
      if (e?.target?.value && e?.target?.value > 0) {
        let body = {
          name: formik?.values["isin"],
          yield: e.target.value,
          ccy: formik?.values["ccy"],
          coupon: formik?.values["coupon_rate"],
          maturity: formik?.values["maturity_date"],
          effectiveDate: formik?.values["issued_date"],
        };
      } else {
        formik.setFieldValue("price", "");
        formik.setFieldValue("csd_cost", "");
        formik.setFieldValue("consideration", "");
        formik.setFieldValue("bondDirtyPrice", "");
        formik.setFieldValue("clean_price", "");
        formik.setFieldValue("qty", "");
      }
    } else {
      formik.setFieldValue("yield", "");
    }
  };

  const onConsiderationChange = (e) => {
    e.preventDefault();
    if (
      formik?.values["isin"] &&
      formik?.values["maturity_date"] &&
      formik?.values["coupon_rate"] &&
      formik?.values["ccy"]
    ) {
      if (e?.target?.value) {
        formik.setFieldValue("consideration", e?.target?.value);
        formik.setFieldValue(
          "csd_cost",
          parseFloat(
            (parseFloat(
              watchlistData?.exchangelist?.response?.csd_cost_percentage
            ) /
              100) *
              parseFloat(e?.target?.value)
          )?.toFixed(2)
        );
      } else {
        formik.setFieldValue("consideration", "");
        formik.setFieldValue("csd_cost", "");
      }
    } else {
      toast.error(`Please select bond`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const getConsideration = (consideration, quantity) => {
    setTimeout(() => {
      if (!consideration && !quantity) {
        formik.setFieldValue("consideration", "");
        formik.setFieldValue("csd_cost", "");
      }
    }, 0);
  };

  const getQuantity = (nominal, price) => {
    setTimeout(() => {
      formik.setFieldValue("nominal", nominal?.replace(/,/g, ""));
      if (nominal && price) {
        formik.setFieldValue(
          "qty",
          Math.trunc(nominal?.replace(/,/g, "") / price)
        );
        getConsideration(
          formik?.values["bondDirtyPrice"],
          Math.trunc(nominal?.replace(/,/g, "") / price)
        );
      } else {
        formik.setFieldValue("qty", "");
      }
    }, 0);
  };

  const isWeekday = (date) => {
    return [0, 6].includes(date.day());
  };

  const onchangedate = (date) => {
    var moment1 = moment(moment().toDate(), "MM/D/YYYY");
    var moment2 = moment(date, "MM/D/YYYY");
    // console.log(moment2);
    var result = 0;
    if (moment1.isSame(moment2, "date")) {
      result = 0;
    } else {
      const days = moment2.diff(moment1, "days") + 1;

      let newDay = moment1.toDate(),
        workingDays = 0;
      for (let i = 0; i < days; i++) {
        const day = newDay.getDay();
        newDay = moment1.add(1, "days").toDate();
        const isWeekend = day % 6 === 0;
        if (!isWeekend) {
          workingDays++;
        }
      }
      result = workingDays;
    }
    formik.setFieldValue("settlement_string", "T+" + result);
    formik.setFieldValue("settlement_date", date);
  };

  const onChangeSettlement = (data) => {
    var strreplce = data?.replace("T+", "");
    var addday = 0;
    let workingDays = 0;
    var moment1 = moment(moment().toDate(), "MM/D/YYYY");
    let newDay = moment1.toDate();
    do {
      const day = newDay.getDay();
      const isWeekend = day % 6 === 0;
      if (!isWeekend) {
        workingDays++;
        // console.log(workingDays);
      }
      newDay = moment1.add(1, "days").toDate();
      addday++;
    } while (workingDays < parseInt(strreplce) + 1);
    formik.setFieldValue(
      "settlement_date",
      moment()
        .add(parseInt(addday) - 1, "days")
        .toDate()
    );
    formik.setFieldValue("settlement_string", data);
  };

  const getMaxDate = (count) => {
    var addday = 0;
    let workingDays = 0;
    var moment1 = moment(moment().toDate(), "MM/D/YYYY");
    let newDay = moment1.toDate();
    do {
      const day = newDay.getDay();
      newDay = moment1.add(1, "days").toDate();
      const isWeekend = day % 6 === 0;
      if (!isWeekend) {
        workingDays++;
      }
      addday++;
    } while (workingDays < parseInt(count) + (count ? 1 : 0));
    return moment()
      .add(addday - (count ? 1 : 0), "days")
      .toDate();
  };

  const formatDate = (date, field, format = "YYYY/MM/DD HH:mm:00") => {
    formik.setFieldValue(field, moment(date).format(format));
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      className={classes.dialog}
      disableBackdropClick
    >
      <DialogTitle>MODIFY ORDER</DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["bond_id"] && formik.touched["bond_id"]}
                label="Bond ID"
                name="bond_id"
                helperText={
                  formik.touched["bond_id"] && formik.errors["bond_id"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["bond_id"]}
                fullWidth={true}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["isin"] && formik.touched["isin"]}
                label="ISIN"
                name="isin"
                helperText={formik.touched["isin"] && formik.errors["isin"]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["isin"]}
                fullWidth={true}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                name={"maturity_date"}
                value={formik.values["maturity_date"]}
                onChange={(data) =>
                  formatDate(data, "maturity_date", "YYYY-MM-DD")
                }
                inputVariant="outlined"
                label={"Maturity date"}
                showTodayButton={false}
                clearable
                format="DD/MM/YYYY"
                error={
                  formik.errors["maturity_date"] &&
                  formik.touched["maturity_date"]
                }
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Select
                name="settlement_date"
                displayEmpty
                onChange={(e) => onChangeSettlement(e?.target?.value)}
                value={formik.values["settlement_string"]}
                input={<OutlinedInput />}
                className={classes.select}
              >
                {settlementDayCount > 0 &&
                  Array.from(
                    Array(parseInt(settlementDayCount) + 1),
                    (e, i) => {
                      return (
                        <MenuItem key={i} value={`T+${i}`}>
                          T+{i}
                        </MenuItem>
                      );
                    }
                  )}
              </Select>
            </Grid>
            <Grid item xs={6}>
              <Select
                name="side"
                displayEmpty
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["side"]}
                disabled
                input={<OutlinedInput />}
                label="Action"
                className={classes.select}
                fullWidth={true}
              >
                <MenuItem value="Buy">Buy</MenuItem>
                <MenuItem value="Sell">Sell</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                name={"settlement_date"}
                value={formik.values["settlement_date"]}
                onChange={(date) => onchangedate(date)}
                inputVariant="outlined"
                label={"Settlement date"}
                showTodayButton={false}
                clearable
                format="dd-MMM-yyyy"
                error={
                  formik.errors["settlement_date"] &&
                  formik.touched["settlement_date"]
                }
                shouldDisableDate={isWeekday}
                maxDate={getMaxDate(settlementDayCount)}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                Nominal
              </Typography>
              <CurrencyFormat
                thousandSeparator={true}
                name="nominal"
                onChange={(e) =>
                  getQuantity(e?.target?.value, formik?.values["price"])
                }
                onBlur={formik.handleBlur}
                value={formik.values["nominal"]}
                className={classes.input}
              />
              {formik.touched["nominal"] && formik.errors["nominal"] ? (
                <Typography component="div" color="secondary">
                  {formik.errors["nominal"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Select
                name="allOrNothing"
                displayEmpty
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["allOrNothing"]}
                disabled
                input={<OutlinedInput />}
                label="All or nothing"
                className={classes.select}
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                error={formik.errors["yield"] && formik.touched["yield"]}
                label="Yield (%)"
                name="yield"
                helperText={formik.touched["yield"] && formik.errors["yield"]}
                onBlur={formik.handleBlur}
                value={formik.values["yield"]}
                onChange={(e) => onYieldPriceChange(e)}
                fullWidth={true}
                disabled
                InputProps={{
                  inputComponent: NumericFormatInput,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["coupon_rate"] && formik.touched["coupon_rate"]
                }
                label="Coupon (%)"
                name="coupon_rate"
                helperText={
                  formik.touched["coupon_rate"] && formik.errors["coupon_rate"]
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values["coupon_rate"]}
                fullWidth={true}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                Price
              </Typography>
              <CurrencyFormat
                name="price"
                onChange={(e) => onPriceChange(e)}
                onBlur={formik.handleBlur}
                thousandSeparator={true}
                value={formik.values["price"]}
                className={classes.input}
              />
              {formik.touched["price"] && formik.errors["price"] ? (
                <Typography component="div" color="secondary">
                  {formik.errors["price"]}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                Consideration
              </Typography>
              <input
                type="hidden"
                name="bondDirtyPrice"
                onChange={formik.handleChange}
                value={formik.values["bondDirtyPrice"]}
              />
              <CurrencyFormat
                name="consideration"
                onChange={(e) => onConsiderationChange(e)}
                onBlur={formik.handleBlur}
                disabled
                thousandSeparator={true}
                className={classes.input}
                value={
                  formik?.values["bondDirtyPrice"] &&
                  formik?.values["qty"] &&
                  (
                    parseFloat(formik?.values["bondDirtyPrice"])?.toFixed(2) *
                    parseFloat(formik?.values["qty"])
                  )?.toFixed(2)
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                Quantity
              </Typography>
              <CurrencyFormat
                name="qty"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
                thousandSeparator={true}
                value={formik.values["qty"]}
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" component="label">
                CSD Cost
              </Typography>
              <CurrencyFormat
                name="csd_cost"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled
                thousandSeparator={true}
                className={classes.input}
                value={
                  formik?.values["bondDirtyPrice"] &&
                  formik?.values["qty"] &&
                  (
                    (parseFloat(
                      watchlistData?.exchangelist?.response?.csd_cost_percentage
                    ) /
                      100) *
                    (parseFloat(formik?.values["bondDirtyPrice"])?.toFixed(2) *
                      parseFloat(formik?.values["qty"]))
                  )?.toFixed(2)
                }
              />
            </Grid>
          </Grid>
          <div className={classes.dialogActions}>
            <Button
              variant="contained"
              type="submit"
              className={classes.actionBtn}
              size="small"
            >
              Modify order
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(OrderFormModal);
