import * as types from "../actions/types";
import { sortByAsc, sortByDesc } from "../../helpers/utilities";

const INITIAL_FUND = {
  loading: false,
  error: null,
  items: [],
  fund: null,
  filteredItems: [],
  sortBy: {},
};

export const fundReducer = (state = INITIAL_FUND, action) => {
  switch (action.type) {
    case types.constants.SORT_FUND:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_FUND:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter((item) =>
        item.name.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_FUND_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        error: null,
      };

    case types.constants.LOADING_FUND:
      return { ...state, loading: true };

    case types.constants.GET_FUND_BY_ID_SUCCESS:
      return { ...state, loading: false, fund: action.fund };

    case types.constants.STORE_FUND_SUCCESS:
    case types.constants.UPDATE_FUND_SUCCESS:
      return { ...state, loading: false, fund: null };

    case types.constants.DELETE_FUND_SUCCESS:
      const fundIndex = state.items.findIndex((item) => item.id === action.id);

      let newState = { ...state };

      if (fundIndex >= 0) {
        newState.items.splice(fundIndex, 1);
      }

      newState.loading = false;

      return newState;

    case types.constants.GET_FUND_ERROR:
    case types.constants.STORE_FUND_ERROR:
    case types.constants.UPDATE_FUND_ERROR:
    case types.constants.DELETE_FUND_ERROR:
    case types.constants.GET_FUND_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
