import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import Loader from "../../../components/Loader";
import { randomColor } from "../../../helpers/utilities";

const PositionVenuePieChart = ({ positions, loader }) => {
  const [labels, setLabels] = useState([]);
  const [data, setData] = useState([]);
  const [backgroundColors, setBackgroundColors] = useState([]);

  useEffect(() => {
    setLabels(
      Array.from(new Set(positions.map((position) => position.origVenue)))
    );

    let sectors = {};

    positions.forEach((position) => {
      if (!sectors[position.origVenue]) {
        sectors[position.origVenue] = 0;
      }
      sectors[position.origVenue] += position?.marketValue
        ? position?.marketValue
        : 0;
    });

    setData(Object.values(sectors));

    let colors = [];

    for (let i = 0; i < Object.values(sectors).length; i++) {
      colors.push(randomColor());
    }

    setBackgroundColors(colors);
  }, [positions]);

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        align: "start",
        fullWidth: true,
        reverse: false,
        labels: {
          boxWidth: 12,
          fontSize: 14,
          fontColor: "#808080",
          fill: true,
        },
      },
    },
  };

  const MKTVAL = () => {
    return (
      <Box
        p={2}
        style={{
          height: `${loader || data.length == 0 ? "auto" : "500px"}`,
          width: "500px",
          margin: "0 auto",
        }}
      >
        {loader ? (
          <Loader text="Loading data..." />
        ) : data.length > 0 ? (
          <Pie
            data={{
              labels: [...labels],
              datasets: [
                {
                  label: "Market value by venue",
                  data: [...data],
                  backgroundColor: [...backgroundColors],
                  hoverOffset: 4,
                },
              ],
            }}
            options={doughnutOptions}
          />
        ) : (
          <Box height={"100%"}>
            <Typography variant="subtitle2" align="center">
              No data
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return <MKTVAL />;
};

export default React.memo(PositionVenuePieChart);
