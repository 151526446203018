import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncExternalTransactions = async (payload) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/group-accounts/ex-transactions/${payload.fund}/${payload.startDate}/${payload.endDate}`
    );
    return data;
  } catch (error) {
    throw new Error(
      error?.response?.status ?? 0 >= 500
        ? "Something went wrong! Please try again."
        : error?.data?.message ?? "Error"
    );
  }
};

const statusChangeAsyncExternalTransactions = async ({ payload }) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/group-accounts/ex-transactions/${payload.id}/${payload.type}`
    );

    return {
      error: false,
      data:
        data.message ??
        `External transactions ${payload.type
          .toString()
          .toLowerCase()}ed successfully.`,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

export function* loadExternalTransactions({ payload }) {
  try {
    const externalTransactions = yield call(
      loadAsyncExternalTransactions,
      payload
    );

    yield put({
      type: types.constants.GET_EXTERNAL_TRANSACTIONS_SUCCESS,
      payload: externalTransactions,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_EXTERNAL_TRANSACTIONS_ERROR,
      payload: err.message,
    });
  }
}

export function* statusChangeExternalTransactions(payload) {
  const response = yield call(statusChangeAsyncExternalTransactions, payload);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.STATUS_CHANGE_EXTERNAL_TRANSACTIONS_SUCCESS,
    });
    if (
      payload.payload.onSuccess &&
      typeof payload.payload.onSuccess === "function"
    ) {
      payload.payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STATUS_CHANGE_EXTERNAL_TRANSACTIONS_ERROR,
    });
  }
}
