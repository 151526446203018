import * as types from "../actions/types";

const INITIAL_PERMISSION = {
  loading: false,
  error: null,
  items: [],
};

export const permissionReducer = (state = INITIAL_PERMISSION, action) => {
  switch (action.type) {
    case types.constants.GET_PERMISSION_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        error: null,
      };

    case types.constants.LOADING_PERMISSION:
      return { ...state, loading: true };

    case types.constants.DELETE_PERMISSION_SUCCESS:
      const permissionIndex = state.items.findIndex(
        (item) =>
          item.entityId === action.entityId &&
          item.entityType === action.entityType &&
          item.userId === action.userId
      );

      let newState = { ...state };

      if (permissionIndex >= 0) {
        newState.items.splice(permissionIndex, 1);
      }

      newState.loading = false;

      return newState;

    case types.constants.GET_PERMISSION_ERROR:
      return { ...state, loading: false, error: action.payload };

    case types.constants.STORE_PERMISSION_ERROR:
    case types.constants.DELETE_PERMISSION_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
