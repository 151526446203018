import React, { useMemo } from "react";
import DataTable from "react-data-table-component";

const QuoteTable = () => {
  const columns = useMemo(
    () => [
      {
        name: "Order ID",
        selector: "recordID",
        sortable: false,
        minWidth: "165px",
        cell: (row) => (row?.recordID ? row?.recordID : "-"),
        compact: true,
      },
      {
        name: "Bond ID",
        selector: "bond_id",
        sortable: false,
        right: false,
        minWidth: "170px",
        cell: (row) => (row?.bond_id ? row?.bond_id : "-"),
        compact: true,
      },
      {
        name: "ISIN",
        selector: "isin",
        sortable: false,
        right: false,
        cell: (row) => (row?.isin ? row?.isin : "-"),
        compact: true,
        minWidth: "100px",
      },
      {
        name: "Exchange",
        selector: "exchange_name",
        sortable: false,
        center: true,
        minWidth: "70px",
        cell: (row) => (row?.exchange_name ? row?.exchange_name : "-"),
        compact: true,
      },
      {
        name: "",
        selector: "allOrNothing",
        sortable: false,
        center: true,
        minWidth: "120px",
        cell: (row) => (row?.allOrNothing ? row?.allOrNothing : "-"),
        compact: true,
      },
      {
        name: "",
        selector: "allOrNothing",
        sortable: false,
        center: true,
        minWidth: "120px",
        cell: (row) => (row?.allOrNothing ? row?.allOrNothing : "-"),
        compact: true,
      },
      {
        name: "",
        selector: "allOrNothing",
        sortable: false,
        center: true,
        minWidth: "120px",
        cell: (row) => (row?.allOrNothing ? row?.allOrNothing : "-"),
        compact: true,
      },
      {
        name: "",
        selector: "allOrNothing",
        sortable: false,
        center: true,
        minWidth: "120px",
        cell: (row) => (row?.allOrNothing ? row?.allOrNothing : "-"),
        compact: true,
      },
    ],
    []
  );

  return (
    <>
      <DataTable
        columns={columns}
        data={[]}
        theme={"dark"}
        highlightOnHover
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        persistTableHead={true}
        noHeader={true}
      />
    </>
  );
};

export default React.memo(QuoteTable);
