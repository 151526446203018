import React from "react";
import Watchlist from "./Watchlist/Watchlist";
import TradingBlotter from "./TradingBlotter/TradingBlotter";
import MarketOrder from "./MarketOrder/MarketOrder";
import { Grid, styled, Paper } from "@material-ui/core";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#252525",
}));

const FixedIncome = () => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={4}>
        <Item>
          <Watchlist />
        </Item>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <Item>
          <MarketOrder />
        </Item>
      </Grid>
      <Grid item xs={12}>
        <Item>
          <TradingBlotter />
        </Item>
      </Grid>
    </Grid>
  );
};

export default React.memo(FixedIncome);
