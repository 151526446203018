import * as types from "../actions/types";
export function fixedIncomeReducer(state = {}, action) {
  switch (action.type) {
    case types.constants.GET_FI_MARKET_ORDER_SUCCESS:
      return {
        ...state,
        marketorderdata: action?.marketorder,
      };
    case types.constants.GET_FI_MARKET_ORDER_ERROR:
      return {
        ...state,
        marketorderdataerror: "Error",
      };
    case types.constants.CREATE_FI_MARKET_ORDER_SUCCESS:
      if (action?.createmarketorder?.response)
        state?.marketorderdata?.response?.set?.unshift(
          action?.createmarketorder?.response
        );
      return {
        ...state,
        createmarketorderata: action?.createmarketorder,
      };
    case types.constants.CREATE_FI_MARKET_ORDER_ERROR:
      return {
        ...state,
        createmarketorderdataerror: "Error",
      };
    case types.constants.UPDATE_FI_MARKET_ORDER_SUCCESS:
      return {
        ...state,
        updatemarketorderdata: action?.updatemarketorder,
      };
    case types.constants.UPDATE_FI_MARKET_ORDER_ERROR:
      return {
        ...state,
        updatemarketorderdataerror: "Error",
      };
    case types.constants.CREATE_FI_REQUEST_QUOTE_SUCCESS:
      // if(action?.createrequestquoteorder?.response)
      // state?.marketorderdata?.response?.set?.push(action?.createrequestquoteorder?.response);
      return {
        ...state,
        createrequestquoteorderdata: action?.createrequestquoteorder,
      };
    case types.constants.CREATE_FI_REQUEST_QUOTE_ERROR:
      return {
        ...state,
        createrequestquoteorderdataerror: "Error",
      };
    case types.constants.GET_FI_ALL_ORDER_SUCCESS:
      return {
        ...state,
        allorderdata: action?.allorder,
      };
    case types.constants.GET_FI_ALL_ORDER_ERROR:
      return {
        ...state,
      };
    case types.constants.GET_FI_ALL_OFFER_SUCCESS:
      return {
        ...state,
        allofferdata: action?.alloffer,
      };
    case types.constants.GET_FI_ALL_OFFER_ERROR:
      return {
        ...state,
      };
    case types.constants.GET_FI_OUTGOING_ORDER_SUCCESS:
      return {
        ...state,
        outgoingorderdata: action?.outgoingorder,
      };
    case types.constants.GET_FI_OUTGOING_ORDER_ERROR:
      return {
        ...state,
        outgoingorderdataerror: "Error",
      };
    case types.constants.GET_FI_OUTGOING_QUOTES_ORDER_SUCCESS:
      return {
        ...state,
        outgoingquotesorderdata: action?.outgoingquotesorder,
      };
    case types.constants.GET_FI_OUTGOING_QUOTES_ORDER_ERROR:
      return {
        ...state,
        outgoingquotesorderdataerror: "Error",
      };
    case types.constants.GET_FI_OUTGOING_OFFER_ORDER_SUCCESS:
      return {
        ...state,
        outgoingofferorderdata: action?.outgoingofferorder,
      };
    case types.constants.GET_FI_OUTGOING_OFFER_ORDER_ERROR:
      return {
        ...state,
        outgoingofferorderdataerror: "Error",
      };
    case types.constants.CREATE_FI_OFFER_ORDER_SUCCESS:
      return {
        ...state,
        createofferorderdata: action?.createofferorder,
      };
    case types.constants.CREATE_FI_OFFER_ORDER_ERROR:
      return {
        ...state,
        createofferorderdataerror: "Error",
      };
    case types.constants.UPDATE_FI_OFFER_ORDER_SUCCESS:
      return {
        ...state,
        updateofferorderdata: action?.updateofferorder,
      };
    case types.constants.UPDATE_FI_OFFER_ORDER_ERROR:
      return {
        ...state,
        updateofferorderdataerror: "Error",
      };
    case types.constants.GET_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        getsystemuserdata: action?.getsystemuser,
      };
    case types.constants.GET_SYSTEM_USER_ERROR:
      return {
        ...state,
        getsystemuserdataerror: "Error",
      };
    case types.constants.GET_FI_INCOMING_QUOTES_ORDER_SUCCESS:
      return {
        ...state,
        incomingquotesorderdata: action?.incomingquotesorder,
      };
    case types.constants.GET_FI_INCOMING_QUOTES_ORDER_ERROR:
      return {
        ...state,
        incomingquotesorderdataerror: "Error",
      };
    case types.constants.GET_FI_INCOMING_OFFER_ORDER_SUCCESS:
      return {
        ...state,
        incomingofferorderdata: action?.incomingofferorder,
      };
    case types.constants.GET_FI_INCOMING_OFFER_ORDER_ERROR:
      return {
        ...state,
        incomingofferorderdataerror: "Error",
      };
    case types.constants.GET_FI_ORDER_BY_USER_SUCCESS:
      return {
        ...state,
        userorderbyiddata: action?.userorderbyid,
      };
    case types.constants.GET_FI_ORDER_BY_USER_ERROR:
      return {
        ...state,
        userorderbyiddataerror: "Error",
      };
    case types.constants.CREATE_FI_TREDE_SUCCESS:
      return {
        ...state,
        createtradedata: action?.createTrade,
      };
    case types.constants.CREATE_FI_TREDE_ERROR:
      return {
        ...state,
        createtradedataerror: "Error",
      };
    case types.constants.GET_FI_ALL_TREDE_SUCCESS:
      return {
        ...state,
        alltradedata: action?.alltrade,
      };
    case types.constants.GET_FI_ALL_TREDE_ERROR:
      return {
        ...state,
      };
    case types.constants.GET_FI_PENDING_TREDE_SUCCESS:
      return {
        ...state,
        gettradeorderdata: action?.gettradeorder,
      };
    case types.constants.GET_FI_PENDING_TREDE_ERROR:
      return {
        ...state,
        gettradeorderdataerror: "Error",
      };
    case types.constants.GET_FI_EXECUTED_TREDE_SUCCESS:
      return {
        ...state,
        executedtradedata: action?.getexecutedtrade,
      };
    case types.constants.GET_FI_EXECUTED_TREDE_ERROR:
      return {
        ...state,
      };
    case types.constants.GET_USER_POSITION_SUCCESS:
      return {
        ...state,
        getUserPotisionData: action?.getUserPotision,
      };
    case types.constants.GET_USER_POSITION_ERROR:
      return state;
    default:
      return state;
  }
}
