import React, { useState, useEffect } from "react";

import StompJs from "stompjs";

import { useKeycloak } from "@react-keycloak/web";
import SockJS from "sockjs-client";

const WebSocketContext = React.createContext({
  ws: null,
  connected: false,
});

export const WebSocketProvider = (props) => {
  const [ws, setWs] = useState(null);
  const [connected, setConnected] = useState(false);
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    initialized &&
      keycloak.authenticated &&
      keycloak.realmAccess?.roles?.length > 0 &&
      initSocket();
  }, [keycloak, initialized, ws]);

  useEffect(() => {
    ws && connectSocket();
  }, [ws]);

  const initSocket = () => {
    if (!ws) {
      var sockjs = new SockJS(
        `${process.env.REACT_APP_FIN_TECH_SOCKET}?token=Bearer ${keycloak.token}&type=websocket`
      );
      sockjs.withCredentials = true;
      setWs(StompJs.over(sockjs));
    }
  };

  const connectSocket = () => {
    if (ws && !ws.connected) {
      ws.debug = null;
      ws.connect({}, stompSuccessCallback, stompFailureCallback);
    }
  };

  const stompSuccessCallback = () => {
    setConnected(true);
  };

  const stompFailureCallback = () => {
    ws.disconnect(() => {
      setConnected(false);
      setWs(null);
    });
  };

  return (
    <WebSocketContext.Provider value={{ ws, connected }}>
      {props.children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketContext;
