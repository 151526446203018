import * as types from "../actions/types";
import { sortByAsc, sortByDesc } from "../../helpers/utilities";

const INITIAL_FUND_ACCOUNT2 = {
  loading: false,
  error: null,
  items: [],
  fundAccount2: null,
  filteredItems: [],
  sortBy: {},
};

export const fundAccount2Reducer = (state = INITIAL_FUND_ACCOUNT2, action) => {
  switch (action.type) {
    case types.constants.SORT_FUND_ACCOUNT2:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_FUND_ACCOUNT2:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter((item) =>
        item.fundAccountName.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_FUND_ACCOUNT2_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        error: null,
      };

    case types.constants.LOADING_FUND_ACCOUNT2:
      return { ...state, loading: true };

    case types.constants.STORE_FUND_ACCOUNT2_SUCCESS:
      return { ...state, loading: false, fundAccount2: null };

    case types.constants.TOGGLE_FUND_ACCOUNT2_STATUS_SUCCESS:
      const fundAccountStatus2Index = state.items.findIndex(
        (item) => item.id === action.id
      );

      let newState__ = { ...state };

      if (fundAccountStatus2Index >= 0) {
        newState__.items[fundAccountStatus2Index] = {
          ...newState__.items[fundAccountStatus2Index],
          disabled: !newState__.items[fundAccountStatus2Index].disabled,
        };
      }

      newState__.loading = false;

      return newState__;

    case types.constants.LOAD_FUND_ACCOUNT2_CURRENT_BALANCE_SUCCESS:
      const fundAccountIndex = state.items.findIndex(
        (_) => _.id == action.payload.id
      );

      let newState_ = { ...state };

      if (fundAccountIndex >= 0) {
        newState_.items[fundAccountIndex]["currentBalance"] =
          action.payload.balance;
      }

      return newState_;

    case types.constants.GET_FUND_ACCOUNT2_ERROR:
    case types.constants.STORE_FUND_ACCOUNT2_ERROR:
    case types.constants.LOAD_FUND_ACCOUNT2_CURRENT_BALANCE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
