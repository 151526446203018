import { sortByAsc, sortByDesc } from "../../helpers/utilities";
import * as types from "../actions/types";

const INITIAL_SCHEDULE = {
  loading: false,
  error: null,
  items: [],
  filteredItems: [],
  schedule: {},
  sortBy: {},
};

export const scheduleReducer = (state = INITIAL_SCHEDULE, action) => {
  switch (action.type) {
    case types.constants.SORT_SCHEDULE:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.GET_SCHEDULE_SUCCESS:
      const schedules_ = action.payload.map((_) => ({
        ..._,
        isDefault: _.isDefault ? "Default" : "Not default",
      }));

      return {
        ...state,
        loading: false,
        items: schedules_,
        filteredItems: schedules_,
        schedule: {},
        error: null,
        sortBy: {},
      };

    case types.constants.LOADING_SCHEDULE:
      return { ...state, loading: true };

    case types.constants.GET_SCHEDULE_BY_ID_SUCCESS:
      return { ...state, loading: false, schedule: action.schedule };

    case types.constants.STORE_SCHEDULE_SUCCESS:
    case types.constants.UPDATE_SCHEDULE_SUCCESS:
      return { ...state, loading: false, schedule: {} };

    case types.constants.TOGGLE_SCHEDULE_STATUS_SUCCESS:
      const scheduleStatusIndex = state.items.findIndex(
        (item) => item.id === action.id
      );

      let newState__ = { ...state };

      if (scheduleStatusIndex >= 0) {
        newState__.items[scheduleStatusIndex] = {
          ...newState__.items[scheduleStatusIndex],
          enabled: !newState__.items[scheduleStatusIndex].enabled,
        };
      }

      newState__.loading = false;

      return newState__;

    case types.constants.GET_SCHEDULE_ERROR:
    case types.constants.STORE_SCHEDULE_ERROR:
    case types.constants.UPDATE_SCHEDULE_ERROR:
    case types.constants.GET_SCHEDULE_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
