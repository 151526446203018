import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Redirect, useLocation } from "react-router";
import CompanyLogo from "../../assets/Images/logo.png";

import LoginBgImage from "../../assets/Images/login_page_background.png";
import { useFormik } from "formik";

import * as Yup from "yup";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  CardMedia,
  Zoom,
  TextField,
  IconButton,
} from "@material-ui/core";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import axios from "axios";

import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
Yup.addMethod(Yup.string, "strongPassword", strongPasswordMethod);

function strongPasswordMethod() {
  return this.test("strongPasswordTest", function (value) {
    const { path, createError } = this;
    switch (Boolean(value)) {
      case !/^(?=.*[a-z])/.test(value):
        return createError({
          path,
          message: "password must include at least 1 lowercase letter",
        });
      case !/^(?=.*[A-Z])/.test(value):
        return createError({
          path,
          message: "password must include at least 1 uppercase letter",
        });
      case !/^(?=.*[0-9])/.test(value):
        return createError({
          path,
          message: "password must include at least 1 digit",
        });
      case !/^(?=.*[#?!@$%^&*\-.?+])/.test(value):
        return createError({
          path,
          message: "password must include at least 1 special character",
        });
      default:
        return true;
    }
  });
}

const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundColor: "#C5C5C5",
    backgroundImage: `url(${LoginBgImage})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loginCard: {
    backgroundColor: "white",
    padding: "1rem 16px",
    width: "400px",
  },
  loginCardContent: {
    padding: "0px",
    textAlign: "center",
  },

  loginCardFooter: {
    justifyContent: "center",
    flexDirection: "column",
    color: "black",
  },
  footerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.5rem",
  },
  footer: {
    marginTop: "20px",
    color: "#C7C5C7",
  },
  submitBtn: {
    marginTop: "1rem",
    marginBottom: "1rem",
    textTransform: "none",
    "&.MuiButton-contained.Mui-disabled": {
      color: "#fff",
      backgroundColor: "#0B6FB4",
    },
  },
  heading1: {
    color: "gray",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginBottom: "20px",
  },
  heading2: {
    color: "gray",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: "center",
    marginBottom: "1.2rem",
  },
  logo: {
    width: "100px",
    height: "100%",
    objectFit: "cover",
    maxWidth: "100%",
    margin: "0 auto",
    marginBottom: "10px",
  },
  "@media (max-width: 500px)": {
    loginCard: {
      width: "80%",
      margin: "0 auto",
    },
  },
  "@media (max-width: 360px)": {
    footerFlex: {
      flexDirection: "column",
    },
  },
}));

const MuiTextField = withStyles({
  root: {
    marginBottom: "10px",
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& input::placeholder": { color: "grey" },
      "& input": { color: "grey" },

      "& fieldset": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);

const ResetPassword = () => {
  const classes = useStyles();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  let token = query.get("token");

  const [submitted, setsubmitted] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showCPassword, setShowCPassword] = React.useState(false);

  const { keycloak } = useKeycloak();

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Must be atleast 8 characters.")
        .strongPassword(),
      confirmPassword: Yup.string()
        .required("please enter confirm password")
        .oneOf([Yup.ref("password"), null], "Passwords not matching"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting, resetForm, setFieldError }) => {
      setSubmitting(true);
      try {
        await axios.post(
          process.env.REACT_APP_API_URL + "/users/api/v1/users-password/update",
          {
            token,
            password: values.password,
          }
        );
        resetForm();
        setsubmitted(true);
      } catch (error) {
        if (error.response.status == 404) {
          setFieldError("password", "Token not found")
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  if (keycloak.authenticated) {
    return <Redirect to="/trade" />;
  }

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <Box className={classes.bg}>
      <Zoom in style={{ transitionDelay: "500ms" }}>
        <Card className={classes.loginCard}>
          <CardMedia
            component="img"
            className={classes.logo}
            image={CompanyLogo}
            alt="SSX logo"
          />
          <CardContent className={classes.loginCardContent}>
            {submitted ? (
              <>
                <Typography className={classes.heading1}>
                  Your password has been successfully reset
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className={classes.submitBtn}
                  onClick={(e) => keycloak.login()}
                >
                  Continue
                </Button>
              </>
            ) : (
              <form noValidate onSubmit={formik.handleSubmit}>
                <Typography className={classes.heading1}>
                  Set new password
                </Typography>
                <MuiTextField
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  name={"password"}
                  fullWidth={true}
                  placeholder="New Password"
                  error={
                    formik.errors["password"] && formik.touched["password"]
                  }
                  helperText={
                    formik.touched["password"] && formik.errors["password"]
                  }
                  autoFocus={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["password"]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword((s) => !s)}>
                          {showPassword ? (
                            <VisibilityIcon color="primary" />
                          ) : (
                            <VisibilityOffIcon color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <MuiTextField
                  variant="outlined"
                  type={showCPassword ? "text" : "password"}
                  name={"confirmPassword"}
                  fullWidth={true}
                  placeholder="Confirm password"
                  error={
                    formik.errors["confirmPassword"] &&
                    formik.touched["confirmPassword"]
                  }
                  helperText={
                    formik.touched["confirmPassword"] &&
                    formik.errors["confirmPassword"]
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values["confirmPassword"]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowCPassword((s) => !s)}>
                          {showCPassword ? (
                            <VisibilityIcon color="primary" />
                          ) : (
                            <VisibilityOffIcon color="primary" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disableElevation
                  className={classes.submitBtn}
                  disabled={formik.isSubmitting || !formik.isValid}
                  onClick={formik.submitForm}
                >
                  {formik.isSubmitting ? "loading..." : "Reset password"}
                </Button>
              </form>
            )}
          </CardContent>
          <CardActions className={classes.loginCardFooter}>
            <Typography variant="subtitle2" className={classes.footer}>
              Copyright {new Date().getFullYear()} @SecondSTAX
            </Typography>
          </CardActions>
        </Card>
      </Zoom>
    </Box>
  );
};

export default ResetPassword;
