import * as types from "../actions/types";

const INITIAL_USER_ACCOUNT_DATA = {
  loading: false,
  error: null,
  data: {},
};

export const userAccountReducer = (
  state = INITIAL_USER_ACCOUNT_DATA,
  action
) => {
  switch (action.type) {
    case types.constants.USER_ACCOUNT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };

    case types.constants.USER_ACCOUNT_DATA_LOADING:
      return { ...state, loading: true };

    case types.constants.USER_ACCOUNT_DATA_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
