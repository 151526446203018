import { Bar } from "react-chartjs-2";
import React from "react";
import { Box, Typography } from "@material-ui/core";

const AccountLineChart = ({ labels = [], data: chartData = [], ccy = "" }) => {
  const data = {
    labels: [...labels],
    datasets: [
      {
        label: `Cash${ccy ? ` (${ccy})` : ""}`,
        data: [...chartData],
        backgroundColor: "#0174cd",
      },
    ],
  };

  const options = {
    responsive: true,
  };

  return (
    <>
      {labels.length === 0 && chartData.length === 0 ? (
        <Box height={"100%"}>
          <Typography variant="subtitle2" align="center">
            No data
          </Typography>
        </Box>
      ) : (
        <Box
          style={{
            width: "500px",
            margin: "0 auto",
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      )}
    </>
  );
};

export default React.memo(AccountLineChart);
