import { Box, Typography } from "@material-ui/core";
import React from "react";

function ContactAdministrator({
  classes = "",
  component = "div",
  styles = {},
  text = "Contact Administrator",
  bgcolor = "#252522",
  p = 2,
}) {
  return (
    <Box
      p={p}
      bgcolor={bgcolor}
      className={classes}
      component={component}
      style={{ ...styles }}
    >
      <Typography variant="h4" align="center" color="secondary">
        {text}
      </Typography>
    </Box>
  );
}

export default ContactAdministrator;
