import React, { useState } from "react";
import moment from "moment";
import HistoryTab from "./Table/HistoryTab";
import TradeTab from "./Table/TradeTab";
import IncomingTab from "./Table/IncomingTab";
import OutgoingTab from "./Table/OutgoingTab";

import {
  Box,
  Grid,
  makeStyles,
  styled,
  Tab,
  Tabs,
  Typography,
  Paper,
} from "@material-ui/core";

import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#252525",
}));

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#0c6fb3",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "rgba(255, 255, 255, 0.7)",
  "&.Mui-selected": {
    color: "#fff",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const useStyles = makeStyles(() => ({
  tabBox: {
    marginBottom: "1rem",
    marginTop: "1rem",
  },
}));

const TradingBlotter = () => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(0);
  const [orderHistoryDate, setOrderHistoryDate] = useState({
    historyStartDate: moment().subtract(6, "days"),
    historyEndDate: moment(),
  });
  const { historyStartDate, historyEndDate } = orderHistoryDate;

  const handleOrderTabChange = (type) => {
    setActiveTab(type);
    setOrderHistoryDate({
      historyStartDate: moment().subtract(6, "days"),
      historyEndDate: moment(),
    });
    sessionStorage.removeItem("paginationkey");
  };

  const opentradetab = () => {
    sessionStorage.removeItem("paginationkey");
    setActiveTab(2);
  };

  const handleHistoryCallback = (start, end) => {
    setOrderHistoryDate({ historyStartDate: start, historyEndDate: end });
  };

  return (
    <Box className={classes.tabBox}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Item style={{ padding: "1rem" }}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6" align="left">
                  Trading Blotter
                </Typography>
              </Grid>
              <Grid item>
                {activeTab == 3 && (
                  <DateRangePicker
                    onChange={handleHistoryCallback}
                    startingDate={moment(historyStartDate?.toDate())}
                    endingDate={moment(historyEndDate?.toDate())}
                  />
                )}
              </Grid>
            </Grid>
            <StyledTabs
              value={activeTab}
              onChange={(e, value) => handleOrderTabChange(value)}
            >
              <StyledTab label={`Outgoing`} />
              <StyledTab label={`Incoming`} />
              <StyledTab label={`Trades`} />
              <StyledTab label={`History`} />
            </StyledTabs>
            {activeTab === 0 && <OutgoingTab />}
            {activeTab === 1 && <IncomingTab opentradetab={opentradetab} />}
            {activeTab === 2 && <TradeTab />}
            {activeTab === 3 && <HistoryTab daterange={orderHistoryDate} />}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(TradingBlotter);
