import moment from "moment";
import "moment-timezone";
import React, { useState, useEffect, useCallback } from "react";
import CurrencyFormat from "react-currency-format";
import DataTable, { createTheme } from "react-data-table-component";
import CustomPagination from "../../../../../components/CustomPagination/CustomPagination";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import UpdateOrderModal from "../../../Modal/UpdateOrderModal";
import { toast } from "react-toastify";
import * as fixedIncome from "../../../../../store/actions/fixedIncome.action";
import * as fixedIncomeCountAction from "../../../../../store/actions/fixedIncomeCount.action";
import { Button, Grid, makeStyles } from "@material-ui/core";

createTheme("dark", {
  background: {
    default: "#252525",
  },
  text: {
    primary: "#FCFCFC",
    secondary: "#FCFCFC",
  },
});

const useStyles = makeStyles(() => ({
  actionBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#145582",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#145582",
    },
    "&:disabled": {
      backgroundColor: "#083a5e",
      color: "#888888",
    },
  },
  cancelBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#3d3d3d",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#3d3d3d",
    },
  },
}));

const OrderTable = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [bondData, setBondData] = useState({});
  const [ModifyorderModalShow, setModifyOrderModalShow] = useState(false);
  const [timerInterval, setTimerInterval] = useState(0);
  const orderData = useSelector(
    (state) => state?.fixedincomedata?.outgoingorderdata?.response
  );

  useEffect(() => {
    sessionStorage.removeItem("paginationkey");
    setCurrentPageNumber(1);
    dispatch(fixedIncomeCountAction.getOutgoingOrderCountAction());
    dispatch(
      fixedIncome.getOutgoingOrderAction(
        sessionStorage.getItem("paginationkey")
      )
    );
    const timer = setInterval(() => {
      dispatch(fixedIncomeCountAction.getOutgoingOrderCountAction());
      dispatch(
        fixedIncome.getOutgoingOrderAction(
          sessionStorage.getItem("paginationkey")
        )
      );
    }, 10000);
    setTimerInterval(timer);
  }, [dispatch]);

  useEffect(() => {
    return () => {
      clearInterval(timerInterval);
      sessionStorage.removeItem("paginationkey");
    };
  }, [timerInterval]);

  const columns = [
    {
      name: "Order ID",
      selector: "recordID",
      sortable: false,
      compact: true,
      cell: (row) => (row?.recordID ? row?.recordID : "-"),
    },
    {
      name: "Bond ID",
      selector: "bond_id",
      sortable: false,
      right: false,
      cell: (row) => (row?.bond_id ? row?.bond_id : "-"),
      compact: true,
    },
    {
      name: "ISIN",
      selector: "isin",
      sortable: false,
      right: false,
      cell: (row) => (row?.isin ? row?.isin : "-"),
      compact: true,
    },
    {
      name: "Exchange",
      selector: "exchange_name",
      sortable: false,
      center: true,
      cell: (row) => (row?.exchange_name ? row?.exchange_name : "-"),
      compact: true,
    },
    {
      name: "Coupon (%)",
      selector: "coupon_rate",
      sortable: false,
      center: true,
      cell: (row) =>
        row?.coupon_rate ? parseFloat(row?.coupon_rate)?.toFixed(4) : "-",
      compact: true,
    },
    {
      name: "Mat Date",
      selector: "maturity_date",
      sortable: false,
      right: false,
      cell: (row) =>
        row.createdAt ? (
          <span className="text-uppercase">
            {moment(row.maturity_date).format("DD/MM/YYYY")}
          </span>
        ) : (
          "-"
        ),
      compact: true,
    },
    {
      name: "Side",
      selector: "side",
      sortable: false,
      cell: (row) =>
        row?.side ? <span className="text-uppercase">{row?.side}</span> : "-",
      compact: true,
    },
    {
      name: "All or Nothing ",
      selector: "allOrNothing",
      sortable: false,
      cell: (row) =>
        row?.allOrNothing ? (
          <span className="text-uppercase">{row?.allOrNothing}</span>
        ) : (
          "-"
        ),
      compact: true,
      center: true,
    },
    {
      name: "CCY",
      selector: "ccy_suffix",
      sortable: false,
      right: false,
      cell: (row) => (row?.ccy_suffix ? row?.ccy_suffix : "-"),
      compact: true,
    },
    {
      name: "Market Yield (%)",
      selector: "yield",
      sortable: false,
      center: true,
      cell: (row) =>
        row?.offer_yield ? parseFloat(row?.offer_yield)?.toFixed(4) : "-",
      compact: true,
    },
    {
      name: "Order Yield (%)",
      selector: "offer_yield",
      sortable: false,
      center: true,
      cell: (row) => (row?.yield ? parseFloat(row?.yield)?.toFixed(4) : "-"),
      compact: true,
    },

    {
      name: "Market Amount",
      selector: "amount",
      sortable: false,
      cell: (row) =>
        row?.offer_amount ? (
          <CurrencyFormat
            value={parseFloat(row?.offer_amount)?.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "-"
        ),
      compact: true,
      right: true,
    },
    {
      name: "Order Amount",
      selector: "offer_amount",
      sortable: false,
      cell: (row) =>
        row?.order_amount ? (
          row?.order_amount ? (
            <CurrencyFormat
              value={parseFloat(row?.order_amount)?.toFixed(2)}
              displayType={"text"}
              thousandSeparator={true}
            />
          ) : (
            "-"
          )
        ) : row?.amount ? (
          <CurrencyFormat
            value={parseFloat(row?.amount)?.toFixed(2)}
            displayType={"text"}
            thousandSeparator={true}
          />
        ) : (
          "-"
        ),
      compact: true,
      right: true,
    },
    {
      name: "Date",
      selector: "createdAt",
      sortable: false,
      cell: (row) => (
        <span className="text-uppercase">
          {moment(row.createdAt).format("DD/MM/YYYY")}
        </span>
      ),
    },
    {
      name: "Time (GMT)",
      selector: "createdAt",
      sortable: false,
      center: true,
      cell: (row) =>
        row?.createdAt
          ? moment(row.createdAt).tz("GMT").format("hh:mm:ss A")
          : "-",
      compact: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      center: true,
      cell: (row) => (row.status ? row?.status : "-"),
      compact: true,
    },
    {
      name: "Action",
      cell: (row) =>
        (row?.status === "open" || row?.status === "partially_filled") &&
        localStorage.getItem("client") === row?.client
          ? actionButton(row)
          : "-",
      button: true,
      ignoreRowClick: true,
      allowOverflow: true,
      compact: true,
      center: true,
    },
  ];

  const actionButton = (data) => (
    <Grid justify="space-between" alignItems="center" container spacing={1}>
      <Grid item>
        <Button
          variant="contained"
          style={{ minWidth: 60 }}
          onClick={() => modifyOrderModal(data)}
          className={classes.actionBtn}
        >
          MODIFY
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          style={{ minWidth: 60 }}
          onClick={(e) => canceledOrder(data)}
          className={classes.cancelBtn}
        >
          CANCEL
        </Button>
      </Grid>
    </Grid>
  );

  const modifyOrderModal = useCallback((data) => {
    setBondData(data);
    setModifyOrderModalShow(true);
  }, []);

  function canceledOrder(data) {}

  const updateOrder = (data) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to canceled this order ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let payload = {
          values: [
            { name: "bond", value: data["bond"] },
            { name: "settlement_date", value: data?.settlement_date },
            { name: "nominal", value: data["nominal"]?.toString() },
            { name: "price", value: data["price"]?.toString() },
            { name: "side", value: data["side"]?.toString() },
            { name: "consideration", value: data.consideration?.toString() },
            { name: "csd_cost", value: data.csd_cost?.toString() },
            { name: "allOrNothing", value: data["allOrNothing"] },
            { name: "qty", value: data["qty"]?.toString() },
            { name: "amount", value: data["nominal"]?.toString() },
            {
              name: "order_date",
              value: moment(Date.now())?.format("YYYY/MM/DD"),
            },
            { name: "sendTo", value: data["sendTo"] },
            { name: "quote", value: "no" },
            { name: "party", value: "" },
            { name: "yield", value: data["yield"]?.toString() },
            { name: "maturity_date", value: data["maturity_date"] },
            { name: "status", value: "canceled" },
            { name: "dirty_price", value: data["dirty_price"]?.toString() },
            { name: "clean_price", value: data["clean_price"]?.toString() },
            { name: "state", value: "closed" },
          ],
        };
        dispatch(
          fixedIncome.updateMarketOrderAction({
            payload: JSON.stringify(payload),
            recordID: data?.recordID,
          })
        );
        setTimeout(() => {
          dispatch(fixedIncome.getMarketOrderAction());
        }, 1000);
      }
    });
  };

  const handlePaginationChange = (page) => {
    if (
      currentPageNumber > page &&
      orderData?.filter &&
      orderData?.filter?.prevPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", orderData?.filter?.prevPage);
      dispatch(fixedIncome.getOutgoingOrderAction(orderData?.filter?.prevPage));
    } else if (
      currentPageNumber < page &&
      orderData?.filter &&
      orderData?.filter?.nextPage
    ) {
      setCurrentPageNumber(page);
      sessionStorage.setItem("paginationkey", orderData?.filter?.nextPage);
      dispatch(fixedIncome.getOutgoingOrderAction(orderData?.filter?.nextPage));
    }
  };

  return (
    <>
      <DataTable
        defaultSortField="createdAt"
        defaultSortAsc={false}
        columns={columns}
        data={orderData?.set}
        theme={"dark"}
        highlightOnHover
        persistTableHead={true}
        noHeader={true}
        pagination
        paginationServer
        paginationIconFirstPage={false}
        paginationIconLastPage={false}
        paginationTotalRows={props?.totalRecord}
        paginationPerPage={15}
        paginationDefaultPage={currentPageNumber}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        onChangePage={(page) => handlePaginationChange(page)}
        paginationComponent={CustomPagination}
      />
      <UpdateOrderModal
        show={ModifyorderModalShow}
        onHide={() => setModifyOrderModalShow(false)}
        bond={bondData}
      />
    </>
  );
};

export default React.memo(OrderTable);
