import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncRoles = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/users/api/v1/roles`
    );
    return data;
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const storeAsyncRole = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/api/v1/roles`,
      payload
    );

    return {
      error: false,
      data: data.message ?? "Role created successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

export function* loadRoles() {
  try {
    const bonds = yield call(loadAsyncRoles);
    yield put({ type: types.constants.LOAD_ROLE_SUCCESS, payload: bonds });
  } catch (err) {
    yield put({ type: types.constants.LOAD_ROLE_ERROR, payload: err.message });
  }
}

export function* storeRole({ payload }) {
  const response = yield call(storeAsyncRole, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({ type: types.constants.STORE_ROLE_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STORE_ROLE_ERROR,
      payload: response.data,
    });
  }
}
