import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useKeycloak } from "@react-keycloak/web";
import React, { useCallback, useEffect, useRef, useState } from "react";
const events = ["click", "mousemove", "scroll", "load"];
const SessionTimeout = () => {
  const [showModal, setShowModal] = useState(false);
  const [countDown, setCountDown] = useState(60);
  const timerRef = useRef();
  const countDownRef = useRef();
  const logoutTimerRef = useRef();

  const { keycloak } = useKeycloak();
  const setActive = useCallback(() => {
    localStorage.setItem("lastActvity", new Date());
  }, []);

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      let lastAcivity = localStorage.getItem("lastActvity");
      var diffMs = Math.abs(new Date(lastAcivity) - new Date());
      var seconds = Math.floor(diffMs / 1000);
      var minute = Math.floor(seconds / 60);
      if (minute >= 20) {
        //20 mins
        setShowModal(true);
      }
    }, 1000);
  };

  useEffect(() => {
    if (showModal && !countDownRef.current) {
      countDownRef.current = setInterval(() => {
        setCountDown((s) => (s > 0 ? s - 1 : s));
      }, 1000);
    }

    return () => {
      clearInterval(countDownRef.current);
      countDownRef.current = null;
    };
  }, [showModal]);

  useEffect(() => {
    if (countDown === 0) {
      keycloak.logout();
    }
  }, [countDown]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, setActive);
    });
    if (keycloak.authenticated) {
      setActive();
      clearInterval(logoutTimerRef.current);
      startTimer();
    }

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, setActive);
      });
      reset();
    };
  }, [keycloak.authenticated]);

  const reset = () => {
    clearInterval(logoutTimerRef.current);

    clearInterval(countDownRef.current);
    countDownRef.current = null;

    setShowModal(false);
    setCountDown(60);
  };

  return (
    <>
      <Dialog open={showModal} onClose={reset}>
        <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session seems to have been inactive for the past 20 minutes. It will end in {countDown} seconds. Click RESUME to extend it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={reset} color="primary">
            Resume
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SessionTimeout;
