import * as types from "./types";

export const getOutgoingOrderCountAction = () => {
  return {
    type: types.constants.GET_OUTGOING_ORDER_COUNT
  };
};

export const getOutgoingOfferCountAction = () => {
  return {
    type: types.constants.GET_OUTGOING_OFFER_COUNT
  };
};

export const getOutgoingQuotesCountAction = () => {
  return {
    type: types.constants.GET_OUTGOING_QUOTES_COUNT
  };
};

export const getIncomingOfferCountAction = () => {
  return {
    type: types.constants.GET_INCOMING_OFFER_COUNT
  };
};

export const getIncomingQuotesCountAction = () => {
  return {
    type: types.constants.GET_INCOMING_QUOTES_COUNT
  };
};

export const getPendingTradeCountAction = () => {
    return {
      type: types.constants.GET_PENDING_TREDE_COUNT
    };
  };

export const getExecutedTradeCountAction = () => {
    return {
      type: types.constants.GET_EXECUTED_TREDE_COUNT
    };
  };

  export const getAllTradeCountAction = (payload) => {
    return {
      type: types.constants.GET_ALL_TREDE_COUNT,
      payload
    };
  };

  export const getAllOrderCountAction = (payload) => {
    return {
      type: types.constants.GET_ALL_ORDER_COUNT,
      payload
    };
  };

  export const getAllOfferCountAction = (payload) => {
    return {
      type: types.constants.GET_ALL_OFFER_COUNT,
      payload
    };
  };
