import {
  Box, Button, Collapse, Divider,
  IconButton, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField, Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { Tooltip } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { AddCircle, Close, RemoveCircle, TextFormatRounded } from "@material-ui/icons";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";
import "moment-timezone";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import Sorting from "../../../components/Sorting/Sorting";
import { thousandSeparator, toCurrency } from "../../../helpers/utilities";
import {
  loadOrderTrades, sortOrder,
  tradeLoading
} from "../../../store/actions/order.action";


const CustomTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    borderBottom: "none !important",
    color: "#808080",
  },
});

const useStyles = makeStyles(() => ({
  minWidth: {
    minWidth: "170px !important",
  },
  tableHead: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "75px",
    minWidth: "75px",
  },
  tableHeadRight: {
    justifyContent: "flex-end !important",
    textAlign: "right !important",
  },
  "@media (max-width: 1024px)": {
    tableHead: {
      justifyContent: "flex-start",
      maxWidth: "125px",
      minWidth: "125px",
    },
  },
  modalTable: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "100px",
    minWidth: "100px",
  },
  mixFields: {
    borderRight: "0px !important",
    marginRight: "0.5rem",
    minWidth: "40ch !important",
    "& .MuiOutlinedInput-input": {
      padding: "10px 0px !important",
      paddingLeft: "10px !important",
    },
  },
  inputField: {
    borderRight: "0px !important",
    minWidth: "60% !important",
    "& .MuiOutlinedInput-input": {
      padding: "10px 0px !important",
      paddingLeft: "10px !important",
    },
  },
  "@media (max-width: 1024px)": {
    inputField: {
      minWidth: "80% !important",
    },
  },
  cancelBtn: {
    backgroundColor: "#f50057 !important",
    color: "white !important",
    minWidth: "max-content !important",
    marginLeft: "0.5rem !important",
  },
  modifyBtn: {
    backgroundColor: "#0B6FB4 !important",
    color: "white !important",
    minWidth: "max-content !important",
    marginLeft: "0.5rem !important",
  },
  DisbleClick:{
    cursor: 'not-allowed'
  }
}));

function OrderTable({
  data: orders = [],
  sortBy = {},
  orderLoading,
  error,
  detailPageBtn = false,
  cancelOrderHandler = (id) => null,
  closeOrderHandler = (id) => null,

}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const modifyOrderHandler = orderArg => {
    showHandle(orderArg)
  };

  let ordersData = null;

  const [order, setOrder] = useState({});




  const { tradeLoading: tradeLoader, trades, showHandle } = useSelector(
    (state) => state.orders
  );

  useEffect(() => {
    setOrder({});
  }, [orders]);

  useEffect(() => {
    if (Object.keys(order).length > 0) {
      dispatch(tradeLoading());
      dispatch(loadOrderTrades(order.id));
    }
  }, [order]);

  const { keycloak } = useKeycloak();
  const roles = keycloak?.realmAccess?.roles ?? [];
  const clickActionAllowed = roles.some((_) =>
    ["DF_ADMIN", "BROKER_ADMIN", "TRADER"].includes(_)
  );

  const createOrderUI = (order) => {
    return (
      <Box
        color={"white"}
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <TextField
          variant="outlined"
          className={classes.mixFields}
          label={"Filled / Outstanding volume"}
          value={`${thousandSeparator(order?.filledVolume ?? "0") ?? "-"
            } / ${thousandSeparator(order?.outstandingVolume ?? "0")}`}
          InputProps={{
            disabled: true,
            readOnly: true,
          }}
        />
        {order?.rejectExpirationMessage && (
          <>
            <TextField
              variant="outlined"
              className={classes.inputField}
              label={"Message"}
              value={order?.rejectExpirationMessage ?? "-"}
              InputProps={{
                disabled: true,
                readOnly: true,
              }}
            />
            {order["status"] ? (
              <Box
                display={"flex"}
                justifyContent="flex-start"
                alignItems="center"
              >
                {["REJECTED"].includes(order["status"]) && (
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => {
                      if(clickActionAllowed)
                      closeOrderHandler(order["id"])
                    }}
                    className={`${classes.cancelBtn} ${!clickActionAllowed ? classes.DisbleClick : ''}`}
                  >
                    Close
                  </Button>
                )}
              </Box>
            ) : (
              "-"
            )}
          </>
        )}
      </Box>
    );
  };

  let tradesData = null;
  if (tradeLoader) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={9}>Please wait loading...</td>
      </tr>
    );
  } else if (!tradeLoader && error) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={9}>
          {typeof error === "string"
            ? error
            : Object.keys(error).length > 0
              ? "Something went wrong! Please try again."
              : "No data"}
        </td>
      </tr>
    );
  } else if (trades.length === 0) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={9}>No trades yet</td>
      </tr>
    );
  } else {
    tradesData = trades.map((trade, tradeIndex) => {
      return (
        <TableRow hover key={`tr-${tradeIndex}`}>
          <CustomTableCell padding="default" align="left">
            {trade["id"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["price"] ? (
              <CurrencyFormat
                value={toCurrency(trade["price"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["volume"] ? (
              <CurrencyFormat
                value={trade["volume"]}
                thousandSeparator
                displayType="text"
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["commissions"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(trade["commissions"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["dutiesTaxes"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(trade["dutiesTaxes"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["status"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["execId"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["tradeDate"]
              ? moment(trade["tradeDate"]).format("DD/MM/YYYY")
              : "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["tradeDate"]
              ? moment(trade["tradeDate"]).tz("GMT").format("hh:mm:ss")
              : "-"}
          </CustomTableCell>
        </TableRow>
      );
    });
  }

  const detailsData = (order_, orderIndex) => (
    <TableRow hover key={`detail-row-${orderIndex}`}>
      <CustomTableCell colSpan={15}>
        <Collapse
          in={order_?.id == order?.id}
          timeout="auto"
          unmountOnExit
          style={{
            margin: "0 3rem",
            padding: "1rem",
            border: "0.5px solid rgba(255, 255, 255, 0.12)",
          }}
        >
          {createOrderUI(order)}
          <Box style={{ marginTop: "1rem" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Trade #</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Price</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Quantity</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Commissions</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">
                          Duties &amp; Taxes
                        </Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Status</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">
                          Execution ID
                        </Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">
                          Trade Date (GMT)
                        </Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">
                          Trade Time (GMT)
                        </Typography>
                      </div>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tradesData}</TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </CustomTableCell>
    </TableRow>
  );

  const mapFundAccount = (allocations) => {
    let result = allocations.map(
      (allocation) => allocation?.fundAccount?.fundAccountName ?? ""
    );
    return result.join(", ");
  };

  if (orderLoading) {
    ordersData = (
      <tr className="text-center">
        <td colSpan={16}>Please wait loading...</td>
      </tr>
    );
  } else if (!orderLoading && error) {
    ordersData = (
      <tr className="text-center">
        <td colSpan={16}>
          {typeof error === "string"
            ? error
            : Object.keys(error).length > 0
              ? "Something went wrong! Please try again."
              : "No data"}
        </td>
      </tr>
    );
  } else if (orders.length === 0) {
    ordersData = (
      <tr className="text-center">
        <td colSpan={16}>No orders yet</td>
      </tr>
    );
  } else {
    ordersData = orders.map((order_, orderIndex) => {
      return (
        <>
          <TableRow
            hover
            key={`tr-order-${orderIndex}`}
            onClick={(e) => {
              if (e?.target?.dataset?.["closeBtn"]) {
                return cancelOrderHandler(order_["id"]);
              }
              setOrder((prev) => {
                if (prev?.id == order_?.id) {
                  return {};
                }
                return order_;
              });
            }}
            style={{ cursor: "pointer" }}
          >
            <CustomTableCell
              padding="default"
              align="left"
              className={classes.minWidth}
            >
              <Box
                display={"flex"}
                justifyContent="flex-start"
                alignItems={"center"}
              >
                {detailPageBtn && (
                  <IconButton
                    variant="contained"
                    color="inherit"
                    size="small"
                    style={{ paddingRight: "0.5rem" }}
                  >
                    {
                      order_['status'] === 'MODIFIED' ?
                        (
                          <Tooltip
                            placement="top-start"
                            title={order_["rejectExpirationMessage"] ?? "-"}
                            arrow
                          >
                            <span style={{ background: '#f00', borderRadius: '50%', display: 'flex' }}>
                              <TextFormatRounded rounded fontSize="inherit" style={{ color: "#333" }} />
                            </span>
                          </Tooltip>

                        )
                        :
                        (
                          order?.id == order_.id ? (
                            <RemoveCircle fontSize="inherit" />
                          ) : (
                            <AddCircle fontSize="inherit" />
                          )
                        )
                    }
                  </IconButton>
                )}
                {order_["id"] ?? "-"}
              </Box>
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["ticker"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["symbol"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["venue"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["orderType"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["action"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["timeInForce"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["ccy"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["allocations"]?.length > 0
                ? mapFundAccount(order_["allocations"])
                : "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="right">
              {order_["price"] > 0 ? (
                <CurrencyFormat
                  value={toCurrency(order_["price"])}
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={true}
                />
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="right">
              {order_["volume"] > 0 ? (
                <CurrencyFormat
                  value={order_["volume"]}
                  thousandSeparator
                  displayType="text"
                />
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="right">
              {order_["commissions"] > 0 ? (
                <CurrencyFormat
                  value={toCurrency(order_["commissions"])}
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={true}
                />
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="right">
              {order_["dutiesTaxes"] > 0 ? (
                <CurrencyFormat
                  value={toCurrency(order_["dutiesTaxes"])}
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={true}
                />
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["status"] ? (
                <Box
                  display={"flex"}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {order_["status"]}
                  {["ENQUEUED", "OPEN", "PARTIALLY_FILLED"].includes(
                    order_["status"]
                  ) && (
                      <>
                        {
                          order_["status"] === "OPEN" && order_["venue"] !== "GSE"
                          && <Button
                            color="secondary"
                            size="small"
                            onClick={() => {
                              if(clickActionAllowed)
                              modifyOrderHandler(order_)
                            }}
                            className={`${classes.modifyBtn} ${!clickActionAllowed ? classes.DisbleClick : ''}`}

                          >Modify
                          </Button>
                        }
                        {
                          !clickActionAllowed ? (
                            <Button
                              color="secondary"
                              size="small"
                              className={`${classes.cancelBtn} ${classes.DisbleClick} `}
                            >
                              <Close fontSize="inherit" />
                            </Button>
                          ):
                          (
                            <Button
                              color="secondary"
                              size="small"
                              data-close-btn
                              className={`${classes.cancelBtn} `}
                            >
                              <Close data-close-btn fontSize="inherit" />
                            </Button>
                          )
                        }
                      </>
                    )}
                </Box>
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["createdBy"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {moment(order_["orderDate"]).format("DD/MM/YYYY") ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {order_["orderTime"] ?? "-"}
            </CustomTableCell>
          </TableRow>
          {
            detailPageBtn &&
            order?.id == order_.id &&
            detailsData(order_, orderIndex)
          }
        </>
      );
    });
  }

  const fetchCount = (status) => {
    if (!status) {
      return 0;
    }

    return orders.filter((order) => order.status == status).length;
  };

  const onSort = (field, sortBy) => {
    dispatch(sortOrder({ field, sortBy }));
  };

  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        padding={"0 1rem"}
        color={"white"}
      >
        <Typography variant="caption">
          ENQUEUED: {fetchCount("ENQUEUED")}
        </Typography>
        <Typography variant="caption">OPEN: {fetchCount("OPEN")}</Typography>
        <Typography variant="caption">
          PARTIALLY FILLED: {fetchCount("PARTIALLY_FILLED")}
        </Typography>
        <Typography variant="caption">
          FILLED: {fetchCount("FILLED")}
        </Typography>
        <Typography
          variant="caption"
          color={
            orders.filter((_) => _.status == "REJECTED").length > 0
              ? "secondary"
              : "initial"
          }
        >
          REJECTED: {fetchCount("REJECTED")}
        </Typography>
        <Typography variant="caption">
          CANCELLED: {fetchCount("CANCELLED")}
        </Typography>
        <Typography variant="caption">
          EXPIRED: {fetchCount("EXPIRED")}
        </Typography>
      </Box>
      <Divider style={{ margin: "0.5rem 0" }} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <CustomTableCell align="left" className={classes.minWidth}>
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Order #</Typography>&nbsp;
                  <Sorting field={"id"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Ticker</Typography>&nbsp;
                  <Sorting field={"ticker"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Symbol</Typography>&nbsp;
                  <Sorting field={"symbol"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Exchange</Typography>&nbsp;
                  <Sorting field={"venue"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Order Type</Typography>&nbsp;
                <Sorting field={"orderType"} onSort={onSort} active={sortBy} />
              </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Side</Typography>&nbsp;
                  <Sorting field={"action"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Type</Typography>&nbsp;
                  <Sorting
                    field={"timeInForce"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Ccy</Typography>&nbsp;
                  <Sorting field={"ccy"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Account</Typography>
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                >
                  <Typography variant="subtitle2">Price</Typography>&nbsp;
                  <Sorting field={"price"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                >
                  <Typography variant="subtitle2">Quantity</Typography>&nbsp;
                  <Sorting field={"volume"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                  style={{ minWidth: "100px" }}
                >
                  <Typography variant="subtitle2">Commissions</Typography>&nbsp;
                  <Sorting
                    field={"commissions"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="right">
                <div
                  className={`${classes.tableHead} ${classes.tableHeadRight}`}
                >
                  <Typography variant="subtitle2">
                    Duties &amp; Taxes
                  </Typography>
                  &nbsp;
                  <Sorting
                    field={"dutiesTaxes"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Status</Typography>&nbsp;
                  <Sorting field={"status"} onSort={onSort} active={sortBy} />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Created by</Typography>&nbsp;
                  <Sorting
                    field={"createdBy"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Order Date (GMT)</Typography>
                  &nbsp;
                  <Sorting
                    field={"orderDate"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
              <CustomTableCell align="left">
                <div className={classes.tableHead}>
                  <Typography variant="subtitle2">Order Time (GMT)</Typography>
                  &nbsp;
                  <Sorting
                    field={"orderTime"}
                    onSort={onSort}
                    active={sortBy}
                  />
                </div>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>{ordersData}</TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default OrderTable;
