import * as types from "../actions/types";
import { sortByAsc, sortByDesc } from "../../helpers/utilities";

const INITIAL_TRADE = {
  loading: false,
  error: null,
  items: [],
  trade: null,
  filteredItems: [],
  sortBy: {},
};

export const trade2Reducer = (state = INITIAL_TRADE, action) => {
  switch (action.type) {
    case types.constants.SORT_TRADE:
      const sortBy = {
        ...state.sortBy,
        [`${action.data.field}${
          action.data.subField ? "-" + action.data.subField : ""
        }`]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(
          sortedItems,
          action.data.field,
          action.data.subField
        );
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(
          sortedItems,
          action.data.field,
          action.data.subField
        );
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_TRADE:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter((item) =>
        item.ticker.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.ACCOUNT_FILTER_TRADE:
      let trades = [];

      if (action.search) {
        state.items.forEach((_) => {
          if (_.fundAccount.id == action.search) {
            trades.push(_);
          }
        });
      } else {
        trades = [...state.items];
      }

      return { ...state, filteredItems: trades, sortBy: {} };

    case types.constants.GET_TRADE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        error: null,
      };

    case types.constants.LOADING_TRADE:
      return { ...state, loading: true };

    case types.constants.GET_TRADE_BY_ID_SUCCESS:
      return { ...state, loading: false, trade: action.trade };

    case types.constants.GET_TRADE_ERROR:
    case types.constants.GET_TRADE_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
