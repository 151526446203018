import { sortByAsc, sortByDesc } from "../../helpers/utilities";
import * as types from "../actions/types";

const INITIAL_COUNTRY = {
  loading: false,
  error: null,
  items: [],
  country: null,
  filteredItems: [],
  sortBy: {},
};

export const countryReducer = (state = INITIAL_COUNTRY, action) => {
  switch (action.type) {
    case types.constants.SORT_COUNTRY:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_COUNTRY:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter(
        (item) => item.countryName.match(filter) || item.isoCode.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        country: null,
        error: null,
      };

    case types.constants.LOADING_COUNTRY:
      return { ...state, loading: true };

    case types.constants.GET_COUNTRY_BY_ID_SUCCESS:
      return { ...state, loading: false, country: action.country };

    case types.constants.STORE_COUNTRY_SUCCESS:
    case types.constants.UPDATE_COUNTRY_SUCCESS:
      return { ...state, loading: false, country: null };

    case types.constants.DELETE_COUNTRY_SUCCESS:
      const countryIndex = state.items.findIndex(
        (item) => item.isoCode === action.id
      );

      let newState = { ...state };

      if (countryIndex >= 0) {
        newState.items.splice(countryIndex, 1);
      }

      newState.loading = false;

      return newState;

    case types.constants.GET_COUNTRY_ERROR:
    case types.constants.STORE_COUNTRY_ERROR:
    case types.constants.UPDATE_COUNTRY_ERROR:
    case types.constants.DELETE_COUNTRY_ERROR:
    case types.constants.GET_COUNTRY_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
