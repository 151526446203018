import * as types from "../actions/types";
import { sortByAsc, sortByDesc } from "../../helpers/utilities";
import { showHandle } from "../actions/order.action";

const INITIAL_ORDER = {
  loading: false,
  tradeLoading: false,
  error: {},
  items: [],
  order: null,
  trades: [],
  filteredItems: [],
  sortBy: {},
  showHandle: null,
};

export const orderReducer = (state = INITIAL_ORDER, action) => {
  switch (action.type) {
    case types.constants.SORT_ORDER:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.SHOW_HANDLE:
      return { ...state, showHandle: action.val };

    case types.constants.FILTER_ORDER:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter((item) =>
        item.ticker.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.ACCOUNT_FILTER_ORDER:
      let orders = [];

      if (action.search) {
        state.items.forEach((_) => {
          _.allocations.forEach((allocation) => {
            if (allocation.fundAccount.id == action.search) {
              orders.push(_);
            }
          });
        });
      } else {
        orders = [...state.items];
      }

      return { ...state, filteredItems: orders, sortBy: {} };

    case types.constants.GET_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        error: null,
      };

    case types.constants.ORDER_TRADE_SUCCESS:
      return {
        ...state,
        tradeLoading: false,
        trades: action.trades,
        error: null,
      };

    case types.constants.LOADING_ORDER:
      return { ...state, loading: true };

    case types.constants.LOADING_ORDER_TRADE:
      return { ...state, tradeLoading: true };

    case types.constants.STORE_ORDER_SUCCESS:
      return { ...state, loading: false, order: null };

    case types.constants.GET_ORDER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.order,
      };

    case types.constants.GET_ORDER_ERROR:
    case types.constants.STORE_ORDER_ERROR:
    case types.constants.GET_ORDER_BY_ID_ERROR:
    case types.constants.ORDER_TRADE_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
