import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import CurrencyFormat from "react-currency-format";
import { toCurrency } from "../../../helpers/utilities";

const useStyles = makeStyles(() => ({
  box: {
    margin: "0.5rem 0",
    "&:not(:last-child)": {
      borderRight: "1px solid rgba(255, 255, 255, 0.12)",
    },
  },
}));

const AccountDetail = ({ previous = 0, total = 0, ccy = "" }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={4} lg={4} className={classes.box}>
        <Box>
          <Typography variant="subtitle1">Previous value</Typography>
          <Typography
            variant="h5"
            align="right"
            style={{
              color: "#00bd80",
            }}
            component="h5"
          >
            {previous != 0 ? (
              <Box p={1}>
                <CurrencyFormat
                  value={toCurrency(Math.abs(previous))}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={`${ccy} ${previous < 0 ? "(" : ""}`}
                  suffix={`${previous < 0 ? ")" : ""}`}
                  fixedDecimalScale={true}
                />
              </Box>
            ) : (
              "-"
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} lg={4} className={classes.box}>
        <Box>
          <Typography variant="subtitle1">Total value</Typography>
          <Typography
            variant="h5"
            align="right"
            style={{
              color: "#00bd80",
            }}
            component="h5"
          >
            {total != 0 ? (
              <Box p={1}>
                <CurrencyFormat
                  value={toCurrency(Math.abs(total))}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={`${ccy} ${total < 0 ? "(" : ""}`}
                  suffix={`${total < 0 ? ")" : ""}`}
                  fixedDecimalScale={true}
                />
              </Box>
            ) : (
              "-"
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={4} lg={4} className={classes.box}>
        <Box>
          <Typography variant="subtitle1">Total net change</Typography>
          <Typography
            variant="h5"
            align="right"
            style={{
              color: total - previous > 0 ? "#00bd80" : "#E04C38",
            }}
          >
            {total - previous != 0 ? (
              <Box p={1}>
                <CurrencyFormat
                  allowNegative={true}
                  value={toCurrency(Math.abs(total - previous))}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={`${ccy} ${total - previous < 0 ? "(" : ""}`}
                  suffix={`${total - previous < 0 ? ")" : ""}`}
                  fixedDecimalScale={true}
                />
              </Box>
            ) : (
              "-"
            )}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(AccountDetail);
