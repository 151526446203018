export const sortByAsc = (data = [], field = null, objectKey = null) => {
  return data.sort((a, b) => {
    if (!a[field] || !b[field]) {
      return 0;
    }

    let aValue = a[field];
    let bValue = b[field];

    if (typeof aValue === "object" && typeof bValue === "object") {
      aValue = aValue?.[objectKey];
      bValue = bValue?.[objectKey];
    }

    if (typeof aValue == "string" && typeof bValue == "string") {
      if (
        aValue
          .toString()
          .replace("-", "")
          .replace(",", "")
          .replace(".", "")
          .toLowerCase() <
        bValue
          .toString()
          .replace("-", "")
          .replace(",", "")
          .replace(".", "")
          .toLowerCase()
      ) {
        return -1;
      }
      if (
        aValue
          .toString()
          .replace("-", "")
          .replace(",", "")
          .replace(".", "")
          .toLowerCase() >
        bValue
          .toString()
          .replace("-", "")
          .replace(",", "")
          .replace(".", "")
          .toLowerCase()
      ) {
        return 1;
      }
      return 0;
    }

    if (typeof aValue == "number" && typeof bValue == "number") {
      if (aValue < bValue) {
        return -1;
      }
      if (aValue > bValue) {
        return 1;
      }
      return 0;
    }
  });
};

export const sortByDesc = (data, field, objectKey = null) => {
  return data.sort((a, b) => {
    if (!a[field] || !b[field]) {
      return 0;
    }

    let aValue = a[field];
    let bValue = b[field];

    if (typeof aValue === "object" && typeof bValue === "object") {
      aValue = aValue?.[objectKey];
      bValue = bValue?.[objectKey];
    }

    if (typeof aValue == "string" && typeof bValue == "string") {
      if (
        aValue
          .toString()
          .replace("-", "")
          .replace(",", "")
          .replace(".", "")
          .toLowerCase() >
        bValue
          .toString()
          .replace("-", "")
          .replace(",", "")
          .replace(".", "")
          .toLowerCase()
      ) {
        return -1;
      }
      if (
        aValue
          .toString()
          .replace("-", "")
          .replace(",", "")
          .replace(".", "")
          .toLowerCase() <
        bValue
          .toString()
          .replace("-", "")
          .replace(",", "")
          .replace(".", "")
          .toLowerCase()
      ) {
        return 1;
      }
      return 0;
    }

    if (typeof aValue == "number" && typeof bValue == "number") {
      if (aValue > bValue) {
        return -1;
      }
      if (aValue < bValue) {
        return 1;
      }
      return 0;
    }
  });
};

export const toDecimal = (number, to = 4) => {
  if (number) {
    return parseFloat(number).toFixed(to);
  }
  return number;
};

export const toCurrency = (number, to = 2) => {
  if (number) {
    return parseFloat(Math.round(Math.round(number * 1000) / 10) / 100).toFixed(
      to
    );
  }
  return number;
};

export const randomColor = () => {
  let r = Math.floor(Math.random() * 255);
  let g = Math.floor(Math.random() * 255);
  let b = Math.floor(Math.random() * 255);

  return `rgb(${r},${g},${b})`;
};

export const randomString = (length = 5) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const CLIENT_ERRORS = {
  400: "Error",
  401: "Unauthorized",
  404: "Not found",
  422: "Incomplete details",
};

export const errorMessage = (code) => {
  return CLIENT_ERRORS[code] ?? "Error";
};

export const isset = (value) => {
  return value != null && value != undefined;
};

export const thousandSeparator = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const exportFunc = (fileName) => {
  var data = [];

  let heads = document.querySelector("table thead tr");
  // array of table header column values for add extra new tab in excel export
  let headerExist = [
    'Order #', 'ID', 'Trade #'
  ];
  let headerIndex = [];
  heads = [...heads.querySelectorAll("th")]
    .map((_, index) => {
      if (!("noExport" in _?.dataset)) {
        let str = _.innerText.toString().trim().replace(",", "").replace("\n", "");
        if(headerExist.includes(str)){
          headerIndex.push(index);
        }
        return str;
      }
      return undefined;
    })
    .map((_) => (_ == undefined ? "" : _));

  data.push([...heads].join(","));

  
  document.querySelectorAll("table tbody tr").forEach((tr) => {
    let rowData = [...tr.querySelectorAll("td")]
      .map((_, index) => {
        if (!("noExport" in _?.dataset)) {
          let tab = '';
          if(headerIndex.includes(index)){
            tab = '\t';
          }
          return _.innerText
            .toString()
            .trim()
            .replace(",", "")
            .replace("\n", "") + tab;
        }
        return undefined;
      })
      .map((_) => (_ == undefined ? "-" : _));

    data.push([...rowData].join(","));
  });

  downloadCSVFile(data.join("\n"), fileName);
};

export const downloadCSVFile = (csv, filename) => {
  var csv_file, download_link;

  csv_file = new Blob([csv], { type: "text/csv" });

  download_link = document.createElement("a");

  download_link.download = filename;

  download_link.href = window.URL.createObjectURL(csv_file);

  download_link.style.display = "none";

  document.body.appendChild(download_link);

  download_link.click();
};

export const debounce = function (func, delay = 500) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};


export function comma_separator(numb) {
  var str = numb.toString().split(".");
  str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return str.join(".");
}
