import { sortByAsc, sortByDesc } from "../../helpers/utilities";
import * as types from "../actions/types";

const INITIAL_CLIENT = {
  loading: false,
  error: null,
  items: [],
  funds: [],
  client: null,
  filteredItems: [],
  sortBy: {},
};

export const clientReducer = (state = INITIAL_CLIENT, action) => {
  switch (action.type) {
    case types.constants.SORT_CLIENT:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_CLIENT:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter((item) => {
        return item.name.match(filter);
      });

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        error: null,
      };

    case types.constants.GET_CLIENT_FUNDS_SUCCESS:
      return {
        ...state,
        funds: action.payload,
      };

    case types.constants.LOADING_CLIENT:
      return { ...state, loading: true };

    case types.constants.GET_CLIENT_BY_ID_SUCCESS:
      return { ...state, loading: false, client: action.client };

    case types.constants.STORE_CLIENT_SUCCESS:
    case types.constants.UPDATE_CLIENT_SUCCESS:
      return { ...state, loading: false, client: null };

    case types.constants.DELETE_CLIENT_SUCCESS:
      const clientIndex = state.items.findIndex(
        (item) => item.id === action.id
      );

      let newState = { ...state };

      if (clientIndex >= 0) {
        newState.items.splice(clientIndex, 1);
      }

      newState.loading = false;

      return newState;

    case types.constants.GET_CLIENT_ERROR:
    case types.constants.STORE_CLIENT_ERROR:
    case types.constants.UPDATE_CLIENT_ERROR:
    case types.constants.DELETE_CLIENT_ERROR:
    case types.constants.GET_CLIENT_BY_ID_ERROR:
    case types.constants.GET_CLIENT_FUNDS_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
