import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncSchedules = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/tax-duty-commission-schedules`
    );
    return data;
  } catch (error) {
    throw new Error(
      error?.response?.status ?? 0 >= 500
        ? "Something went wrong! Please try again."
        : error?.data?.message ?? "Error"
    );
  }
};

const storeAsyncSchedule = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/tax-duty-commission-schedules`,
      payload
    );

    return {
      error: false,
      data: data.message ?? "Schedule created successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncSchedule = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/tax-duty-commission-schedules/${payload}`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const updateAsyncSchedule = async (payload) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/tax-duty-commission-schedules/${payload.id}`,
      payload.data
    );

    return {
      error: false,
      data: data.message ?? "Schedule updated successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const toggleAsyncScheduleStatus = async ({ payload }) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/tax-duty-commission-schedules/${payload.id}/enable/${payload.status}`
    );

    return {
      error: false,
      data: data.message ?? `Schedule ${payload.type} successfully.`,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

export function* loadSchedules() {
  try {
    const schedules = yield call(loadAsyncSchedules);
    yield put({
      type: types.constants.GET_SCHEDULE_SUCCESS,
      payload: schedules,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_SCHEDULE_ERROR,
      payload: err.message,
    });
  }
}

export function* storeSchedule({ payload }) {
  const response = yield call(storeAsyncSchedule, payload.data);

  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.STORE_SCHEDULE_SUCCESS });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STORE_SCHEDULE_ERROR,
      payload: response.data,
    });
  }
}

export function* loadSchedule(payload) {
  const response = yield call(loadAsyncSchedule, payload);
  if (!response.error) {
    yield put({
      type: types.constants.GET_SCHEDULE_BY_ID_SUCCESS,
      schedule: response.data,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.GET_SCHEDULE_BY_ID_ERROR,
      payload: response.data,
    });
  }
}

export function* updateSchedule({ payload }) {
  const response = yield call(updateAsyncSchedule, payload);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.UPDATE_SCHEDULE_SUCCESS });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.UPDATE_SCHEDULE_ERROR,
      payload: response.data,
    });
  }
}

export function* toggleScheduleStatus(payload) {
  const response = yield call(toggleAsyncScheduleStatus, payload);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.TOGGLE_SCHEDULE_STATUS_SUCCESS,
      id: payload.payload.id,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.TOGGLE_SCHEDULE_STATUS_ERROR,
      payload: response.data,
    });
  }
}
