import { sortByAsc, sortByDesc } from "../../helpers/utilities";
import * as types from "../actions/types";

const INITIAL_HOLIDAY = {
  loading: false,
  error: null,
  items: [],
  filteredItems: [],
  holiday: {},
  sortBy: {},
};

export const holidayReducer = (state = INITIAL_HOLIDAY, action) => {
  switch (action.type) {
    case types.constants.SORT_HOLIDAY:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.GET_HOLIDAY_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        holiday: {},
        error: null,
        sortBy: {},
      };

    case types.constants.LOADING_HOLIDAY:
      return { ...state, loading: true };

    case types.constants.GET_HOLIDAY_BY_ID_SUCCESS:
      return { ...state, loading: false, holiday: action.holiday };

    case types.constants.STORE_HOLIDAY_SUCCESS:
    case types.constants.UPDATE_HOLIDAY_SUCCESS:
      return { ...state, loading: false, holiday: {} };

    case types.constants.GET_HOLIDAY_ERROR:
    case types.constants.STORE_HOLIDAY_ERROR:
    case types.constants.UPDATE_HOLIDAY_ERROR:
    case types.constants.GET_HOLIDAY_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
