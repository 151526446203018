import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExecuteTable from "./Trade/ExecuteTable";
import PendingTable from "./Trade/PendingTable";
import * as fixedIncomeCountAction from "../../../../store/actions/fixedIncomeCount.action";

import { Box, styled, Tab, Tabs } from "@material-ui/core";

const StyledTabs = styled((props) => <Tabs {...props} />)({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#0c6fb3",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minWidth: "50px !important",
  maxWidth: "50px !important",
  paddingInline: "5rem",
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "white",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  "&.Mui-selected": {
    backgroundColor: "#0c6fb3",
    borderRadius: "20px",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const TradeTab = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const totalRecordData = useSelector((state) => state?.fixedincomecount);

  useEffect(() => {
    dispatch(fixedIncomeCountAction.getExecutedTradeCountAction());
  }, [dispatch]);

  return (
    <Box>
      <StyledTabs
        value={activeTab}
        onChange={(e, value) => setActiveTab(value)}
      >
        <StyledTab
          label={`Pending ${
            totalRecordData?.blotterCount !== 0
              ? totalRecordData?.blotterCount
              : ""
          }`}
        />
        <StyledTab
          label={`Executed ${
            totalRecordData?.executedtradeCount !== 0
              ? totalRecordData?.executedtradeCount
              : ""
          }`}
        />
      </StyledTabs>
      {activeTab == 0 && (
        <PendingTable totalRecord={totalRecordData?.blotterCount} />
      )}
      {activeTab == 1 && (
        <ExecuteTable totalRecord={totalRecordData?.executedtradeCount} />
      )}
    </Box>
  );
};

export default React.memo(TradeTab);
