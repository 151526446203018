import * as types from "../actions/types";

export function authReducer(state = {}, action) {
  const response = action.response;
  switch (action.type) {
    case types.constants.REGISTER_USER_SUCCESS:
      return { ...state, response };
    case types.constants.REGISTER_USER_ERROR:
      return state;
    default:
      return state;
  }
}
