import * as types from "../actions/types";

export function tradeReducer(state = {}, action) {
  switch (action.type) {
    case types.constants.GET_CCY_LIST_SUCCESS:
      return {
        ...state,
        ccylist: action.ccylist,
      };
    case types.constants.GET_CCY_LIST_ERROR:
      return {
        ...state,
        cylisterror: "Error",
      };
    default:
      return state;
  }
}
