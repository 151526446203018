import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncCountries = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/countries`
    );
    return data;
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const storeAsyncCountry = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/countries`,
      payload
    );

    return {
      error: false,
      data: data.message ?? "Country created successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncCountry = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/countries/${payload}`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const updateAsyncCountry = async (payload) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/countries/${payload.id}`,
      payload.data
    );

    return {
      error: false,
      data: data.message ?? "Broker updated successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const deleteAsyncCountry = async ({ payload }) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/currencies/api/v1/countries/${payload}`
    );

    return data.message ?? "Country deleted successfully.";
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

export function* loadCountries() {
  try {
    const countries = yield call(loadAsyncCountries);
    yield put({
      type: types.constants.GET_COUNTRY_SUCCESS,
      payload: countries,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_COUNTRY_ERROR,
      payload: err.message,
    });
  }
}

export function* storeCountry({ payload }) {
  const response = yield call(storeAsyncCountry, payload.data);

  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.STORE_COUNTRY_SUCCESS });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STORE_COUNTRY_ERROR,
      payload: response.data,
    });
  }
}

export function* loadCountry(payload) {
  const response = yield call(loadAsyncCountry, payload);
  if (!response.error) {
    yield put({
      type: types.constants.GET_COUNTRY_BY_ID_SUCCESS,
      country: response.data,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.GET_COUNTRY_BY_ID_ERROR,
      payload: response.data,
    });
  }
}

export function* updateCountry({ payload }) {
  const response = yield call(updateAsyncCountry, payload.data);

  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.UPDATE_COUNTRY_SUCCESS });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    yield put({
      type: types.constants.UPDATE_COUNTRY_ERROR,
      payload: response.data,
    });
  }
}

export function* deleteCountry(payload) {
  try {
    const message = yield call(deleteAsyncCountry, payload);

    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({
      type: types.constants.DELETE_COUNTRY_SUCCESS,
      id: payload.payload,
    });
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_COUNTRY_ERROR,
      payload: err.message,
    });
  }
}
