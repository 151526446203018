import moment from "moment";
import CurrencyFormat from "react-currency-format";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  Typography,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#252525",
      color: "#dedede",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
    },
    "& .MuiDialogContent-root": {
      color: "#dedede !important",
    },
  },
  dialogActions: {
    margin: "0 auto",
    marginTop: "2rem",
  },
  cancelBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#3d3d3d",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#3d3d3d",
    },
  },
  dialogContent: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "1rem 0",
    "& > *": {
      padding: "0.5rem",
      border: "1px solid grey",
      borderRadius: "2px",
      flexGrow: "1",
      flexShrink: "0 !important",
      marginBottom: "0.5rem",
    },
  },
  reviewBadge: {
    backgroundColor: "#22312c",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.7rem 1.5rem",
    borderRadius: "10px",
    minWidth: "150px",
  },
}));

const ViewBondModal = (props) => {
  const classes = useStyles();

  return (
    <Dialog {...props} className={classes.dialog} disableBackdropClick>
      <DialogTitle>{props?.bond?.isin}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} className={classes.dialogContent}>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Bond ID
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.bond_id ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Exchange
            </Typography>
            <Typography variant="body2" component="p">
              {props?.symbolDetail?.exchange_name ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Ticker
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.symbol ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Coupon Type
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.coupon_type ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Issuer
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.company ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Coupon Rate
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.coupon_rate ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              ISIN
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.isin ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Currency
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.ccy_suffix ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Issued Amount
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.issued_quantity ? (
                <CurrencyFormat
                  value={props?.bond?.issued_quantity}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              ) : (
                "-"
              )}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Tender Number
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.tender_number ?? "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Issued Date
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.issued_date
                ? moment(props?.bond?.issued_date).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" component="h6">
              Maturity Date
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.maturity_date
                ? moment(props?.bond?.maturity_date).format("DD/MM/YYYY")
                : "-"}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          variant="contained"
          onClick={props.onHide}
          className={classes.cancelBtn}
          size="small"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewBondModal;
