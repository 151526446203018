import React, { Suspense, useEffect, useState } from "react";
import {
  Redirect, Route,
  Switch, useHistory, useLocation
} from "react-router-dom";
import Account from "../../container/Account/Account";
import FixedIncome from "../../container/FixedIncome/FixedIncome";
import Trade from "../../container/Trade/Trade";
import Footer from "./Footer";
import Header from "./Header";

import Select from "react-select";
import { menus } from "../../helpers/menus";

import {
  Box,
  Button,
  Select as MSelect,
  MenuItem,
  OutlinedInput,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useKeycloak } from "@react-keycloak/web";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loadAllClients } from "../../store/actions/client.action";
import { loadAllCurrencies } from "../../store/actions/currency.action";
import { loadAllFunds } from "../../store/actions/fund.action";
import {
  loadAllFundAccounts,
  loading
} from "../../store/actions/fundAccount.action";
import Loader from "../Loader";

import { Print } from "@material-ui/icons";
import AcceptancePolicy from "../../AcceptancePolicy";
import RoleBasedRoute from "../../helpers/RoleBasedRoute";
import { sortByAsc } from "../../helpers/utilities";
import ContactAdministrator from "../ContactAdministrator/ContactAdministrator";

let ExternalLinks = [
  { label: "Newsroom", url: process.env.REACT_APP_NEWSROOM },
  { label: "Research", url: process.env.REACT_APP_RESEARCH },
  {
    label: "Market Data",
    url: process.env.REACT_APP_MARKET_DATA,
  },
  { label: "Analytics", url: process.env.REACT_APP_ANALYTICS },
  { label: "Liquidity", url: process.env.REACT_APP_LIQUIDITY },
];

const useStyles = makeStyles(() => ({
  mainContent: {
    overflowX: "hidden",
    backgroundColor: "#2E2E2F",
    minHeight: "80vh",
    margin: "1rem",
    marginTop: "80px",
    overflowY: "hidden",
  },
  externalLinks: {
    textDecoration: "none",
    color: "#fff",
  },

  headerTab: {
    backgroundColor: "#2E2E2F",
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "0.5px solid #0c6fb3",
    borderRadius: "20px",
    marginBottom: "1rem",
    transition: "all 300ms ease-in-out",
    overflow: "auto",
    marginRight: "10px",
    cursor: "pointer",
    "& > *": {
      padding: "0.4rem 1rem",
    },
    "& > *:hover": {
      backgroundColor: "#0c6fb3",
      color: "white",
    },
    "& > *.active": {
      backgroundColor: "#0c6fb3",
      color: "white",
    },
  },
  select: {
    width: "auto !important",
    height: "35px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:not(:last-child)": {
      marginRight: "0.5rem",
    },
  },
  "@media print": {
    hideOnPrint: {
      display: "none !important",
    },
  },
}));

let adminRoutes = [];
menus.forEach((menu, menuIndex) => {
  menu.menus.forEach((_) => {
    adminRoutes.push(
      <RoleBasedRoute
        key={`${_.to}-${menuIndex}`}
        exact={_.exact}
        path={_.to}
        component={_.component}
        roles={_.roles ?? []}
      />
    );

    _.subs.forEach((sub, subIndex) => {
      adminRoutes.push(
        <RoleBasedRoute
          key={`${_.to}-${sub.to}-${subIndex}`}
          exact={sub.exact}
          path={`${_.to}${sub.to}`}
          component={sub.component}
          roles={_.roles ?? []}
        />
      );
    });
  });
});

const Layout = () => {
  const classes = useStyles();

  const { pathname } = useLocation();

  const history = useHistory();

  const dispatch = useDispatch();

  const { keycloak, initialized } = useKeycloak();

  const [fund, setFund] = useState({});
  const [account, setAccount] = useState("All");

  const { items: funds } = useSelector((state) => state.funds);
  const { items: accounts, loading: accountLoading } = useSelector(
    (state) => state.fundAccount2s
  );
  const [setModel, setAccModel] = useState(true);

  useEffect(() => {
    if (initialized) {
      axios.interceptors.request.use((config) => {
        if (keycloak.authenticated) {
          config.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
        }
        return config;
      });
    }
  }, [keycloak, initialized]);

  useEffect(() => {
    if (initialized && keycloak.authenticated && !setModel) {
      dispatch(loadAllFunds());
      dispatch(loadAllClients());
      dispatch(loadAllCurrencies());
    }
  }, [dispatch, initialized, keycloak, setModel]);

  useEffect(() => {
    if (funds.length > 0) {
      setFund(funds[0]);
    }
  }, [funds]);

  useEffect(() => {
    if ("id" in fund) {
      setAccount("All");
      dispatch(loading());
      dispatch(loadAllFundAccounts(fund.id));
    }
  }, [fund, dispatch]);

  let headerTabs = null;

  if (keycloak?.realmAccess?.roles.length > 0 && !pathname.includes("admin")) {
    headerTabs = (
      <Box className={classes.headerTab}>
        <Typography
          variant="subtitle1"
          onClick={() => history.push("/trade")}
          className={`${["/trade", "/"].includes(pathname) ? "active" : ""}`}
        >
          Equity
        </Typography>
        <Typography
          variant="subtitle1"
          onClick={() => history.push("/fixed-income")}
          className={`${["/fixed-income"].includes(pathname) ? "active" : ""}`}
        >
          Fixed Income
        </Typography>
        <Typography
          variant="subtitle1"
          onClick={() => history.push("/accounts")}
          className={`${["/accounts"].includes(pathname) ? "active" : ""}`}
        >
          My Accounts
        </Typography>
        {ExternalLinks.map((_, key) => (
          <Typography
            key={key}
            className={classes.externalLinks}
            variant="subtitle1"
            component="a"
            href={_.url}
            style={{ color: "#fff" }}
          >
            {_.label}
          </Typography>
        ))}
      </Box>
    );
  }

  
  return (
    <>
      {initialized ? (

        <>
        {
          initialized && setModel?
          <AcceptancePolicy setAccModel={setAccModel} />
          : 
          <>
            <Header />
            {keycloak?.realmAccess?.roles.length > 0 ? (
                <Box
                  className={classes.mainContent}
                  component="main"
                  style={{
                    marginLeft:
                      pathname.includes("admin") &&
                      keycloak?.realmAccess?.roles.some((val) =>
                        [
                          "DF_ADMIN",
                          "bizio_finance_admin",
                          "BROKER_ADMIN",
                          "OPERATIONS",
                        ].includes(val)
                      )
                        ? "260px"
                        : "",
                  }}
                >
                  {!keycloak?.realmAccess?.roles.includes("OPERATIONS") && (
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      className={classes.hideOnPrint}
                    >
                      {headerTabs}
                      <Box
                        display={"flex"}
                        justifyContent={"flex-start"}
                        alignItems={"center"}
                      >
                        {["/accounts"].includes(pathname) && (
                          <Button
                            style={{
                              marginRight: "0.5rem",
                            }}
                            variant="contained"
                            color="primary"
                            onClick={() => window.print()}
                          >
                            <Print />
                          </Button>
                        )}
                        {["/", "/trade", "/fixed-income", "/accounts"].includes(
                          pathname
                        ) && (
                          <>
                          <Select
                            onChange={(e) => {setFund(e?.value)}}
                            name="fund"
                            options={sortByAsc(funds, "name").map((fund) => {
                              return {
                                label: fund.name,
                                value: fund,
                              };
                            })}
                            value={funds?.map((fund) => {
                                return { label: fund.name,value: fund }
                              }).filter(function(option) {
                                return option?.value?.id === fund?.id;
                              })
                            }
                            placeholder={"Select Group account"}
                            label="Group account"
                            width={'500px'}
                            className={`group-account-dd`}
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: "999 !important",
                                backgroundColor: "#313131",
                                ":hover": {
                                  backgroundColor: "#313131",
                                },
                              }),
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "#252525 !important",
                                border: "1px solid #ffffff3b",
                                outline: "none",
                                // padding: "6px 0",
                                padding: '0px 0px',
                                height: '35px !important',
                                width: '250px',
                              }),
                              option: (provided) => ({
                                ...provided,
                                color: "#fcfcfc",
                                backgroundColor: "#252525 !important",
                                cursor: "pointer",
                                fontSize: "12px",
                                textAlign: "left",
                                ":hover": {
                                  backgroundColor: "#454545 !important",
                                },
                                ":active": {
                                  backgroundColor: "#454545 !important",
                                },
                              })
                            }}
                          />
                          {/* <MSelect
                            name="fund"
                            displayEmpty
                            onChange={(e) => setFund(e.target.value)}
                            value={fund}
                            input={<OutlinedInput />}
                            label="Group account"
                            className={classes.select}
                          >
                            <MenuItem disabled value={{}}>
                              Select Group account
                            </MenuItem>
                            {sortByAsc(funds, "name").map((fund) => (
                              <MenuItem key={`fund-${fund.id}`} value={fund}>
                                {fund.name}
                              </MenuItem>
                            ))}
                          </MSelect> */}
                          </>
                        )}
                        {["/accounts"].includes(pathname) && (
                          <MSelect
                            name="account"
                            displayEmpty
                            onChange={(e) => setAccount(e.target.value)}
                            value={account}
                            input={<OutlinedInput />}
                            label="Fund Account"
                            className={classes.select}
                          >
                            <MenuItem value={"All"}>All</MenuItem>
                            {sortByAsc(accounts, "fundAccountName")
                              .filter((_) => _.disabled === false)
                              .map((account) => (
                                <MenuItem
                                  key={`account-${account.id}`}
                                  value={account}
                                >
                                  {account.fundAccountName}
                                </MenuItem>
                              ))}
                          </MSelect>
                        )}
                      </Box>
                    </Box>
                  )}
                  {keycloak?.realmAccess?.roles.includes("OPERATIONS") ? (
                    <Switch>
                      <Redirect
                        exact
                        from="/trade"
                        to="/admin/external-transactions"
                      />
                      <Suspense fallback={<Loader />}>{adminRoutes}</Suspense>
                    </Switch>
                  ) : (
                    <Switch>
                      <Route exact path="/trade">
                        <Trade fund={fund} />
                      </Route>
                      <Route exact path="/fixed-income">
                        <FixedIncome fund={fund} />
                      </Route>
                      <Route exact path="/accounts">
                        <Account
                          fund={fund}
                          account={account}
                          accounts={accounts}
                          accountLoading={accountLoading}
                        />
                      </Route>
                      <Route exact path="/">
                        <Trade fund={fund} />
                      </Route>
                      <Suspense fallback={<Loader />}>{adminRoutes}</Suspense>
                    </Switch>
                  )}
                </Box>
              ) : (
                <ContactAdministrator
                  classes={classes.mainContent}
                  component="main"
                  styles={{
                    marginLeft: pathname.includes("admin") ? "260px" : "",
                  }}
                />
              )}
              <Footer />
            </>
          }
          </>
        ) : (
        <Loader />
      )}
    </>
  );
};

export default Layout;
