import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { makeStyles, MenuItem, OutlinedInput, Select } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  select: {
    width: "auto !important",
    height: "35px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SYMBOLS = {
  BWP: "P",
  NGN: "₦",
  KES: "Ksh",
  GHS: "₵",
  USD: "$",
};

const CurrencyList = ({ setActiveCurrency, activeCurrency }) => {
  const classes = useStyles();

  const { items: currencies } = useSelector((state) => state.currencies);

  // const [currencyLists, setCurrencyLists] = useState([]);

  // useEffect(() => {
  //   if (currencies.length > 0) {
  //     let data = [];
  //     Object.keys(SYMBOLS).forEach((_) => {
  //       const currency = currencies.find((__) => __.isoCode == _);
  //       if (currency) {
  //         data.push({
  //           symbol: SYMBOLS[_],
  //           isoCode: _,
  //           name: currency.name,
  //         });
  //       } else {
  //         data.push({ symbol: SYMBOLS[_], isoCode: _ });
  //       }
  //     });
  //     setCurrencyLists(data);
  //   }
  // }, [currencies]);

  return (
    <Select
      displayEmpty
      input={<OutlinedInput />}
      value={activeCurrency}
      onChange={(e) => {
        setActiveCurrency(e.target.value);
      }}
      className={classes.select}
    >
      {currencies?.map((currency) => (
        <MenuItem key={`currency-${currency.isoCode}`} value={currency.isoCode}>
          {currency.isoCode}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencyList;
