import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Login from "../container/Login/Login";
import Register from "../container/Register/Register";
import { PrivateRoute } from "../helpers/PrivateRoute";

import { useKeycloak } from "@react-keycloak/web";
import Loader from "../components/Loader";
import { makeStyles, Box } from "@material-ui/core";

import LoginBgImage from "../assets/Images/login_page_background.png";
import Forgotpassword from "../container/ForgotPassword/Forgotpassword";
import ResetPassword from "../container/ResetPassword/ResetPassword";

const useStyles = makeStyles(() => ({
  bg: {
    backgroundColor: "#C5C5C5",
    backgroundImage: `url(${LoginBgImage})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Routes = () => {
  const classes = useStyles();

  const { initialized } = useKeycloak();

  return initialized ? (
    <BrowserRouter>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/forgot-password" component={Forgotpassword} />
        <Route path="/reset-password" component={ResetPassword} />
        {/* <Route path="/register" component={Register} /> */}
        <PrivateRoute path="/" component={Layout} />
        <Redirect to="/trade" from="/" />
      </Switch>
    </BrowserRouter>
  ) : (
    <Box className={classes.bg}>
      <Loader showBg={false} />
    </Box>
  );
};
export default Routes;
