import React, { useEffect, useState } from "react";

import {
  Box, Button,
  Collapse, IconButton, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, TextField, Typography
} from "@material-ui/core";

import { Tooltip } from "@material-ui/core";
import { makeStyles, styled } from "@material-ui/core/styles";
import { AddCircle, Close, RemoveCircle, TextFormatRounded } from "@material-ui/icons";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";
import "moment-timezone";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import Sorting from "../../../components/Sorting/Sorting";
import { thousandSeparator, toCurrency } from "../../../helpers/utilities";
import {
  loadOrderTrades,
  sortOrder,
  tradeLoading
} from "../../../store/actions/order.action";

const CustomTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    borderBottom: "none !important",
    color: "#808080",
  },
});

const useStyles = makeStyles(() => ({
  minWidth: {
    minWidth: "170px !important",
  },
  tableHead: {
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: "75px",
    minWidth: "75px",
  },
  tableHeadRight: {
    justifyContent: "flex-end !important",
    textAlign: "right !important",
  },
  "@media (max-width: 1024px)": {
    tableHead: {
      justifyContent: "flex-start",
      maxWidth: "125px",
      minWidth: "125px",
    },
  },
  mixFields: {
    borderRight: "0px !important",
    marginRight: "0.5rem",
    minWidth: "40ch !important",
    "& .MuiOutlinedInput-input": {
      padding: "10px 0px !important",
      paddingLeft: "10px !important",
    },
  },
  inputField: {
    borderRight: "0px !important",
    minWidth: "60% !important",
    "& .MuiOutlinedInput-input": {
      padding: "10px 0px !important",
      paddingLeft: "10px !important",
    },
  },
  "@media (max-width: 1024px)": {
    inputField: {
      minWidth: "80% !important",
    },
  },
  cancelBtn: {
    backgroundColor: "#f50057 !important",
    color: "white !important",
    minWidth: "max-content !important",
    marginLeft: "0.5rem !important",
  },
  DisbleClick:{
    cursor: 'not-allowed'
  }
}));

const HistoryTable = ({
  data: histories = [],
  sortBy = {},
  historyLoading = false,
  error = null,
  detailPageBtn = false,
  cancelOrderHandler = (id) => null,
  closeOrderHandler = (id) => null,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let historyData = null;

  const [history, setHistory] = useState({});

  const { tradeLoading: tradeLoader, trades } = useSelector(
    (state) => state.orders
  );

  useEffect(() => {
    if (Object.keys(history).length > 0) {
      dispatch(tradeLoading());
      dispatch(loadOrderTrades(history.id));
    }
  }, [history]);

  const { keycloak } = useKeycloak();
  const roles = keycloak?.realmAccess?.roles ?? [];
  const clickActionAllowed = roles.some((_) =>
    ["DF_ADMIN", "BROKER_ADMIN", "TRADER"].includes(_)
  );

  const createHistoryUI = (history) => {
    return (
      <Box
        color={"white"}
        display={"flex"}
        justifyContent={"flex-start"}
        alignItems={"center"}
      >
        <TextField
          variant="outlined"
          className={classes.mixFields}
          label={"Filled / Outstanding volume"}
          value={`${
            thousandSeparator(history?.filledVolume ?? "0") ?? "-"
          } / ${thousandSeparator(history?.outstandingVolume ?? "0")}`}
          InputProps={{
            disabled: true,
            readOnly: true,
          }}
        />
        {history?.rejectExpirationMessage && (
          <>
            <TextField
              variant="outlined"
              className={classes.inputField}
              label={"Message"}
              value={history?.rejectExpirationMessage ?? "-"}
              InputProps={{
                disabled: true,
                readOnly: true,
              }}
            />
            {history["status"] ? (
              <Box
                display={"flex"}
                justifyContent="flex-start"
                alignItems="center"
              >
                {["REJECTED"].includes(history["status"]) && (
                  <Button
                    color="secondary"
                    size="small"
                    onClick={() => {
                      if(clickActionAllowed)
                      closeOrderHandler(history["id"])
                    }}
                    className={`${classes.cancelBtn} ${!clickActionAllowed ? classes.DisbleClick : ''}`}
                  >
                    Close
                  </Button>
                )}
              </Box>
            ) : (
              "-"
            )}
          </>
        )}
      </Box>
    );
  };

  let tradesData = null;
  if (tradeLoader) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={9}>Please wait loading...</td>
      </tr>
    );
  } else if (!tradeLoader && error) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={9}>
          {typeof error === "string"
            ? error
            : "Something went wrong! Please try again."}
        </td>
      </tr>
    );
  } else if (trades.length === 0) {
    tradesData = (
      <tr className="text-center">
        <td colSpan={9}>No trades yet</td>
      </tr>
    );
  } else {
    tradesData = trades.map((trade, tradeIndex) => {
      return (
        <TableRow hover key={`tr-${tradeIndex}`}>
          <CustomTableCell padding="default" align="left">
            {trade["id"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["price"] ? (
              <CurrencyFormat
                value={toCurrency(trade["price"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["volume"] ? (
              <CurrencyFormat
                value={trade["volume"]}
                thousandSeparator
                displayType="text"
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["commissions"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(trade["commissions"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="right">
            {trade["dutiesTaxes"] > 0 ? (
              <CurrencyFormat
                value={toCurrency(trade["dutiesTaxes"])}
                thousandSeparator
                displayType="text"
                fixedDecimalScale={true}
              />
            ) : (
              "-"
            )}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["status"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["execId"] ?? "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["tradeDate"]
              ? moment(trade["tradeDate"]).format("DD/MM/YYYY")
              : "-"}
          </CustomTableCell>
          <CustomTableCell padding="default" align="left">
            {trade["tradeDate"]
              ? moment(trade["tradeDate"]).tz("GMT").format("hh:mm:ss")
              : "-"}
          </CustomTableCell>
        </TableRow>
      );
    });
  }

  const detailsData = (history_, historyIndex) => (
    <TableRow
      hover
      key={`detail-row-${historyIndex}`}
      style={{
        borderTop: "0.5px solid #808080",
        borderBottom: "0.5px solid #808080",
      }}
    >
      <CustomTableCell colSpan={14 + +Boolean(detailPageBtn)}>
        <Collapse
          in={history_?.id == history?.id}
          timeout="auto"
          unmountOnExit
          style={{
            margin: "0 3rem",
            padding: "1rem",
            border: "0.5px solid rgba(255, 255, 255, 0.12)",
          }}
        >
          {createHistoryUI(history)}
          <Box style={{ marginTop: "1rem" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Trade #</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Price</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Quantity</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Commissions</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="right">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">
                          Duties &amp; Taxes
                        </Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">Status</Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">
                          Execution ID
                        </Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">
                          Trade Date (GMT)
                        </Typography>
                      </div>
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <div className={classes.modalTable}>
                        <Typography variant="subtitle2">
                          Trade Time (GMT)
                        </Typography>
                      </div>
                    </CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tradesData}</TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Collapse>
      </CustomTableCell>
    </TableRow>
  );

  const mapFundAccount = (allocations) => {
    let result = allocations.map(
      (allocation) => allocation?.fundAccount?.fundAccountName ?? ""
    );
    return result.join(", ");
  };

  const onSort = (field, sortBy) => {
    dispatch(sortOrder({ field, sortBy }));
  };

  if (historyLoading) {
    historyData = (
      <tr className="text-center">
        <td colSpan={16 + +Boolean(detailPageBtn)}>Please wait loading...</td>
      </tr>
    );
  } else if (!historyLoading && error) {
    historyData = (
      <tr className="text-center">
        <td colSpan={16 + +Boolean(detailPageBtn)}>
          {typeof error === "string"
            ? error
            : "Something went wrong! Please try again."}
        </td>
      </tr>
    );
  } else if (histories.length === 0) {
    historyData = (
      <tr className="text-center">
        <td colSpan={16 + +Boolean(detailPageBtn)}>No data yet</td>
      </tr>
    );
  } else {
    historyData = histories.map((history_, historyIndex) => {
      return (
        <>
          <TableRow
            hover
            key={`tr-${historyIndex}`}
            onClick={(e) => {
              if (e?.target?.dataset?.["closeBtn"]) {
                return cancelOrderHandler(history_["id"]);
              }
              setHistory((prev) => {
                if (prev?.id == history_?.id) {
                  return {};
                }
                return history_;
              });
            }}
            style={{ cursor: "pointer" }}
          >
            <CustomTableCell
              padding="default"
              align="left"
              className={classes.minWidth}
            >
              {detailPageBtn && (
                <IconButton variant="contained" color="inherit" size="small">
                  {
                    history_['status'] === 'MODIFIED'?
                    (
                      <Tooltip
                      placement="top-start"
                      title={history_["rejectExpirationMessage"] ?? "-"}
                      arrow
                    >
                      <span style={{background: '#f00',borderRadius: '50%', display: 'flex'}}>
                        <TextFormatRounded rounded fontSize="inherit" style={{ color: "#333" }} />
                      </span>
                    </Tooltip>
                      
                    )
                    :
                    (
                    history?.id == history_.id ? (
                      <RemoveCircle fontSize="inherit" />
                    ) : (
                      <AddCircle fontSize="inherit" />
                    )
                  )
                  }
                </IconButton>
              )}
              {history_["id"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["ticker"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["symbol"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["venue"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["orderType"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["action"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["timeInForce"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["ccy"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["allocations"]?.length > 0
                ? mapFundAccount(history_["allocations"])
                : "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="right">
              {history_["price"] != null ? (
                <CurrencyFormat
                  value={toCurrency(history_["price"])}
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={true}
                />
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="right">
              {history_["volume"] != null ? (
                <CurrencyFormat
                  value={history_["volume"]}
                  thousandSeparator
                  displayType="text"
                />
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="right">
              {history_["commissions"] > 0 ? (
                <CurrencyFormat
                  value={toCurrency(history_["commissions"])}
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={true}
                />
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="right">
              {history_["dutiesTaxes"] > 0 ? (
                <CurrencyFormat
                  value={toCurrency(history_["dutiesTaxes"])}
                  thousandSeparator
                  displayType="text"
                  fixedDecimalScale={true}
                />
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["status"] ? (
                <Box
                  display={"flex"}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {history_["status"]}
                  {["ENQUEUED", "OPEN", "PARTIALLY_FILLED"].includes(
                    history_["status"]
                  ) && (
                    !clickActionAllowed ? (
                      <Button
                        color="secondary"
                        size="small"
                        className={`${classes.cancelBtn} ${classes.DisbleClick} `}
                      >
                        <Close fontSize="inherit" />
                      </Button>
                    ):(
                      <Button
                        color="secondary"
                        size="small"
                        data-close-btn
                        className={classes.cancelBtn}
                      >
                        <Close data-close-btn fontSize="inherit" />
                      </Button>
                    )
                    
                  )}
                </Box>
              ) : (
                "-"
              )}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["createdBy"] ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {moment(history_["orderDate"]).format("DD/MM/YYYY") ?? "-"}
            </CustomTableCell>
            <CustomTableCell padding="default" align="left">
              {history_["orderTime"] ?? "-"}
            </CustomTableCell>
          </TableRow>
          {detailPageBtn &&
            history?.id == history_.id &&
            detailsData(history_, historyIndex)}
        </>
      );
    });
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <CustomTableCell align="left" className={classes.minWidth}>
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Order #</Typography>&nbsp;
                <Sorting field={"id"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Ticker</Typography>&nbsp;
                <Sorting field={"ticker"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Symbol</Typography>&nbsp;
                <Sorting field={"symbol"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Exchange</Typography>&nbsp;
                <Sorting field={"venue"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Order Type</Typography>&nbsp;
                <Sorting field={"orderType"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Side</Typography>&nbsp;
                <Sorting field={"action"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Type</Typography>&nbsp;
                <Sorting
                  field={"timeInForce"}
                  onSort={onSort}
                  active={sortBy}
                />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Ccy</Typography>&nbsp;
                <Sorting field={"ccy"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Account</Typography>
              </div>
            </CustomTableCell>
            <CustomTableCell align="right">
              <div className={`${classes.tableHead} ${classes.tableHeadRight}`}>
                <Typography variant="subtitle2">Price</Typography>&nbsp;
                <Sorting field={"price"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="right">
              <div className={`${classes.tableHead} ${classes.tableHeadRight}`}>
                <Typography variant="subtitle2">Quantity</Typography>&nbsp;
                <Sorting field={"volume"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="right">
              <div
                className={`${classes.tableHead} ${classes.tableHeadRight}`}
                style={{ minWidth: "100px" }}
              >
                <Typography variant="subtitle2">Commissions</Typography>&nbsp;
                <Sorting
                  field={"commissions"}
                  onSort={onSort}
                  active={sortBy}
                />
              </div>
            </CustomTableCell>
            <CustomTableCell align="right">
              <div className={`${classes.tableHead} ${classes.tableHeadRight}`}>
                <Typography variant="subtitle2">Duties &amp; Taxes</Typography>
                &nbsp;
                <Sorting
                  field={"dutiesTaxes"}
                  onSort={onSort}
                  active={sortBy}
                />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Status</Typography>&nbsp;
                <Sorting field={"status"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Created by</Typography>&nbsp;
                <Sorting field={"createdBy"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Order Date (GMT)</Typography>
                &nbsp;
                <Sorting field={"orderDate"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
            <CustomTableCell align="left">
              <div className={classes.tableHead}>
                <Typography variant="subtitle2">Order Time (GMT)</Typography>
                &nbsp;
                <Sorting field={"orderTime"} onSort={onSort} active={sortBy} />
              </div>
            </CustomTableCell>
          </TableRow>
        </TableHead>
        <TableBody>{historyData}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(HistoryTable);
