import * as types from "./types";

export const loading = () => {
  return { type: types.constants.LOADING_FUND };
};

export const sortFund = (data) => {
  return {
    type: types.constants.SORT_FUND,
    data,
  };
};

export const filterFund = (search) => {
  return { type: types.constants.FILTER_FUND, search };
};

export const loadAllFunds = () => {
  return { type: types.constants.GET_FUND };
};

export const loadFund = (payload) => {
  return { type: types.constants.GET_FUND_BY_ID, payload };
};

export const storeFund = (payload) => {
  return { type: types.constants.STORE_FUND, payload };
};

export const updateFund = (payload) => {
  return { type: types.constants.UPDATE_FUND, payload };
};

export const deleteFund = (payload) => {
  return { type: types.constants.DELETE_FUND, payload };
};

export const storeFundPosition = (payload) => {
  return { type: types.constants.STORE_FUND_POSITIONS, payload };
};

export const loaderFundBalanceSheet = (payload) => {
  return { type: types.constants.LOADING_FUND_BALANCE_SHEET, payload };
};

export const loadFundBalanceSheet = (payload) => {
  return { type: types.constants.LOAD_FUND_BALANCE_SHEET, payload };
};

export const loaderFundLedger = (payload) => {
  return { type: types.constants.LOADING_FUND_LEDGER, payload };
};

export const loadFundLedger = (payload) => {
  return { type: types.constants.LOAD_FUND_LEDGER, payload };
};
