import * as types from "../actions/types";
import { sortByAsc, sortByDesc } from "../../helpers/utilities";

const INITIAL_VENUE = {
  loading: false,
  error: null,
  items: [],
  venue: null,
  filteredItems: [],
  sortBy: {},
};

export const venueReducer = (state = INITIAL_VENUE, action) => {
  switch (action.type) {
    case types.constants.SORT_VENUE:
      const sortBy = {
        ...state.sortBy,
        [action.data.field]: action.data.sortBy,
      };

      let sortedItems = [...state.filteredItems];

      if (action.data.sortBy === "asc") {
        sortedItems = sortByAsc(sortedItems, action.data.field);
      }

      if (action.data.sortBy === "desc") {
        sortedItems = sortByDesc(sortedItems, action.data.field);
      }

      return {
        ...state,
        filteredItems: sortedItems,
        sortBy,
      };

    case types.constants.FILTER_VENUE:
      const filter = new RegExp(action.search, "i");

      const filteredItems = state.items.filter((item) =>
        item.name.match(filter)
      );

      return { ...state, filteredItems, sortBy: {} };

    case types.constants.GET_VENUE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
        filteredItems: action.payload,
        sortBy: {},
        error: null,
      };

    case types.constants.LOADING_VENUE:
      return { ...state, loading: true };

    case types.constants.GET_VENUE_BY_ID_SUCCESS:
      return { ...state, loading: false, venue: action.venue };

    case types.constants.STORE_VENUE_SUCCESS:
    case types.constants.UPDATE_VENUE_SUCCESS:
      return { ...state, loading: false, venue: null };

    case types.constants.DELETE_VENUE_SUCCESS:
      const venueIndex = state.items.findIndex((item) => item.id === action.id);

      let newState = { ...state };

      if (venueIndex >= 0) {
        newState.items.splice(venueIndex, 1);
      }

      newState.loading = false;

      return newState;

    case types.constants.GET_VENUE_ERROR:
    case types.constants.STORE_VENUE_ERROR:
    case types.constants.UPDATE_VENUE_ERROR:
    case types.constants.DELETE_VENUE_ERROR:
    case types.constants.GET_VENUE_BY_ID_ERROR:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
