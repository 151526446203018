import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddlerware from "redux-saga";
import rootReducer from "./store/reducers";
import { watchUserAuthentication } from "./store/sagas/index";
import { ToastContainer } from "react-toastify";
import { composeWithDevTools } from "redux-devtools-extension";

import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keyCloak";
import { WebSocketProvider } from "./store/context/websocket-context";
import App from "./App";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const sagaMiddlewere = createSagaMiddlerware();
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddlewere))
);
sagaMiddlewere.run(watchUserAuthentication);

ReactDOM.render(
  <ReactKeycloakProvider authClient={keycloak} initOptions={{ pkceMethod: "S256" }}>
    <WebSocketProvider>
      <Provider store={store}>
        <ToastContainer
          bodyClassName="toastBody"
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </Provider>
    </WebSocketProvider>
  </ReactKeycloakProvider>,
  document.getElementById("root")
);
