import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Button,
  Box,
} from "@material-ui/core";

import { DatePicker } from "@material-ui/pickers";

import moment from "moment";

import { useState, useRef, useEffect } from "react";

import { ArrowDropDown } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  inputField: {
    borderRight: "0px !important",
    maxWidth: "15ch",
    "& .MuiOutlinedInput-input": {
      padding: "10px 0px !important",
      textAlign: "center",
    },
  },
  dropDownBtn: {
    padding: "",
    minWidth: "auto !important",
    backgroundColor: "#252525",
    color: "white",
    border: "none !important",
    outline: "none !important",
    "&:hover": {
      backgroundColor: "#252525",
      color: "white",
    },
  },
  menuZIndex: {
    zIndex: "10 !important",
  },
  "@media (max-width: 768px)": {
    btnGrp: {
      marginTop: "1rem",
      marginBottom: "1rem",
    },
  },
}));

const options = [
  {
    label: "Today",
    onClick: (setStartDate, setEndDate) => {
      setStartDate(moment());
      setEndDate(moment());
    },
  },
  {
    label: "Yesterday",
    onClick: (setStartDate, setEndDate) => {
      setStartDate(moment().subtract("1", "days"));
      setEndDate(moment().subtract("1", "day"));
    },
  },
  {
    label: "Last 7 Days",
    onClick: (setStartDate, setEndDate) => {
      setStartDate(moment().subtract("7", "days"));
      setEndDate(moment());
    },
  },
  {
    label: "Last 30 Days",
    onClick: (setStartDate, setEndDate) => {
      setStartDate(moment().subtract("30", "days"));
      setEndDate(moment());
    },
  },
  {
    label: "This Month",
    onClick: (setStartDate, setEndDate) => {
      setStartDate(moment().startOf("month"));
      setEndDate(moment().endOf("month"));
    },
  },
  {
    label: "Last Month",
    onClick: (setStartDate, setEndDate) => {
      setStartDate(moment().subtract("1", "month").startOf("month"));
      setEndDate(moment().subtract("1", "month").endOf("month"));
    },
  },
];

function DateRangePicker({
  inputVariant = "outlined",
  startText = "Start date",
  endText = "End date",
  showFormat = "DD/MM/YYYY",
  startingDate = moment().startOf("month"),
  endingDate = moment().endOf("month"),
  onChange = (start, end) => null,
  fullWidth = false,
}) {
  const classes = useStyles();

  const [startDate, setStartDate] = useState(startingDate);
  const [endDate, setEndDate] = useState(endingDate);

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const anchorRef = useRef(null);

  const handleMenuItemClick = (index, func) => {
    setSelectedIndex(index);
    setOpen(false);
    func(setStartDate, setEndDate);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    onChange(startDate, endDate);
  }, [startDate, endDate, onChange]);

  return (
    <Box display={"flex"} justifyContent={"flex-start"} alignItems={"center"}>
      <DatePicker
        value={startDate}
        onChange={(date) => setStartDate(moment(date))}
        inputVariant={inputVariant}
        label={startText}
        clearable={false}
        format={showFormat}
        style={{ marginRight: "5px" }}
        fullWidth={fullWidth}
        className={classes.inputField}
        color={"primary"}
        autoOk
      />
      <DatePicker
        value={endDate}
        onChange={(date) => setEndDate(moment(date))}
        inputVariant={inputVariant}
        label={endText}
        clearable={false}
        format={showFormat}
        fullWidth={fullWidth}
        className={classes.inputField}
        color={"primary"}
        autoOk
      />
      <Button
        size="small"
        aria-controls={open ? "date-range-picker-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="menu"
        onClick={handleToggle}
        className={classes.dropDownBtn}
        ref={anchorRef}
      >
        <ArrowDropDown />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-end"
        className={classes.menuZIndex}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="date-range-picker-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={`picker-${option.label}`}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index, option.onClick)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

export default DateRangePicker;
