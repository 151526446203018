import {
  AppBar, Box, Button, ClickAwayListener, Drawer, Grow, List, MenuItem, MenuList,
  Paper, Popper, Toolbar,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowDown } from "@material-ui/icons";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../assets/Images/headerlogo.svg";
import { menus } from "../../helpers/menus";
import { UnlockAccess } from "../../helpers/RoleBasedRoute";

import { useRef, useState } from "react";

const useStyles = makeStyles(() => ({
  appBar: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  logo: {
    width: "150px",
    maxWidth: "100%",
    objectFit: "cover",
    cursor: "pointer",
  },
  listItem: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  activeMenu: {
    backgroundColor: "#626262",
  },
  menuZIndex: {
    zIndex: "10 !important",
  },
  drawer: {
    "& .MuiDrawer-paper": {
      backgroundColor: "#2E2E2F",
      width: "250px",
    },
  },
  accordianHeader: {
    backgroundColor: "#333333",
    color: "#0E6BAB",
    cursor: "pointer",
    userSelect: "none",
    "& h5": {
      fontWeight: "500",
      textTransform: "uppercase",
      letterSpacing: "2px",
    },
  },
  accordianContent: {
    backgroundColor: "transparent",
    color: "#808080",
    padding: "0.5rem",
    cursor: "pointer",
    "&:hover": {
      borderRadius: "2px",
      backgroundColor: "#252525",
      color: "#F2F2F2",
    },
    "&.active": {
      borderRadius: "2px",
      backgroundColor: "#252525",
      color: "#F2F2F2",
    },
  },
  "@media print": {
    hideOnPrint: {
      display: "none !important",
    },
  },
}));

const Header = () => {
  const classes = useStyles();

  const { pathname } = useLocation();
  const history = useHistory();

  const { keycloak } = useKeycloak();

  const redirectTo = (to) => {
    history.push(to);
  };

  const [userOpen, setUserOpen] = useState(false);
  const [accordian, setAccordian] = useState({
    "accordian-transactions": true,
  });

  const userAnchorRef = useRef(null);

  const handleToggle = (func) => {
    func((prevOpen) => !prevOpen);
  };

  const handleClose = (event, ref, func) => {
    if (ref.current && ref.current.contains(event.target)) {
      return;
    }

    func(false);
  };

  const handleListKeyDown = (event, func) => {
    if (event.key === "Tab") {
      event.preventDefault();
      func(false);
    } else if (event.key === "Escape") {
      func(false);
    }
  };

  let route = null;

  let adminMenu = [];

  menus.forEach((menu) => {
    let roleLists = [...menu.menus.map((_) => _.roles)];

    roleLists = Array.from(new Set(...roleLists));

    if (
      roleLists.some((_) => (keycloak?.realmAccess?.roles ?? []).includes(_))
    ) {
      if (!route) {
        route = menu?.menus?.[0]?.to;
      }

      adminMenu.push(
        <Box key={`accordian-${menu.title.toString().toLocaleLowerCase()}`}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className={classes.accordianHeader}
            padding={"0.5rem"}
            onClick={() => {
              setAccordian((prev) => {
                let accordians = {
                  ...prev,
                };

                if (
                  accordians[
                    `accordian-${menu.title.toString().toLocaleLowerCase()}`
                  ]
                ) {
                  delete accordians[
                    `accordian-${menu.title.toString().toLocaleLowerCase()}`
                  ];
                } else {
                  accordians[
                    `accordian-${menu.title.toString().toLocaleLowerCase()}`
                  ] = true;
                }

                return accordians;
              });
            }}
          >
            <Typography variant="subtitle1">{menu.title}</Typography>
            {accordian[
              `accordian-${menu.title.toString().toLocaleLowerCase()}`
            ] ? (
              <svg
                width="13"
                viewBox="0 0 6 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M3 0.5L5.625 3.125L5.09625 3.65375L3 1.56125L0.90375 3.65375L0.375 3.125L3 0.5Z"
                  fill="#b4b4b4"
                />
              </svg>
            ) : (
              <svg
                width="13"
                viewBox="0 0 6 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
              >
                <path
                  d="M3 3.49994L0.375 0.874941L0.90375 0.346191L3 2.43869L5.09625 0.346191L5.625 0.874941L3 3.49994Z"
                  fill="#b4b4b4"
                />
              </svg>
            )}
          </Box>
          {accordian[
            `accordian-${menu.title.toString().toLocaleLowerCase()}`
          ] && (
            <Box p={1}>
              {menu.menus.map((_) => (
                <UnlockAccess
                  key={`accordian-menu-${_.title.toLocaleLowerCase()}-index`}
                  roles={_.roles ?? []}
                >
                  <Box
                    className={`${classes.accordianContent} ${
                      pathname.includes(_.to) ? "active" : ""
                    }`}
                    onClick={() => redirectTo(_.to)}
                  >
                    {_.title}
                  </Box>
                </UnlockAccess>
              ))}
            </Box>
          )}
        </Box>
      );
    }
  });

  const userMenu = (
    <>
      <Button
        ref={userAnchorRef}
        id="user-actions"
        aria-controls={userOpen ? "user-menu" : undefined}
        aria-expanded={userOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={() => handleToggle(setUserOpen)}
        variant="outlined"
        style={{ marginLeft: "5px" }}
        size="small"
      >
        {keycloak?.tokenParsed?.given_name ?? "Guest"}&nbsp;
        <KeyboardArrowDown />
      </Button>
      <Popper
        open={userOpen}
        anchorEl={userAnchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        className={classes.menuZIndex}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(e) => handleClose(e, userAnchorRef, setUserOpen)}
              >
                <MenuList
                  autoFocusItem={userOpen}
                  id="user-menu"
                  aria-labelledby="user-actions"
                  onKeyDown={(e) => handleListKeyDown(e, setUserOpen)}
                  className={classes.listItem}
                >
                  {pathname.includes("/admin") && (
                    <UnlockAccess
                      roles={[
                        "DF_ADMIN",
                        "bizio_finance_admin",
                        "BROKER_ADMIN",
                        "TRADER",
                      ]}
                    >
                      <MenuItem
                        onClick={() => {
                          redirectTo("/trade");
                          setUserOpen(false);
                        }}
                      >
                        Trade
                      </MenuItem>
                    </UnlockAccess>
                  )}

                  {!pathname.includes("/admin") && (
                    <UnlockAccess
                      roles={[
                        "DF_ADMIN",
                        "bizio_finance_admin",
                        "BROKER_ADMIN",
                        "OPERATIONS",
                      ]}
                    >
                      <MenuItem
                        onClick={() => {
                          redirectTo(route ?? "/admin/orders");
                          setUserOpen(false);
                        }}
                      >
                        Admin
                      </MenuItem>
                    </UnlockAccess>
                  )}

                  <MenuItem onClick={() => keycloak.logout()}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );

  return (
    <Box className={classes.hideOnPrint}>
      <AppBar
        style={{ backgroundColor: "#252525", zIndex: 1250 }}
        position="fixed"
      >
        <Toolbar className={classes.appBar}>
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Typography
              className={classes.logo}
              component="img"
              src={Logo}
              alt="Company logo"
              width="100"
              onClick={() => redirectTo("/trade")}
            />
          </Box>
          <Box>{userMenu}</Box>
        </Toolbar>
      </AppBar>
      {pathname.includes("admin") && (
        <UnlockAccess
          roles={[
            "DF_ADMIN",
            "bizio_finance_admin",
            "BROKER_ADMIN",
            "OPERATIONS",
          ]}
        >
          <Drawer className={classes.drawer} variant="permanent">
            <Toolbar />
            <Box sx={{ overflow: "auto" }}>
              <List>{adminMenu}</List>
            </Box>
          </Drawer>
        </UnlockAccess>
      )}
    </Box>
  );
};

export default Header;
