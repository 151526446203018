import * as types from "./types";

export const loadWatchlists = () => {
  return {
    type: types.constants.GET_WATCH_LIST,
  };
};

export const getWatchlistSelect = (data) => {
  return {
    type: types.constants.GET_WATCH_LIST_SELECT,
    data,
  };
};

export const getExchange = (data) => {
  return {
    type: types.constants.GET_EXCHANGE_LIST,
    data,
  };
};

export const createWatchlist = (data) => {
  return {
    type: types.constants.CREATE_WATCH_LIST,
    data,
  };
};

export const updateWatchlist = (data) => {
  return {
    type: types.constants.UPDATE_WATCH_LIST,
    data,
  };
};

export const getWebSocket = (payload) => {
  return {
    type: types.constants.WEB_SOCKET,
    payload,
  };
};

export const watchlistIndex = (payload) => {
  return {
    type: types.constants.WATCHLIST_INDEX,
    payload,
  };
};

export const activeWatchlistFun = (payload) => {
  return {
    type: types.constants.ACTIVE_WATCHLIST,
    payload,
  };
};

export const addNonExistings = (payload) => {
  return {
    type: types.constants.ADD_NON_EXISTING,
    payload,
  };
};

export const sortWatchlist = (data) => {
  return {
    type: types.constants.SORT_WATCHLIST,
    data,
  };
};
