import { call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import axios from "axios";

const loadEquityWatchlist = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/watchlists/api/v1/getWatchlist?watchlist=ssx-equity-default-test`
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const addEquityWatchlist = async (watchlists) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/watchlists/api/v1/add`,
      watchlists
    );

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

const removeEquityWatchlist = async (watchlists) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/watchlists/api/v1/remove`,
      watchlists
    );
  } catch (error) {
    throw new Error(error);
  }
};

export function* getWatchListSaga() {
  try {
    const watchlist = yield call(loadEquityWatchlist);
    yield put({ type: types.constants.GET_WATCH_LIST_SUCCESS, watchlist });
  } catch (error) {
    yield put({ type: types.constants.GET_WATCH_LIST_ERROR, error });
  }
}

export function* createWatchListSaga(payload) {
  try {
    const data = yield call(addEquityWatchlist, payload.data);
    yield put({
      type: types.constants.CREATE_WATCH_LIST_SUCCESS,
      data,
    });
  } catch (error) {
    yield put({ type: types.constants.CREATE_WATCH_LIST_ERROR, error });
  }
}

export function* updateWatchListSaga(payload) {
  try {
    yield call(removeEquityWatchlist, payload.data);
    yield put({
      type: types.constants.UPDATE_WATCH_LIST_SUCCESS,
      data: payload,
    });
  } catch (error) {
    yield put({ type: types.constants.UPDATE_WATCH_LIST_ERROR, error });
  }
}

export function* getWebSocketListSaga({ payload }) {
  yield put({
    type: types.constants.WEB_SOCKET_SUCCESS,
    webSocketlist: payload,
  });
}
