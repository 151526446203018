import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as fixedIncomeCountAction from "../../../../store/actions/fixedIncomeCount.action";
import OrderTable from "./Outgoing/OrderTable";
import OfferTable from "./Outgoing/OfferTable";
import QuoteTable from "./Outgoing/QuoteTable";

import { Box, styled, Tab, Tabs } from "@material-ui/core";

const StyledTabs = styled((props) => <Tabs {...props} />)({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#0c6fb3",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  minWidth: "50px !important",
  maxWidth: "50px !important",
  paddingInline: "5rem",
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "white",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  "&.Mui-selected": {
    backgroundColor: "#0c6fb3",
    borderRadius: "20px",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "rgba(100, 95, 228, 0.32)",
  },
}));

const OutgoingTab = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const totalRecordData = useSelector((state) => state?.fixedincomecount);

  useEffect(() => {
    dispatch(fixedIncomeCountAction.getOutgoingOfferCountAction());
    dispatch(fixedIncomeCountAction.getOutgoingQuotesCountAction());
  }, [dispatch]);

  return (
    <Box>
      <StyledTabs
        value={activeTab}
        onChange={(e, value) => setActiveTab(value)}
      >
        <StyledTab
          label={`Orders ${
            totalRecordData?.outgoingOrderCount !== 0
              ? totalRecordData?.outgoingOrderCount
              : ""
          }`}
        />
        <StyledTab
          label={`Offers ${
            totalRecordData?.outgoingOfferCount !== 0
              ? totalRecordData?.outgoingOfferCount
              : ""
          }`}
        />
        <StyledTab
          label={`Quotes ${
            totalRecordData?.outgoingQuotesCount !== 0
              ? totalRecordData?.outgoingQuotesCount
              : ""
          }`}
        />
      </StyledTabs>
      {activeTab == 0 && (
        <OrderTable totalRecord={totalRecordData?.outgoingOrderCount} />
      )}
      {activeTab == 1 && (
        <OfferTable totalRecord={totalRecordData?.outgoingOfferCount} />
      )}
      {activeTab == 2 && (
        <QuoteTable totalRecord={totalRecordData?.outgoingQuotesCount} />
      )}
    </Box>
  );
};

export default React.memo(OutgoingTab);
