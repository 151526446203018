import * as types from "./types";

export const loading = () => {
  return { type: types.constants.LOADING_CLIENT };
};

export const sortClient = (data) => {
  return {
    type: types.constants.SORT_CLIENT,
    data,
  };
};

export const filterClient = (search) => {
  return { type: types.constants.FILTER_CLIENT, search };
};

export const loadAllClients = () => {
  return { type: types.constants.GET_CLIENT };
};

export const loadClient = (payload) => {
  return { type: types.constants.GET_CLIENT_BY_ID, payload };
};

export const storeClient = (payload) => {
  return { type: types.constants.STORE_CLIENT, payload };
};

export const updateClient = (payload) => {
  return { type: types.constants.UPDATE_CLIENT, payload };
};

export const deleteClient = (payload) => {
  return { type: types.constants.DELETE_CLIENT, payload };
};

export const loadClientFunds = (client) => {
  return { type: types.constants.GET_CLIENT_FUNDS, client };
};
