import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";

const loadAsyncFundLedgers = async (payload) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/gl/glByAccFundAcc/${payload.fundAccount}/${payload.account}`
    );
    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export function* loadFundLedgers({ payload }) {
  try {
    const balanceSheets = yield call(loadAsyncFundLedgers, payload);
    yield put({
      type: types.constants.LOAD_FUND_LEDGER_SUCCESS,
      payload: balanceSheets,
    });
  } catch (err) {
    yield put({
      type: types.constants.LOAD_FUND_LEDGER_ERROR,
      payload: err.message,
    });
  }
}
