import React, { useCallback, useEffect, useState } from "react";
import OrderFormModal from "../Modal/OrderFormModal";
import OfferFormModal from "../Modal/OfferFormModal";
import * as watchlist from "../../../store/actions/watchlist.actions";
import * as fixedIncome from "../../../store/actions/fixedIncome.action";
import { useDispatch, useSelector } from "react-redux";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import "moment-timezone";
import UpdateOrderModal from "../Modal/UpdateOrderModal";
import refresh from "../../../assets/Icons/refresh.svg";
import swal from "sweetalert";
import { toast } from "react-toastify";

import {
  Box,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";

import { makeStyles, styled } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  marketingOrderHeaderFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: "0.5rem",
    },
  },
  actionBtn: {
    backgroundColor: "#0C6FB3",
    color: "white",
    "&:hover": {
      backgroundColor: "#0C6FB3",
      color: "white",
    },
    "&:focus": {
      backgroundColor: "#0C6FB3",
      color: "white",
    },
  },
  modifyBtn: {
    backgroundColor: "#0c6fb3",
    color: "#cfcfcf",
    "&:hover": {
      backgroundColor: "#0c6fb3",
      color: "#cfcfcf",
    },
    "&:focus": {
      backgroundColor: "#0c6fb3",
      color: "#cfcfcf",
    },
  },
  removeBtn: {
    backgroundColor: "#2e2e2f",
    color: "white",
    marginLeft: theme.spacing(1),
    "&:hover": {
      backgroundColor: "#2e2e2f",
      color: "white",
    },
    "&:focus": {
      backgroundColor: "#2e2e2f",
      color: "white",
    },
  },
}));

const CustomTableCell = styled(TableCell)({
  "&.MuiTableCell-body": {
    borderBottom: "none !important",
    color: "#808080",
  },
});

const MarketOrder = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [ModifyorderModalShow, setModifyOrderModalShow] = useState(false);
  const [orderModalShow, setOrderModalShow] = useState(false);
  const [offerModalShow, setOfferModalShow] = useState(false);
  const [isNewOrder, setIsNewOrder] = useState(false);
  const [bondData, setBondData] = useState({});
  const marketOrderData = useSelector((state) => state?.fixedincomedata);

  useEffect(() => {
    dispatch(fixedIncome.getMarketOrderAction());
    dispatch(fixedIncome.getOutgoingOrderAction());
  }, [dispatch]);

  const openOrderModal = useCallback((e1) => {
    setOrderModalShow(true);
    e1.target.id === "new-market-order"
      ? setIsNewOrder(true)
      : setIsNewOrder(false);
  }, []);

  const openOfferModal = useCallback(
    (obj) => {
      setBondData(obj);
      setOfferModalShow(true);
      dispatch(watchlist.getExchange({ exchangeId: obj?.exchange }));
    },
    [dispatch]
  );

  const modifyOrderModal = useCallback((data) => {
    setBondData(data);
    setModifyOrderModalShow(true);
  }, []);

  const MarketOrderRefresh = useCallback(() => {
    dispatch(fixedIncome.getMarketOrderAction());
  }, [dispatch]);

  function canceledOrder(data) {}

  const updateOrder = (data) => {
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to canceled this order ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let payload = {
          values: [
            { name: "bond", value: data["bond"] },
            { name: "settlement_date", value: data?.settlement_date },
            { name: "nominal", value: data["nominal"]?.toString() },
            { name: "price", value: data["price"]?.toString() },
            { name: "side", value: data["side"]?.toString() },
            { name: "consideration", value: data.consideration?.toString() },
            { name: "csd_cost", value: data.csd_cost?.toString() },
            { name: "allOrNothing", value: data["allOrNothing"] },
            { name: "qty", value: data["qty"]?.toString() },
            { name: "amount", value: data["nominal"]?.toString() },
            {
              name: "order_date",
              value: moment(Date.now())?.format("YYYY/MM/DD"),
            },
            { name: "sendTo", value: data["sendTo"] },
            { name: "quote", value: "no" },
            { name: "party", value: "" },
            { name: "yield", value: data["yield"]?.toString() },
            { name: "maturity_date", value: data["maturity_date"] },
            { name: "status", value: "canceled" },
            { name: "dirty_price", value: data["dirty_price"]?.toString() },
            { name: "clean_price", value: data["clean_price"]?.toString() },
            { name: "state", value: "closed" },
          ],
        };
        dispatch(
          fixedIncome.updateMarketOrderAction({
            payload: JSON.stringify(payload),
            recordID: data?.recordID,
          })
        );
        setTimeout(() => {
          dispatch(fixedIncome.getMarketOrderAction());
        }, 1000);
      }
    });
  };

  return (
    <Box p={2}>
      <Grid container spacing={1} justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h6">Market order</Typography>
        </Grid>
        <Grid item className={classes.marketingOrderHeaderFlex}>
          <Box
            component="img"
            src={refresh}
            height="32"
            width="32"
            alt="Refresh image"
            onClick={MarketOrderRefresh}
          ></Box>
          <Button
            variant="contained"
            id="new-market-order"
            onClick={(e) => openOrderModal(e)}
            size="small"
            className={classes.actionBtn}
          >
            New Market Order
          </Button>
          <Button
            variant="contained"
            id="new-request-quote"
            onClick={(e) => openOrderModal(e)}
            size="small"
            className={classes.actionBtn}
          >
            Request Quote
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Bond ID
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      ISIN
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Coupon (%)
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Mat Date
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Qty
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Yield (%) Bid
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Yield (%) Ask
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      All or Nothing
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Buy/Sell
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      CCY
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Amount
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Date &amp; Time (GMT)
                    </Typography>
                  </div>
                </CustomTableCell>
                <CustomTableCell align="center">
                  <div className={classes.tableHead}>
                    <Typography variant="body2" color="textSecondary">
                      Action
                    </Typography>
                  </div>
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!marketOrderData?.marketorderdata?.response?.set?.length ? (
                <TableRow>
                  <CustomTableCell
                    colSpan={13}
                    className="text-center border-bottom-0 py-3"
                  >
                    No data
                  </CustomTableCell>
                </TableRow>
              ) : (
                marketOrderData?.marketorderdata?.response?.set?.map(
                  (data, indexi) => (
                    <TableRow
                      key={`market-order-${indexi}`}
                      className="pointer"
                    >
                      <CustomTableCell padding="default" align="center">
                        {data?.bond_id ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.isin ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {parseFloat(data?.coupon_rate)?.toFixed(4) ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.maturity_date
                          ? moment(data?.maturity_date).format("DD/MMM/YY")
                          : "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {
                          <CurrencyFormat
                            value={data?.qty ? parseFloat(data?.qty) : 0}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        }
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.side?.toLowerCase() === "buy"
                          ? parseFloat(data?.yield)?.toFixed(4)
                          : ""}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.side?.toLowerCase() !== "buy"
                          ? parseFloat(data?.yield)?.toFixed(4)
                          : ""}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.allOrNothing ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.side ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.ccy_suffix ?? "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.amount ? (
                          <CurrencyFormat
                            value={parseFloat(data?.amount)?.toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        ) : (
                          ""
                        )}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.createdAt
                          ? moment(data?.createdAt)
                              ?.tz("GMT")
                              ?.format("DD/MMM/YY hh:mm:ss A")
                          : "-"}
                      </CustomTableCell>
                      <CustomTableCell padding="default" align="center">
                        {data?.rem_qty !== "0" ? (
                          <>
                            <Button
                              variant="contained"
                              size="small"
                              className={classes.modifyBtn}
                              onClick={() => modifyOrderModal(data)}
                            >
                              Make offer
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={(e) => canceledOrder(data)}
                              className={classes.removeBtn}
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <Button
                            id="new-market-order"
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={(e) => openOfferModal(data)}
                          >
                            Make offer
                          </Button>
                        )}
                      </CustomTableCell>
                    </TableRow>
                  )
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <OrderFormModal
        show={orderModalShow}
        onHide={() => setOrderModalShow(false)}
        isneworder={isNewOrder}
      />
      <UpdateOrderModal
        show={ModifyorderModalShow}
        onHide={() => setModifyOrderModalShow(false)}
        bond={bondData}
      />
      <OfferFormModal
        show={offerModalShow}
        onHide={() => setOfferModalShow(false)}
        bond={bondData}
      />
    </Box>
  );
};

export default React.memo(MarketOrder);
