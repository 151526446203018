import React from "react";

const useDynamicFavicon = () => {
  const mqListener = (e) => {
    setFavicon(e.matches);
  };
  const setFavicon = (dark) => {
    let fav = document.querySelector("link[rel~='icon']");
    let ati = document.querySelector("link[rel~='apple-touch-icon']");
    if (fav) fav.href = !dark ? "/favicon-dark.png" : "favicon-light.png";
    if (ati) ati.href = !dark ? "/favicon-dark.png" : "favicon-light.png";
  };

  React.useEffect(() => {
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    setFavicon(darkThemeMq.matches);

    darkThemeMq.addEventListener("change", mqListener);
    return () => {
      darkThemeMq.removeEventListener("change", mqListener);
    };
  }, []);
};

export default useDynamicFavicon;
