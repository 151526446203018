import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncOrders = async (payload) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/group-accounts/eq-orders/${payload.fund.id}/${payload.startDate}/${payload.endDate}`
    );
    return data;
  } catch (error) {
    throw new Error(
      error?.response?.status ?? 0 >= 500
        ? "Something went wrong! Please try again."
        : error?.data?.message ?? "Error"
    );
  }
};

const storeAsyncOrder = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/orders`,
      payload
    );

    return {
      error: false,
      data: data.message ?? "Order created successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncOrder = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/orders/${payload}`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncOrderTrades = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/trades/byOrder/${payload}`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const updateAsyncOrder = async (payload) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/orders/${payload.id}`,
      payload.data
    );

    return {
      error: false,
      data: data.message ?? "Order updated successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const deleteAsyncOrder = async ({ payload }) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/orders/${payload}`
    );

    return data.message ?? "Order deleted successfully.";
  } catch (error) {
    throw new Error(
      error?.response?.status ?? 0 >= 500
        ? "Something went wrong! Please try again."
        : error?.data?.message ?? "Error"
    );
  }
};

const cancelAsyncOrder = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/orders/${payload}/CANCEL`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const closeAsyncOrder = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/df-mo/api/v1/orders/${payload}/CLOSE`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

export function* loadOrders({ payload }) {
  try {
    const currencies = yield call(loadAsyncOrders, payload);
    yield put({
      type: types.constants.GET_ORDER_SUCCESS,
      payload: currencies,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_ORDER_ERROR,
      payload: err.message,
    });
  }
}

export function* storeOrder({ payload }) {
  const response = yield call(storeAsyncOrder, payload.data);

  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.STORE_ORDER_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STORE_ORDER_ERROR,
      payload: response.data,
    });
  }
}

export function* loadOrder(payload) {
  const response = yield call(loadAsyncOrder, payload);
  if (!response.error) {
    yield put({
      type: types.constants.GET_ORDER_BY_ID_SUCCESS,
      order: response.data,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.GET_ORDER_BY_ID_ERROR,
      payload: response.data,
    });
  }
}

export function* loadOrderTrades(payload) {
  const response = yield call(loadAsyncOrderTrades, payload);
  if (!response.error) {
    yield put({
      type: types.constants.ORDER_TRADE_SUCCESS,
      trades: response.data,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.ORDER_TRADE_ERROR,
      payload: response.data,
    });
  }
}

export function* updateOrder({ payload }) {
  const response = yield call(updateAsyncOrder, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({ type: types.constants.UPDATE_ORDER_SUCCESS });
    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.UPDATE_ORDER_ERROR,
      payload: response.data,
    });
  }
}

export function* deleteOrder(payload) {
  try {
    const message = yield call(deleteAsyncOrder, payload);
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_ORDER_SUCCESS,
      id: payload.payload,
    });
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_ORDER_ERROR,
      payload: err.message,
    });
  }
}

export function* cancelOrder({ payload }) {
  try {
    yield call(cancelAsyncOrder, payload.id);

    toast.success("Order cancelled successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({
      type: types.constants.ORDER_CANCEL_SUCCESS,
    });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.ORDER_CANCEL_ERROR,
      payload: err.message,
    });
  }
}

export function* closeOrder({ payload }) {
  try {
    yield call(closeAsyncOrder, payload.id);

    toast.success("Order closed successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({
      type: types.constants.ORDER_CLOSE_SUCCESS,
    });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.ORDER_CLOSE_ERROR,
      payload: err.message,
    });
  }
}
