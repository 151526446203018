import { useFormik } from "formik";
import * as Yup from "yup";
import CurrencyFormat from "react-currency-format";
import * as fixedIncome from "../../../store/actions/fixedIncome.action";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import { toast } from "react-toastify";

import { useKeycloak } from "@react-keycloak/web";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  Typography,
  Box,
  Grid,
  TextField,
} from "@material-ui/core";
import NumericFormatInput from "../../../components/NumericFormatInput";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#252525",
      color: "#dedede",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px",
    },
    "& .MuiDialogContent-root": {
      color: "#dedede !important",
    },
  },
  dialogActions: {
    margin: "0 auto",
    marginTop: "2rem",
    "& > *:first-child": {
      marginRight: "0.5rem",
    },
  },
  actionBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#145582",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#145582",
    },
    "&:disabled": {
      backgroundColor: "#083a5e",
      color: "#888888",
    },
  },
  cancelBtn: {
    textTransform: "uppercase",
    color: "#dedede",
    backgroundColor: "#3d3d3d",
    "&:hover": {
      color: "#dedede",
      backgroundColor: "#3d3d3d",
    },
  },
  dialogContentFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "1rem 0",
    "& > *": {
      padding: "0.5rem",
      border: "1px solid grey",
      borderRadius: "2px",
      flexGrow: "1",
      flexShrink: "0 !important",
    },
  },
  reviewBadge: {
    backgroundColor: "#22312c",
    display: "inline-flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.7rem 1.5rem",
    borderRadius: "10px",
    minWidth: "150px",
  },
}));

const OfferFormModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { keycloak } = useKeycloak();

  const initialValues = {
    bond: "",
    order: "",
    offer_quantity: "",
    offer_yield: "",
    offer_amount: "",
    bondDirtyPrice: "",
    clean_price: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      bond: Yup.string(),
      order: Yup.string(),
      offer_amount:
        props?.bond?.allOrNothing?.toUpperCase() !== "YES"
          ? Yup.number()
              .required("This field is required.")
              .positive("Amount must be a positive number and not zero")
          : Yup.string(),
      bondDirtyPrice: Yup.string(),
      offer_quantity:
        props?.bond?.allOrNothing?.toUpperCase() !== "YES"
          ? Yup.number()
              .integer()
              .positive("Quantity must be a positive number and not zero")
              .required("This field is required.")
              .test(
                "",
                `Quantity must be a less than or equal to ${
                  props.bond.offer_quantity
                    ? props.bond.offer_quantity
                    : props.bond.rem_qty
                }`,
                (value) =>
                  value <= props?.bond?.offer_quantity
                    ? parseFloat(props.bond.offer_quantity)
                    : parseFloat(props.bond.rem_qty)
              )
          : Yup.string(),
      offer_yield: Yup.string().required("This field is required."),
      clean_price: Yup.string(),
    }),
    onSubmit: (e) => {
      let newquantity =
        props?.bond?.allOrNothing?.toUpperCase() === "YES"
          ? props?.bond?.offer_quantity
            ? props?.bond?.offer_quantity
            : props?.bond?.rem_qty
          : e?.offer_quantity;
      let price =
        parseFloat(e["clean_price"])?.toFixed(2) * Math.trunc(newquantity);
      let dirty_price = parseFloat(
        parseFloat(e["bondDirtyPrice"]) / 100
      )?.toFixed(4);

      let orderid = props?.outgonigorder
        ? props?.bond?.order
        : props?.bond?.recordID;

      let consideration =
        parseFloat(e["bondDirtyPrice"])?.toFixed(4) * Math.trunc(newquantity);

      let payload = {
        values: [
          { name: "order", value: orderid },
          { name: "offer_quantity", value: newquantity?.toString() },
          { name: "offer_yield", value: e?.offer_yield?.toString() },
          { name: "offer_amount", value: price.toString() },
          { name: "status", value: "open" },
          {
            name: "order_amount",
            value: props?.outgonigorder
              ? props?.bond?.order_amount.toString()
              : props?.bond?.amount?.toString(),
          },
          { name: "dirty_price", value: dirty_price?.toString() },
          { name: "clean_price", value: e["clean_price"]?.toString() },
          { name: "state", value: props?.outgonigorder ? "modified" : "new" },
          {
            name: "offer_consideration",
            value: consideration.toString(),
          },
        ],
      };
      if (props?.outgonigorder) {
        fetch(
          `${process.env.REACT_APP_API_URL}api/compose/namespace/${process.env.REACT_APP_NAMESPACE_ID}/module/${process.env.REACT_APP_FI_OFFER_MODULE_ID}/record/${props?.bond?.recordID}`,
          {
            method: "get",
            headers: new Headers({
              authorization: "Bearer " + keycloak.token,
              "Content-Type": "application/json",
            }),
          }
        )
          .then((response) => response.json())
          .then((res) => {
            if (res?.response) {
              let setData = res.response.values;
              setData.forEach((data) => {
                setData[data.name] = data.value;
              });
              if (setData.status === "open") {
                dispatch(
                  fixedIncome.updateOfferOrderAction({
                    recordID: props?.bond.recordID,
                    orderdata: JSON.stringify(payload),
                    counter_offer_check: true,
                  })
                );
                handleClose();
              } else {
                toast.error(
                  `This offer can't be modified, because it is already ${setData.status}`,
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                handleClose();
              }
            }
          });
      } else {
        dispatch(fixedIncome.createOfferOrderAction(JSON.stringify(payload)));
        handleClose();
      }
    },
  });

  const handleClose = () => {
    formik.resetForm({ values: initialValues });
    props.onHide();
  };

  useEffect(() => {
    if (props.show && props?.outgonigorder) {
      formik.setFieldValue("offer_yield", props?.bond?.offer_yield);
      formik.setFieldValue("offer_quantity", props?.bond?.offer_quantity);
      formik.setFieldValue("offer_amount", props?.bond?.offer_amount);
      onYieldChangeAmonut(
        props?.bond?.offer_yield,
        props?.bond?.offer_quantity
      );
    }
  }, [props.show]);

  const onYieldPriceChange = (e) => {
    e.preventDefault();
    if (e?.target?.value) onYieldChangeAmonut(e?.target?.value);
  };

  const onYieldChangeAmonut = (val, qty) => {
    if (
      props?.bond?.isin &&
      props?.bond?.ccy_suffix &&
      props?.bond?.coupon_rate &&
      props?.bond?.maturity_date &&
      props?.bond?.issued_date
    ) {
      formik.setFieldValue("offer_yield", val);
      if (val) {
        let body = {
          name: props?.bond?.isin,
          yield: val,
          ccy: props?.bond?.ccy_suffix,
          coupon: props?.bond?.coupon_rate,
          maturity: props?.bond?.maturity_date,
          effectiveDate: props?.bond?.issued_date,
        };
      } else {
        formik.setFieldValue("offer_amount", "");
      }
    }
  };

  const onOfferAmountChange = (e) => {
    e.preventDefault();
    formik.setFieldValue("offer_amount", e?.target?.value);
    if (
      e?.target?.value &&
      e?.target?.value > 0 &&
      formik.values["bondDirtyPrice"]
    ) {
      let offerQuantity = parseFloat(
        parseFloat(e?.target?.value) /
          parseFloat(formik.values["bondDirtyPrice"])?.toFixed(2)
      );
      formik.setFieldValue("offer_quantity", Math.trunc(offerQuantity));
    }
  };

  const onOfferQuantityChange = (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      formik.setFieldValue("offer_quantity", e?.target?.value);
    }
    if (e?.target?.value && formik.values["bondDirtyPrice"]) {
      let offeramount =
        parseFloat(formik.values["bondDirtyPrice"]) *
        parseFloat(e?.target?.value);
      console.log(
        formik.values["bondDirtyPrice"],
        e?.target?.value,
        offeramount
      );
      formik.setFieldValue("offer_amount", parseFloat(offeramount)?.toFixed(2));
    } else {
      formik.setFieldValue("offer_amount", "");
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={handleClose}
      className={classes.dialog}
      disableBackdropClick
    >
      <DialogTitle>
        {props?.outgonigorder ? "Modify" : "Place"} Offer
      </DialogTitle>
      <DialogContent>
        <Box className={classes.dialogContentFlex}>
          <Box>
            <Typography variant="body1" component="h6">
              Bond ID
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.bond_id ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Yield(%)
            </Typography>
            <Typography variant="body2" component="p">
              {parseFloat(props?.bond?.yield)?.toFixed(4) ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              ISIN
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.isin ?? "-"}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Quantity
            </Typography>
            <Typography variant="body2" component="p">
              <CurrencyFormat
                value={parseFloat(
                  props?.outgonigorder
                    ? props?.bond?.offer_quantity
                    : props?.bond?.rem_qty
                )}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Nominal
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.nominal ? (
                <CurrencyFormat
                  value={parseFloat(props?.bond?.nominal)?.toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              ) : (
                "-"
              )}
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" component="h6">
              Ccy
            </Typography>
            <Typography variant="body2" component="p">
              {props?.bond?.ccy_suffix ?? "-"}
            </Typography>
          </Box>
        </Box>
        <Typography variant="h6" component="h6">
          My Offer
        </Typography>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                type="text"
                InputProps={{
                  inputComponent: NumericFormatInput,
                }}
                error={
                  formik.errors["offer_yield"] && formik.touched["offer_yield"]
                }
                label="Yield (%)"
                name="offer_yield"
                helperText={
                  formik.touched["offer_yield"] && formik.errors["offer_yield"]
                }
                onChange={(e) => onYieldPriceChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values["offer_yield"]}
                fullWidth={true}
              />
            </Grid>
            {props?.bond?.allOrNothing?.toUpperCase() !== "YES" && (
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  type="text"
                  error={
                    formik.errors["offer_quantity"] &&
                    formik.touched["offer_quantity"]
                  }
                  label="Quantity"
                  name="offer_quantity"
                  helperText={
                    formik.touched["offer_quantity"] &&
                    formik.errors["offer_quantity"]
                  }
                  onChange={(e) => onOfferQuantityChange(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values["offer_quantity"]}
                  fullWidth={true}
                  InputProps={{
                    inputComponent: NumericFormatInput,
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                type="text"
                error={
                  formik.errors["offer_amount"] &&
                  formik.touched["offer_amount"]
                }
                label="Offer amount"
                name="offer_amount"
                helperText={
                  formik.touched["offer_amount"] &&
                  formik.errors["offer_amount"]
                }
                onChange={(e) => onOfferAmountChange(e)}
                onBlur={formik.handleBlur}
                value={formik.values["offer_amount"]}
                fullWidth={true}
                disabled={props?.bond?.allOrNothing?.toUpperCase() === "YES"}
                InputProps={{
                  inputComponent: NumericFormatInput,
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.dialogActions}>
            <Button
              variant="contained"
              type="submit"
              className={classes.actionBtn}
              size="small"
            >
              Review order
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.cancelBtn}
              size="small"
            >
              Cancel
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(OfferFormModal);
