import { call, put } from "@redux-saga/core/effects";
import * as types from "../actions/types";
import axios from "axios";
import { toast } from "react-toastify";
import { errorMessage } from "../../helpers/utilities";

const loadAsyncBrokers = async () => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/brokers`
    );
    return data;
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const storeAsyncBroker = async (payload) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/brokers`,
      payload
    );

    return {
      error: false,
      data: data.message ?? "Broker created successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const loadAsyncBroker = async ({ payload }) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/brokers/${payload}`
    );

    return {
      error: false,
      data,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const updateAsyncBroker = async (payload) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/brokers/${payload.id}`,
      payload.data
    );

    return {
      error: false,
      data: data.message ?? "Broker updated successfully.",
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

const deleteAsyncBroker = async ({ payload }) => {
  try {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/brokers/${payload}`
    );

    return data.message ?? "Broker deleted successfully.";
  } catch (error) {
    throw new Error(
      typeof error === "object"
        ? "Something went wrong! Please try again."
        : error
    );
  }
};

const toggleAsyncBrokerStatus = async ({ payload }) => {
  try {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/clients/api/v1/brokers/${payload.id}/disable/${payload.type}`
    );

    return {
      error: false,
      data:
        data.message ??
        `Broker ${payload.type ? "disabled" : "enabled"} successfully.`,
    };
  } catch (error) {
    return {
      error: true,
      data: error?.response?.data,
      status: error?.response?.status ?? 0,
    };
  }
};

export function* loadBrokers() {
  try {
    const brokers = yield call(loadAsyncBrokers);
    yield put({
      type: types.constants.GET_BROKER_SUCCESS,
      payload: brokers,
    });
  } catch (err) {
    yield put({
      type: types.constants.GET_BROKER_ERROR,
      payload: err.message,
    });
  }
}

export function* storeBroker({ payload }) {
  const response = yield call(storeAsyncBroker, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({ type: types.constants.STORE_BROKER_SUCCESS });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.STORE_BROKER_ERROR,
      payload: response.data,
    });
  }
}

export function* loadBroker(payload) {
  const response = yield call(loadAsyncBroker, payload);

  if (!response.error) {
    yield put({
      type: types.constants.GET_BROKER_BY_ID_SUCCESS,
      broker: response.data,
    });
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.GET_BROKER_BY_ID_ERROR,
      payload: response.data,
    });
  }
}

export function* updateBroker({ payload }) {
  const response = yield call(updateAsyncBroker, payload.data);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({ type: types.constants.UPDATE_BROKER_SUCCESS });

    if (payload.onSuccess && typeof payload.onSuccess === "function") {
      payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    yield put({
      type: types.constants.UPDATE_BROKER_ERROR,
      payload: response.data,
    });
  }
}

export function* deleteBroker(payload) {
  try {
    const message = yield call(deleteAsyncBroker, payload);

    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    yield put({
      type: types.constants.DELETE_BROKER_SUCCESS,
      id: payload.payload,
    });
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.DELETE_BROKER_ERROR,
      payload: err.message,
    });
  }
}

export function* toggleBrokerStatus(payload) {
  const response = yield call(toggleAsyncBrokerStatus, payload);
  if (!response.error) {
    toast.success(response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put({
      type: types.constants.TOGGLE_BOND_SUCCESS_STATUS,
      id: payload.payload.id,
      to: payload.payload.type,
    });
    if (
      payload.payload.onSuccess &&
      typeof payload.payload.onSuccess === "function"
    ) {
      payload.payload.onSuccess();
    }
  } else {
    let message = "Something went wrong! Please try again.";
    if (response.status >= 400 && response.status <= 499) {
      message = errorMessage(response.status);
    }
    if ("message" in response.data) {
      message = response.data.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });

    yield put({
      type: types.constants.TOGGLE_BOND_ERROR_STATUS,
      payload: response.data,
    });
  }
}
